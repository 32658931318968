import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from "@material-ui/core/es/Divider/Divider";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCardIcon from '@material-ui/icons/CreditCardRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

 
import HelpIcon from '@material-ui/icons/LiveHelpRounded';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    }
  }
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DivPagoConTarjeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:""
    }
  }

  componentDidMount() {

  }

  renderMisTarjetas = () => {
    const {classes, expanded, mmEG, aaEG, cvvEG, disableBtnPay, userNts, handleChangePanel, handleChange, createToken, deleteNt} = this.props;

    if (Object.keys(userNts).length > 0) {
      return(
        <div style={{width:'100%'}}>
          <p style={{color: 'rgba(0, 0, 0, 0.54)', fontWeight: '400'}}>Selecciona la tarjeta con la que realizarás el pago.</p>
          {userNts.map(function (arr, index) {

            var nt = atob(arr.nt);
            var ntX = "XXXXXXXXXXXX"+nt.substr(nt.length -4);

            return(
              <MuiThemeProvider theme={ExpansionPanels} key={index}>
                <ExpansionPanel expanded={expanded === nt} onChange={handleChangePanel(arr)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading} style={{color:'#737373'}}><CreditCardIcon style={{verticalAlign: 'middle'}}/> {ntX}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{display:'inline'}}>
                    <div className="row" style={{width:'100%', margin: '0px'}}>
                      <div className="col-md-4">
                        <TextField
                          error={mmEG}
                          label="Caducidad Mes"
                          placeholder="MM"
                          id="mm"
                          onChange={handleChange('mm')}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{maxLength: 2, style:{textAlign:'center'}}}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          error={aaEG}
                          label="Caducidad Año"
                          placeholder="AA"
                          id="aa"
                          onChange={handleChange('aa')}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{maxLength: 2, style:{textAlign:'center'}}}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          error={cvvEG}
                          label="Código Seguridad CVV"
                          placeholder="CVV"
                          id="cvv"
                          onChange={handleChange('cvv')}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          type="password"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{maxLength: 3, style:{textAlign:'center'}}}
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <MuiThemeProvider theme={FormButtons}>
                          <Button disabled={disableBtnPay} variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>createToken()}>
                            Pagar
                            <DollarIcon className={classes.rightIcon}/>
                          </Button>
                          <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>deleteNt(arr.ntId)}>
                            <DeleteIcon className={classes.leftIcon}/>
                            Eliminar
                          </Button>
                        </MuiThemeProvider>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </MuiThemeProvider>
            )
          })}
        </div>
      )
    } else {
      return(
        <div className="text-center" style={{marginTop:'40px'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
          <h4>Sin tarjetas registradas</h4>
        </div>
      )
    }
  }

  render() {
    const {classes, dataUser, payMonths, payYear, montoPagar, payMonthsE, payYearE, ntE, mmE, aaE, cvvE, nombreTitularE, disableBtnPay, months, registerNt, suscription, handleChange, handleChangeMultiple, createToken, handleChangeCB, disableSelectMonths, handleOpenTour, mobile, checkPermisos, userCasasLocales, casaLocal, casaLocalE, montoPagarSinComision} = this.props;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;

    var styleBtnHelp = {position: 'absolute', top: '-6px', right: '5px'};
    if(mobile === true) {
      styleBtnHelp = {margin: 'auto'};
    }

    var selectInputMonth = <div className="col-md-4" style={{marginTop:'17px'}} data-tour="step2">
      <FormControl variant="outlined" className={classes.formControl} error={payMonthsE}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="outlined-months"
          style={{
            background: 'inherit',
            padding: '0px 5px 1px 5px'
          }}

        >
          Mes a pagar
        </InputLabel>
        <Select
          style={{width:'100%'}}
          value={payMonths}
          onChange={handleChangeMultiple}
          input={<OutlinedInput labelWidth={0} id="outlined-months" disabled={monthsDisabled} error={payMonthsE}/>}
        >
          {months.map(function (arr, index) {

            if(index === 0) {
              return (
                <MenuItem key={index} value={arr}>
                  {arr}
                </MenuItem>
              )
            }
          })}
        </Select>
      </FormControl>
    </div>

    var residencialPlaza = dataUser.residencialPlaza;
    var monthsDisabled = disableSelectMonths;
    if(residencialPlaza === "plaza") {
      monthsDisabled = true;

      selectInputMonth = <div className="col-md-4" data-tour="step2"><TextField
        disabled={monthsDisabled}
        error={payMonthsE}
        label="Mes a pagar"
        placeholder="Mes a pagar"
        id="payMonths"
        margin="normal"
        variant="outlined"
        style={{width:'100%'}}
        value={payMonths}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </div>
    }

    var casaLocalLabel = "Casa"
    if(checkPermisos("125")) {
      casaLocalLabel = "Local";
    }

    var infoComision = "";
    var separatorComision = "";

    if(dataUser.comisiones === '1') {
      infoComision = <div style={{float:'left', fontSize:'14px', width:'260px', lineHeight:'16px', marginTop:'4px'}}>
        <font style={{color:'#0345ea'}}>El pago con tarjeta genera una comisión extra de <b>2.9% + $2.5mxn + IVA</b></font>
      </div>;
      separatorComision = <font style={{color:'#0345ea', marginLeft: '5px'}}>|</font>;
    }

    var divMontoSinComision = "";
    var colMdNombreTitular = "col-md-9";
    if(dataUser.comisiones === "1") {
      divMontoSinComision = <div className="col-md-3">
        <TextField
          disabled={true}
          label="Monto sin comisión"
          placeholder="$$$"
          id="montoPagarSinComision"
          margin="normal"
          variant="outlined"
          style={{width:'100%'}}
          value={montoPagarSinComision}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      colMdNombreTitular = "col-md-6";
    }

    return (
      <MuiThemeProvider theme={FormInputs}>
        <form id="formDivPago">
          <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
            <div className="col-md-12">
              <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
              <MuiThemeProvider theme={FormButtons}>
                <Button variant="contained" color="primary" className={classes.button} style={styleBtnHelp} onClick={()=>handleOpenTour("pagoEnLinea")} data-tour="step7">
                  ¿Como realizo mi pago con tarjeta?
                  <HelpIcon className={classes.rightIcon}/>
                </Button>
              </MuiThemeProvider>
            </div>
            <div className="col-md-4" style={{marginTop:'17px'}} data-tour="step1">
              <FormControl variant="outlined" className={classes.formControl} error={payYearE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="payYear"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  Año
                </InputLabel>
                <Select
                  value={payYear}
                  onChange={handleChange('payYear')}
                  input={
                    <OutlinedInput id="payYear" labelWidth={0}/>
                  }
                >
                  <MenuItem value={nextYear}>{nextYear}</MenuItem>
                  <MenuItem value={actualYear}>{actualYear}</MenuItem>
                  <MenuItem value={passYear}>{passYear}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4" style={{marginTop:'17px'}}>
              <FormControl variant="outlined" className={classes.formControl} error={casaLocalE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="casaLocal"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  {casaLocalLabel}
                </InputLabel>
                <Select
                  value={casaLocal}
                  onChange={handleChange('casaLocal')}
                  input={
                    <OutlinedInput id="casaLocal" labelWidth={0} error={casaLocalE}/>
                  }
                >
                  {userCasasLocales.map(function (arr, index) {

                    return (
                      <MenuItem key={index} value={arr.casaLocalId}>
                        {arr.casaLocal}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
            {selectInputMonth}
          </div>
          <div data-tour="step4">
            <div className="row" style={{paddingTop:'20px', margin:'0px'}}>
              <div className="col-md-12" style={{display:'flex'}}>
                <span>
                  <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>
                    <div style={{width:'226px', float:'left'}}>
                      Pago con tarjeta
                      {separatorComision}
                    </div>
                    {infoComision}
                  </h3>
                </span>
                <span style={{position:'absolute', right:'11px'}}><img src="assets/img/iconos/visa-mastercard-amex.png" style={{width: '100px', marginBottom: '15px'}} alt="img"/></span>
              </div>
              <div className="col-md-9" style={{marginTop:'20px'}}>
                <TextField
                  error={ntE}
                  label="Número de Tarjeta"
                  placeholder="Número de Tarjeta"
                  id="nt"
                  onChange={handleChange('nt')}
                  margin="normal"
                  variant="outlined"
                  style={{width:'100%'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{maxLength: 16}}
                />
              </div>
            </div>
            <div className="row" style={{margin: '0px'}}>
              <div className="col-md-3">
                <TextField
                  error={mmE}
                  label="Caducidad Mes"
                  placeholder="MM"
                  id="mm"
                  onChange={handleChange('mm')}
                  margin="normal"
                  variant="outlined"
                  style={{width:'100%'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{maxLength: 2, style:{textAlign:'center'}}}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  error={aaE}
                  label="Caducidad Año"
                  placeholder="AA"
                  id="aa"
                  onChange={handleChange('aa')}
                  margin="normal"
                  variant="outlined"
                  style={{width:'100%'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{maxLength: 2, style:{textAlign:'center'}}}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  error={cvvE}
                  label="Código Seguridad CVV"
                  placeholder="CVV"
                  id="cvv"
                  onChange={handleChange('cvv')}
                  margin="normal"
                  variant="outlined"
                  style={{width:'100%'}}
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{maxLength: 3, style:{textAlign:'center'}}}
                />
              </div>
            </div>
            <div className="row" style={{margin: '0px'}}>
              <div className={colMdNombreTitular}>
                <TextField
                  error={nombreTitularE}
                  label="Nombre del Titular"
                  placeholder="Nombre del Titular"
                  id="nombre"
                  onChange={handleChange('nombreTitular')}
                  margin="normal"
                  variant="outlined"
                  style={{width:'100%'}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              {divMontoSinComision}
              <div className="col-md-3">
                <TextField
                  disabled={true}
                  label="Monto a Pagar"
                  placeholder="$$$"
                  id="montoPagar"
                  onChange={handleChange('montoPagar')}
                  margin="normal"
                  variant="outlined"
                  style={{width:'100%'}}
                  value={montoPagar}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-md-12">
                <FormGroup row>
                  <FormControlLabel
                    data-tour="step5"
                    control={
                      <Checkbox
                        checked={registerNt}
                        onChange={handleChangeCB('registerNt')}
                        value="registerNt"
                        color="primary"
                      />
                    }
                    label="Registrar mi numero de tarjeta"
                  />
                  <FormControlLabel
                    data-tour="step6"
                    control={
                      <Checkbox
                        checked={suscription}
                        onChange={handleChangeCB('suscription')}
                        value="suscription"
                        color="primary"/>
                    }
                    label="Domiciliar cargo a mi tarjeta"
                  />
                </FormGroup>
              </div>
              <div className="col-md-12 text-center">
                <MuiThemeProvider theme={FormButtons}>
                  <Button disabled={disableBtnPay} variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>createToken()} data-tour="step7">
                    Pagar
                    <DollarIcon className={classes.rightIcon}/>
                  </Button>
                </MuiThemeProvider>
              </div>
            </div>
          </div>
          <div className="row" style={{margin: '0px'}}>
            <Divider/>
            <div className="col-md-12">
              <h3 className="darkGrayUpera">Mis tarjetas registradas</h3>
            </div>
            <div className="col-md-12">
              {this.renderMisTarjetas()}
            </div>
          </div>
        </form>
      </MuiThemeProvider>
    );
  }
}

DivPagoConTarjeta.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivPagoConTarjeta);
