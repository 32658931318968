import { container, title } from "../../material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-50px 0px 0px",
    borderRadius: "6px",
  },
  textList: {
    color:'#131316'
  },
  textListBlue: {
    color:'#036adb'
  },
  textListGreen: {
    color:'#22b573'
  },
  textListGray: {
    fontFamily: 'Poppins Medium !important',
    color:'#7e7e84',
    fontSize:'15px !important'
  },
  textListDarkGray: {
    color:'#383838',
    fontFamily: 'Poppins Regular',
    fontSize:'15px'
  }
};

export default landingPageStyle;
