import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import EncuestasIcon from '@material-ui/icons/FeedbackRounded';
import ResponderIcon from '@material-ui/icons/ThumbsUpDownRounded';
import StarIcon from '@material-ui/icons/StarRateRounded';
import styled from 'styled-components';
import DivCrearEncuestas from "./DivCrearEncuestas";
import DivMisEncuestas from "./DivMisEncuestas";
import DivMensajesDifusion from "../comunicacionInterna/DivComunicacionInterna";
import DivResultados from "./DivResultados";


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '20px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivEncuestas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      destinatarios: [],
      grupos: [],
      encuestas: [],
      resultados:[]
    }
  }


  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected, checkPermisos} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getEncuestasInfo();
      if(checkPermisos("135") && !checkPermisos("133")) {
        this.setState({value:1});
      }
    }
  }

  getEncuestasInfo = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos} = this.props;
    var userId = dataUser.userId;

    getData(ajaxSource, "encuestas/encuestasInfo.php",{
      userId:userId,
      }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var destinatarios = data[0];
        var grupos = data[1];
        var encuestas = data[3];
        var resultados = data[4];

        self.setState({destinatarios:destinatarios,grupos:grupos,encuestas:encuestas,resultados:resultados}, ()=> {
          loadedPage(true);
        });
      }
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };


  render() {
    const {theme, dataUser, ajaxSource, cerrarNotificacion, checkPermisos, crearNotificacion, getData, crearAlerta, cerrarAlerta, handleOpenTour, dialogStateForTour, handleOptionSelected, mobile, prodEnv} = this.props;
    const {value, destinatarios, grupos, encuestas, resultados} = this.state;

    var userUperaPerfil = dataUser.userUperaPerfil;
    var numberTab1 = "01";
    var numberTab2 = "02";

    if(userUperaPerfil === "7" || userUperaPerfil === "8") {
      numberTab2 = "01";
    }

    var tabCrearEncuestas = <div></div>;
    var containerDivCrearEncuestas = <div></div>;
    if(checkPermisos("1") || checkPermisos("132")) {
      tabCrearEncuestas = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <EncuestasIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>{numberTab1}</div>
            <div className="textTab"><b>Crear </b><br/><div className="textTabSub">Encuestas.</div></div>
          </div>
        </div>
      } />

      containerDivCrearEncuestas =
        <div>
          <DivCrearEncuestas
            tab={0}
            index={value}
            ajaxSource={ajaxSource}
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            destinatarios={grupos}
            usuarios={destinatarios}
            getEncuestasInfo={this.getEncuestasInfo}
            prodEnv={prodEnv}
          />
        </div>
    }

    var tabEncuestas = <div></div>;
    var containerDivEncuestas = <div></div>;
    if(checkPermisos("1") || checkPermisos("135")) {
      tabEncuestas = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <ResponderIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>{numberTab2}</div>
            <div className="textTab"><b>Responder </b><br/><div className="textTabSub">Encuestas.</div></div>
          </div>
        </div>
      } />

      containerDivEncuestas =
        <div>
          <DivMisEncuestas
            tab={1}
            encuestas={encuestas}
            index={value}
            ajaxSource={ajaxSource}
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            getEncuestasInfo={this.getEncuestasInfo}
            prodEnv={prodEnv}
          />
        </div>
    }

    var tabResultados = <div></div>;
    var containerResultados = <div></div>;
    if(checkPermisos("1") || checkPermisos("137")) {
      tabResultados = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <StarIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>03</div>
            <div className="textTab"><b>Resultados </b><br/><div className="textTabSub">Encuestas.</div></div>
          </div>
        </div>
      } />

      containerResultados =
        <div>
          <DivResultados
            tab={2}
            resultados={resultados}
            index={value}
            ajaxSource={ajaxSource}
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            getEncuestasInfo={this.getEncuestasInfo}
            prodEnv={prodEnv}
          />
        </div>
    }

    return (
      <MuiThemeProvider theme={tabs}>
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabCrearEncuestas}
            {tabEncuestas}
            {tabResultados}
          </StyledTabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
          style={{overFlow:'hidden'}}
          slideClassName="slideOverflow"
        >
          <TabContainer dir={theme.direction}>
            {containerDivCrearEncuestas}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivEncuestas}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerResultados}
          </TabContainer>
        </SwipeableViews>
      </MuiThemeProvider>
    );
  }
}

DivEncuestas.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivEncuestas);
