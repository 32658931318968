import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Slide from '@material-ui/core/Slide';

//DIVS
import DivPagoEnLinea from "./pagoEnLinea/DivPagoEnLinea";
import DivRevisarEstadoCuenta from "./revisarEstadoCuenta/DivRevisarEstadoCuenta";
import DivAdministracion from "./administracion/DivAdministracion";
import DivNotificaciones from "./notificaciones/DivNotificaciones";
import DivMiCuenta from "./cuenta/DivMiCuenta";

import DivEstadoPersonalCuenta from "./estadoPersonalCuenta/DivEstadoPersonalCuenta";

import DivMisTickets from "./tickets/DivMisTickets";
import DivRevisarTickets from "./tickets/DivRevisarTickets";
import DivIndicadoresMantto from "./indicadoresMantto/DivIndicadoresMantto";
import DivComunicacionInterna from "./comunicacionInterna/DivComunicacionInterna";
import DivEncuestas from "./encuestas/DivEncuestas";
import DivAccesosQR from "./accesosQR/DivAccesosQR";
import DivFacturacion from "./facturacion/DivFacturacion";

const styles = {
  card: {
    minWidth: 275,
    marginBottom: '0px',
    //boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    boxShadow: 'none'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

const cardTheme = createMuiTheme({
  overrides: {}
});

class DivMainContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }

  renderEmptyMain = () => {
    const {divMainEmpty, dataUser} = this.props;
    var imageHome = dataUser.homeImagePath;

    return (
      <Slide direction="left" in={divMainEmpty} mountOnEnter unmountOnExit>
        <div style={{textAlign: 'center'}}>
          <img className="emptyMainImage" src={imageHome} alt="img"/>
        </div>
      </Slide>
    );
  }

  renderMain = () => {
    const {classes, divMainEmpty, optionSelected, dataUser, checkPermisos, crearNotificacion, cerrarNotificacion, getData, ajaxSource, crearAlerta, cerrarAlerta, dataUserSet, loadedPage, handleOptionSelected, handleOpenTour, dialogStateForTour, mobile, prodEnv,handleReloadComponent, loadNotificationCounter, documentHeight, dev} = this.props;

    var option = "";

    if(optionSelected === 1) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivRevisarEstadoCuenta
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        ajaxSource={ajaxSource}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        dataUserSet={dataUserSet}
        loadedPage={loadedPage}
        handleOptionSelected={handleOptionSelected}
        prodEnv={prodEnv}
        mobile={mobile}
      />
          </CardContent>
        </Card>
    } else if(optionSelected === 2) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivPagoEnLinea
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        ajaxSource={ajaxSource}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        dataUserSet={dataUserSet}
        loadedPage={loadedPage}
        handleOptionSelected={handleOptionSelected}
        handleOpenTour={handleOpenTour}
        mobile={mobile}
        prodEnv={prodEnv}
      />
          </CardContent>
        </Card>
    } else if(optionSelected === 4) {
      option = <DivNotificaciones
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        ajaxSource={ajaxSource}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        dataUserSet={dataUserSet}
        loadedPage={loadedPage}
        handleOptionSelected={handleOptionSelected}
        loadNotificationCounter={loadNotificationCounter}
      />
    } else if(optionSelected === 5) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivMiCuenta
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
          />
          </CardContent>
        </Card>
    } else if(optionSelected === 6) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivAdministracion
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        ajaxSource={ajaxSource}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        dataUserSet={dataUserSet}
        loadedPage={loadedPage}
        handleOptionSelected={handleOptionSelected}
        handleOpenTour={handleOpenTour}
        dialogStateForTour={dialogStateForTour}
        mobile={mobile}
        prodEnv={prodEnv}
      />
          </CardContent>
        </Card>
    } else if(optionSelected === 7) {
      option =
        <DivMisTickets
          dataUser={dataUser}
          checkPermisos={checkPermisos}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          getData={getData}
          ajaxSource={ajaxSource}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
          dataUserSet={dataUserSet}
          loadedPage={loadedPage}
          handleOptionSelected={handleOptionSelected}
          handleOpenTour={handleOpenTour}
          dialogStateForTour={dialogStateForTour}
          mobile={mobile}
        />
    } else if(optionSelected === 8) {
      option =
        <DivRevisarTickets
          dataUser={dataUser}
          checkPermisos={checkPermisos}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          getData={getData}
          ajaxSource={ajaxSource}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
          dataUserSet={dataUserSet}
          loadedPage={loadedPage}
          handleOptionSelected={handleOptionSelected}
          handleOpenTour={handleOpenTour}
          dialogStateForTour={dialogStateForTour}
          mobile={mobile}
        />
    } else if(optionSelected === 9) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivIndicadoresMantto
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
            handleOpenTour={handleOpenTour}
            dialogStateForTour={dialogStateForTour}
            mobile={mobile}
            handleReloadComponent={handleReloadComponent}
            prodEnv={prodEnv}
          />
          </CardContent>
        </Card>
    } else if(optionSelected === 10) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivComunicacionInterna
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
            handleOpenTour={handleOpenTour}
            dialogStateForTour={dialogStateForTour}
            mobile={mobile}
            handleReloadComponent={handleReloadComponent}
            prodEnv={prodEnv}
          />
          </CardContent>
        </Card>
    } else if(optionSelected === 11) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivEncuestas
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
            handleOpenTour={handleOpenTour}
            dialogStateForTour={dialogStateForTour}
            mobile={mobile}
            handleReloadComponent={handleReloadComponent}
            prodEnv={prodEnv}
          />
          </CardContent>
        </Card>
    } else if(optionSelected === 12) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivAccesosQR
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
            handleOpenTour={handleOpenTour}
            dialogStateForTour={dialogStateForTour}
            mobile={mobile}
            handleReloadComponent={handleReloadComponent}
            prodEnv={prodEnv}
          />
          </CardContent>
        </Card>
    } else if(optionSelected === 13) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivFacturacion
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
            handleOpenTour={handleOpenTour}
            dialogStateForTour={dialogStateForTour}
            mobile={mobile}
            handleReloadComponent={handleReloadComponent}
            prodEnv={prodEnv}
            documentHeight={documentHeight}
            dev={dev}
          />
          </CardContent>
        </Card>
    }
    else if(optionSelected === 14) {
      option =
        <Card className={classes.card}>
          <CardContent className="cardContent"><DivEstadoPersonalCuenta
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            ajaxSource={ajaxSource}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            dataUserSet={dataUserSet}
            loadedPage={loadedPage}
            handleOptionSelected={handleOptionSelected}
            handleOpenTour={handleOpenTour}
            dialogStateForTour={dialogStateForTour}
            mobile={mobile}
            handleReloadComponent={handleReloadComponent}
            prodEnv={prodEnv}
            documentHeight={documentHeight}
            dev={dev}
          />
          </CardContent>
        </Card>
    }


    if(divMainEmpty === false) {
      return (
        <>
          {option}
        </>
      );
    }
  }

  render() {
    return (
      <div style={{width: '100%'}}>
        {this.renderEmptyMain()}
        {this.renderMain()}
      </div>
    );
  }
}


DivMainContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivMainContent);
