import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PassIcon from '@material-ui/icons/VpnKeyRounded';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
  td: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  th: {
    color: '#737373',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '40%'
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused:not $notchedOutline:not": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DivMiCuenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:"",
      actualPass:"",
      actualPassE:false,
      newPass:"",
      newPassE:false,
      newPassC:"",
      newPassCE:false
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value});
    this.setState({[name+'E']:false});
  };

  changePass = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    const {actualPass, newPass, newPassC} = this.state;

    if (actualPass === "") {
      this.setState({actualPassE: true}, () => {
        crearNotificacion("warning", "Ingresa tu contraseña.", "bottom", "right");
      });
    } else if (newPass === "") {
      this.setState({actualPassE: true}, () => {
        crearNotificacion("warning", "Ingresa tu nueva contraseña.", "bottom", "right");
      });
    } else if (newPassC === "") {
      this.setState({newPassCE: true}, () => {
        crearNotificacion("warning", "Confirma tu nueva contraseña.", "bottom", "right");
      });
    } else if (newPass !== newPassC) {
      this.setState({newPassCE: true}, () => {
        crearNotificacion("warning", "Las nuevas contraseñas no son iguales.", "bottom", "right");
      });
    } else {

      var userId = dataUser.userId;

      crearNotificacion("info", "Cambiando contraseña...", "bottom", "right", 1000000);

      getData(ajaxSource, "cuenta/changePass.php",{
        userId:userId,
        newPass:newPass,
        actualPass:actualPass
      }).then(function (response) {
        if (response.data) {
          var data = response.data;
          cerrarNotificacion();
          if(data[0].error === 1) {
            crearNotificacion("success", "Tu contraseña fue cambiada con exito.", "bottom", "right", 3000);

            self.setState({actualPass:""});
            self.setState({actualPassE:false});
            self.setState({newPass:""});
            self.setState({newPassE:false});
            self.setState({newPassC:""});
            self.setState({newPassCE:false});
          } else if(data[0].error === 2) {
            self.setState({actualPassE: true}, () => {
              crearNotificacion("error", "Tu contraseña actual es incorrecta.", "bottom", "right", 3000);
            });
          } else {
            crearNotificacion("error", "Hubo un problema al intentar cambiar tu contraseña "+data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  render() {
    const {classes} = this.props;
    const {actualPass, newPass, actualPassE, newPassE, newPassCE, newPassC} = this.state;

    return(
      <MuiThemeProvider theme={FormInputs}>
        <div className="row" style={{paddingBottom:'20px', margin: '0px'}}>
          <div className="col-md-12 mtM">
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Nueva Contraseña</h3>
          </div>
          <div className="col-md-12" style={{marginTop:'20px'}}>
            <TextField
              error={actualPassE}
              label="Contraseña actual"
              placeholder="Contraseña actual"
              id="actualPass"
              value={actualPass}
              onChange={this.handleChange('actualPass')}
              margin="normal"
              variant="outlined"
              style={{width:'100%'}}
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-12">
            <TextField
              error={newPassE}
              label="Nueva contraseña"
              placeholder="Nueva contraseña"
              id="actualPass"
              value={newPass}
              onChange={this.handleChange('newPass')}
              margin="normal"
              variant="outlined"
              style={{width:'100%'}}
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-12">
            <TextField
              error={newPassCE}
              label="Confirma tu nueva contraseña"
              placeholder="Confirma tu nueva contraseña"
              id="actualPass"
              value={newPassC}
              onChange={this.handleChange('newPassC')}
              margin="normal"
              variant="outlined"
              style={{width:'100%'}}
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-12 text-center">
            <MuiThemeProvider theme={FormButtons}>
              <Button  variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.changePass()}>
                Cambiar
                <PassIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}

DivMiCuenta.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivMiCuenta);
