import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AddUserIcon from '@material-ui/icons/GroupAdd';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/LiveHelpRounded';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import InfoIcon from '@material-ui/icons/InfoRounded';
import ChatIcon from '@material-ui/icons/ChatBubbleRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/SendRounded';
import EditIcon from '@material-ui/icons/EditRounded';

import GroupAddIcon from '@material-ui/icons/GroupAddRounded';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DialogGrupoDifusion from "./DialogGrupoDifusion";


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
  cardBlue: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    color:'#FFF',
    backgroundColor:'#0345ea !important',
    width:'100%'
  },
  cardGray: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    color:'#FFF',
    backgroundColor:'#777 !important',
    width:'100%'
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

class DivCrearMensajes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      mensajes:[],
      destinatarioId:"",
      destinatarioSelected:"",
      destinatarioData: "",
      mensaje:"",
      mensajeE:false,
      openDialogGrupoDifusion: false,
      handleOpenDialog:0,
      optionDialog:"crear",
      grupoSelected:[]
    }
  }

  handleOpenDialogGrupoDifusion = (option, data) => {
    const {handleOpenDialog} = this.state;
    this.setState({optionDialog:option,grupoSelected:data,openDialogGrupoDifusion:true},() => {
      this.setState({handleOpenDialog:handleOpenDialog+1});
    });
  }

  handleCloseDialogGrupoDifusion = () => {
    this.setState({openDialogGrupoDifusion:false});
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.setState({[name+'E']:false});
  };

  getMensajes = (data) => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos, tab, index} = this.props;

    if(tab === index) {
      crearNotificacion("info", "Cargando mensajes", "bottom", "right", 1000000);

      this.setState({
        destinatarioId: data.destinatarioId,
        destinatarioSelected: data.completName,
        destinatarioData: data,
        mensaje: ""
      }, () => {

        const {destinatarioId} = this.state;
        var userId = dataUser.userId;

        getData(ajaxSource, "comunicacionInterna/getMensajes.php", {
          userId: userId,
          destinatarioId: destinatarioId,
          tab: tab,
        }).then(function (response) {
          if (response.data) {
            var data = response.data;
            var mensajes = data[0];

            self.setState({mensajes: mensajes}, () => {
              cerrarNotificacion();
            });
          }
        });
      })
    }
  }

  crearMensaje = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos, tab} = this.props;
    const {mensaje, destinatarioId, destinatarioData} = this.state;
    var userId = dataUser.userId;

    if(mensaje === "") {
      this.setState({mensajeE:true},()=> {
        crearNotificacion("warning", "Ingresa el contenido del mensaje.", "bottom", "right");
      });
    } else {

      crearNotificacion("info", "Enviando Mensaje...", "bottom", "right", 1000000);

      getData(ajaxSource, "comunicacionInterna/crearMensaje.php", {
        userId: userId,
        mensaje: mensaje,
        destinatarioId: destinatarioId,
        tab:tab
      }).then(function (response) {
        if (response.data) {
          var data = response.data;
          cerrarNotificacion();

          if(data[0].error === 1) {
            crearNotificacion("success", "Mensaje enviado con exito.", "bottom", "right", 3000);
            self.getMensajes(destinatarioData);
          } else {
            crearNotificacion("error", "Hubo un problema al intentar enviar el mensaje. " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  renderTablaDestinatarios = () => {
    const {dataUser, destinatarios, checkPermisos, tab} = this.props;
    const {destinatarioId} = this.state;

    var casaLocal = "";
    var destEmisor = "Destinatario";
    if(checkPermisos("124")) {
      casaLocal = "Casa";
    } else if(checkPermisos("125")) {
      casaLocal = "Local";
    }

    var columCasa =  { title: casaLocal, field: 'noCasa', cellStyle: {textAlign: 'center'}}
    if(tab === 1) {
      destEmisor = "Emisor";
      columCasa = { title: casaLocal, field: 'noCasa', cellStyle: {textAlign: 'center'}, hidden:true}
    }

    if(tab === 2) {
      destEmisor = "Grupo";
      columCasa = { title: casaLocal, field: 'noCasa', cellStyle: {textAlign: 'center'}, hidden:true}
    }

    return(
      <DataMaterialTableNE
        dataUser={dataUser}
        title={""}
        data={destinatarios}
        columns={[
          { title: 'ID', field: 'destinatarioId', editable: 'never', hidden:true},
          {
            title: 'Info',
            field: 'destinatarioId',
            ellStyle: {textAlign: 'center'},
            sorting:false,
            render: rowData => {

              var activeChatColor = {};
              if(destinatarioId === rowData.destinatarioId) {
                activeChatColor = {color:'#0345ea'};
              }

              var btnEdit = "";

              if(tab === 2 && rowData.destinatarioId > 0) {
                btnEdit = <IconButton aria-label="Edit" onClick={()=>this.handleOpenDialogGrupoDifusion("editar", rowData)}>
                  <EditIcon/>
                </IconButton>
              }

              return(<div>
                {btnEdit}
                <IconButton aria-label="Abrir" onClick={()=>this.getMensajes(rowData)}>
                  <ChatIcon style={activeChatColor}/>
                </IconButton>
              </div>)
            },
          },
          { title: destEmisor, field: 'completName', cellStyle: {textAlign: 'center'}},
          columCasa
        ]}
      />
    )
  }

  renderFormCrearMensaje = () => {
    const {classes} = this.props;
    const {mensajeE, mensaje, maxCharacters, destinatarioSelected} = this.state;

    return (
      <div className="col-md-12 text-center" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
        <h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}> {destinatarioSelected}</h3>
        <TextField
          error={mensajeE}
          label="Mensaje"
          placeholder="Mensaje"
          id="mensaje"
          onChange={this.handleChange('mensaje')}
          margin="normal"
          variant="outlined"
          style={{width:'100%'}}
          multiline={true}
          rowsMax={7}
          rows={7}
          value={mensaje}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.crearMensaje()}>
            Enviar
            <SendIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      </div>
    )
  }

  renderMensajes = () => {
    const {classes, tab} = this.props;
    const {mensajes, destinatarioId} = this.state;
    if (Object.keys(mensajes).length > 0) {
      return(
        <div className="row" style={{margin:'0px', width:'100%'}}>
          {this.renderFormCrearMensaje()}
          <div className="col-md-12" style={{marginTop:'30px'}}>
            {mensajes.map(function (arr, index) {

              var cardStyle = {marginBottom: '10px', width: '90%', float: 'left'}
              var cardClass = classes.cardGray;
              if(arr.comuStatus === "S" || tab === 2) {
                cardStyle = {marginBottom: '10px', width: '90%', float: 'right'}
                cardClass = classes.cardBlue;
              }

              return(
                <Card key={index} className={cardClass} style={cardStyle}>
                  <CardContent>
                    <div style={{whiteSpace:'pre-line'}}>
                      {arr.mensaje}
                    </div>
                    <div style={{width:'100%', textAlign:'right', fontSize:'13px', marginBottom:'-20px'}}>
                      {arr.fecha}
                    </div>
                  </CardContent>
                </Card>
              )

            })}
          </div>
        </div>
      )
    } else if(destinatarioId !== "" && Object.keys(mensajes).length === 0){
      return(
        <div className="row" style={{margin:'0px', width:'100%'}}>
          {this.renderFormCrearMensaje()}
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'60px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Sin mensajes</h4>
            </div>
          </div>
        </div>
      )
    } else {

      var destinatarioTxt = "destinatario";
      if(tab == 2) {
        destinatarioTxt = "grupo";
      }

      return(
        <div className="text-center" style={{marginTop:'40px'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
          <h4>Selecciona un {destinatarioTxt}</h4>
        </div>
      )
    }
  }

  render() {
    const {classes, dataUser,crearNotificacion, cerrarNotificacion, ajaxSource, getData, crearAlerta, cerrarAlerta, cuotas, checkPermisos, mobile, perfilUsuarios, tab, usuarios, getComunicacionInternaInfo} = this.props;
    const {optionDialog, openDialogGrupoDifusion, handleOpenDialog, grupoSelected} = this.state;

    var title = "Destinatarios";
    var btnCrearGrupo = "";
    var dialogGrupoDifusion = "";
    if(tab === 1) {
      title = "Mis mensajes";
    } else if(tab === 2) {
      title = "Grupos";

      if(checkPermisos("1") || checkPermisos("130")) {
        btnCrearGrupo =
          <div style={{width:'100%', textAlign:'right', marginBottom:'20px'}}>
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={()=>this.handleOpenDialogGrupoDifusion("crear")}>
                Crear grupo difusión
                <GroupAddIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
          </div>
      }

      dialogGrupoDifusion = <DialogGrupoDifusion
        open={openDialogGrupoDifusion}
        option={optionDialog}
        handleClose={this.handleCloseDialogGrupoDifusion}
        handleOpenDialog={handleOpenDialog}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        ajaxSource={ajaxSource}
        getData={getData}
        dataUser={dataUser}
        usuarios={usuarios}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        checkPermisos={checkPermisos}
        getComunicacionInternaInfo={getComunicacionInternaInfo}
        grupoSelected={grupoSelected}
      />
    }

    return(
      <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
        <div className="col-md-6">
          <span>
            <h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>{title}</h3>
            {btnCrearGrupo}
            {this.renderTablaDestinatarios()}
          </span>
        </div>
        <div className="col-md-6">
          {this.renderMensajes()}
        </div>
        {dialogGrupoDifusion}
      </div>
    )
  }
}

DivCrearMensajes.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivCrearMensajes);
