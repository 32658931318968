/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../../componentsmKit/Header/Header.js";
import Footer from "../../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../../componentsmKit/Grid/GridItem.js";
import Button from "../../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

class HomeBannerUno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val:""
    }
  }

  render() {
    const {classes, Element, changePage} = this.props;

    return(
      <div id="homeBannerUno" style={{backgroundImage:"url(assets/img/webPage/bgSection.png)", width:'100%', borderRadius:'0px', paddingTop:'30px'}}>
        <div className={classes.container}>
          <div style={{width:'100%', paddingBottom:'50px'}}>
            <GridContainer style={{alignItems:'center'}}>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{width:'100%'}}>
                  <img src="assets/img/iconos/LOGO_BLANCO.png" style={{width:'80px', marginLeft:'10px'}}/>
                </div>
                <div style={{width:'100%', marginTop:'30px'}}>
                  <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#e6e6e6', fontWeight:'50', fontSize:'2.1125rem', lineHeight:'45px'}}>Con upera obtienes una <br></br> plataforma con herramientas<br></br> avanzadas de administración <br></br> y contabilidad.</h1>
                </div>
                <div style={{width:'100%', marginTop:'30px'}}>
                  <Button
                    color="blueUpera"
                    size="md"
                    onClick={()=>changePage("uperaHerramientas")}
                  >
                    Conoce las herramientas
                  </Button>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{width:'100%', textAlign:'center'}}>
                  <img src="assets/img/webPage/apps.png" style={{marginTop:'50px', width:'80%'}}></img>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

HomeBannerUno.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeBannerUno);
