/*Componentes globales*/
import React, {Component} from 'react';
import 'date-fns';
import moment from 'moment'
import format from 'date-fns/format'
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker} from '@material-ui/pickers';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core';
import esLocale from 'date-fns/locale/es';

/*Componentes de la aplicación*/

/*Variables globales*/
const materialTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0345ea',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        marginTop: '0px !important'
      }
    },
    MuiPickersDay:{
      selected :{
        backgroundColor: '#0345ea',
      }
    },
    MuiButton: {
      textPrimary: {
        color : '#0345ea'
      }
    },
    MuiFormLabel: {
      '&$focused': {
        color:"#fff !important"
      }
    },
    MuiInput: {
      input: {
        color: '#737373 !important'
      },
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '2px solid #0345ea',
        },
        '&:after': {
          borderBottom: '2px solid #0345ea'
        },
        '&:before': {
          borderBottom: '2px solid rgba(3, 69, 234, 0.42);'
        }
      },
    },
  },
});

class FrDateFnsUtils extends DateFnsUtils {
  startOfMonth(date) {
    return moment(date).startOf('month').toDate();
  }
  getDatePickerHeaderText(date) {
    return format(date, 'd MMMM', {locale: this.locale})
  }
}

class MyDatePicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      selectedDate: new Date(),
    }
  }

  handleDateChange = date => {
    this.setState({selectedDate: date});
  };


  render() {
    const {label, handleDateChange, selectedDate, disabled} = this.props;

    return (
      <MuiThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={FrDateFnsUtils} locale={esLocale}>
          <DatePicker
            autoOk
            inputVariant="outlined"
            disabled={disabled}
            margin="normal"
            label={label}
            value={selectedDate}
            onChange={handleDateChange}
            InputAdornmentProps={{ position: "start" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}


export default MyDatePicker;
