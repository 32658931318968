/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../componentsmKit/Header/Header.js";
import Footer from "../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../componentsmKit/Grid/GridItem.js";
import Button from "../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../componentsmKit/Header/HeaderLinks.js";
import TextField from '@material-ui/core/TextField';
import ArrowIcon from '@material-ui/icons/ArrowForwardRounded';

import styles from "../../assets/jss/material-kit-react/views/landingPage.js";
import {Icon} from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily:'Poppins Regular !important'
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily:'Poppins Regular !important'
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily:'Poppins Regular !important',
        color:'#7e7e84',
        "&.Mui-focused":{
          color:'#7e7e84',
        }
      }
    },
    MuiInputBase: {
      input: {
        fontFamily:'Poppins Regular !important',
        color:'#7e7e84'
      }
    },
    MuiSelect: {
      select: {
        textAlign: 'left'
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor:'rgba(126,126,32,0.23)',
        borderRadius:'11px'
      },
      root:{
        legend: {
          width: "calc(10px + var(--label-width) * 0.75) !important"
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor:'rgba(5,95,195,0.53)'
        },
        "&$focused $notchedOutline": {
          borderColor: '#055fc3'
        } ,
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#FFF'
      }
    },
    MuiInputLabel: {
      outlined: {
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, -6px) scale(0.69) !important"
        }
      }
    },
    MuiButton:{
      root: {
        borderRadius:'11px'
      },
      containedPrimary:{
        backgroundColor:'#86c03f',
        "&:hover":{
          backgroundColor:'#86c03f',
        }
      },
      label:{
        fontFamily:'Poppins Regular !important',
        textTransform:'none'
      }
    },
  }
});

class WebUperaContacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      nombre:"",
      nombreE:false,
      apellido:"",
      apellidoE:false,
      telefono:"",
      telefonoE:false,
      correo:"",
      correoE:false,
      mensaje:"",
      mensajeE:false,
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {changePageBand, loadedPage} = this.props;
    if(nextProps.changePageBand !== changePageBand) {
      loadedPage(true);
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value, [name+"E"]: false });
  };

  enviarMensaje = () => {
    const self    = this;
    const {crearNotificacion, cerrarNotificacion, crearAlerta, cerrarAlerta, loginSession, getData, ajaxSource} = this.props;
    const {nombre, apellido, correo, telefono, mensaje} = this.state;

    if(nombre === "") {
      this.setState({nombreE:true},()=> {
        crearNotificacion("warning", "Ingresa tu nombre.", "bottom", "right");
      });
    }  else if(apellido === "") {
      this.setState({apellidoE:true},()=> {
        crearNotificacion("warning", "Ingresa tu apellido.", "bottom", "right");
      });
    } else if(correo === "") {
      this.setState({correoE:true},()=> {
        crearNotificacion("warning", "Ingresa tu correo.", "bottom", "right");
      });
    } else if(telefono === "") {
      this.setState({telefonoE:true},()=> {
        crearNotificacion("warning", "Ingresa tu telefono.", "bottom", "right");
      });
    } else if(mensaje === "") {
      this.setState({mensajeE:true},()=> {
        crearNotificacion("warning", "Ingresa tu mensaje.", "bottom", "right");
      });
    } else {

      crearNotificacion("info", "Enviando mensaje...", "bottom", "right", 1000000);

      var name = nombre+" "+apellido;
      getData(ajaxSource, "landingPage/sendMessage.php",{
        nombre:name,
        correo:correo,
        telefono:telefono,
        mensaje:mensaje
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            cerrarNotificacion();
            crearAlerta("success", "Mensaje enviado", "Gracias por tu interes en upera, nos pondremos en contacto lo mas pronto posible.", false, cerrarAlerta);
            self.setState({nombre:"", apellido:"", correo:"", telefono:"", mensaje:""});
          } else {
            cerrarNotificacion();
            crearNotificacion("error", "Hubo un problema al intentar enviar tu mensaje" + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  render() {
    const {classes, changePage, handleScrollTo, mobile, ...rest} = this.props;
    const {nombre, nombreE, apellido, apellidoE, correo, correoE, telefono, telefonoE, mensaje, mensajeE} = this.state;

    return(
      <MuiThemeProvider theme={theme}>
        <Header
          color="white"
          routes={[]}
          brand="Material Kit React"
          rightLinks={<HeaderLinks
            handleScrollTo={handleScrollTo}
          />}
          fixed
          changePage={changePage}
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          handleScrollTo={handleScrollTo}
          {...rest}
        />
        <div className={classes.main} style={{paddingBottom:'50px', paddingTop:'70px'}}>
          <div style={{ width:'100%', borderRadius:'0px'}}>
            <div className={classes.container}>
              <div style={{width:'100%'}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{width:'100%', marginTop:'50px'}}>
                      <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'2.8125rem', lineHeight:'47px'}}>Comunícate <br></br> con nosotros</h1>
                    </div>
                    <div style={{width:'100%'}}>
                      <h4 className={classes.title} style={{marginTop:'5px', fontFamily: 'Poppins SemiBold', color:'#036adb', fontSize:'18px'}}>Creamos la plataforma a la medida <br></br> para que se ajuste a todas tus necesidades.</h4>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{width:'100%', backgroundColor:'#f2f2f2', padding:!mobile ? '50px' : '15px', marginTop:'30px'}}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <div style={{width:'100%'}}>
                            <h1 className={classes.title} style={{margin:'0px',fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'1.4125rem', lineHeight:'30px'}}>Completa el siguiente <br></br> formulario y nos comunicaremos contigo.</h1>
                          </div>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <div style={{width:'100%', marginTop:'30px'}}>
                            <TextField
                              id="nombre"
                              label="Nombre*"
                              variant="outlined"
                              style={{width:'100%'}}
                              value={nombre}
                              error={nombreE}
                              onChange={this.handleChange("nombre")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <div style={{width:'100%', marginTop:'30px'}}>
                            <TextField
                              id="apellido"
                              label="Apellido*"
                              variant="outlined"
                              style={{width:'100%'}}
                              value={apellido}
                              error={apellidoE}
                              onChange={this.handleChange("apellido")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <div style={{width:'100%', marginTop:'30px'}}>
                            <TextField
                              id="correo"
                              label="Correo*"
                              variant="outlined"
                              style={{width:'100%'}}
                              value={correo}
                              error={correoE}
                              onChange={this.handleChange("correo")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <div style={{width:'100%', marginTop:'30px'}}>
                            <TextField
                              id="telefono"
                              label="Telefono*"
                              variant="outlined"
                              style={{width:'100%'}}
                              value={telefono}
                              error={telefonoE}
                              onChange={this.handleChange("telefono")}
                              type="number"
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} >
                          <div style={{width:'100%', marginTop:'30px'}}>
                            <TextField
                              id="mensaje"
                              multiline={true}
                              rows={5}
                              rowsMax={5}
                              label="¿Como podemos ayudarte?"
                              variant="outlined"
                              style={{width:'100%'}}
                              value={mensaje}
                              error={mensajeE}
                              onChange={this.handleChange("mensaje")}
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12}>
                          <div style={{width:'100%', textAlign:'left', marginTop:'10px'}}>
                            <Button
                              color="orangeUpera"
                              size="md"
                              onClick={()=>this.enviarMensaje()}
                            >
                              Enviar
                              <ArrowIcon style={{marginLeft:'5px'}}/>
                            </Button>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <img src="assets/img/webPage/contactanos.png" style={{width:'100%', marginTop:'30px'}}/>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
        <Footer
          changePage={changePage}
          mobile={mobile}
        />
      </MuiThemeProvider>
    )
  }
}

WebUperaContacto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WebUperaContacto);
