import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AddUserIcon from '@material-ui/icons/GroupAdd';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/LiveHelpRounded';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import InfoIcon from '@material-ui/icons/InfoRounded';
import ChatIcon from '@material-ui/icons/ChatBubbleRounded';
import EncuestasIcon from '@material-ui/icons/FeedbackRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/SendRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import PublishIcon from '@material-ui/icons/PublishRounded';

import GroupAddIcon from '@material-ui/icons/GroupAddRounded';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DialogGrupoEncuestas from "./DialogGrupoEncuestas";
import DokaManager from "../../commons/DokaManager";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  formControlSelect: {
    marginTop: '16px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
  cardBlue: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    color:'#FFF',
    backgroundColor:'#0345ea !important',
    width:'100%'
  },
  cardGray: {
    boxShadow: 'none',
    backgroundColor:'#eee !important',
    width:'100%'
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

class DivCrearEncuestas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      encuestas:[],
      destinatarioId:"",
      destinatarioSelected:"",
      destinatarioData: "",

      openDialogGrupoDifusion: false,
      handleOpenDialog:0,
      optionDialog:"crear",
      grupoSelected:[],

      pregunta:"",
      preguntaE:false,
      titulo:"",
      tituloE:false,
      descripcion:"",
      descripcionE:false,
      maxCharacters:"0/128",

      componentKey:0,
      fileUpload:false,
      fileLoaded:false,
      fileName:"",
      duracion:5,
      duracionE:false,
      encuestaPendiente:[],
      opciones:[]
    }
  }

  handleOpenDialogGrupoDifusion = (option, data) => {
    const {handleOpenDialog} = this.state;
    this.setState({optionDialog:option,grupoSelected:data,openDialogGrupoDifusion:true},() => {
      this.setState({handleOpenDialog:handleOpenDialog+1});
    });
  }

  handleComponentKey = () => {
    const {componentKey} = this.state;
    this.setState({componentKey:componentKey+1});
  }

  handleCloseDialogGrupoDifusion = () => {
    this.setState({openDialogGrupoDifusion:false});
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value },()=> {
      if(name === "descripcion") {
        const {descripcion} = this.state;
        var length = descripcion.length;
        this.setState({maxCharacters:length+"/128"});
      }
    });
    this.setState({[name+'E']:false});
  };


  getEncuestas = (data) => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos, tab, index} = this.props;

    this.setState({
      destinatarioId: data.destinatarioId,
      destinatarioSelected: data.completName,
      destinatarioData: data,
      mensaje: ""
    }, () => {

      const {destinatarioId} = this.state;
      var userId = dataUser.userId;

      crearNotificacion("info", "Cargando datos de encuesta...", "bottom", "right", 1000000);

      getData(ajaxSource, "encuestas/encuestasInfo.php",{
        userId:userId,
        grupoId:destinatarioId
      }).then(function (response) {
        if (response.data) {
          var data = response.data;
          var encuestaPendiente = data[2];

          self.setState({encuestaPendiente:encuestaPendiente}, () => {

            if(Object.keys(encuestaPendiente).length > 0) {

              self.setState({pregunta:encuestaPendiente[0].pregunta, duracion:encuestaPendiente[0].duration, opciones:encuestaPendiente[0].opciones}, ()=>{
                cerrarNotificacion();
              });
            } else {
              cerrarNotificacion();
            }
          });
        }
      });
    });
  }

  agregarOpcionEncuesta = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, getEncuestasInfo} = this.props;
    const {pregunta, titulo, descripcion, fileUpload, fileLoaded, fileName, destinatarioId, duracion, destinatarioData} = this.state;

    if(pregunta === "") {
      this.setState({preguntaE:true}, ()=> {
        crearNotificacion("warning", "Ingresa la pregunta de la encuesta.", "bottom", "right");
      });
    } else if(duracion === 0) {
      this.setState({duracionE:true}, ()=> {
        crearNotificacion("warning", "Selecciona la duración de la encuesta.", "bottom", "right");
      });
    } else if(titulo === "") {
      this.setState({tituloE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el titulo de la opción.", "bottom", "right");
      });
    } else if(descripcion === "") {
      this.setState({descripcionE:true}, ()=> {
        crearNotificacion("warning", "Ingresa una descripción de la opción.", "bottom", "right");
      });
    } else if(fileUpload === false) {
      crearNotificacion("warning", "Sube la imagen o el documento seleccionado.", "bottom", "right");
    } else {
      var userId = dataUser.userId;
      crearNotificacion("info", "Creando opción...", "bottom", "right", 1000000);

      getData(ajaxSource, "encuestas/createOpcionEncuesta.php",{
        userId:userId,
        pregunta:pregunta,
        titulo:titulo,
        descripcion:descripcion,
        fileName:fileName,
        grupoId:destinatarioId,
        duracion:duracion
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            crearNotificacion("success", "Opción creada con exito.", "bottom", "right", 3000);

            self.setState({descripcion:"", fileName:"", fileLoaded: false, fileUpload: false, maxCharacters:"0/128", titulo:""}, () => {
              self.handleComponentKey();
              self.getEncuestas(destinatarioData);
            });

          } else {
            crearNotificacion("error", "Hubo un problema al intentar crear la opción" + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  publicarEncuestaAlerta = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, getEncuestasInfo, cerrarAlerta, crearAlerta} = this.props;
    const {pregunta, duracion, opciones, destinatarioData, destinatarioId} = this.state;

    console.log(Object.keys(opciones).length);

    if(pregunta === "") {
      this.setState({preguntaE:true}, ()=> {
        crearNotificacion("warning", "Ingresa la pregunta de la encuesta.", "bottom", "right");
      });
    } else if(duracion === 0) {
      this.setState({duracionE:true}, ()=> {
        crearNotificacion("warning", "Selecciona la duración de la encuesta.", "bottom", "right");
      });
    } else if(Object.keys(opciones).length === 0 || Object.keys(opciones).length <= 1) {
      this.setState({tituloE:true}, ()=> {
        crearNotificacion("warning", "Debes ingresar al menos dos opciones.", "bottom", "right");
      });
    } else {
      crearAlerta("warning", "", "¿Seguro que deseas publicar la encuesta?, una vez publicada no podrás eliminarla ni cambiar las opciones.", true, this.publicarEncuesta);
    }
  }

  publicarEncuesta = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, getEncuestasInfo, cerrarAlerta} = this.props;
    const {pregunta, duracion, opciones, destinatarioData, destinatarioId} = this.state;

    cerrarAlerta();

    var userId = dataUser.userId;
    crearNotificacion("info", "Publicando encuesta...", "bottom", "right", 1000000);

    getData(ajaxSource, "encuestas/publicarEncuesta.php",{
      userId:userId,
      pregunta:pregunta,
      duracion:duracion,
      grupoId:destinatarioId
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          crearNotificacion("success", "Encuesta publicada con exito.", "bottom", "right", 3000);

          setTimeout(function () {
            self.setState({pregunta:"", duracion:"", opciones: [], descripcion:"", fileName:"", fileLoaded: false, fileUpload: false, maxCharacters:"0/128", titulo:""}, () => {
              self.handleComponentKey();
              self.getEncuestas(destinatarioData);
              getEncuestasInfo();
            });
          },500);

        } else {
          crearNotificacion("error", "Hubo un problema al intentar publicar la encuesta" + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  getFileId = (error, file) => {
    var fileName = file.filename;
    this.setState({fileUpload: true, fileName:fileName});
  }

  onRemoveFile = (error, file) => {
    const {ajaxSource, getData, dataUser} = this.props;
    const {fileUpload} = this.state;

    if(fileUpload === true) {
      var userId = dataUser.userId;
      var fileName = file.filename;

      getData(ajaxSource, "encuestas/deleteTempFile.php",{
        userId:userId,
        fileName:fileName
      }).then(function (response) {
        if (response.data) {}
      });
    }

    this.setState({fileUpload: false, fileLoaded: false, fileName:"", fileExtension:""});
  }

  onAddfile = () => {
    this.setState({imageLoaded: true});
  }


  renderTablaDestinatarios = () => {
    const {dataUser, destinatarios, checkPermisos, tab} = this.props;
    const {destinatarioId} = this.state;

    var casaLocal = "";
    var destEmisor = "Grupo";
    if(checkPermisos("124")) {
      casaLocal = "Casa";
    } else if(checkPermisos("125")) {
      casaLocal = "Local";
    }

    return(
      <DataMaterialTableNE
        dataUser={dataUser}
        title={""}
        data={destinatarios}
        columns={[
          { title: 'ID', field: 'destinatarioId', editable: 'never', hidden:true},
          {
            title: 'Info',
            field: 'destinatarioId',
            ellStyle: {textAlign: 'center'},
            sorting:false,
            render: rowData => {

              var activeChatColor = {};
              if(destinatarioId === rowData.destinatarioId) {
                activeChatColor = {color:'#0345ea'};
              }

              var btnEdit = "";
              if(rowData.destinatarioId > 0) {
                btnEdit = <IconButton aria-label="Edit" onClick={() => this.handleOpenDialogGrupoDifusion("editar", rowData)}>
                  <EditIcon/>
                </IconButton>;
              }

              return(<div>
                {btnEdit}
                <IconButton aria-label="Abrir" onClick={()=>this.getEncuestas(rowData)}>
                  <EncuestasIcon style={activeChatColor}/>
                </IconButton>
              </div>)
            },
          },
          { title: destEmisor, field: 'completName', cellStyle: {textAlign: 'center'}},
        ]}
      />
    )
  }

  renderFormCrearEncuestas = () => {
    const {classes, dataUser, ajaxSource, crearNotificacion} = this.props;
    const {mensajeE, mensaje, destinatarioSelected, preguntaE, pregunta, titulo, tituloE, descripcion, descripcionE, maxCharacters, componentKey, fileUpload, duracion, duracionE} = this.state;

    var fupload = "no";
    if(fileUpload === true) {
      fupload = "si";
    }

    return (
      <div className="col-md-12 text-center" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
        <h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}> {destinatarioSelected}</h3>
        <div className="row" style={{width:'100%', margin: '0px', textAlign:'left'}}>
          <div className="col-md-8" style={{marginTop:'10px'}}>
            <MuiThemeProvider theme={FormInputs}>
              <TextField
                error={preguntaE}
                label="Pregunta"
                placeholder="Ingresa la Pregunta"
                id="pregunta"
                onChange={this.handleChange('pregunta')}
                value={pregunta}
                margin="normal"
                variant="outlined"
                style={{width:'100%'}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiThemeProvider>
          </div>
          <div className="col-md-4" style={{marginTop:'10px'}}>
            <MuiThemeProvider theme={FormInputs}>
              <FormControl variant="outlined" className={classes.formControlSelect}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="factura"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  Duración
                </InputLabel>
                <Select
                  value={duracion}
                  onChange={this.handleChange('duracion')}
                  input={
                    <OutlinedInput id="factura" labelWidth={0} error={duracionE}/>
                  }
                >
                  <MenuItem value={5}>
                    5 días
                  </MenuItem>
                  <MenuItem value={10}>
                    10 días
                  </MenuItem>
                  <MenuItem value={15}>
                    15 días
                  </MenuItem>
                  <MenuItem value={20}>
                    20 días
                  </MenuItem>
                  <MenuItem value={30}>
                    30 días
                  </MenuItem>
                </Select>
              </FormControl>
            </MuiThemeProvider>
          </div>
          <div className="col-md-12" style={{marginTop:'20px'}}>
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
          </div>
          <div className="col-md-12">
            <MuiThemeProvider theme={FormInputs}>
              <TextField
                error={tituloE}
                label="Título"
                placeholder="Ingresa el título de la opción"
                id="pregunta"
                onChange={this.handleChange('titulo')}
                value={titulo}
                margin="normal"
                variant="outlined"
                style={{width:'100%'}}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiThemeProvider>
          </div>
          <div className="col-md-12">
            <MuiThemeProvider theme={FormInputs}>
              <TextField
                error={descripcionE}
                label="Descripción"
                placeholder="Descripción"
                id="descripcion"
                onChange={this.handleChange('descripcion')}
                margin="normal"
                variant="outlined"
                style={{width:'100%'}}
                multiline={true}
                rowsMax={7}
                rows={7}
                value={descripcion}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{maxLength: 128}}
                helperText={maxCharacters}
              />
            </MuiThemeProvider>
          </div>
          <div className="col-md-12">
            <DokaManager
              key={componentKey}
              type="image"
              labelIdle={[
                'Arrastra y suelta ó <span class="filepond--label-action">busca</span> una imagen o documento PDF'
              ]}
              ajaxSource={ajaxSource}
              urlServerUpload={"uploadManager/index.php?option=fileOpcionEncuesta&userId="+dataUser.userId+"&fileUpload="+fupload}
              onprocessfile={(error, file)=>this.getFileId(error, file)}
              onremovefile={(error, file)=>this.onRemoveFile(error, file)}
              onaddfile={this.onAddfile}
              fileRenameFunction={(file) => {return Math.random()+file.extension}}
              acceptedFileTypes={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
              imagePreviewHeight={250}
              imageEditInstantEdit={false}
              editorOptions={{
                cropAspectRatioOptions: [
                  {
                    label: 'Foto Ticket',
                  }
                ]
              }}
            />
          </div>
          <div className="col-md-12 text-center">
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={()=>this.agregarOpcionEncuesta()}>
                Agregar opción
                <AddIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={()=>this.publicarEncuestaAlerta()}>
                Publicar encuesta
                <PublishIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    )
  }

  renderEncuestas = () => {
    const {classes, tab, ajaxSource, prodEnv} = this.props;
    const {destinatarioId, opciones} = this.state;
    if(destinatarioId !== ""){
      return(
        <div className="row" style={{margin:'0px', width:'100%'}}>
          {this.renderFormCrearEncuestas()}
          <div className="col-md-12" style={{marginTop:'20px'}}>
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Vista Previa Opciones</h3>
          </div>
          {opciones.map(function (arr, index) {

            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(arr.imgPath)[1];

            var path = <a href={ajaxSource + arr.imgPath} target="_blank">
              <img className="img-raised rounded img-fluid" src={ajaxSource + arr.imgPath} style={{maxWidth:'300px'}}/>
            </a>

            if(ext === "pdf" || ext === "PDF") {
              path = <iframe
                src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + arr.imgPath}
                style={{width: '100%', height: '160px', border: '4px solid #737373', borderRadius: '4px'}}/>;
            }


            return(
              <div key={index} className="col-md-12">
                <Card className={classes.cardGray} style={{marginTop:'10px'}}>
                  <CardContent>
                    <div style={{whiteSpace:'pre-line'}}>
                      <h3 style={{margin:'0px'}}>{index+1}) Opción: {arr.titulo}</h3>
                    </div>
                    <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
                      {path}
                    </div>
                    <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                      Descripción: {arr.descripcion}
                    </div>
                  </CardContent>
                </Card>
              </div>
            )
          })}
        </div>
      )
    } else {
      var destinatarioTxt = "grupo";
      return(
        <div className="text-center" style={{marginTop:'40px'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
          <h4>Selecciona un {destinatarioTxt}</h4>
        </div>
      )
    }
  }

  render() {
    const {classes, dataUser,crearNotificacion, cerrarNotificacion, ajaxSource, getData, crearAlerta, cerrarAlerta, checkPermisos, mobile, perfilUsuarios, tab, usuarios, getEncuestasInfo} = this.props;
    const {optionDialog, openDialogGrupoDifusion, handleOpenDialog, grupoSelected} = this.state;

    var title = "Grupos";
    var btnCrearGrupo = "";

    if(checkPermisos("1") || checkPermisos("134")) {
      btnCrearGrupo =
        <div style={{width:'100%', textAlign:'right', marginBottom:'20px'}}>
          <MuiThemeProvider theme={FormButtons}>
            <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={()=>this.handleOpenDialogGrupoDifusion("crear")}>
              Crear grupo encuesta
              <GroupAddIcon className={classes.rightIcon}/>
            </Button>
          </MuiThemeProvider>
        </div>
    }

    var dialogGrupoDifusion = <DialogGrupoEncuestas
      open={openDialogGrupoDifusion}
      option={optionDialog}
      handleClose={this.handleCloseDialogGrupoDifusion}
      handleOpenDialog={handleOpenDialog}
      crearNotificacion={crearNotificacion}
      cerrarNotificacion={cerrarNotificacion}
      ajaxSource={ajaxSource}
      getData={getData}
      dataUser={dataUser}
      usuarios={usuarios}
      crearAlerta={crearAlerta}
      cerrarAlerta={cerrarAlerta}
      checkPermisos={checkPermisos}
      getEncuestasInfo={getEncuestasInfo}
      grupoSelected={grupoSelected}
    />


    return(
      <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
        <div className="col-md-5" style={{borderRight: '1px solid #eee'}}>
          <span>
            <h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>{title}</h3>
            {btnCrearGrupo}
            {this.renderTablaDestinatarios()}
          </span>
        </div>
        <div className="col-md-7" style={{borderLeft: '1px solid #eee'}}>
          {this.renderEncuestas()}
        </div>
        {dialogGrupoDifusion}
      </div>
    )
  }
}

DivCrearEncuestas.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivCrearEncuestas);
