import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/index';
import AppBar from '@material-ui/core/AppBar/index';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs/index';
import Tab from '@material-ui/core/Tab/index';
import Typography from '@material-ui/core/Typography/index';
import AddTicketIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ReOpenTicketIcon from '@material-ui/icons/SearchRounded';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';
import ClockIcon from '@material-ui/icons/AlarmRounded';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/SearchRounded';
import AddIcon from "@material-ui/icons/AddRounded";
import CloseIcon from "@material-ui/icons/DoneAllRounded";
import TextField from "@material-ui/core/TextField";
import DokaManager from "../../commons/DokaManager";
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';

const styles = theme => ({
  chipCurso: {
    margin: theme.spacing(1),
    backgroundColor: '#558b2f',
    color:'#FFF'
  },
  chipClose: {
    margin: theme.spacing(1),
    backgroundColor: '#e53935',
    color:'#FFF'
  },
  chip: {
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    marginBottom: '50px',
    //boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    boxShadow: 'none',
    width:'100%'
  },
  cardTicketSelected: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    color:'#FFF',
    backgroundColor:'#0345ea !important',
    width:'100%'
  },
  cardTicket: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    backgroundColor:'#cfd8dc !important',
    width:'100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      },
      elevation4: {
        boxShadow: 'none !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '0px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px',
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          //border:'2px solid #1a49bf',
          backgroundColor:'transparent !important',
          boxShadow:'none'
        },
        backgroundColor:'transparent !important',
        boxShadow:'none'
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    },
    MuiTypography: {
      body1: {
        padding: '0px !important'
      },
      h5: {
        fontSize:'18px'
      }
    },
  }
})

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivRevisarTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      ticketsAbiertos:[],
      ticketsCerrados:[],
      ticketSelected:"",
      tickeDataSelected:[],
      maxCharacters:"0/128",
      mensaje:"",
      mensajeE:false,
      componentKey:0,
      imageUpload:false,
      imageLoaded:false,
      fileName:"",
      bitacora:[],
      buscarTicket:""
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getTicketsInfo();
    }
  }

  handleComponentKey = () => {
    const {componentKey} = this.state;
    this.setState({componentKey:componentKey+1});
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value },() => {
      if(name === "mensaje") {
        const {mensaje} = this.state;
        var length = mensaje.length;
        this.setState({maxCharacters:length+"/128"});
      }
      if(name === "buscarTicket") {
        const {buscarTicket, ticketsCerrados} = this.state;
        if(buscarTicket === "") {
          if (Object.keys(ticketsCerrados).length === 0) {
            this.getTicketsInfo();
          }
        }
      }
    });
    this.setState({[name+'E']:false});
  };


  handleChangeTab = (event, value) => {
    this.setState({ticketSelected:""},()=> {
      this.setState({ value });
    });
  };

  handleChangeIndex = index => {
    this.setState({ticketSelected:""},()=> {
      this.setState({value: index});
    });
  };

  handleTicketSelected = (ticketId, data, aviso) => {
    this.setState({ticketSelected:ticketId, tickeDataSelected:data}, ()=> {
      const {crearNotificacion} = this.props;
      crearNotificacion("info", "Cargando información del ticket...", "bottom", "right", 1000000);
      if(aviso !== undefined && aviso !== "") {
        this.sendAviso()
      }
      this.getTicketsInfo();
    });
  }

  sendAviso = () => {
    const {ajaxSource, getData, dataUser} = this.props;
    const {ticketSelected} = this.state;
    var userId = dataUser.userId;

    getData(ajaxSource, "tickets/sendAvisoLectura.php",{
      ticketId:ticketSelected,
      userId:userId
    }).then(function (response) {
      if (response.data) {}
    });
  }

  getFileId = (error, file) => {
    var fileName = file.filename;
    this.setState({imageUpload: true, fileName:fileName});
  }

  onRemoveFile = (error, file) => {
    const {ajaxSource, getData, dataUser} = this.props;
    const {imageUpload, ticketSelected} = this.state;

    if(imageUpload === true) {
      var userId = dataUser.userId;
      var fileName = file.filename;

      getData(ajaxSource, "tickets/deleteImageTicket.php",{
        ticketId:ticketSelected,
        fileName:fileName,
      }).then(function (response) {
        if (response.data) {}
      });
    }

    this.setState({imageUpload: false, imageLoaded: false, fileName:"", fileExtension:""});
  }

  onAddfile = () => {
    this.setState({imageLoaded: true});
  }

  getTicketsInfo = (searchData) => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos} = this.props;
    const {ticketSelected} = this.state;
    var userId = dataUser.userId;

    var admin = "";
    if(checkPermisos("1") || checkPermisos("108") || checkPermisos("126")) {
      admin = "ok";
    }

    getData(ajaxSource, "tickets/ticketsInfo.php",{
      userId:userId,
      ticketId:ticketSelected,
      admin:admin,
      searchData:searchData
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var ticketsAbiertos = data[2];
        var bitacora = data[3];
        var ticketsCerrados = data[4];

        loadedPage(true);

        self.setState({ticketsAbiertos:ticketsAbiertos, bitacora:bitacora, ticketsCerrados:ticketsCerrados}, ()=> {
          cerrarNotificacion();
        });
      }
    });
  }

  agregarComentario = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion} = this.props;
    const {mensaje, ticketSelected} = this.state;
    var userId = dataUser.userId;

    if(mensaje === "") {
      this.setState({mensajeE:true}, ()=> {
        crearNotificacion("warning", "Ingesa tu comentario.", "bottom", "right");
      });
    } else {

      crearNotificacion("info", "Guardando comentario...", "bottom", "right", 1000000);

      getData(ajaxSource, "tickets/createComentario.php",{
        userId:userId,
        mensaje:mensaje,
        ticketId:ticketSelected
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            crearNotificacion("success", "Comentario creado con exito.", "bottom", "right", 3000);

            self.setState({mensaje:"", imageUpload:false, imageLoaded:false, fileName:""}, () => {
              self.handleComponentKey();
              crearNotificacion("info", "Cargando información del ticket...", "bottom", "right", 1000000);
              self.getTicketsInfo();
            });
          } else {
            crearNotificacion("error", "Hubo un problema al intentar crear el comentario " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  cerrarTicket = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion} = this.props;
    const {ticketSelected, imageUpload, imageLoaded, mensaje, fileName} = this.state;
    var userId = dataUser.userId;

    if(imageUpload === false && imageLoaded === false) {
      crearNotificacion("warning", "Selecciona y sube una foto del problema resuelto.", "bottom", "right");
    } else {
      crearNotificacion("info", "Cerrando ticket...", "bottom", "right", 1000000);

      getData(ajaxSource, "tickets/cerrarTicket.php",{
        userId:userId,
        ticketId:ticketSelected,
        mensaje:mensaje,
        fileName:fileName
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            crearNotificacion("success", "Ticket cerrado con exito.", "bottom", "right", 3000);

            self.setState({mensaje:"", imageUpload:false, imageLoaded:false, ticketSelected:"", fileName:""}, () => {
              self.handleComponentKey();
              self.getTicketsInfo();
            });
          } else {
            crearNotificacion("error", "Hubo un problema al intentar cerrar el ticket " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  buscarTicket = () => {
    const {crearNotificacion} = this.props;
    const {buscarTicket} = this.state;
    crearNotificacion("info", "Buscando tickets...", "bottom", "right", 1000000);
    this.getTicketsInfo(buscarTicket);
  }

  renderTicketForm = () => {
    const {classes, checkPermisos, ajaxSource, dataUser} = this.props;
    const {ticketSelected, mensaje, mensajeE, maxCharacters, componentKey} = this.state;

    if(ticketSelected !== "") {

      var btnAgregarComentario = "";
      var btnCerrarTicket = "";

      if(checkPermisos("1") || checkPermisos("116")) {
        btnAgregarComentario = <Button variant="contained" color="primary" className={classes.button} style={{width:'100%'}} onClick={()=>this.agregarComentario()}>
          Agregar<br/>Comentario
          <AddIcon className={classes.rightIcon}/>
        </Button>
      }

      if(checkPermisos("1") || checkPermisos("117")) {
        btnCerrarTicket = <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'10px', width: '100%'}} onClick={()=>this.cerrarTicket()}>
          Cerrar<br/>Ticket
          <CloseIcon className={classes.rightIcon}/>
        </Button>
      }

      var textField = <MuiThemeProvider theme={FormInputs}>
        <TextField
          error={mensajeE}
          label="Mensaje"
          placeholder="Mensaje"
          id="mensaje"
          onChange={this.handleChange('mensaje')}
          margin="normal"
          variant="outlined"
          style={{width:'100%'}}
          multiline={true}
          rowsMax={7}
          rows={7}
          value={mensaje}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{maxLength: 128}}
          helperText={maxCharacters}
        />
      </MuiThemeProvider>

      var dokaM = <DokaManager
        key={componentKey}
        type="image"
        labelIdle={[
          'Arrastra y suelta ó <span class="filepond--label-action">busca</span> una foto del problema resuelto (unicamente al cerrar el ticket)'
        ]}
        ajaxSource={ajaxSource}
        urlServerUpload={"uploadManager/index.php?option=imgTicketCerrar&ticketId="+ticketSelected}
        onprocessfile={(error, file)=>this.getFileId(error, file)}
        onremovefile={(error, file)=>this.onRemoveFile(error, file)}
        onaddfile={this.onAddfile}
        fileRenameFunction={(file) => {return "imgCloseTicket"+file.extension}}
        acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
        imagePreviewHeight={250}
        imageEditInstantEdit={false}
        editorOptions={{
          cropAspectRatioOptions: [
            {
              label: 'Foto Ticket',
            }
          ]
        }}
      />

      if(checkPermisos("126")) {
        textField = "";
        dokaM = "";
      }

      return(
        <div className="col-md-12" style={{margin:'0px', textAlign:'center'}}>
          <MuiThemeProvider theme={FormButtons}>
            {btnAgregarComentario}
            {btnCerrarTicket}
          </MuiThemeProvider>
          {textField}
          {dokaM}
        </div>
      )
    }
  }

  renderTickets = () => {
    const self = this;
    const {classes} = this.props;
    const {ticketsAbiertos, ticketSelected} = this.state;

    if ((Object.keys(ticketsAbiertos).length > 0)) {

      return (
        <MuiThemeProvider theme={FormInputs}>
          <div className="row" style={{paddingBottom: '20px', margin: '0px', width: '100%', marginTop: '20px'}}>
            <div className="col-md-6" style={{padding: '0px 3px', paddingBottom:'20px'}}>

              {ticketsAbiertos.map(function (arr, index) {

                var ticketSelectedClass = classes.cardTicket;

                if(ticketSelected === arr.ticketId) {
                  ticketSelectedClass = classes.cardTicketSelected;
                }

                var chipReincidencia = "";
                if(arr.reincidencia === "1") {
                  chipReincidencia = <Chip label={"Reincidencia del ticket: "+ arr.originalTicketId} className={classes.chipClose} />
                }

                return (
                  <div className="col-md-12" style={{padding: '0px', marginBottom:'10px'}}>
                    <Button style={{padding:'0px', textAlign:'left', width:'100%'}} onClick={()=>self.handleTicketSelected(arr.ticketId, arr, 1)}>
                      <Card className={ticketSelectedClass} style={{marginBottom: '0px'}}>
                        <CardContent className="cardContent" style={{paddingBottom: '0px'}}>
                          <div className="row" style={{ margin: '0px',width: '100%',borderBottom: '1px dashed #FFF',paddingBottom: '5px'}}>
                            <div className="col-md-4" style={{padding: '0px'}}>
                              <span><h5 style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>Ticket: {arr.ticketId}</h5></span>
                            </div>
                            <div className="col-md-8" style={{textAlign: 'right', padding: '0px'}}>
                              <span><h5 style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>{arr.fechaIni}</h5></span>
                            </div>
                          </div>
                          <div className="row" style={{ margin: '0px',width: '100%', paddingTop: '10px'}}>
                            <div className="col-md-12" style={{padding:'0px'}}>
                              <span><h6 style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Solicitante:</h6></span>
                              <span><h5 style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}>{arr.solicitante}</h5></span>
                            </div>
                            <div className="col-md-12" style={{padding:'0px', textAlign:'right'}}>
                              {chipReincidencia}
                              <Chip label="En curso" className={classes.chipCurso} />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Button>
                  </div>
                )
              })}
            </div>
            <div className="col-md-6" style={{padding:'0px'}}>
              {this.renderTicketForm()}
            </div>
          </div>
        </MuiThemeProvider>
      )
    } else {
      return (
        <div className="row" style={{margin: '0px', width: '100%'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop: '40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png"
                   style={{width: '100px'}}/>
              <h4>Sin tickets abiertos.</h4>
            </div>
          </div>
        </div>
      )
    }
  }

  renderTicketInfo = () => {
    const self = this;
    const {ajaxSource, classes, checkPermisos} = this.props;
    const {ticketSelected, value, tickeDataSelected, bitacora} = this.state;

    var casaLocal = ""
    if(checkPermisos("124") && checkPermisos("125")) {
      casaLocal = "Res-Pla/Local-Casa";
    } else if(checkPermisos("124")) {
      casaLocal = "Residencial/Casa";
    } else if(checkPermisos("125")) {
      casaLocal = "Plaza/Local";
    }

    if(value === 0 || value === 1) {
      if (ticketSelected !== "") {

        var chip =  <Chip icon={<ClockIcon />} label={"Tiempo en curso: "+tickeDataSelected.tiempoAbierto} className={classes.chip} style={{fontSize:'20px'}}/>
        var evidencia = "";

        if(tickeDataSelected.ticketStatus === "1") {
          chip =  <Chip icon={<ClockIcon style={{color:'#FFF'}} />} label={"Tiempo solución: "+tickeDataSelected.tiempoAbierto} className={classes.chipClose} style={{fontSize:'20px'}}/>

          var estrellas = "";

          var est = <IconButton>
            <StarIcon style={{color:'#0345ea'}}/>
          </IconButton>;

          var ests = <div></div>;
          if(tickeDataSelected.estrellas === "1") {
            ests = <div>
              <h3>Satisfacción mala</h3>
              {est}
            </div>
          }
          if(tickeDataSelected.estrellas === "2") {
            ests = <div>
              <h3>Satisfacción regular</h3>
              {est}
              {est}
            </div>
          }
          if(tickeDataSelected.estrellas === "3") {
            ests = <div>
              <h3>Satisfacción buena</h3>
              {est}
              {est}
              {est}
            </div>
          }
          if(tickeDataSelected.estrellas === "4") {
            ests = <div>
              <h3>Satisfacción muy buena</h3>
              {est}
              {est}
              {est}
              {est}
            </div>
          }

          if(tickeDataSelected.estrellas !== 0 && tickeDataSelected.estrellas !== null) {
            estrellas = <div className="col-md-12 text-center" style={{color: 'rgb(115, 115, 115)', marginBottom:'20px'}}>
              {ests}
            </div>
          }

          evidencia =
            <ExpansionPanel style={{marginTop: '5px'}}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography style={{color: '#737373'}}>Evidencia Solución</Typography>
                <div className={classes.secondaryHeading}>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{display: 'inline'}}>
                <div className="row" style={{width: '100%', margin: '0px'}}>
                  {estrellas}
                  <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                    <img className="img-raised rounded img-fluid" src={ajaxSource + tickeDataSelected.imgPathClose}
                         style={{width: '100%'}}/>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
        }

        return (
          <div className="col-md-12" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: '10px', paddingTop: '10px'}}>
            <Card className={classes.cardTicketSelected}>
              <CardContent className="cardContent">
                <div className="row" style={{ margin: '0px',width: '100%',borderBottom: '1px dashed #FFF',paddingBottom: '5px'}}>
                  <div className="col-md-4">
                    <span><h5 className="text-white" style={{marginTop: '0px', marginBottom: '0px', textTransform:'initial'}}>Ticket: {tickeDataSelected.ticketId}</h5></span>
                  </div>
                  <div className="col-md-8" style={{textAlign: 'right'}}>
                    <span><h5 className="text-white" style={{marginTop: '0px', marginBottom: '0px', textTransform:'initial'}}>{tickeDataSelected.fechaIni}</h5></span>
                  </div>
                </div>
                <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
                  <div className="col-md-6">
                    <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Nombre:</h6></span>
                    <span><h4 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{tickeDataSelected.solicitante}</b></h4></span>
                  </div>
                  <div className="col-md-6">
                    <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>{casaLocal}:</h6></span>
                    <span><h4 className="text-white" style={{marginTop: '0px',marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{tickeDataSelected.residencialSolicitante}/{tickeDataSelected.casaSolicitante}</b></h4></span>
                  </div>
                </div>
                <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
                  <div className="col-md-12">
                    <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Area/Responsale:</h6></span>
                    <span><h4 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{tickeDataSelected.area}/{tickeDataSelected.responsable}</b></h4></span>
                  </div>
                </div>
              </CardContent>
            </Card>

            <MuiThemeProvider theme={ExpansionPanels}>
              <ExpansionPanel style={{marginTop: '5px'}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                  <Typography style={{color: '#737373'}}>Mensaje</Typography>
                  <div className={classes.secondaryHeading}>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'inline'}}>
                  <div className="row" style={{width: '100%', margin: '0px'}}>
                    <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                      <img className="img-raised rounded img-fluid" src={ajaxSource + tickeDataSelected.imgPath}
                           style={{width: '100%'}}/>
                    </div>
                    <div className="col-md-12" style={{color: 'rgb(115, 115, 115)', marginTop: '10px'}}>
                      {tickeDataSelected.mensaje}
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel style={{marginTop: '5px'}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                  <Typography style={{color: '#737373'}}>Bitácora</Typography>
                  <div className={classes.secondaryHeading}>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'inline'}}>
                  <div className="row" style={{width: '100%', margin: '0px'}}>
                    {bitacora.map(function (arr, index) {

                      return(
                        <div className="col-md-12">
                          <Card className={classes.card} style={{marginBottom: '10px'}}>
                            <CardContent>
                              <div style={{whiteSpace:'pre-line'}}>
                                {arr.comentario}
                              </div>
                              <div style={{width:'100%', textAlign:'right', fontSize:'13px', marginBottom:'-20px'}}>
                                {arr.comentarioFecha}
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      )
                    })}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {evidencia}
            </MuiThemeProvider>
            <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
              {chip}
            </div>
          </div>
        )
      } else {
        return (
          <div className="row" style={{margin: '0px', width: '100%'}}>
            <div className="col-md-12">
              <div className="text-center" style={{marginTop: '40px'}}>
                <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png"
                     style={{width: '100px'}}/>
                <h4>Selecciona un ticket.</h4>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderTicketsCerrados = () => {
    const self = this;
    const {classes} = this.props;
    const {ticketsCerrados, ticketSelected, buscarTicket} = this.state;

    var tickets = <div className="row" style={{margin: '0px', width: '100%'}}>
      <div className="col-md-12">
        <div className="text-center" style={{marginTop: '40px'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png"
               style={{width: '100px'}}/>
          <h4>Sin tickets cerrados.</h4>
        </div>
      </div>
    </div>

    if (Object.keys(ticketsCerrados).length > 0 || buscarTicket !== "") {
      tickets = <MuiThemeProvider theme={FormInputs}>
        <div className="row" style={{paddingBottom: '20px', margin: '0px', width: '100%', marginTop: '20px'}}>
          <div className="col-md-8" style={{padding: '0px 3px', marginBottom:'10px'}}>
            <TextField
              label="Búscar"
              placeholder="Búscar por folio, nombre, no.local"
              id="buscarTicket"
              onChange={this.handleChange('buscarTicket')}
              margin="normal"
              value={buscarTicket}
              variant="outlined"
              style={{width:'100%'}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-4 text-center" style={{padding: '0px 3px', marginBottom:'10px'}}>
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" style={{marginTop:'20px'}} color="primary" className={classes.button} onClick={()=>this.buscarTicket()}>
                Buscar
                <SearchIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
          </div>
          <div className="col-md-12" style={{padding: '0px 3px', paddingBottom:'20px'}}>

            {ticketsCerrados.map(function (arr, index) {

              var ticketSelectedClass = classes.cardTicket;

              if(ticketSelected === arr.ticketId) {
                ticketSelectedClass = classes.cardTicketSelected;
              }

              var chipTotalReincidencias = "";
              if(arr.totalReincidencias > 0) {
                chipTotalReincidencias = <Chip label={"Reincidencias: "+ arr.totalReincidencias} className={classes.chipClose} />
              }

              return (
                <div className="col-md-6" style={{padding: '0px', marginBottom:'10px'}}>
                  <Button style={{padding:'0px', textAlign:'left', width:'100%'}} onClick={()=>self.handleTicketSelected(arr.ticketId, arr)}>
                    <Card className={ticketSelectedClass} style={{marginBottom: '0px'}}>
                      <CardContent className="cardContent" style={{paddingBottom: '0px'}}>
                        <div className="row" style={{ margin: '0px',width: '100%',borderBottom: '1px dashed #FFF',paddingBottom: '5px'}}>
                          <div className="col-md-4" style={{padding: '0px'}}>
                            <span><h5 style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>Ticket: {arr.ticketId}</h5></span>
                          </div>
                          <div className="col-md-8" style={{textAlign: 'right', padding: '0px'}}>
                            <span><h5 style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>{arr.fechaIni}</h5></span>
                          </div>
                        </div>
                        <div className="row" style={{ margin: '0px',width: '100%', paddingTop: '10px'}}>
                          <div className="col-md-12" style={{padding:'0px'}}>
                            <span><h6 style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Solicitante:</h6></span>
                            <span><h5 style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}>{arr.solicitante}</h5></span>
                          </div>
                          <div className="col-md-12" style={{padding:'0px', textAlign:'right'}}>
                            {chipTotalReincidencias}
                            <Chip label={"Cerrado el "+arr.fechaFin} className={classes.chipCurso} />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      </MuiThemeProvider>
    }

    return(
      <div className="row" style={{paddingBottom: '20px', margin: '0px', width: '100%', marginTop: '20px'}}>
        <div className="col-md-12">
          <span><h3 className="darkGrayUpera" style={{margin:'0px'}}>Ultimos 10 tickets cerrados</h3> <h5 className="darkGrayUpera" style={{margin:'0px'}}>ó busca un ticket</h5></span>
        </div>
        <div className="col-md-6">

        </div>
        {tickets}
      </div>
    )
  }

  render() {
    const {classes, theme, dataUser, ajaxSource, cerrarNotificacion, checkPermisos, crearNotificacion, getData, crearAlerta, cerrarAlerta} = this.props;
    const {value} = this.state;

    var tabRevisarTickets = "";
    var containerDivRevisarTickets = <div></div>
    if(checkPermisos("1") || checkPermisos("119")) {
      tabRevisarTickets = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <AddTicketIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>01</div>
            <div className="textTab"><b>Revisar </b><br/><div className="textTabSub">tickets nuevos y en curso.</div></div>
            <div className="textTabSmall">Revisa el estatus de los tickets en curso.</div>
          </div>
        </div>
      } />

      containerDivRevisarTickets = this.renderTickets();
    }

    var tabBuscarTicket = "";
    var containerDivBuscarTicket = <div></div>
    if(checkPermisos("1") || checkPermisos("120")) {
      tabBuscarTicket = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <ReOpenTicketIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>02</div>
            <div className="textTab"><b>Buscar </b><br/><div className="textTabSub">ticket ya cerrado.</div></div>
            <div className="textTabSmall">Lista de tickets cerrados.</div>
          </div>
        </div>
      } />

      containerDivBuscarTicket = this.renderTicketsCerrados();
    }


    return (
      <div className="row" style={{margin:'0px', width:'100%'}}>
        <div className="col-md-7">
          <Card className={classes.card}>
            <CardContent className="cardContent">
              <MuiThemeProvider theme={tabs}>
                <AppBar position="static" color="default">
                  <StyledTabs
                    value={value}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    {tabRevisarTickets}
                    {tabBuscarTicket}
                  </StyledTabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={this.state.value}
                  onChangeIndex={this.handleChangeIndex}
                  style={{overFlow:'hidden', padding: ''}}
                  slideClassName="slideOverflow"
                >
                  <TabContainer dir={theme.direction}>
                    {containerDivRevisarTickets}
                  </TabContainer>
                  <TabContainer dir={theme.direction}>
                    {containerDivBuscarTicket}
                  </TabContainer>
                </SwipeableViews>
              </MuiThemeProvider>
            </CardContent>
          </Card>
        </div>
        <div className="col-md-5" style={{padding:'0px'}}>
          {this.renderTicketInfo()}
        </div>
      </div>
    );
  }
}

DivRevisarTickets.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivRevisarTickets);
