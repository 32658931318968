/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../../componentsmKit/Header/Header.js";
import Footer from "../../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../../componentsmKit/Grid/GridItem.js";
import Button from "../../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

class HomeBannerDos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val:""
    }
  }

  render() {
    const {classes, mobile, changePage} = this.props;

    return(
      <div id={!mobile ? "" : "homeBannerUno"} style={{ width:'100%', borderRadius:'0px', backgroundColor: !mobile ? '#FFF':'#f2f2f2'}}>
        <div className={classes.container}>
          <div style={{width:'100%'}}>
            <GridContainer style={{alignItems:'center'}}>
              <GridItem xs={12} sm={12} md={5}>
                {!mobile ?
                  <div style={{width:'100%', marginTop:'50px'}}>
                    <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'2.8125rem', lineHeight:'47px'}}>Trabaja en <br></br> una plataforma <br></br> que se ajusta <br></br> a tus necesidades.</h1>
                  </div>
                  :
                  <div style={{width:'100%', marginTop:'50px', textAlign:'center'}}>
                    <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'1.8125rem', lineHeight:'30px'}}>Trabaja en <br></br> una plataforma <br></br> <font style={{color:'#036adb'}}>que se ajusta</font> <br></br> <font style={{color:'#036adb'}}>a tus necesidades.</font></h1>
                  </div>
                }
                {!mobile ?
                <div style={{width:'100%'}}>
                  <h4 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins Medium', color:'#7e7e84', fontWeight:'50', fontSize:'15px'}}>Con upera obtienes una plataforma con herramientas <br></br> avanzadas de administración y contabilidad</h4>
                </div>
                  :
                  <div style={{width:'100%', textAlign:'center', marginBottom:'0px'}}>
                    <h4 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins Medium', color:'#7e7e84', fontWeight:'50', fontSize:'12px'}}>Con upera obtienes una plataforma con herramientas <br></br> avanzadas de administración y contabilidad</h4>
                  </div>
                }
                {!mobile ?
                  <div style={{width: '100%', marginTop: '30px'}}>
                    <Button
                      color="blueUpera"
                      size="md"
                      onClick={() => changePage("uperaHerramientas")}
                    >
                      Conoce las herramientas
                    </Button>
                  </div>
                  :
                  <div style={{width: '100%', marginTop: '0px', textAlign:'center', marginBottom:'20px'}}>
                    <Button
                      color="blueUpera"
                      size="md"
                      onClick={() => changePage("uperaHerramientas")}
                    >
                      Conoce las herramientas
                    </Button>
                  </div>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={7}>
                <div style={{width:'100%', textAlign:'center'}}>
                  <img src="assets/img/webPage/apps2.png" style={{width:'100%'}}></img>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

HomeBannerDos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeBannerDos);
