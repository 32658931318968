/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowIcon from '@material-ui/icons/ArrowForwardRounded';
import ResetIcon from '@material-ui/icons/RestoreRounded';
import Slide from '@material-ui/core/Slide';
import Loader from "./commons/Loader";

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: '50px',
  },
  rightIconSmall: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  h1: {
    fontSize: '60px',
    color:'#FFF',
    margin:'0px',
    lineHeight:'50px'
  },
  h1Mobile: {
    fontSize: '55px',
    color:'#FFF',
    margin:'0px',
    lineHeight:'50px'
  },
  h3: {
    marginBottom: '3vh'
  },
  widthIcon: {
    width: '100px',
    marginLeft: '20px'
  }
});

const LoginInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'rgba(255,255,255,0.5) !important',
        padding: '1px 1px 5px 10px',
        marginTop: '-11px',
        fontFamily:'Poppins Regular',
      },
    },
    MuiInput: {
      input: {
        color: '#bfc2cb !important',
        padding: '6px 3px 4px 8px',
        fontFamily:'Poppins Regular',
      },
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none'
        },
        '&:before': {
          borderBottom: 'none'
        }
      },
    },
    MuiFormControl: {
      root: {
        background: 'rgba(0,0,0,0.5)',
        borderRadius: '8px',
        height: '44px'
      }
    },
    MuiInputLabel: {
      animated: {
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
      },
      shrink: {
        transform : 'translate(0, 14.5px) scale(0.75)'
      }
    }
  },
});

const ButtonsLogin = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root:{
        fontFamily:'Poppins Regular',
        textTransform:'none'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

/*Componentes de la aplicación*/

/*Variables globales*/

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      emailLogin:"",
      passLogin:"",
      showLoginForm: false,
      showLoginLoading:false,
      showResetPassword:false
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  showLoginForm = () => {
    this.setState({showLoginForm:true, showResetPassword:false});
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login = () => {
    const self    = this;
    const {crearNotificacion, loginSession, getData} = this.props;
    const {emailLogin, passLogin} = this.state;

    if(emailLogin === "") {
      crearNotificacion("warning", "Ingresa tu correo.", "bottom", "right");
    } else if(passLogin === ""){
      crearNotificacion("warning", "Ingresa tu contraseña.", "bottom", "right");
    } else {

      this.setState({showLoginForm:false, showLoginLoading:true},()=> {

        getData(self.props.ajaxSource, "login/login.php", {
          emailLogin: emailLogin,
          passLogin: passLogin
        }).then(function (response) {
          var data = response.data[0];

          if(data.error === 1){
            loginSession(data, self.props.router);
          } else if(data.error === 2){
            self.setState({showLoginLoading:false, showLoginForm:true},()=> {
              crearNotificacion("error", "No cuentas con privilegios.", "bottom", "right");
            });
          } else if(data.error === 3){
            self.setState({showLoginLoading:false, showLoginForm:true},()=> {
              crearNotificacion("error", "Tu contraseña es incorrecta.", "bottom", "right");
            });
          } else if(data.error === 4){
            self.setState({showLoginLoading:false, showLoginForm:true},()=> {
              crearNotificacion("error", "Email no existe.", "bottom", "right");
            });
          } else if(data.error === 5){
            self.setState({showLoginLoading:false, showLoginForm:true},()=> {
              crearNotificacion("error", "Tu usuario aun no se encuentra activo, favor de contactar al Administrador.", "bottom", "right");
            });
          }
        });
      });
    }
  }

  handleShowResetPassword = () => {
    this.setState({showResetPassword:true});
  }

  handleHideResetPassword = () => {
    this.setState({showResetPassword:false});
  }

  resetPassword = () => {
    const self = this;
    const {crearNotificacion, getData} = this.props;
    const {emailLogin} = this.state;

    if(emailLogin === "") {
      crearNotificacion("warning", "Ingresa el correo con el que inicias sesión.", "bottom", "right");
    } else {

      this.setState({showLoginForm:false, showLoginLoading:true},()=> {

        getData(self.props.ajaxSource, "login/resetPassword.php", {
          emailLogin: emailLogin,
        }).then(function (response) {
          var data = response.data[0];

          if(data.error === 1){
            self.handleHideResetPassword();

            self.setState({showLoginLoading:false, showLoginForm:true, showResetPassword:false},()=> {
              crearNotificacion("success", "Tu contraseña fue restablecida, revisa tu correo.", "bottom", "right");
            });

          } else if(data.error === 2){
            self.setState({showLoginLoading:false, showLoginForm:true},()=> {
              crearNotificacion("error", "Este correo no se encuentra registrado.", "bottom", "right");
            });
          }
        });
      });
    }
  }

  renderLoginForm = () => {
    const {classes, mobile} = this.props;
    const {showLoginForm, showResetPassword} = this.state;

    var divSlide = {maxWidth:'230px', marginLeft:'50px'};
    var textFieldStyle = {width: '230px', float: 'right'};
    var buttonLoginStyle = {margin: 'auto', borderRadius: '8px', marginTop: '18px', float: 'right', color:'#FFF'};
    var slideDirection = "right";
    if(mobile === true) {
      divSlide = {maxWidth: '100%', marginLeft: '0px', textAlign: 'center'};
      textFieldStyle = {width: '70%'};
      buttonLoginStyle = {margin: '0px', borderRadius: '8px', marginTop: '0px', color:'#FFF'};
      slideDirection = "up";

    }

    return (
      <Slide direction={slideDirection} in={showLoginForm} mountOnEnter unmountOnExit>
        <div style={divSlide}>
          <MuiThemeProvider theme={LoginInputs}>
            <TextField
              label="Email"
              id="emailLogin"
              onChange={this.handleChange('emailLogin')}
              margin="normal"
              style={textFieldStyle}
              type="email"
            />
            {!showResetPassword && (
            <TextField
              label="Contraseña"
              id="passLogin"
              onChange={this.handleChange('passLogin')}
              onKeyDown={this.handleKeyDown}
              margin="normal"
              style={textFieldStyle}
              type="password"
            />
            )}
          </MuiThemeProvider>
          {!showResetPassword && (
            <div>
              <MuiThemeProvider theme={ButtonsLogin}>
                <Button color="default" className={classes.button} style={buttonLoginStyle} onClick={()=>this.handleShowResetPassword()}>
                  ¿Olvidaste tu contraseña?
                </Button>
              </MuiThemeProvider>
            </div>
          )}
          {!showResetPassword && (
          <div>
            <MuiThemeProvider theme={ButtonsLogin}>
              <Button variant="contained" color="primary" className={classes.button} style={buttonLoginStyle} onClick={()=>this.login()}>
                Entrar
                <ArrowIcon className={classes.rightIconSmall}/>
              </Button>
            </MuiThemeProvider>
          </div>
          )}
          {showResetPassword && (
            <div>
              <MuiThemeProvider theme={ButtonsLogin}>
                <Button variant="contained" color="primary" className={classes.button} style={buttonLoginStyle} onClick={()=>this.resetPassword()}>
                  Recuperar
                  <ResetIcon className={classes.rightIconSmall}/>
                </Button>
              </MuiThemeProvider>
            </div>
          )}
        </div>
      </Slide>
    )
  }

  renderLoginLoading = () => {
    const {mobile} = this.props;
    const {showLoginLoading} = this.state;
    var slideDirection = "right";
    var style = {marginLeft: '20px', marginTop: '18px'}
    if(mobile === true) {
      slideDirection = "up";
      style = {marginTop: '60px'}
    }
    return (
      <div style={style}>
        <Slide direction={slideDirection} in={showLoginLoading} mountOnEnter unmountOnExit>
          <Loader/>
        </Slide>
      </div>
    )
  }

  render() {
    const {classes, version, mobile, changePage, mantto, handleOpenDialogUpdateAvalible} = this.props;

    var classWelcome = classes.h1;
    var styleDivLoginButton = {};
    var displayFlex = {display:'flex', marginTop:'20px'};
    var h1Center = {};
    var imgStyle = {width:'180px', marginBottom: '40px'};
    var textLoginStyle = {marginTop:'50px', textAlign:'center'}
    var colStyle = {};
    var footer = <footer className="footer" style={{padding:'0px', display:'inline'}}>
      <div className="container">
        <nav className="float-center">
          <ul>
            <li>
              <a href="#l" className="btn-link" style={{color:'#FFF'}}>
                México. Copyright &copy; 2019 upera. Todos los derechos reservados
              </a>
            </li>
            <li>
              <a href="#l" className="btn-link" style={{color:'#FFF'}} onClick={()=>handleOpenDialogUpdateAvalible(1)}>
                {version}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>

    var backGroundImage = "assets/img/login/bg-black-1.png";
    var styleBackGroundImage = {
      backgroundImage: "url(" + backGroundImage + ")",
      backgroundSize: "cover",
      backgroundPosition: "top center"
    };

    if(mobile === true) {
      backGroundImage = "assets/img/login/bg-black.png"
      styleBackGroundImage = {
        backgroundImage: "url(" + backGroundImage + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height:'auto'
      };
      classWelcome = classes.h1Mobile;
      styleDivLoginButton = {width: '100%', textAlign: 'center', marginBottom: '20px'};
      displayFlex = {};
      h1Center = {textAlign: 'center'};
      imgStyle = {width:'150px', marginBottom: '40px', marginTop:'15px'}
      colStyle = {textAlign:'center'}
      footer = "";
      textLoginStyle = {marginTop:'50px', marginBottom:'200px', textAlign:'center'}
    }

    var login = <div className="page-header login-page" style={styleBackGroundImage}>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className="container" style={{marginTop:'2%'}}>
          <div className="row">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12" style={colStyle}>
                  <img src="assets/img/login/logo-white.png" style={imgStyle} onClick={()=>changePage('inicio')}/>
                  <h3 className={classWelcome} style={h1Center}>Bienvenido <br/> a la comunidad <br/> <font style={{color:'#29ABE2'}}>upera.</font></h3>
                </div>
                <div className="col-md-12" style={displayFlex}>
                  <div style={styleDivLoginButton}>
                    <MuiThemeProvider theme={ButtonsLogin}>
                      <Button variant="contained" color="primary" className={classes.button} style={{margin: 'auto', borderRadius: '8px', marginTop: '18px'}} onClick={()=>this.showLoginForm()}>
                        Iniciar Sesión
                        <ArrowIcon className={classes.rightIcon}/>
                      </Button>
                    </MuiThemeProvider>
                  </div>
                  {this.renderLoginForm()}
                  {this.renderLoginLoading()}
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row" style={{margin:'0px'}}>
                <div className="col-md-12">
                  <img src="assets/img/login/icns-datos_login.png" style={{width:'100%'}}/>
                </div>
                <div className="col-md-12" style={textLoginStyle}>
                  <img src="assets/img/login/text-login.png" style={{width:'80%'}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      {footer}
    </div>

    if(mantto === 1) {

      backGroundImage = "assets/img/mantto_web.png";
      styleBackGroundImage = {
        backgroundImage: "url(" + backGroundImage + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center"
      };

      if(mobile === true) {
        backGroundImage = "assets/img/mantto_web_mobile.png"
        styleBackGroundImage = {
          backgroundImage: "url(" + backGroundImage + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        };
      }

      login = <div className="page-header login-page" style={styleBackGroundImage}>
      </div>;

    }

    return (
      <div>
        {/*Navbar*/}
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white" id="navigation-example" style={{padding: '10px'}}>
          <div className="container">
            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav">
                <img src="assets/img/login/icn-home.png" style={{width:'120px', cursor: 'pointer'}} onClick={()=>changePage('inicio')}/>
              </ul>
            </div>
          </div>
        </nav>
        {/*Fin Navbar*/}
        <div className="wrapper wrapper-full-page">
          {login}
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
