/*Componentes globales*/
import React, { Component } from "react";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Notificacion from "./componentes/Notificaciones";
import Alerta from "./componentes/Alertas";
import { curi } from "@curi/router";
import Browser from "@hickory/browser";
import { curiProvider } from "@curi/react-dom";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import UperaTour from "./componentes/commons/UperaTour";
import ErrorsReporterDialog from "./componentes/commons/ErrorsReporterDialog";
import { clarity } from 'react-microsoft-clarity';

/*Componentes de la aplicación*/
import routes from "./routes";
import DialogNewUpdateAvalible from "./componentes/commons/index/DialogNewUpdateAvalible";
import Loader from "./componentes/commons/Loader";
import ErrorReporter from "./componentes/commons/ErrorReporter";

/*Variables globales*/
const $ = require("jquery");
const history = Browser();
const router = curi(history, routes);
const Router = curiProvider(router);
var cookies = new Cookies();

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});

const uperaTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Poppins Regular",
  },
});

const headerButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Poppins Regular",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "8px",
      },
      containedPrimary: {
        backgroundColor: "#0345ea",
        "&:hover": {
          backgroundColor: "#1a49bf",
        },
      },
      containedSizeLarge: {
        padding: "19px 22px",
      },
      label: {
        textTransform: "none",
      },
    },
  },
});

// ajaxSource host: "https://upera.mx/",
// ../
//1400 x 900 945166

// version "V 8.3.7",
// test http://localhost:81/upera-backend/api/

//prod "https://upera.mx/api/"
var t = 0;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: true,
      version: "V 8.4.0",
      newVersion: "",
      ajaxSource: "https://upera.mx/api/",
      pathProdu: "../",
      prodEnv: "https://upera.mx/api/",
      page: "login",
      mantto: 0,
      dataUser: [],
      dataUserSet: false,
      mobile: false,
      mobileiPad: false,
      login: false,
      mostrarNotificacion: false,
      notificacionTipo: "info",
      notificacionMsg: "process",
      notificacionVertical: "top",
      notificacionHorizontal: "right",
      notificacionDuration: 3000,
      mostrarAlerta: false,
      tipoAlerta: "info",
      tituloAlerta: "",
      txtAlerta: "",
      showCancelButtonAlerta: false,
      onConfirmAlerta: function () {},
      pageLoaded: false,
      changePageBand: 0,
      intervalCheckActiveSession: "",
      intervalCheckSistemVersion: "",
      openDialogUpdateAvalible: false,
      handleOpenNovedades: false,
      versionMejoras: [],
      logVersiones: [],
      documentHeight: 0,
      openTour: false,
      typeTour: "",
      dialogStateForTour: false,
      handleOptionPagoEnLinea: 0,
      handleOptionEstadoCuentaPersonal: 0,
      mobileWebPage: false,
      width: 0,
      height: 0,
      optionMenu: "",
      handleReloadComponent: 0,
      openDialogDocumentos: false,
      handleOpenDialogDocumentos: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    clarity.init('ngrw74ve1s');
  }

  componentDidMount() {
    const { ajaxSource, login, prodEnv, dev } = this.state;

    this.checkSession();
    this.checkSistemVersion();
    this.setState({
      intervalCheckSistemVersion: setInterval(this.checkSistemVersion, 60000),
    });
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
    window.onmousemove = this.resetTimer; // catches mouse movements
    window.onmousedown = this.resetTimer; // catches mouse movements
    window.onclick = this.resetTimer; // catches mouse clicks
    window.onscroll = this.resetTimer; // catches scrolling
    window.onkeypress = this.resetTimer; //catches keyboard actions

    var protocol =
      window.location.href.indexOf("https://") == 0 ? "https" : "http";
    if (dev === false && protocol === "http" && login === false) {
      window.location = "https://upera.mx";
    }
  }

  resetTimer = () => {
    const self = this;
    clearTimeout(t);
    t = setTimeout(self.handleAutoCloseSession, 600000); // time is in milliseconds (1000 is 1 second)
  };

  handleAutoCloseSession = () => {
    const { login } = this.state;
    if (login === true) {
      this.closeSession(1);
    }
  };

  handleOpenDialogDocumentos = () => {
    const { handleOpenDialogDocumentos } = this.state;
    this.setState({
      openDialogDocumentos: true,
      handleOpenDialogDocumentos: handleOpenDialogDocumentos + 1,
    });
  };

  handleCloseDialogDocumentos = () => {
    this.setState({ openDialogDocumentos: false });
  };

  updateWindowDimensions() {
    this.setState(
      { width: window.innerWidth, height: window.innerHeight },
      () => {
        this.updateDimensions();
      }
    );
  }

  getData = (ajaxSource, url, data) => {
    const res = axios.post(ajaxSource + url, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return res;
  };

  checkSession = () => {
    var dataUserCookie = cookies.get("uperaDataUser");
    var loginCookie = cookies.get("uperaLogin");
    var pageCookie = cookies.get("uperaPage");
    var uperaOptionMenu = cookies.get("uperaOptionMenu");

    if (loginCookie === "true") {
      this.setState({
        intervalCheckActiveSession: setInterval(this.checkActiveSession, 2000),
      });
      this.setState(
        {
          login: true,
          dataUser: dataUserCookie,
          page: pageCookie,
          dataUserSet: true,
          optionMenu: uperaOptionMenu,
        },
        () => {
          router.navigate({ name: pageCookie });
          $(window).scrollTop(0);
        }
      );
    } else {
      var path = window.location.pathname.split("/")[1];
      console.log("path: " + path);
      if (path === "") {
        path = "inicio";
      }

      this.setState({ page: path }, () => {
        cookies.set("uperaPage", path, { path: "/" });
        router.navigate({ name: path });
        $(window).scrollTop(0);
      });
    }
  };

  updateSistemVersion = () => {
    cookies.set("uperaUpdated", true, { path: "/" });
    window.location.reload();

    if('caches' in window){
      caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach(name => {
                  caches.delete(name);
              })
          });
  
          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload();
      }
  };

  checkSistemVersion = (option) => {
    const self = this;
    const { version, ajaxSource } = this.state;
    var uperaUpdated = cookies.get("uperaUpdated");

    if (uperaUpdated === "true") {
      this.crearNotificacion(
        "success",
        "Actualización correcta.",
        "bottom",
        "right",
        3000
      );
      cookies.set("uperaUpdated", "false", { path: "/" });
    }

    this.getData(ajaxSource, "login/checkSistemVersion.php", {
      version: version,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        self.setState({ logVersiones: data[2] });

        if (version !== data[0]) {
          self.setState({ newVersion: data[0], versionMejoras: data[1] });
          console.log("nueva version disponible");
          self.handleOpenDialogUpdateAvalible();
        }
      }
    });
  };

  handleOpenDialogUpdateAvalible = (option) => {
    this.setState({ openDialogUpdateAvalible: true });
    if (option === 1) {
      this.setState({ handleOpenNovedades: true });
    }
  };

  handleCloseDialogUpdateAvalible = () => {
    this.setState({
      openDialogUpdateAvalible: false,
      handleOpenNovedades: false,
    });
  };

  checkActiveSession = () => {
    var loginCookie = cookies.get("uperaLogin");
    if (loginCookie === "false") {
      this.closeSession();
    }
  };

  checkPermisos = (permiso, permisos) => {
    if (permisos === undefined) {
      if (this.state.dataUser.userUpera !== undefined) {
        var isInArray = this.state.dataUser.userUpera.includes(permiso);
        return isInArray;
      }
    } else {
      var isInArray = permisos.includes(permiso);
      return isInArray;
    }
  };

  updateDimensions = () => {
    const { page, width, height, handleReloadComponent } = this.state;
    if (width > 0 && width <= 900) {
      this.setState({ mobile: true });
    } else {
      this.setState({ mobile: false });
    }

    if (width > 0 && width <= 1023) {
      this.setState({ mobileWebPage: true });

      if (
        page === "inicio" ||
        page === "uperaInfo" ||
        page === "uperaContacto"
      ) {
        document.body.classList.remove("sections-page");
        document.body.classList.add("presentation-page");
      } else {
        document.body.classList.add("sections-page");
        document.body.classList.remove("presentation-page");
      }
    } else {
      this.setState({ mobileWebPage: false });
      document.body.classList.add("sections-page");
      document.body.classList.remove("presentation-page");
    }

    if (width > 0 && width <= 1140) {
      this.setState({ mobileiPad: true });
    } else {
      this.setState({ mobileiPad: false });
    }

    this.setState({
      documentHeight: height,
      handleReloadComponent: handleReloadComponent + 1,
    });
  };

  handleOpenTour = (typeTour) => {
    this.setState({ typeTour: typeTour }, () => {
      this.setState({ openTour: true });
    });
  };

  handleCloseTour = () => {
    this.setState({ openTour: false });
  };

  dialogStateForTour = (option) => {
    this.setState({ dialogStateForTour: option });
  };

  handleOptionPagoEnLinea = () => {
    const { handleOptionPagoEnLinea } = this.state;
    this.setState({ handleOptionPagoEnLinea: handleOptionPagoEnLinea + 1 });
  };

  handleOptionEstadoCuentaPersonal = () => {
    const { handleOptionEstadoCuentaPersonal } = this.state;
    this.setState({
      handleOptionEstadoCuentaPersonal: handleOptionEstadoCuentaPersonal + 1,
    });
  };

  handleScrollTo = (id) => {
    const { page } = this.state;
    if (page === "inicio") {
      $("html, body").animate(
        {
          scrollTop: $("#" + id).offset().top - 200,
        },
        500
      );
    } else {
      this.changePage("inicio", id);
    }
  };

  loginSession = (dataUser, router) => {
    var permisos = dataUser.userUpera;
    var page = "home";

    if (this.checkPermisos("6", permisos)) {
      cookies.set("uperaEmailMC", dataUser.userEmail, { path: "/" });
      page = "homeMC";
    }

    if (this.checkPermisos("18", permisos)) {
      page = "homeDIR";
    }

    this.loadedPage(false);
    this.setState({
      login: true,
      dataUser: dataUser,
      dataUserSet: true,
      page: page,
    });
    cookies.set("uperaLogin", true, { path: "/" });
    cookies.set("uperaDataUser", dataUser, { path: "/" });
    cookies.set("uperaPage", page, { path: "/" });
    router.navigate({ name: page });
    this.setState({
      intervalCheckActiveSession: setInterval(this.checkActiveSession, 2000),
    });
    $(window).scrollTop(0);
  };

  closeSession = (option) => {
    const { intervalCheckActiveSession } = this.state;
    this.updateDimensions();

    var page = "login";
    if (option === 1) {
      page = "sessionClosed";
    }

    this.setState({
      page: "login",
      login: false,
      dataUser: [],
      dataUserSet: false,
      optionMenu: "",
    });
    cookies.set("uperaDataUser", [], { path: "/" });
    cookies.set("uperaLogin", false, { path: "/" });
    cookies.set("uperaPage", page, { path: "/" });
    cookies.set("uperaOptionMenu", "", { path: "/" });
    cookies.set("uperaEmailMC", "", { path: "/" });
    $("html").removeClass("nav-open");
    router.navigate({ name: page });

    clearInterval(intervalCheckActiveSession);
    $(window).scrollTop(0);
  };

  cerrarNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState(
      { mostrarNotificacion: false, notificacionDuration: 3000 },
      () => {
        return true;
      }
    );
  };

  crearNotificacion = (tipo, msg, vertical, horizontal, duration) => {
    //FUNCION QUE CREA NOTIFICACIONES
    /*success, warning, info, error*/
    if (duration !== undefined) {
      this.setState(
        {
          notificacionDuration: duration,
          notificacionTipo: tipo,
          notificacionMsg: msg,
          notificacionVertical: vertical,
          notificacionHorizontal: horizontal,
          mostrarNotificacion: true,
        },
        () => {
          return true;
        }
      );
    } else {
      this.setState(
        {
          notificacionDuration: 3000,
          notificacionTipo: tipo,
          notificacionMsg: msg,
          notificacionVertical: vertical,
          notificacionHorizontal: horizontal,
          mostrarNotificacion: true,
        },
        () => {
          return true;
        }
      );
    }
  };

  crearAlerta = (
    tipoAlerta,
    tituloAlerta,
    txtAlerta,
    showCancelButtonAlerta,
    onConfirmAlerta
  ) => {
    if (showCancelButtonAlerta === undefined) {
      showCancelButtonAlerta = false;
      onConfirmAlerta = function () {};
    }
    this.setState({
      mostrarAlerta: true,
      tipoAlerta: tipoAlerta,
      tituloAlerta: tituloAlerta,
      txtAlerta: txtAlerta,
      showCancelButtonAlerta: showCancelButtonAlerta,
      onConfirmAlerta: onConfirmAlerta,
    });
  };

  cerrarAlerta = () => {
    this.setState({ mostrarAlerta: false });
  };

  loadedPage = (option) => {
    this.setState({ pageLoaded: option });
  };

  changePage = (page, idToScroll) => {
    const self = this;
    var changePageBand = this.state.changePageBand;
    changePageBand = changePageBand + 1;

    $("html, body").animate(
      {
        scrollTop: $("#root").offset().top - 200,
      },
      0
    );

    this.setState({ pageLoaded: false }, () => {
      this.setState({ changePageBand: changePageBand }, () => {
        this.setState({ page: page }, () => {
          cookies.set("uperaPage", page, { path: "/" });
          this.updateWindowDimensions();
          router.navigate({ name: page });

          setTimeout(function () {
            $("#bodyClick").trigger("click");

            if (idToScroll !== undefined) {
              self.handleScrollTo(idToScroll);
            }
          }, 200);
        });
      });
    });
  };

  selectCuenta = () => {
    const that = this;
    const { ajaxSource } = this.state;
    var email = cookies.get("uperaEmailMC");

    this.getData(ajaxSource, "login/login.php", {
      emailLogin: email,
      passLogin: "",
      mc: "mc",
    }).then(function (response) {
      var data = response.data[0];

      if (data.error === 1) {
        that.loginSession(data, router);
      } else if (data.error === 2) {
        that.setState({ showLoginLoading: false, showLoginForm: true }, () => {
          that.crearNotificacion(
            "error",
            "No cuentas con privilegios.",
            "bottom",
            "right"
          );
        });
      } else if (data.error === 3) {
        that.setState({ showLoginLoading: false, showLoginForm: true }, () => {
          that.crearNotificacion(
            "error",
            "Tu contraseña es incorrecta.",
            "bottom",
            "right"
          );
        });
      } else if (data.error === 4) {
        that.setState({ showLoginLoading: false, showLoginForm: true }, () => {
          that.crearNotificacion(
            "error",
            "Email no existe.",
            "bottom",
            "right"
          );
        });
      } else if (data.error === 5) {
        that.setState({ showLoginLoading: false, showLoginForm: true }, () => {
          that.crearNotificacion(
            "error",
            "Tu usuario aun no se encuentra activo, favor de contactar al Administrador.",
            "bottom",
            "right"
          );
        });
      }
    });
  };

  renderLoginOrNav = () => {
    const { classes } = this.props;
    const {
      dataUser,
      login,
      mobile,
      mobileWebPage,
      pathProdu,
      ajaxSource,
      changePageBand,
      version,
      dataUserSet,
      page,
      handleOpenDialogDocumentos,
      openDialogDocumentos,
      mantto,
      mobileiPad,
      dev,
    } = this.state;

    if (
      (dataUserSet === true || login === false) &&
      !this.checkPermisos("18")
    ) {
      var router = (
        <Router>
          {({ response, router }) => {
            const { body: Body } = response;
            return (
              <Body
                mantto={mantto}
                response={response}
                router={router}
                mobile={mobile}
                mobileWebPage={mobileWebPage}
                pathProdu={pathProdu}
                ajaxSource={ajaxSource}
                getData={this.getData}
                crearNotificacion={this.crearNotificacion}
                cerrarNotificacion={this.cerrarNotificacion}
                crearAlerta={this.crearAlerta}
                cerrarAlerta={this.cerrarAlerta}
                loginSession={this.loginSession}
                closeSession={this.closeSession}
                loadedPage={this.loadedPage}
                dataUser={dataUser}
                changePage={this.changePage}
                changePageBand={changePageBand}
                checkPermisos={this.checkPermisos}
                version={version}
                dialogStateForTour={this.dialogStateForTour}
                handleOpenDialogDocumentos={handleOpenDialogDocumentos}
                handleOpenDialogUpdateAvalible={
                  this.handleOpenDialogUpdateAvalible
                }
                openDialogDocumentos={openDialogDocumentos}
                handleCloseDialogDocumentos={this.handleCloseDialogDocumentos}
                handleScrollTo={this.handleScrollTo}
                dev={dev}
              />
            );
          }}
        </Router>
      );

      if (login === false) {
        return router;
      } else {
        var classNavBar = "navbar  bg-white   fixed-top  navbar-expand-lg ";
        if (page !== "login") {
          classNavBar =
            "navbar navbarBorderSolid  bg-white   fixed-top  navbar-expand-lg ";
        }

        var resiName1 = "";
        var resiName2 = "";
        var resiName3 = "";

        if (!this.checkPermisos("18")) {
          if (
            dataUserSet === true &&
            dataUser.userResidencialName !== undefined
          ) {
            resiName1 = dataUser.userResidencialName.toUpperCase();
            resiName2 = "";
            resiName3 = "";

            var resSplit = dataUser.userResidencialName.split(" ");

  

            if (resSplit[1] !== undefined) {
              resiName1 = resSplit[0].toUpperCase();
              resiName2 = resSplit[1].toUpperCase();
              resiName3 = resSplit[2];
            }
          }
        }

        var btnDocumentos = "";
        var btnEstadoCuenta = "";
        var btnReglamento = "";
        var btnPagoEnLinea = "";

        if (this.checkPermisos("1") || this.checkPermisos("121")) {
          btnDocumentos = (
            <li className="nav-item">
              <Button
                style={{
                  padding: "0px",
                  marginRight: "5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => this.handleOpenDialogDocumentos()}
              >
                <img
                  src="assets/img/iconos/icono_documentos.png"
                  style={{ width: "250px", marginTop: "1px" }}
                  alt="img"
                />
              </Button>
            </li>
          );
        }

        if (
          (this.checkPermisos("1") || this.checkPermisos("124")) &&
          dataUser.casasLocales.length > 0
        ) {
          btnEstadoCuenta = (
            <li className="nav-item">
              <Button
                style={{
                  padding: "0px",
                  marginRight: "5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => this.handleOptionEstadoCuentaPersonal()}
              >
                <img
                  src="assets/img/iconos/icono_edopersonalcuenta.png"
                  style={{ width: "250px", marginTop: "1px" }}
                  alt="img"
                />
              </Button>
            </li>
          );
        }

        if (this.checkPermisos("1") || this.checkPermisos("122")) {
          var residencialId = dataUser.userResidencialId;
          btnReglamento = (
            <li className="nav-item">
              <a
                href={
                  "https://upera.mx/assets/files/" +
                  residencialId +
                  "_REGLAMENTO.pdf"
                }
                target="e_blank"
                className="nav-link"
                style={{ fontSize: "15px", textTransform: "none" }}
              >
                Reglamento
              </a>
            </li>
          );
        }

        if (this.checkPermisos("1") || this.checkPermisos("4")) {
          btnPagoEnLinea = (
            <li className="nav-item">
              <Button
                style={{
                  padding: "0px",
                  marginRight: "5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => this.handleOptionPagoEnLinea()}
              >
                <img
                  src="assets/img/thumbnail_BOTON_PAGOENLINEA.png"
                  style={{ width: "250px" }}
                  alt="img"
                />
              </Button>
            </li>
          );
        }

        return (
          <nav
            className={classNavBar}
            id="sectionsNav"
            style={{ padding: "0px" }}
          >
            <div className="container" style={{ width: "100%" }}>
              <div className="navbar-translate">
                <a className="navbar-brand" href="index.html">
                  <h3 className="grayUpera" style={{ margin: "0px" }}>
                    {resiName1} <b>{resiName2}</b> <b>{resiName3}</b>
                  </h3>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav ml-auto">
                  {btnReglamento}
                  {btnEstadoCuenta}
                  {btnDocumentos}
                  {btnPagoEnLinea}
                  {cookies.get("uperaEmailMC") !== "" &&
                    cookies.get("uperaEmailMC") !== undefined &&
                    this.state.page !== "homeMC" && (
                      <li className="nav-item">
                        <MuiThemeProvider theme={headerButtons}>
                          <Button
                            variant="contained"
                            color="default"
                            size="large"
                            className={classes.button}
                            endIcon={<Icon>people</Icon>}
                            onClick={() => this.selectCuenta()}
                            style={{
                              padding: "19px 22px",
                              borderRadius: "8px",
                            }}
                          >
                            <font style={{ fontFamily: "Poppins Regular" }}>
                              Cambiar cuenta
                            </font>
                          </Button>
                        </MuiThemeProvider>
                      </li>
                    )}
                  <li className="nav-item">
                    <MuiThemeProvider theme={headerButtons}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        endIcon={<Icon>login</Icon>}
                        onClick={() => this.closeSession()}
                        style={{
                          background: "#0345ea",
                          padding: "19px 22px",
                          borderRadius: "8px",
                        }}
                      >
                        <font style={{ fontFamily: "Poppins Regular" }}>
                          Cerrar Sesión
                        </font>
                      </Button>
                    </MuiThemeProvider>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        );
      }
    }
  };

  renderMainPage = () => {
    const {
      dataUser,
      login,
      mobile,
      pathProdu,
      ajaxSource,
      changePageBand,
      version,
      dataUserSet,
      page,
      documentHeight,
      openTour,
      handleOptionPagoEnLinea,
      handleOptionEstadoCuentaPersonal,
      optionMenu,
      prodEnv,
      handleReloadComponent,
      handleOpenDialogDocumentos,
      openDialogDocumentos,
      mantto,
      mobileiPad,
      dev,
    } = this.state;

    if (dataUserSet === true) {
      var router = (
        <Router>
          {({ response, router }) => {
            const { body: Body } = response;
            return (
              <Body
                response={response}
                router={router}
                mobile={mobile}
                pathProdu={pathProdu}
                ajaxSource={ajaxSource}
                getData={this.getData}
                crearNotificacion={this.crearNotificacion}
                cerrarNotificacion={this.cerrarNotificacion}
                crearAlerta={this.crearAlerta}
                cerrarAlerta={this.cerrarAlerta}
                loginSession={this.loginSession}
                closeSession={this.closeSession}
                loadedPage={this.loadedPage}
                dataUser={dataUser}
                dataUserSet={dataUserSet}
                changePage={this.changePage}
                changePageBand={changePageBand}
                checkPermisos={this.checkPermisos}
                version={version}
                login={login}
                page={page}
                documentHeight={documentHeight}
                openTour={openTour}
                handleOpenTour={this.handleOpenTour}
                dialogStateForTour={this.dialogStateForTour}
                handleOptionPagoEnLinea={handleOptionPagoEnLinea}
                handleOptionEstadoCuentaPersonal={
                  handleOptionEstadoCuentaPersonal
                }
                optionMenu={optionMenu}
                prodEnv={prodEnv}
                handleReloadComponent={handleReloadComponent}
                handleOpenDialogDocumentos={handleOpenDialogDocumentos}
                openDialogDocumentos={openDialogDocumentos}
                handleCloseDialogDocumentos={this.handleCloseDialogDocumentos}
                mantto={mantto}
                mobileiPad={mobileiPad}
                dev={dev}
              />
            );
          }}
        </Router>
      );

      return router;
    }
  };

  render() {
    const dialogStateForTour = this.dialogStateForTour;
    var animatedLoaded = "";
    if (this.state.pageLoaded === true) {
      animatedLoaded = "loader-wrapper loaded";
    } else if (this.state.pageLoaded === false && this.state.page !== "login") {
      animatedLoaded = "loader-wrapper";
    }

    return (
      <>
        <ErrorReporter
          dataUser={this.state.dataUser}
          mobile={this.state.mobile}
          pathProdu={this.state.pathProdu}
          ajaxSource={this.state.ajaxSource}
          prodEnv={this.state.prodEnv}
          getData={this.getData}
        >
          <div className={animatedLoaded}>
            <div className="div_animation_loading" style={{ marginTop: "5px" }}>
              <Loader />
            </div>
          </div>
          <Alerta
            mostrarAlerta={this.state.mostrarAlerta}
            cerrarAlerta={this.cerrarAlerta}
            tipo={this.state.tipoAlerta}
            titulo={this.state.tituloAlerta}
            txt={this.state.txtAlerta}
            showCancelButton={this.state.showCancelButtonAlerta}
            onConfirmAlerta={this.state.onConfirmAlerta}
          />
          <Notificacion
            mostrarNotificacion={this.state.mostrarNotificacion}
            cerrarNotificacion={this.cerrarNotificacion}
            tipo={this.state.notificacionTipo}
            msg={this.state.notificacionMsg}
            vertical={this.state.notificacionVertical}
            horizontal={this.state.notificacionHorizontal}
            duration={this.state.notificacionDuration}
          />
          <DialogNewUpdateAvalible
            ajaxSource={this.state.ajaxSource}
            getData={this.getData}
            open={this.state.openDialogUpdateAvalible}
            handleClose={this.handleCloseDialogUpdateAvalible}
            newVersion={this.state.newVersion}
            versionMejoras={this.state.versionMejoras}
            logVersiones={this.state.logVersiones}
            checkSistemVersion={this.checkSistemVersion}
            version={this.state.version}
            handleOpenNovedades={this.state.handleOpenNovedades}
            updateSistemVersion={this.updateSistemVersion}
          />
          <UperaTour
            tour={this.state.typeTour}
            open={this.state.openTour}
            closeTour={this.handleCloseTour}
            dialogStateForTour={this.state.dialogStateForTour}
          />
          {this.renderLoginOrNav()}
          {this.renderMainPage()}
          <ErrorsReporterDialog
            login={this.state.login}
            mobile={this.state.mobile}
            pathProdu={this.state.pathProdu}
            ajaxSource={this.state.ajaxSource}
            getData={this.getData}
            crearNotificacion={this.crearNotificacion}
            cerrarNotificacion={this.cerrarNotificacion}
            crearAlerta={this.crearAlerta}
            cerrarAlerta={this.cerrarAlerta}
            dataUser={this.state.dataUser}
            dataUserSet={this.state.dataUserSet}
          />
        </ErrorReporter>
      </>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
