/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import GroupAddIcon from '@material-ui/icons/GroupAddRounded';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth : "700px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogGrupoEncuestas extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      value:"",
      nombreGrupo:"",
      nombreGrupoE:false,
      grupoUsuarios:[]
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOpenDialog, option, dataCuota, grupoSelected} = this.props;
    if(nextProps.handleOpenDialog !== handleOpenDialog) {
      if(option === "editar") {
        this.setState({nombreGrupo:grupoSelected.completName, grupoUsuarios:grupoSelected.grupoUsuarios});
      }
    }
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState);
    handleClose();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.setState({[name+'E']:false});
  };

  searchKey = (arr, key) => {
    let obj = arr.find(o => o.destinatarioId === key);
    if(obj === undefined) {
      return false;
    } else {
      return true;
    }
  }

  onSelectionChange = (data) => {
    const {grupoUsuarios} = this.state;
    var exists = this.searchKey(grupoUsuarios, data.destinatarioId);
    if(exists === false) {
      grupoUsuarios.push(data);
      this.setState({grupoUsuarios:grupoUsuarios});
    } else {
      var index = grupoUsuarios.map(function(o) { return o.destinatarioId; }).indexOf(data.destinatarioId);
      grupoUsuarios.splice(index, 1);
      this.setState({grupoUsuarios:grupoUsuarios});
    }
  }

  crearGrupoDifusion = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion, option, getEncuestasInfo, grupoSelected} = this.props;
    const {grupoUsuarios, nombreGrupo} = this.state;

    if(nombreGrupo === "") {
      this.setState({nombreGrupoE:true},()=> {
        crearNotificacion("warning", "Ingresa el nombre del grupo.", "bottom", "right");
      });
    } else if(Object.keys(grupoUsuarios).length === 0) {
      crearNotificacion("warning", "Selecciona al menos un usuario.", "bottom", "right");
    } else {

      var userId = dataUser.userId;
      var notiCargando = "Creando";
      var notiExito = "creado"
      var notiError = "crear";
      var grupoId = "";

      if(option === "editar") {
        notiCargando = "Editando";
        notiExito = "editado"
        notiError = "editar";
        grupoId = grupoSelected.destinatarioId;
      }

      crearNotificacion("info", notiCargando+" grupo...", "bottom", "right", 1000000);

      getData(ajaxSource, "encuestas/createEditGrupo.php",{
        userId:userId,
        grupoUsuarios:grupoUsuarios,
        option:option,
        nombreGrupo:nombreGrupo,
        grupoId:grupoId
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            cerrarNotificacion();
            setTimeout(function () {
              crearNotificacion("success", "Grupo "+notiExito+" con exito.", "bottom", "right", 3000);
              getEncuestasInfo();
              self.handleClose();
            },500);
          } else {
            cerrarNotificacion();
            crearNotificacion("error", "Hubo un problema al intentar "+notiError+" el grupo " + data[0].error, "bottom", "right", 3000);
          }
        }
      });

    }
  }

  render() {
    const {classes, open, option, checkPermisos, usuarios, crearNotificacion, dataUser, handleOpenDialog} = this.props
    const {nombreGrupo, nombreGrupoE, grupoUsuarios} = this.state;

    var textCasasLocales = "Casa";
    var table = "";
    if(checkPermisos("125")) {
      textCasasLocales = "Local";
    }

    var title = "Crear Grupo Encuesta";
    var btnTxt = "Crear grupo";
    if(option === "editar") {
      title = "Editar Grupo Encuesta";
      btnTxt = "Editar grupo";
    }

    if(open === true) {
      table = <DataMaterialTableNE
        key={handleOpenDialog}
        type="gruposDifusion"
        dataUser={dataUser}
        title={""}
        data={usuarios}
        columns={[
          {title: 'ID', field: 'destinatarioId', editable: 'never', hidden: true},
          {title: '', field: 'destinatarioId', sorting:false,
            render: rowData => {

              var checked = false;
              var exists = this.searchKey(grupoUsuarios, rowData.destinatarioId);
              if(exists === true) {
                checked = true;
              }

              return(<Checkbox
                checked={checked}
                value={rowData.destinatarioId}
                onChange={()=>this.onSelectionChange(rowData)}
              />)
            },
            cellStyle:{width:'20px'},
          },
          {title: "Destinatario", field: 'completName', cellStyle: {textAlign: 'center'}},
          {title: textCasasLocales, field: 'noCasa', cellStyle: {textAlign: 'center'}},
        ]}
        crearNotificacion={crearNotificacion}
        checkPermisos={checkPermisos}
        selection={false}
      />
    }

    return (
      <MuiThemeProvider theme={DialogT}>
        <Dialog
          scroll={'body'}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="simple-dialog-title">
            <span className="darkGrayUpera">{title}</span>
            <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', top:'10px'}} onClick={()=>this.handleClose()}>
              <CloseIcon className="darkGrayUpera"/>
            </IconButton>
          </DialogTitle>
          <List>
            <MuiThemeProvider theme={FormInputs}>
              <ListItem style={{display:'inline'}}>
                <div className="row" style={{margin: '0px', width: '100%'}}>
                  <div className="col-md-12">
                    <TextField
                      error={nombreGrupoE}
                      label="Nombre del Grupo"
                      placeholder="Nombre del Grupo"
                      id="nombreGrupo"
                      onChange={this.handleChange('nombreGrupo')}
                      value={nombreGrupo}
                      margin="normal"
                      variant="outlined"
                      style={{width:'100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  {table}
                </div>
                <div className="col-md-12 text-center">
                  <MuiThemeProvider theme={FormButtons}>
                    <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={()=>this.crearGrupoDifusion()}>
                      {btnTxt}
                      <GroupAddIcon className={classes.rightIcon}/>
                    </Button>
                  </MuiThemeProvider>
                </div>
              </ListItem>
            </MuiThemeProvider>
          </List>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogGrupoEncuestas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogGrupoEncuestas);
