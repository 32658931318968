import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import MaterialTable from 'mui-datatables';

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#3443d6',
        '&:hover': {
          backgroundColor: '#3443d6',
        }
      }
    }
  }
});

const Table = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: Array(25).fill('none'),
      },
      root:{
        backgroundColor: "transparent"
      }
    },
    MuiTableCell: {
      body: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px',
        textAlign: 'center !important'
      }
    },
    MuiList: {
      root: {
        backgroundColor:'#FFF'
      }
    }
  }
});

const TableUsuarios = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: Array(25).fill('none'),
      },
      root:{
        backgroundColor: "transparent"
      }
    },
    MuiTableCell: {
      body: {
        color: '#FFF',
        fontSize: '14px',
        textAlign: 'center !important'
      },
      head: {
        backgroundColor:'transparent !important',
        color:'#FFF !important'
      }
    },
    MuiIcon: {
      root: {
        color:'#FFF'
      }
    },
    MuiInputBase: {
      input: {
        color: '#FFF !important'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255,255,255,0.42)'
        },
      }
    },
    MuiSelect: {
      icon: {
        color:'#FFF'
      }
    },
    MuiTypography: {
      caption: {
        color:'#FFF'
      }
    },
    MuiPopover:{
      paper: {
        backgroundColor:'#FFF !important'
      }
    },
    MuiList: {
      root: {
        backgroundColor:'#FFF'
      }
    }
  }
});

class DataMaterialTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data:this.props.data,
    }
  }

  getMuiTheme = () => createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily:'Poppins Regular',
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding:'0px',
        },
        body: {
          color: 'rgba(0, 0, 0, 0.54)',
          fontSize: '16x',
          textAlign: 'left'
        }
      },
      MUIDataTableHeadCell: {
        sortAction: {
          display:'block !important',
          textAlign:'center !important',
          padding:'14px 16px 14px 16px !important'
        },
        data: {
          width:'100%',
          textAlign:'center',
          fontSize: '15px',
          fontWeight:'bold',
          padding:'14px 16px 14px 16px !important'
        }
      },
      MuiPaper: {
        elevation4: {
          boxShadow:'none'
        }
      },
      MUIDataTableBodyCell:{
        cellStacked: {
          width:'calc(50%) !important'
        },
        responsiveStacked: {
          width:'calc(50% - 38px) !important'
        }
      }
    }
  })

  getMuiThemeMobile = () => createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily:'Poppins Regular',
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding:'0px',
        },
        body: {
          color: 'rgba(0, 0, 0, 0.54)',
          textAlign: 'left',
          fontSize: '15px',
          fontWeight:'bold'
        }
      },
      MUIDataTableHeadCell: {
        data: {
          width:'100%',
          textAlign:'left',
          padding:'14px 16px 14px 16px !important'
        }
      },
      MuiPaper: {
        elevation4: {
          boxShadow:'none'
        }
      },
      MUIDataTableBodyCell:{
        cellStacked: {
          width:'calc(35%) !important',
          fontSize:'12px !important',
          height:'30px !important'
        },
        responsiveStacked: {
          width:'calc(54% - 1px) !important',
          fontSize:'12px !important',
          height:'30px !important'
        }
      }
    }
  });

  render() {
    const {title, data, columns, mobile, download} = this.props;

   
    var theme = this.getMuiTheme();
    if(mobile === true) {
      theme = this.getMuiThemeMobile();
    }

    return(
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={title}
          columns={columns}
          data={data}
          options={{
            
             
            
            sorting: true,
            filtering: true,
            selectableRows:'none',
            headerStyle: {
              color: '#737373',
              fontSize: '14px',
              textAlign: 'center'
            },
            rowStyle: {
              textAlign: 'center'
            },
            textLabels: {
              body: {
                noMatch: "Sin datos",
                toolTip: "Ordenar",
              },
              pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
              },
              toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver Columnas",
                filterTable: "Filtrar",
              },
              filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
              },
              viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
              },
              selectedRows: {
                text: "fila(s) seleccionadas",
                delete: "Borrar",
                deleteAria: "Borrar filas selccionadas",
              },
              
            }
          }}
        />
      </MuiThemeProvider>
    )
  }
}

export default DataMaterialTable;
