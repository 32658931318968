/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../../componentsmKit/Header/Header.js";
import Footer from "../../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../../componentsmKit/Grid/GridItem.js";
import Button from "../../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

class HomeBannerTres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val:""
    }
  }

  render() {
    const {classes, mobile} = this.props;

    return(
      <div style={{ width:'100%', borderRadius:'0px'}}>
        <div className={classes.container}>
          <div style={{width:'100%'}}>
            <GridContainer style={{padding:'0px'}}>
              <GridItem xs={12} sm={12} md={6} style={!mobile ? {}:{padding:'0px'}}>
                <div style={{backgroundImage:'linear-gradient(#036adb, #0466d3, #055fc3, #094e9c, #10345e)', height:'100%'}}>
                  <div style={{width:'100%', textAlign:'center'}}>
                    <h4 className={classes.title} style={{marginTop:'65px', fontFamily: 'Poppins SemiBold', color:'#f2f2f2', fontSize:'17px'}}>upera <font style={{color:'#59cef7'}}> app</font></h4>
                  </div>
                  {!mobile ?
                    <div style={{width:'100%', textAlign:'center'}}>
                      <h1 className={classes.title} style={{marginTop:'0px', fontFamily: 'Poppins SemiBold', color:'#f2f2f2', fontWeight:'50', fontSize:'2.1125rem', lineHeight:'42px'}}>Gestiona y administra <br></br> desde cualquier lugar <br></br> y de forma sencilla.</h1>
                    </div>
                  :
                    <div style={{width:'100%', textAlign:'center'}}>
                      <h1 className={classes.title} style={{marginTop:'0px', fontFamily: 'Poppins SemiBold', color:'#f2f2f2', fontWeight:'50', fontSize:'1.6125rem', lineHeight:'30px'}}>Gestiona y administra <br></br> desde cualquier lugar <br></br> y de forma sencilla.</h1>
                    </div>
                  }
                  <div style={{width:'100%', marginTop:'30px', textAlign:'center'}}>
                    <img src="assets/img/webPage/mockupiPhone.png" style={{width:'50%'}}></img>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={!mobile ? {}:{padding:'0px', paddingBottom:'30px'}}>
                <div style={!mobile ? {backgroundImage:'linear-gradient(#383d46, #292b31, #1e1f23)', height:'100%'}:{backgroundImage:'linear-gradient(#383d46, #292b31, #1e1f23)', height:'100%', paddingBottom:'60px'}}>
                  <div style={{width:'100%', textAlign:'center'}}>
                    <h4 className={classes.title} style={{marginTop:'65px', fontFamily: 'Poppins SemiBold', color:'#f2f2f2', fontSize:'17px'}}>upera <font style={{color:'#59cef7'}}> web</font></h4>
                  </div>
                  {!mobile ?
                    <div style={{width: '100%', textAlign: 'center'}}>
                      <h1 className={classes.title} style={{
                        marginTop: '0px',
                        fontFamily: 'Poppins SemiBold',
                        color: '#f2f2f2',
                        fontWeight: '50',
                        fontSize: '2.1125rem',
                        lineHeight: '42px'
                      }}>La herramienta <br></br> mas potente y avanzada.</h1>
                    </div>
                    :
                    <div style={{width: '100%', textAlign: 'center'}}>
                      <h1 className={classes.title} style={{
                        marginTop: '0px',
                        fontFamily: 'Poppins SemiBold',
                        color: '#f2f2f2',
                        fontWeight: '50',
                        fontSize: '1.6125rem',
                        lineHeight: '30px'
                      }}>La herramienta <br></br> mas potente y avanzada.</h1>
                    </div>
                  }
                  <div style={{width:'100%', marginTop:'30px', textAlign:'center'}}>
                    <img src="assets/img/webPage/mockupMacBook.png" style={{width:'90%', marginTop:'45px'}}></img>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

HomeBannerTres.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeBannerTres);
