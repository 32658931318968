import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
 
import FormControl from '@material-ui/core/FormControl';
 
import KpisIcon from '@material-ui/icons/PieChartRounded';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';



import {LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, PieChart, Pie, Sector, Cell,} from 'recharts';

const $ = require('jquery');

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
  td: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  th: {
    color: '#737373',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '40%'
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused:not $notchedOutline:not": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

class DivIndicadoresMantto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:"",
      kpiYear:"",
      kpiMonth:"",
      kpiYearE:false,
      kpiMonthE:false,
      kpiTicketsCursoTerminados: [],
      kpiTotalTickets:0,
      kpiTotalTicketsMalos:0,
      kpiTotalTicketsRegular:0,
      kpiTotalTicketsBuenos:0,
      kpiTotalTicketsMuyBuenos:0,
      kpiTotalTicketsCalificados:0,
      kpiTicketsArea: [],
      kpiTicketsTiempo: [],
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected, loadedPage} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      loadedPage(true);
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value},()=> {
    });
    this.setState({[name+'E']:false});
  };

  generarKpis = (downloadReporte) => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    const {kpiYear, kpiMonth} = this.state;
    var userId = dataUser.userId;

    if(kpiYear === "") {
      this.setState({kpiYearE:true}, ()=> {
        crearNotificacion("warning", "Seleccione un año.", "bottom", "right");
      });
    } else if(kpiMonth === "") {
      this.setState({kpiMonthE:true}, ()=> {
        crearNotificacion("warning", "Seleccione el mes.", "bottom", "right");
      });
    } else {
      crearNotificacion("info", "Generando indicadores...", "bottom", "right", 1000000);

      getData(ajaxSource, "indicadoresMantto/kpis.php",{
        userId:userId,
        kpiYear:kpiYear,
        kpiMonth:kpiMonth,
        }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          var kpiTotalTickets = data[0];
          var kpiTicketsCursoTerminados = data[1];
          var kpiTotalTicketsMalos = data[2];
          var kpiTotalTicketsRegular = data[3];
          var kpiTotalTicketsBuenos = data[4];
          var kpiTotalTicketsMuyBuenos = data[5];
          var kpiTicketsArea = data[6];
          var kpiTicketsTiempo = data[7];
          var kpiTotalTicketsCalificados = data[8];

          self.setState({kpiTotalTickets:kpiTotalTickets,kpiTicketsCursoTerminados:kpiTicketsCursoTerminados, kpiTotalTicketsMalos:kpiTotalTicketsMalos, kpiTotalTicketsRegular:kpiTotalTicketsRegular, kpiTotalTicketsBuenos:kpiTotalTicketsBuenos, kpiTotalTicketsMuyBuenos:kpiTotalTicketsMuyBuenos, kpiTicketsArea:kpiTicketsArea, kpiTicketsTiempo:kpiTicketsTiempo, kpiTotalTicketsCalificados:kpiTotalTicketsCalificados}, ()=> {
          });
        }
      });
    }
  }

  svgToPng = (svg, width, height) => {

    return new Promise((resolve, reject) => {
      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');

      // Set background to white
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, width, height);

      let xml = new XMLSerializer().serializeToString(svg);
      let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
      let img = new Image(width, height);

      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        let imageData = canvas.toDataURL('image/png', 1.0);
        resolve(imageData)
      }

      img.onerror = () => reject();
      img.src = dataUrl;
    });
  };

  convertChart = async (ref, chart, heightPie) => {
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    var userId = dataUser.userId;

    if (ref && ref.container) {
      let svg = ref.container.children[0];
      let pngData = await this.svgToPng(svg, 900, 400);

      var ret = await getData(ajaxSource, "indicadoresMantto/saveChartImage.php", {
        userId: userId,
        chart: chart,
        pngData: pngData,
      }).then(function (response) {
        if (response.data) {

          var data = response.data;
          if(data[0].error == 1) {
            return 1
          } else {
            return 2
          }
        }
      });
      return ret;
    }
  };

  getImagesKpis = () => {
    const self = this;
    const {mobile, crearNotificacion, cerrarNotificacion} = this.props;
    var heightPie = 400;
    if(mobile === true) {
      heightPie = 300;
    }

    crearNotificacion("info", "Generando reporte...", "bottom", "right", 1000000);

    this.convertChart(this.kpiTicketsCursoTerminadosChart, "kpiTicketsCursoTerminados", heightPie).then(function (ret) {
      if(ret === 1) {
        self.convertChart(self.kpiTicketsAreaChart, "kpiTicketsAreaChart", heightPie).then(function (ret) {
          if(ret === 1) {
            self.convertChart(self.kpiTicketsTiempoChart, "kpiTicketsTiempoChart", heightPie).then(function (ret) {
              if(ret === 1) {
                self.generateReporte();
              } else {
                cerrarNotificacion();
                crearNotificacion("error", "Ocurrio un error al intentar descargar el reporte, vuelve a intentarlo.", "bottom", "right");
              }
            });
          } else {
            cerrarNotificacion();
            crearNotificacion("error", "Ocurrio un error al intentar descargar el reporte, vuelve a intentarlo.", "bottom", "right");
          }
        });
      } else {
        cerrarNotificacion();
        crearNotificacion("error", "Ocurrio un error al intentar descargar el reporte, vuelve a intentarlo.", "bottom", "right");
      }
    });
  }

  generateReporte = () => {
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, prodEnv} = this.props;
    const {kpiYear, kpiMonth, kpiTotalTicketsMalos, kpiTotalTicketsRegular, kpiTotalTicketsBuenos, kpiTotalTicketsMuyBuenos, kpiTotalTicketsCalificados, kpiTotalTickets} = this.state;
    var userId = dataUser.userId;

    getData(ajaxSource, "indicadoresMantto/generarReporte.php", {
      userId: userId,
      kpiYear:kpiYear,
      kpiMonth:kpiMonth,
      kpiTotalTicketsMalos:kpiTotalTicketsMalos,
      kpiTotalTicketsRegular:kpiTotalTicketsRegular,
      kpiTotalTicketsBuenos:kpiTotalTicketsBuenos,
      kpiTotalTicketsMuyBuenos:kpiTotalTicketsMuyBuenos,
      kpiTotalTicketsCalificados:kpiTotalTicketsCalificados,
      kpiTotalTickets:kpiTotalTickets
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;
        if(data[0].error == 1) {

          var path = data[1];

          window.open(prodEnv+path, '_blank');

        } else {
          crearNotificacion("error", "Hubo un error al intentar descargar el reporte, intenta de nuevo "+data[0].error, "bottom", "right", 3000);
        }
      }
    });

  }

  renderKpis = () => {
    const {classes, mobile, handleReloadComponent} = this.props;
    const {kpiTicketsCursoTerminados, kpiTotalTickets, kpiTotalTicketsMalos, kpiTotalTicketsRegular, kpiTotalTicketsBuenos, kpiTotalTicketsMuyBuenos, kpiTicketsArea, kpiTicketsTiempo} = this.state;

    if (Object.keys(kpiTicketsCursoTerminados).length > 0) {

      var innerRadius = 100;
      var outerRadius = 130;
      var labelText = "40px";
      var subLabelText = "18px";
      var heightPie = 550;
      var widthPie = "100%";
      var xs2 = 2;
      var xs10 = 10;

      if(mobile === true) {
        innerRadius = 60;
        outerRadius = 80;
        labelText = "30px";
        subLabelText = "15px";
        heightPie = 300;
        widthPie = "100%";
        xs2 = 12;
        xs10 = 12;
      }

      const colorsPie = ['#0345ea', '#c34a94'];
      const colorsPie2 = ['#0345ea', '#c34a94', '#84cbf4', '#7e57c2', '#ad1457', '#ec407a']
      const RADIAN = Math.PI / 180;

      const labelChartTicketsCursoTerminados = ({
                                       cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                                     }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
          <text x={x} y={y-50} fill="#777" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            <tspan x={x} dy="1.2em" fontSize={labelText} text-anchor={x > cx ? 'start' : 'end'}>{kpiTicketsCursoTerminados[index].value}</tspan>
            <tspan x={x} dy="1.5em" fontSize={subLabelText} text-anchor={x > cx ? 'start' : 'end'}>{kpiTicketsCursoTerminados[index].name}</tspan>
          </text>
        );
      };

      const labelChartTicketsPorArea = ({
                                         cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                                       }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
          <text x={x} y={y-50} fill="#777" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            <tspan x={x} dy="1.2em" fontSize={labelText} text-anchor={x > cx ? 'start' : 'end'}>{kpiTicketsArea[index].value}</tspan>
            <tspan x={x} dy="1.5em" fontSize={subLabelText} text-anchor={x > cx ? 'start' : 'end'}>{kpiTicketsArea[index].name}</tspan>
          </text>
        );
      };

      return(
        <div className="row text-center" style={{paddingTop: '20px', margin: '0px', width:'100%'}}>
          <h3 style={{textAlign:'center', padding:'10px', background:"#eee", borderRadius:'5px', marginBottom:'20px', width:'100%'}}>{kpiTotalTickets} Tickets en el mes</h3>
          <Grid container spacing={1} justify="center" alignItems="center" direction="row" style={{textAlign:'center'}}>
            <Grid item xs={12}>
              <MuiThemeProvider theme={FormButtons}>
                <Button variant="contained" color="primary" className={classes.button} style={{margin: 'auto'}} onClick={()=>this.getImagesKpis()}>
                  Descargar Reporte
                  <DownloadIcon className={classes.rightIcon}/>
                </Button>
              </MuiThemeProvider>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center" alignItems="center" direction="row" style={{textAlign:'center', borderBottom:'2px solid #eee'}}>
            <Grid item xs={xs2}>
              <img src="assets/img/icn_char_1.png" style={{width:'70px'}}/>
              <h4 className="kpiOptionSubtitle">Tickets en curso y solucionados</h4>
            </Grid>
            <Grid item xs={xs10} style={{textAlign:'center'}}>
              <div style={{maxWidth:'750px'}}>
                <ResponsiveContainer width={widthPie} height={heightPie}>
                  <PieChart
                    key={handleReloadComponent}
                    margin={{
                      top: 0, right: 0, bottom: 10, left: 0,
                    }}
                    ref={(chart) => this.kpiTicketsCursoTerminadosChart = chart}
                  >
                    <Pie
                      data={kpiTicketsCursoTerminados}
                      label={labelChartTicketsCursoTerminados}
                      labelLine={true}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      paddingAngle={5}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {
                        kpiTicketsCursoTerminados.map((entry, index) => <Cell key={`cell-${index}`} fill={colorsPie[index % colorsPie.length]} />)
                      }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center" alignItems="center" direction="row" style={{textAlign:'center', borderBottom:'2px solid #eee', marginTop:'20px'}}>
            <Grid item xs={xs2} style={{paddingTop:'30px', paddingBottom:'30px'}}>
              <img src="assets/img/icn_char_2.png" style={{width:'70px'}}/>
              <h4 className="kpiOptionSubtitle">Satisfacción del cliente</h4>
            </Grid>
            <Grid item xs={xs10} style={{paddingTop:'30px', paddingBottom:'30px'}}>
              <Grid container spacing={1} justify="center" alignItems="center" direction="row" style={{textAlign:'center'}}>
                <Grid item xs={3} style={{textAlign:'center'}}>
                  <StarIcon style={{color:"#0345ea", fontSize:'50px'}}/>
                  <span><h3 style={{color:'#777', fontSize:'50px', margin:'0px'}}>{kpiTotalTicketsMalos}</h3></span>
                  <span><h3 style={{color:'#777', margin:'0px', fontSize:'20px', fontWeight:'bold'}}>Malo</h3></span>
                </Grid>
                <Grid item xs={3} style={{textAlign:'center'}}>
                  <StarIcon style={{color:"#0345ea", fontSize:'50px'}}/>
                  <span><h3 style={{color:'#777', fontSize:'50px', margin:'0px'}}>{kpiTotalTicketsRegular}</h3></span>
                  <span><h3 style={{color:'#777', margin:'0px', fontSize:'20px', fontWeight:'bold'}}>Regular</h3></span>
                </Grid>
                <Grid item xs={3} style={{textAlign:'center'}}>
                  <StarIcon style={{color:"#0345ea", fontSize:'50px'}}/>
                  <span><h3 style={{color:'#777', fontSize:'50px', margin:'0px'}}>{kpiTotalTicketsBuenos}</h3></span>
                  <span><h3 style={{color:'#777', margin:'0px', fontSize:'20px', fontWeight:'bold'}}>Bueno</h3></span>
                </Grid>
                <Grid item xs={3} style={{textAlign:'center'}}>
                  <StarIcon style={{color:"#0345ea", fontSize:'50px'}}/>
                  <span><h3 style={{color:'#777',fontSize:'50px', margin:'0px'}}>{kpiTotalTicketsMuyBuenos}</h3></span>
                  <span><h3 style={{color:'#777', margin:'0px', fontSize:'20px', fontWeight:'bold'}}>Excelente</h3></span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center" alignItems="center" direction="row" style={{textAlign:'center', borderBottom:'2px solid #eee', marginTop:'20px'}}>
            <Grid item xs={xs2}>
              <img src="assets/img/icn_char_3fix.png" style={{width:'70px'}}/>
              <h4 className="kpiOptionSubtitle">Tickets por área</h4>
            </Grid>
            <Grid item xs={xs10} style={{textAlign:'center'}}>
              <div style={{maxWidth:'750px'}}>
                <ResponsiveContainer width={widthPie} height={heightPie}>
                  <PieChart
                    key={handleReloadComponent}
                    margin={{
                      top: 60, right: 0, bottom: 60, left: 0,
                    }}
                    ref={(chart) => this.kpiTicketsAreaChart = chart}
                  >
                    <Pie
                      data={kpiTicketsArea}
                      label={labelChartTicketsPorArea}
                      labelLine={true}
                      outerRadius={outerRadius}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {
                        kpiTicketsArea.map((entry, index) => <Cell key={`cell-${index}`} fill={colorsPie2[index % colorsPie2.length]} />)
                      }
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="center" alignItems="center" direction="row" style={{textAlign:'center', borderBottom:'2px solid #eee', marginTop:'20px'}}>
            <Grid item xs={xs2}>
              <img src="assets/img/icn_char_4.png" style={{width:'70px'}}/>
              <h4 className="kpiOptionSubtitle">Tickets por tiempo</h4>
            </Grid>
            <Grid item xs={xs10} style={{textAlign:'center'}}>
              <div style={{maxWidth:'750px'}}>
                <ResponsiveContainer width={widthPie} height={heightPie}>
                  <BarChart
                    data={kpiTicketsTiempo}
                    margin={{
                    top: 20, right: 0, bottom: 10, left: 0,
                    }}
                    ref={(chart) => this.kpiTicketsTiempoChart = chart}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={true}/>
                    <XAxis dataKey="name" tick={{fontSize: 15, color: '#777'}}/>
                    <YAxis tick={{fontSize: 15, color: '#777'}}/>
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Total Tickets" barSize={50} fill="#0345ea" radius={[5, 5, 5, 5]}>
                      <LabelList dataKey="Total Tickets" position="top" fontSize={14} fontWeight="normal" fill="#777"/>
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return(
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Indicadores</h4>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const {classes, dataUser, crearNotificacion, ajaxSource, getData,  cerrarNotificacion,} = this.props;
    const {kpiYear, kpiMonth, kpiYearE, kpiMonthE} = this.state;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;

    return(
      <MuiThemeProvider theme={FormInputs}>
        <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
          <div className="col-md-12 mtM">
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
          </div>
          <div className="col-md-4" style={{marginTop:'20px'}}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="payYear"
                style={{
                  background: '#FFF',
                  padding: '0px 5px 1px 5px'
                }}
              >
                Año
              </InputLabel>
              <Select
                value={kpiYear}
                onChange={this.handleChange('kpiYear')}
                input={
                  <OutlinedInput id="kpiYear" labelWidth={0} error={kpiYearE}/>
                }
              >
                <MenuItem value={actualYear}>{actualYear}</MenuItem>
                <MenuItem value={passYear}>{passYear}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4" style={{marginTop:'20px'}}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-months"
                style={{
                  background: 'inherit',
                  padding: '0px 5px 1px 5px'
                }}

              >
                Mes
              </InputLabel>
              <Select
                style={{width:'100%'}}
                value={kpiMonth}
                onChange={this.handleChange('kpiMonth')}
                input={<OutlinedInput labelWidth={0} id="outlined-months" error={kpiMonthE}/>}
              >
                {months.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4 text-center" style={{marginTop:'20px', display: 'flex'}}>
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" color="primary" className={classes.button} style={{margin: 'auto'}} onClick={()=>this.generarKpis()}>
                Generar
                <KpisIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
        {this.renderKpis()}
      </MuiThemeProvider>
    )
  }
}

DivIndicadoresMantto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivIndicadoresMantto);
