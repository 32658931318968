import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import ConektaHelper from "../../commons/ConektaHelper";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import CreditCardIcon from "@material-ui/icons/CreditCardRounded";
import AutoRenewIcon from "@material-ui/icons/AutorenewRounded";
import StoreIcon from "@material-ui/icons/StoreRounded";
import AccountIcon from "@material-ui/icons/AccountBalanceWalletRounded";
import styled from "styled-components";

import DivPagoConTarjeta from "./DivPagoConTarjeta";
import DivPagosDomiciliados from "./DivPagosDomiciliados";
import DivPagoOxxo from "./DivPagoOxxo";
import DivMisPagos from "./DivMisPagos";



const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #fff !important;
    background: #0345ea !important;
    border-radius: 5px;
  }
  .PrivateTabIndicator-colorPrimary-625 {
    background-color: transparent;
  }
`;

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Poppins Regular",
  },
  shadows: Array(25).fill("none"),
  palette: {
    primary: {
      main: "#0345ea",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#FFF !important",
      },
    },
    MuiTab: {
      labelContainer: {
        padding: "2px 1px",
      },
      selected: {
        color: "#FFF !important",
        background: "#0345ea !important",
        borderRadius: "5px",
      },
      root: {
        background: "#cfd8dc",
        borderRadius: "5px",
      },
    },
    MuiTypography: {
      body1: {
        padding: "20px 0px 0px 0px !important",
      },
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "transparent",
      },
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: "transparent !important",
      },
    },
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivPagoEnLinea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nt: "",
      mm: "",
      aa: "",
      cvv: "",
      nombreTitular: "",
      montoPagar: "",
      montoPagarSinComision: "",
      cuotaId: "",
      conceptoCuota: "",
      tId: "",
      payMonths: "",
      payYear: "",
      payMonthsE: false,
      payYearE: false,
      ntE: false,
      mmE: false,
      aaE: false,
      cvvE: false,
      mmEG: false,
      aaEG: false,
      cvvEG: false,
      nombreTitularE: false,
      expanded: null,
      disableBtnPay: false,
      payedMonths: [],
      months: [],
      userNts: [],
      registerNt: false,
      suscription: false,
      cpk: "",
      value: 0,
      disableSelectMonths: true,
      pagosDomiciliados: [],
      cusId: "",
      fichaOxxoPay: [],
      recibosYear: "",
      userRecibos: [],
      handleRecibosYear: 0,
      oxxoSpei: "oxxo",
      userCasasLocales: [],
      casaLocal: "",
      casaLocalE: false,
      tk: "",
    };
  }

  componentDidMount() {
    const { checkPermisos } = this.props;
    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js"; //Libreria para tokenizar tarjeta
    script.async = true;
    document.body.appendChild(script);

    if (checkPermisos("143")) {
      this.setState({ value: 0 });
      return;
    } else if (checkPermisos("144")) {
      this.setState({ value: 1 });
      return;
    } else if (checkPermisos("145")) {
      this.setState({ value: 2 });
      return;
    } else if (checkPermisos("146")) {
      this.setState({ value: 3 });
      return;
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { handleOptionSelected } = this.props;
    if (nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getInfoUserPay();
    }
  }

  getInfoUserPay = (name) => {
    const self = this;
    const {
      ajaxSource,
      getData,
      dataUser,
      crearNotificacion,
      cerrarNotificacion,
      loadedPage,
      checkPermisos,
    } = this.props;
    const { payYear, recibosYear, value, oxxoSpei } = this.state;
    var userId = dataUser.userId;
    var residencialPlaza = dataUser.residencialPlaza;

    if (payYear !== "" || recibosYear !== "") {
      self.setState({ disableSelectMonths: true }, () => {
        crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);
      });
    }

    getData(ajaxSource, "ecomm/infoUserPay.php", {
      userId: userId,
      payYear: payYear,
      recibosYear: recibosYear,
      residencialPlaza: residencialPlaza,
      tab: value,
      oxxoSpei: oxxoSpei,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var userNts = data[0];
        var payedMonths = data[1];
        var cpk = data[2];
        var pagosDomiciliados = data[3];
        var fichaOxxoPay = data[4];
        var userRecibos = data[5];
        var userCasasLocales = data[6];
        self.setState(
          {
            userNts: userNts,
            cpk: cpk,
            pagosDomiciliados: pagosDomiciliados,
            fichaOxxoPay: fichaOxxoPay,
            userRecibos: userRecibos,
            userCasasLocales: userCasasLocales,
          },
          () => {
            if (name === "recibosYear") {
              const { handleRecibosYear } = self.state;
              self.setState({ handleRecibosYear: handleRecibosYear + 1 });
            }

            loadedPage(true);
          }
        );

        if (payYear !== "") {
          self.setState({ payedMonths: payedMonths }, () => {
            self.setState({ disableSelectMonths: false });
            cerrarNotificacion();
            //self.handleSelectMonths();
          });
        }
      }
    });
  };

  handleSelectMonths = (payedMonths) => {
    const { dataUser } = this.props;
    var residencialPlaza = dataUser.residencialPlaza;

    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    var userPayedMonths = [];

    for (var i = 0; i < 12; i++) {
      if (!payedMonths.includes(months[i])) {
        userPayedMonths.push(months[i]);
      }
    }

    if (residencialPlaza === "residencial") {
      this.setState({ months: userPayedMonths });
    } else {
      if (payedMonths[0] === "NA") {
        this.setState({ months: months, payMonths: "" });
      } else {
        this.setState({ months: months, payMonths: payedMonths[0] });
      }
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value }, () => {
      if (name === "payYear" || name === "recibosYear") {
        this.setState({ casaLocal: "", months: [] }, () => {
          this.getInfoUserPay(name);
        });
      }
      if (name === "casaLocal") {
        const { payedMonths, casaLocal, userCasasLocales } = this.state;
        this.setState({ payMonth: "" }, () => {
          this.handleSelectMonths(payedMonths[casaLocal]);
        });

        var index = userCasasLocales.findIndex(
          (x) => x.casaLocalId === casaLocal
        );
        var montoPagar = userCasasLocales[index].montoPagar;
        var montoPagarSinComision =
          userCasasLocales[index].montoPagarSinComision;
        var conceptoCuota = userCasasLocales[index].cuotaConcepto;
        this.setState({
          montoPagar: montoPagar,
          conceptoCuota: conceptoCuota,
          montoPagarSinComision: montoPagarSinComision,
        });
      }
      if (name === "oxxoSpei") {
        this.getInfoUserPay();
      }
    });
    this.setState({ [name + "E"]: false });
    this.setState({ [name + "EG"]: false });
  };

  handleChangeMultiple = (event) => {
    this.setState({ payMonths: event.target.value }, () => {
      this.setState({ payMonthsE: false });
    });
  };

  handleChangeCB = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangePanel = (panel) => (event, expanded) => {
    var nt = atob(panel.nt);
    this.setState({ expanded: expanded ? nt : false }, () => {
      const { expanded } = this.state;

      if (expanded === false) {
        this.cleanForms();
      } else {
        this.setState({ nombreTitular: panel.nombreTitular });
        this.setState({ nt: nt });
      }
    });
  };

  handleChangeTab = (event, value) => {
    const { checkPermisos } = this.props;

    console.log(value);
    this.setState({ value }, () => {
      if (value === 0 || value === 2) {
        this.getInfoUserPay();
      }
    });
  };

  handleChangeIndex = (index) => {
    console.log(index);
    this.setState({ value: index }, () => {
      if (index === 0 || index === 2) {
        this.getInfoUserPay();
      }
    });
  };

  cleanForms = () => {
    this.setState({ expanded: null });
    this.setState({ nt: "" });
    this.setState({ mm: "" });
    this.setState({ aa: "" });
    this.setState({ cvv: "" });
    this.setState({ cuota: "" });
    this.setState({ nombreTitular: "" });
    this.setState({ montoPagar: "" });
    this.setState({ payMonths: "" });
    this.setState({ payYear: "" });
    this.setState({ months: [] });
    this.setState({ registerNt: false });
    this.setState({ suscription: false });

    document.getElementById("formDivPago").reset();
  };

  createToken = () => {
    const self = this;
    const { crearNotificacion, checkPermisos } = this.props;
    const {
      nombreTitular,
      nt,
      cvv,
      mm,
      aa,
      payMonths,
      payYear,
      expanded,
      cpk,
      casaLocal,
    } = this.state;
    var not = nt;
    if (expanded !== null && expanded !== "") {
      not = expanded;
    }
    ConektaHelper.initConekta(cpk);
    //console.log(init);

    //var tdBrand = ConektaHelper.getCardBrand(nt);
    //console.log("Tipo de Terjeta: "+nt);

    var tdValid = ConektaHelper.validateCardNumber(not);
    //console.log("Tarjeta valida: "+tdValid);

    var cvvValid = ConektaHelper.validateCvc(cvv);
    //console.log("CVC valido: "+cvvValid);

    var dateValid = ConektaHelper.validateExpirationDate(mm, aa);
    //console.log("Fecha exp valida: "+dateValid);

    var casaLocalTxt = "la casa";
    if (checkPermisos("125")) {
      casaLocalTxt = "el local";
    }

    if (payYear === "") {
      this.setState({ payYearE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione el año correspondiente.",
          "bottom",
          "right"
        );
      });
    } else if (casaLocal === "") {
      this.setState({ casaLocalE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione " + casaLocalTxt + ".",
          "bottom",
          "right"
        );
      });
    } else if (payMonths === "") {
      this.setState({ payMonthsE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione el mes a pagar.",
          "bottom",
          "right"
        );
      });
    } else if (tdValid !== true) {
      if (expanded !== null) {
        this.setState({ ntEG: true }, () => {
          crearNotificacion(
            "error",
            "Número de tarjeta invalida.",
            "bottom",
            "right"
          );
        });
      } else {
        this.setState({ ntE: true }, () => {
          crearNotificacion(
            "error",
            "Número de tarjeta invalida.",
            "bottom",
            "right"
          );
        });
      }
    } else if (dateValid !== true || aa === "") {
      if (expanded !== null) {
        this.setState({ mmEG: true }, () => {
          this.setState({ aaEG: true }, () => {
            crearNotificacion(
              "error",
              "Fecha de expiración incorrecta.",
              "bottom",
              "right"
            );
          });
        });
      } else {
        this.setState({ mmE: true }, () => {
          this.setState({ aaE: true }, () => {
            crearNotificacion(
              "error",
              "Fecha de expiración incorrecta.",
              "bottom",
              "right"
            );
          });
        });
      }
    } else if (cvvValid !== true) {
      if (expanded !== null) {
        this.setState({ cvvEG: true }, () => {
          crearNotificacion("error", "CVV invalido.", "bottom", "right");
        });
      } else {
        this.setState({ cvvE: true }, () => {
          crearNotificacion("error", "CVV invalido.", "bottom", "right");
        });
      }
    } else if (nombreTitular === "") {
      this.setState({ nombreTitularE: true }, () => {
        crearNotificacion(
          "warning",
          "Ingresa el nombre del Titular.",
          "bottom",
          "right"
        );
      });
    } else if (tdValid === true && cvvValid === true && dateValid === true) {
      this.setState({ disableBtnPay: true }, () => {
        crearNotificacion(
          "info",
          "Procesando pago espere...",
          "bottom",
          "right",
          1000000
        );

        setTimeout(function () {
          var tk = btoa(btoa(btoa(not + "||" + cvv + "||" + mm + "||" + aa)));
          self.setState({ tk: tk }, () => {
            ConektaHelper.tokenize(
              not,
              nombreTitular,
              mm,
              aa,
              cvv,
              self.handleSuccessRespons,
              self.handleErrorRespons
            );
          });
        }, 1500);
      });
    }
  };

  handleOxxoPay = () => {
    const { crearNotificacion, checkPermisos } = this.props;
    const { payMonths, payYear, casaLocal } = this.state;

    var casaLocalTxt = "la casa";
    if (checkPermisos("125")) {
      casaLocalTxt = "el local";
    }

    if (payYear === "") {
      this.setState({ payYearE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione el año correspondiente.",
          "bottom",
          "right"
        );
      });
    } else if (casaLocal === "") {
      this.setState({ casaLocalE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione " + casaLocalTxt + ".",
          "bottom",
          "right"
        );
      });
    } else if (payMonths === "") {
      this.setState({ payMonthsE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione el mes o meses a pagar.",
          "bottom",
          "right"
        );
      });
    } else {
      this.setState({ disableBtnPay: true }, () => {
        crearNotificacion(
          "info",
          "Generando ficha de pago espere...",
          "bottom",
          "right",
          1000000
        );
        this.oxxoPay();
      });
    }
  };

  handleSuccessRespons = (data) => {
    this.setState({ tId: data.id }, () => {
      this.pay();
    });
  };

  handleErrorRespons = (error) => {
    console.log(error);
    const { crearAlerta, cerrarAlerta, cerrarNotificacion } = this.props;
    cerrarNotificacion();
    crearAlerta(
      "error",
      "Error al procesar el pago T",
      error.message,
      false,
      cerrarAlerta
    );
  };

  deleteNt = (ntId) => {
    const self = this;
    const {
      ajaxSource,
      getData,
      dataUser,
      crearNotificacion,
      cerrarNotificacion,
    } = this.props;

    var userId = dataUser.userId;

    crearNotificacion("info", "Eliminando...", "bottom", "right", 1000000);

    getData(ajaxSource, "ecomm/deleteNt.php", {
      userId: userId,
      ntId: ntId,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        cerrarNotificacion();
        if (data[0].error === 1) {
          crearNotificacion(
            "success",
            "Tarjeta eliminada con exito.",
            "bottom",
            "right",
            3000
          );
          self.getInfoUserPay();
        } else {
          crearNotificacion(
            "error",
            "Hubo un problema al intentar eliminar la tarjeta " + data[0].error,
            "bottom",
            "right",
            3000
          );
        }
      }
    });
  };

  pay = () => {
    const self = this;
    const {
      ajaxSource,
      getData,
      dataUser,
      crearAlerta,
      cerrarAlerta,
      cerrarNotificacion,
      checkPermisos,
    } = this.props;
    const {
      tk,
      nombreTitular,
      tId,
      montoPagar,
      nt,
      cvv,
      mm,
      aa,
      expanded,
      payMonths,
      payYear,
      suscription,
      registerNt,
      conceptoCuota,
      casaLocal,
    } = this.state;
    var not = nt;

    if (expanded !== null && expanded !== "") {
      not = expanded;
    }
    var userEmail = dataUser.userEmail;
    var userCelular = dataUser.userCelular;
    var userId = dataUser.userId;
    var factura = dataUser.factura;

    var option = "pagoUnico";
    if (suscription === true) {
      option = "crearSuscription";
    }

    var regNt = 0;
    if (registerNt === true) {
      regNt = 1;
    }

    var concpCuota = conceptoCuota;

    var precioPagar = montoPagar;
    if (checkPermisos("125")) {
      precioPagar = montoPagar;
      concpCuota = "Mantenimiento";
    }

    getData(ajaxSource, "ecomm/payOrder.php", {
      nombreTitular: nombreTitular,
      tId: tId,
      montoPagar: precioPagar,
      userEmail: userEmail,
      userCelular: userCelular,
      userId: userId,
      nt: not,
      payMonths: payMonths,
      payYear: payYear,
      option: option,
      regNt: regNt,
      conceptoCuota: concpCuota,
      casaLocal: casaLocal,
      tk: tk,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var dataPayment = data[1];

        self.setState({ disableBtnPay: false }, () => {
          var textFactura = "";
          if (factura === "Si") {
            textFactura =
              "Recibirás tu factura complemento en un plazo minimo de 24 horas";
          }

          if (data[0].error === 1) {
            self.cleanForms();
            self.getInfoUserPay();
            cerrarNotificacion();
            setTimeout(function () {
              crearAlerta(
                "success",
                "Pago realizado!",
                "Número de Orden: " + dataPayment.orderId + " " + textFactura,
                false,
                cerrarAlerta
              );
            }, 1500);
          } else if (data[0].error === 2) {
            self.cleanForms();
            self.getInfoUserPay();
            cerrarNotificacion();

            setTimeout(function () {
              crearAlerta(
                "success",
                "Pago domiciliado",
                "En los proximos minutos recibiras un correo confirmando tu primer pago " +
                  textFactura,
                false,
                cerrarAlerta
              );
            }, 900);
          } else {
            cerrarNotificacion();
            setTimeout(function () {
              crearAlerta(
                "error",
                "Error al procesar el pago S",
                data[0].error,
                false,
                cerrarAlerta
              );
            }, 900);
          }
        });
      }
    });
  };

  oxxoPay = () => {
    const self = this;
    const {
      ajaxSource,
      getData,
      dataUser,
      crearAlerta,
      cerrarAlerta,
      cerrarNotificacion,
      checkPermisos,
    } = this.props;
    const {
      montoPagar,
      payMonths,
      payYear,
      conceptoCuota,
      oxxoSpei,
      casaLocal,
    } = this.state;

    var userId = dataUser.userId;

    var concpCuota = conceptoCuota;

    var precioPagar = montoPagar;
    if (checkPermisos("125")) {
      precioPagar = montoPagar;
      concpCuota = "mantto";
    }

    getData(ajaxSource, "ecomm/generateOxxoPay.php", {
      montoPagar: precioPagar,
      userId: userId,
      payMonths: payMonths,
      payYear: payYear,
      conceptoCuota: concpCuota,
      oxxoSpei: oxxoSpei,
      casaLocal: casaLocal,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var fichaOxxoPay = data[1];

        self.setState({ disableBtnPay: false }, () => {
          if (data[0].error === 1) {
            cerrarNotificacion();
            self.setState({ fichaOxxoPay: fichaOxxoPay });
          } else {
            cerrarNotificacion();
            setTimeout(function () {
              crearAlerta(
                "error",
                "Error al generar la ficha de pago",
                data[0].error,
                false,
                cerrarAlerta
              );
            }, 900);
          }
        });
      }
    });
  };

  alertCancelSuscription = (cusId) => {
    const self = this;
    const { crearAlerta } = this.props;

    self.setState({ cusId: cusId }, () => {
      crearAlerta(
        "warning",
        "",
        "¿Seguro que deseas cancelar este pago domiciliado?",
        true,
        this.cancelSuscription
      );
    });
  };

  cancelSuscription = () => {
    const self = this;
    const {
      ajaxSource,
      getData,
      dataUser,
      cerrarAlerta,
      cerrarNotificacion,
      crearNotificacion,
    } = this.props;
    const { cusId } = this.state;
    cerrarAlerta();

    var userId = dataUser.userId;

    crearNotificacion("info", "Cancelando...", "bottom", "right", 1000000);

    getData(ajaxSource, "ecomm/cancelSuscription.php", {
      userId: userId,
      cusId: cusId,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        if (data[0].error === 1) {
          cerrarNotificacion();
          self.getInfoUserPay();
          crearNotificacion(
            "success",
            "Suscripción cancelada con exito.",
            "bottom",
            "right",
            3000
          );
        } else {
          cerrarNotificacion();
          crearNotificacion(
            "error",
            "Hubo un problema al intentar cancelar la suscripción " +
              data[0].error,
            "bottom",
            "right",
            3000
          );
        }
      }
    });
  };

  render() {
    const {
      theme,
      dataUser,
      ajaxSource,
      cerrarNotificacion,
      openTour,
      handleOpenTour,
      mobile,
      prodEnv,
      checkPermisos,
      getData,
    } = this.props;
    const {
      payMonths,
      payYear,
      montoPagar,
      payMonthsE,
      payYearE,
      ntE,
      mmE,
      aaE,
      cvvE,
      nombreTitularE,
      disableBtnPay,
      months,
      registerNt,
      suscription,
      expanded,
      mmEG,
      aaEG,
      cvvEG,
      userNts,
      value,
      disableSelectMonths,
      pagosDomiciliados,
      fichaOxxoPay,
      recibosYear,
      userRecibos,
      handleRecibosYear,
      oxxoSpei,
      userCasasLocales,
      casaLocal,
      casaLocalE,
      montoPagarSinComision,
    } = this.state;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;

    return (
      <MuiThemeProvider theme={tabs}>
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              style={{
                padding: "0px",
                display:
                  !(checkPermisos("143") || checkPermisos("1")) && "none",
              }}
              label={
                <div
                  style={{
                    width: "100%",
                    padding: "2px 1px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="containerTextTab"
                    style={{ textAlign: "left", marginBottom: "10px" }}
                  >
                    <CreditCardIcon className="iconTab" />
                    <div style={{ fontSize: "11px" }}>01</div>
                    <div className="textTab">
                      <b>Pagos</b>
                      <br />
                      <div className="textTabSub">en línea.</div>
                    </div>
                  </div>
                </div>
              }
            />
            <Tab
              style={{
                marginLeft: "2px",
                padding: "0px",
                display:
                  !(checkPermisos("144") || checkPermisos("1")) && "none",
              }}
              label={
                <div
                  style={{
                    width: "100%",
                    padding: "2px 1px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="containerTextTab"
                    style={{ textAlign: "left", marginBottom: "10px" }}
                  >
                    <AutoRenewIcon className="iconTab" />
                    <div style={{ fontSize: "11px" }}>02</div>
                    <div className="textTab">
                      <b>Pagos</b>
                      <br />
                      <div className="textTabSub">con cargo automático.</div>
                    </div>
                  </div>
                </div>
              }
            />
            <Tab
              style={{
                marginLeft: "2px",
                padding: "0px",
                display:
                  !(checkPermisos("145") || checkPermisos("1")) && "none",
              }}
              label={
                <div
                  style={{
                    width: "100%",
                    padding: "2px 1px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="containerTextTab"
                    style={{ textAlign: "left", marginBottom: "10px" }}
                  >
                    <StoreIcon className="iconTab" />
                    <div style={{ fontSize: "11px" }}>03</div>
                    <div className="textTab">
                      <b>Pagos</b>
                      <br />
                      <div className="textTabSub">en OXXO o SPEI.</div>
                    </div>
                  </div>
                </div>
              }
            />
            <Tab
              style={{
                marginLeft: "2px",
                padding: "0px",
                display:
                  !(checkPermisos("146") || checkPermisos("1")) && "none",
              }}
              label={
                <div
                  style={{
                    width: "100%",
                    padding: "2px 1px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="containerTextTab"
                    style={{ textAlign: "left", marginBottom: "10px" }}
                  >
                    <AccountIcon className="iconTab" />
                    <div style={{ fontSize: "11px" }}>04</div>
                    <div className="textTab">
                      <b>Mis</b>
                      <br />
                      <div className="textTabSub">recibos de pago.</div>
                    </div>
                  </div>
                </div>
              }
            />
          </StyledTabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
          style={{ overFlow: "hidden" }}
          slideClassName="slideOverflow"
        >
          <TabContainer dir={theme.direction}>
            {(checkPermisos("143") || checkPermisos("1")) &&
            dataUser.exento === "0" ? (
              <DivPagoConTarjeta
                dataUser={dataUser}
                payMonths={payMonths}
                payYear={payYear}
                montoPagar={montoPagar}
                montoPagarSinComision={montoPagarSinComision}
                payMonthsE={payMonthsE}
                payYearE={payYearE}
                ntE={ntE}
                mmE={mmE}
                aaE={aaE}
                cvvE={cvvE}
                nombreTitularE={nombreTitularE}
                disableBtnPay={disableBtnPay}
                months={months}
                registerNt={registerNt}
                suscription={suscription}
                actualYear={actualYear}
                passYear={passYear}
                expanded={expanded}
                mmEG={mmEG}
                aaEG={aaEG}
                cvvEG={cvvEG}
                userNts={userNts}
                handleChange={this.handleChange}
                handleChangePanel={this.handleChangePanel}
                handleChangeMultiple={this.handleChangeMultiple}
                handleChangeCB={this.handleChangeCB}
                createToken={this.createToken}
                renderMisTarjetas={this.renderMisTarjetas}
                deleteNt={this.deleteNt}
                disableSelectMonths={disableSelectMonths}
                handleOpenTour={handleOpenTour}
                mobile={mobile}
                checkPermisos={checkPermisos}
                userCasasLocales={userCasasLocales}
                casaLocal={casaLocal}
                casaLocalE={casaLocalE}
              />
            ) : (
              <div className="text-center" style={{ marginTop: "40px" }}>
                <img
                  className="img-raised rounded-circle img-fluid"
                  src="assets/img/vacio.png"
                  style={{ width: "100px" }}
                />
                <h4>Usuario exento de pago</h4>
              </div>
            )}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {checkPermisos("144") || checkPermisos("1") ? (
              <DivPagosDomiciliados
                pagosDomiciliados={pagosDomiciliados}
                alertCancelSuscription={this.alertCancelSuscription}
              />
            ) : (
              <div></div>
            )}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {(checkPermisos("145") || checkPermisos("1")) &&
            dataUser.exento === "0" ? (
              <DivPagoOxxo
                dataUser={dataUser}
                payMonths={payMonths}
                payYear={payYear}
                montoPagar={montoPagar}
                montoPagarSinComision={montoPagarSinComision}
                payMonthsE={payMonthsE}
                payYearE={payYearE}
                nombreTitularE={nombreTitularE}
                disableBtnPay={disableBtnPay}
                months={months}
                handleChangeMultiple={this.handleChangeMultiple}
                handleChange={this.handleChange}
                createToken={this.createToken}
                disableSelectMonths={disableSelectMonths}
                handleOxxoPay={this.handleOxxoPay}
                fichaOxxoPay={fichaOxxoPay}
                handleOpenTour={handleOpenTour}
                mobile={mobile}
                oxxoSpei={oxxoSpei}
                checkPermisos={checkPermisos}
                userCasasLocales={userCasasLocales}
                casaLocal={casaLocal}
                casaLocalE={casaLocalE}
              />
            ) : (
              <div className="text-center" style={{ marginTop: "40px" }}>
                <img
                  className="img-raised rounded-circle img-fluid"
                  src="assets/img/vacio.png"
                  style={{ width: "100px" }}
                />
                <h4>Usuario exento de pago</h4>
              </div>
            )}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {checkPermisos("146") || checkPermisos("1") ? (
              <DivMisPagos
                getData={getData}
                ajaxSource={ajaxSource}
                dataUser={dataUser}
                payMonths={payMonths}
                payYear={payYear}
                payMonthsE={payMonthsE}
                payYearE={payYearE}
                months={months}
                handleChangeMultiple={this.handleChangeMultiple}
                handleChange={this.handleChange}
                disableSelectMonths={disableSelectMonths}
                recibosYear={recibosYear}
                userRecibos={userRecibos}
                cerrarNotificacion={cerrarNotificacion}
                handleRecibosYear={handleRecibosYear}
                prodEnv={prodEnv}
              />
            ) : (
              <div></div>
            )}
          </TabContainer>
        </SwipeableViews>
      </MuiThemeProvider>
    );
  }
}

DivPagoEnLinea.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivPagoEnLinea);
