import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from "@material-ui/icons/Cancel";
import AutorizarIcon from '@material-ui/icons/AssignmentTurnedIn';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import DialogDeclinarPago from "./DialogDeclinarPago";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#777777',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

class DivAutorizarPagos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      ecommId:"",
      openDialog:false
    }
  }

  handleAlertAutorizarPago = (ecommId) => {
    const {crearAlerta} = this.props;
    this.setState({ecommId:ecommId}, () => {
      crearAlerta("warning", "", "¿Seguro que deseas autorizar el pago?", true, this.autorizarPago);
    })
  }

  handleAlertRechazarPago = (ecommId) => {
    this.setState({ecommId:ecommId}, () => {
      this.setState({openDialog:true});
    });
  }

  handleCloseDialog = () => {
    this.setState({openDialog:false});
  }

  autorizarPago = () =>{
    const {cerrarAlerta, ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo} = this.props;
    cerrarAlerta();
    const {ecommId, openDialog} = this.state;
    var userId = dataUser.userId;
    cerrarAlerta();
    crearNotificacion("info", "Autorizando pago...", "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/autorizarPago.php",{
      userId:userId,
      ecommId:ecommId
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          setTimeout(function () {
            crearNotificacion("success", "Pago autorizado con exito.", "bottom", "right", 3000);
            getAdminInfo();
          },500);
        } else {
          crearNotificacion("error", "Hubo un problema al intentar autorizar el pago " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  render() {
    const {dataUser, autorizacionPagos, checkPermisos, prodEnv, ajaxSource, getData, crearNotificacion, cerrarNotificacion, getAdminInfo} = this.props;
    const {openDialog, ecommId} = this.state;

    var casaLocalLabel = "Casa";
    var residenteLocatarioLabel = "Residente"
    if(checkPermisos("125")) {
      casaLocalLabel = "Local";
      residenteLocatarioLabel = "Locatario";
    }

    return(
      <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
        <div className="col-md-6">
          <span><h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>Autorizar pagos.</h3></span>
        </div>
        <div className="col-md-12">
          <DataMaterialTableNE
            dataUser={dataUser}
            title={"Pagos"}
            data={autorizacionPagos}
            columns={[
              {
                title: casaLocalLabel,
                field: 'casaLocal',
                cellStyle: {textAlign: 'center'},
                render: rowData => {
                  return(
                    <>
                      <div style={{width:'100%'}}>{rowData.casaLocal}</div>
                      <div style={{width:'100%', marginTop:'10px'}}>
                        <IconButton style={{padding:'0px'}} aria-label="Autorizar" onClick={()=>this.handleAlertAutorizarPago(rowData.ecommId)}>
                          <AutorizarIcon />
                        </IconButton>
                        <IconButton style={{padding:'0px'}} aria-label="Autorizar" onClick={()=>this.handleAlertRechazarPago(rowData.ecommId)}>
                          <CancelIcon />
                        </IconButton>
                      </div>
                    </>
                  )
                },
              },
              {
                title: 'Comprobante',
                field: 'comprobantePath',
                cellStyle: {textAlign: 'center'},
                render: rowData => {
                  return(<a href={prodEnv + rowData.comprobantePath} target="_blank"><img src="assets/img/iconos/files/png/png.png" style={{width:'40px'}}/></a>)
                },
              },
              { title: residenteLocatarioLabel, field: 'userName', cellStyle: {textAlign: 'center'}},
              { title: "Mes", field: 'payMonth', cellStyle: {textAlign: 'center'}},
              { title: "Año", field: 'payYear', cellStyle: {textAlign: 'center'}},
              { title: "Forma Pago", field: 'tipoComprobante', cellStyle: {textAlign: 'center'}}
            ]}
          />
        </div>
        <DialogDeclinarPago
          ajaxSource={ajaxSource}
          getData={getData}
          open={openDialog}
          dataUser={dataUser}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          getAdminInfo={getAdminInfo}
          handleClose={this.handleCloseDialog}
          ecommId={ecommId}
        />
      </div>
    )
  }
}

DivAutorizarPagos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivAutorizarPagos);
