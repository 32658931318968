import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import OkIcon from '@material-ui/icons/CheckCircleRounded';
import DownLoadIcon from '@material-ui/icons/CloudDownloadRounded';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import DataTableMT from "../../commons/DataTableMT";
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import DialogEgresos from "./DialogEgresos";
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MaterialTable from "mui-datatables";
import styled from 'styled-components';
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoRounded";

import Checkbox from "@material-ui/core/Checkbox";
import DialogIngresos from "./DialogIngresos";

const $ = require('jquery');

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
  td: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  th: {
    color: '#737373',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '40%'
  }
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused:not $notchedOutline:not": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'10px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '20px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivRevisarEstadoCuenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:0,
      estadoYear:"",
      estadoMonth:"",
      disableSelectMonths:true,
      estadoCuenta: [],
      searchTable: "",
      openDialogCrearEgreso: false,
      handleOpenDialogCrearEgreso:0,
      openDialogCrearIngreso: false,
      handleOpenDialogCrearIngreso:0,
      totalIngresos: 0,
      totalEgresos: 0,
      porcPagado: "",
      ingresoIdEliminar:"",
      handleOptionEstadoCuentaPersonal: 0,
      ecommIdEliminar:""
    }
  }

  componentDidMount() {
    const { dataUser } = this.props;
    var actualYear = dataUser.sistemYear;
    var d = new Date();
    var m = d.getMonth();
    var actualMonth = months[m];
    this.setState({estadoYear:actualYear, estadoMonth:actualMonth}, () => {
      this.getEstadoCuenta();
    });
  }

  handleOptionEstadoCuentaPersonal = () => {
    const {handleOptionEstadoCuentaPersonal} = this.state;
    this.setState({handleOptionEstadoCuentaPersonal:handleOptionEstadoCuentaPersonal+1})
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value},()=> {
      if(name === "estadoYear") {
        this.setState({disableSelectMonths:false});
      }
      if(name === "estadoMonth") {
        this.getEstadoCuenta();
      }
    });
    this.setState({[name+'E']:false});
  };

  handleOpenDialogCrearEgreso = () => {
    const {handleOpenDialogCrearEgreso} = this.state;
    this.setState({openDialogCrearEgreso:true},()=> {
      this.setState({handleOpenDialogCrearEgreso:handleOpenDialogCrearEgreso+1});
    });
  }

  handleOpenDialogCrearIngreso = () => {
    const {handleOpenDialogCrearIngreso} = this.state;
    this.setState({openDialogCrearIngreso:true},()=> {
      this.setState({handleOpenDialogCrearIngreso:handleOpenDialogCrearIngreso+1});
    });
  }
 

  handleCloseDialogCrearEgreso = () => {
    this.setState({openDialogCrearEgreso:false});
  }

  handleCloseDialogCrearIngreso = () => {
    this.setState({openDialogCrearIngreso:false});
  }

  handleSearchTable = (id) => {
    var val = $("#"+id+"estadoCuenta").val();
    this.setState({searchTable:val},()=> {
      this.getEstadoCuenta(val);
    });
  }

  handleCerrarEstadoCuenta = () => {
    const {crearAlerta} = this.props;
    crearAlerta("warning", "", "¿Seguro que deseas guardar este estado de cuenta? Estará disponible para los residentes", true, this.cerrarEstadoCuenta);
  }

  handleEliminarEgreso = (egresoId) => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, cerrarAlerta} = this.props;
    var userId = dataUser.userId;

    crearNotificacion("info", "Eliminando egreso...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/deleteEgreso.php",{
      userId:userId,
      egresoId:egresoId}).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          crearNotificacion("success", "Egreso eliminado con exito.", "bottom", "right", 3000);
          self.getEstadoCuenta();
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar eliminar el egreso " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  handleEliminarIngresoManual = (ingresoId, ecommId) => {
    const {crearAlerta} = this.props;

    this.setState({ingresoIdEliminar:ingresoId, ecommIdEliminar:ecommId}, ()=> {
      crearAlerta("warning", "", "¿Seguro que deseas eliminar este registro de pago?", true, this.eliminarIngresoManual);
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({ value }, () => {
      this.getEstadoCuenta();
    });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  eliminarIngresoManual = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, cerrarAlerta} = this.props;
    const {ingresoIdEliminar, ecommIdEliminar, value} = this.state;
    var userId = dataUser.userId;

    cerrarAlerta();
    crearNotificacion("info", "Eliminando egreso...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/deleteIngresoManual.php",{
      userId:userId,
      ingresoId:ingresoIdEliminar,
      ecommId:ecommIdEliminar,
      tab:value
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          crearNotificacion("success", "Ingreso eliminado con exito.", "bottom", "right", 3000);
          setTimeout(function () {
            self.getEstadoCuenta();
          },1000)
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar eliminar el ingreso " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  descargarReportePDF = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, loadedPage, checkPermisos, prodEnv} = this.props;
    const {estadoYear, estadoMonth, value} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Generando reporte...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/crearReportePdf.php",{
      userId:userId,
      estadoYear:estadoYear,
      estadoMonth:estadoMonth,
      tab:value
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();

        var data = response.data;

        if(data[0].error === 2) {
          crearNotificacion("error", "Hubo un problema al intentar cerrar el estado de cuenta " + data[0].error, "bottom", "right", 3000);
        } else if(data[0].error === 1) {

          var path = data[1];

          window.open(prodEnv+path, '_blank');
        }
      }
    });
  }

  descrargarReportePdfUsuarios(){
    console.log("Descargamos PDF de usuarios");

    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, loadedPage, checkPermisos, prodEnv} = this.props;
    const {estadoYear, estadoMonth, value} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Generando reporte...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/crearReportePdfUsuarios.php").then(function (response) {
      if (response.data) {
        cerrarNotificacion();

        var data = response.data;

        if(data[0].error === 2) {
          crearNotificacion("error", "Hubo un problema al intentar cerrar el estado de cuenta " + data[0].error, "bottom", "right", 3000);
        } else if(data[0].error === 1) {

         console.log("no hay error");
         window.open('_blank');
           
        }
      }
    });
  }

  descargarListaCSV = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, loadedPage, checkPermisos, prodEnv} = this.props;
    const {estadoYear, estadoMonth, value} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Generando lista...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/crearListaCSV.php",{
      userId:userId,
      estadoYear:estadoYear,
      estadoMonth:estadoMonth,
      tab:value
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();

        var data = response.data;

        if(data[0].error === 2) {
          crearNotificacion("error", "Hubo un problema al intentar crear la lista " + data[0].error, "bottom", "right", 3000);
        } else if(data[0].error === 1) {

          var path = data[1];

          window.open(prodEnv+path, '_blank');
        }
      }
    });
  }

  getEstadoCuenta = (searchValue) => {
    const self = this;
    const { ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, loadedPage, checkPermisos } = this.props;
    const {estadoYear, estadoMonth, value} = this.state;
    var userId = dataUser.userId;

    if(estadoYear !== "") {

      crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);

      var showInprocess = 0;
      if(checkPermisos("1") || checkPermisos("100")) {
        showInprocess = 1;
      }

      getData(ajaxSource, "estadoCuenta/estadoCuenta.php", {
        userId: userId,
        estadoYear: estadoYear,
        estadoMonth: estadoMonth,
        searchValue: searchValue,
        showInprocess: showInprocess,
        tab: value
      }).then(function (response) {
        if (response.data) {
          loadedPage(true);
          cerrarNotificacion();
          var data = response.data;
          var estadoCuenta = data[0];
          var totalIngresos = data[1];
          var totalEgresos = data[2];
          var porcPagado = data[3];
          self.setState({
            estadoCuenta: estadoCuenta,
            totalIngresos: totalIngresos,
            totalEgresos: totalEgresos,
            porcPagado: porcPagado
          });
        }
      });
    }
  }

  cerrarEstadoCuenta = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, cerrarAlerta} = this.props;
    const {estadoYear, estadoMonth} = this.state;
    var userId = dataUser.userId;
    cerrarAlerta();

    crearNotificacion("info", "Cerrando estado de cuenta...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/cerrarEstadoCuenta.php",{
      userId:userId,
      estadoYear:estadoYear,
      estadoMonth:estadoMonth}).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          crearNotificacion("success", "Estado de cuenta cerrado con exito.", "bottom", "right", 3000);
          self.getEstadoCuenta();
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar cerrar el estado de cuenta " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  renderBtnCrearSalida = () => {
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("1") || checkPermisos("100")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="default" className={classes.button} style={{float: 'right'}} onClick={()=>this.handleOpenDialogCrearEgreso()}>
            Crear Egreso
            <DollarIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }

  renderBtnCrearEntrada = () => {
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("1") || checkPermisos("100")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="default" className={classes.button} style={{float: 'right'}} onClick={()=>this.handleOpenDialogCrearIngreso()}>
            Crear Ingreso
            <DollarIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }




 

  renderBtnCerrarEstado = () => {
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("1") || checkPermisos("101")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right'}} onClick={()=>this.handleCerrarEstadoCuenta()}>
            Guardar Estado
            <OkIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }

  renderBtnDescargarReportePDF = () => {
    const {classes, checkPermisos, dataUser} = this.props;
    
    if(checkPermisos("1") || checkPermisos("140")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right'}} onClick={()=>this.descargarReportePDF()}>
            Descargar Reporte PDF
            <DownLoadIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }
 /* Crear estados de cuenta personales para cada usuario, con sus pagos y saldos. #8
  Botón para dicha tarea solo se habilita en usuarios residentes-locatarios
 
 */
  renderBtnDescargarReportePDFUsuarios = () => {
    const {classes, checkPermisos, dataUser} = this.props;
    if(!(checkPermisos("1") || checkPermisos("140"))) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right'}} onClick={()=> this.handleOptionEstadoCuentaPersonal()}>
            Estado de cuenta de {dataUser.userName}
            <DownLoadIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }


  renderBtnDescargarListaCSV = () => {
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("1") || checkPermisos("148")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right'}} onClick={()=>this.descargarListaCSV()}>
            Descargar Lista en CSV
            <DownLoadIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }

  renderTablaEstadoCuenta = () => {
    const {dataUser, checkPermisos, mobile} = this.props;
    const {value, estadoMonth, estadoYear, estadoCuenta, idsDelete, searchTable, totalEgresos, totalIngresos, porcPagado} = this.state;


    
    var labelResidenteLocatario = "Residente/Locatario";
    var casaLocal = "";
    if(checkPermisos("124")) {labelResidenteLocatario = "Residente"; casaLocal = 'Casa'; } 
    else if(checkPermisos("125")) {labelResidenteLocatario = "Locatario"; casaLocal = 'Local'}

    if ((Object.keys(estadoCuenta).length > 0 || searchTable !== "" || checkPermisos("1") || checkPermisos("100")) && estadoMonth !== "" && estadoYear !== "") {

      var h4PorcPagado = "";
      if(checkPermisos("125")) {
        h4PorcPagado =  <h3 style={{marginLeft: '10px'}}>Porcentaje de pago: {porcPagado}%</h3>
      }

      var paddingRow = '14px 16px 14px 16px';
      if(mobile === true) {
        paddingRow = '0px';
      }

      return (
        <div className="row" style={{paddingTop: '0px', margin: '0px'}}>
          <div className="col-md-4">
            <span><h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>Lista de pago</h3></span>
            <span><h4 className="darkGrayUpera" style={{marginTop: '0px'}}>{estadoMonth} {estadoYear}</h4></span>
          </div>
          <div className="col-md-8" style={{display:'flex'}}>
            {value == 0 &&
              this.renderBtnCerrarEstado()
            }
            {this.renderBtnDescargarReportePDF()}
             
            {this.renderBtnDescargarListaCSV()}
            {value == 0 &&
              this.renderBtnCrearSalida()  
            }
            { value == 0 &&
              this.renderBtnCrearEntrada()  }
          </div>
          <div className="col-md-12" style={{display:'flex'}}>
            <h3>Ingresos: {totalIngresos}</h3>
            <h3 style={{marginLeft: '10px'}}>Egresos: {totalEgresos}</h3>
            {h4PorcPagado}
          </div>
          <div className="col-md-12">
            {/*<DataMaterialTableNE
              dataUser={dataUser}
              title={"Usuarios"}
              data={estadoCuenta}
              columns={[
                { title: 'ID', field: 'id', editable: 'never', hidden:true},
                {
                  title: 'Folio',
                  field: 'folio',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {

                    var terminado = rowData.terminado;
                    var styleRow = {color:"#0345ea", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"#0345ea", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }

                    var val = rowData.id;
                    if(val === "btnEliminar" && (checkPermisos("1") || checkPermisos("102"))) {
                      val =  <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>this.handleEliminarEgreso(rowData.id)}>
                        <DeleteIcon />
                      </IconButton>;
                    }

                    var btnEliminarRegistroManual = "";
                    if(rowData.registroManual === 1 && rowData.entradaSalida === "Entrada" && (checkPermisos("1") || checkPermisos("141"))) {
                      btnEliminarRegistroManual = <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>this.handleEliminarIngresoManual(rowData.id, rowData.ecommId)}>
                        <DeleteIcon />
                      </IconButton>;
                    }
                    return (
                      <div style={styleRow}>
                        <b>{val} {btnEliminarRegistroManual}</b>
                      </div>
                    );
                  },
                },
                {
                  title: 'Fecha',
                  field: 'fechaMovimiento',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {

                    var terminado = rowData.terminado;
                    var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }
                    return (
                      <div style={styleRow}>
                        {rowData.fechaMovimiento}
                      </div>
                    );
                  },
                },
                {
                  title: 'Concepto',
                  field: 'concepto',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {

                    var terminado = rowData.terminado;
                    var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }
                    return (
                      <div style={styleRow}>
                        {rowData.concepto}
                      </div>
                    );
                  },
                },
                {
                  title: 'Mes Pagado',
                  field: 'mesPagado',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {

                    var terminado = rowData.terminado;
                    var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }
                    return (
                      <div style={styleRow}>
                        {rowData.mesPagado}
                      </div>
                    );
                  },
                },
                {
                  title: 'Ingresos',
                  field: 'montoEntrada',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {

                    var terminado = rowData.terminado;
                    var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }
                    return (
                      <div style={styleRow}>
                        {rowData.montoEntrada}
                      </div>
                    );
                  },
                },
                {
                  title: 'Egresos',
                  field: 'montoSalida',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {

                    var terminado = rowData.terminado;
                    var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }
                    return (
                      <div style={styleRow}>
                        {rowData.montoSalida}
                      </div>
                    );
                  },
                },
              ]}
            />*/}
            <DataTableMT
              download={false}
              mobile={mobile}
              dataUser={dataUser}
              title={""}
              data={estadoCuenta}
               
              columns={[
                { label: 'ID', name: 'id', options:{display:false}},
                { label: 'Folio', name: 'folio',textalign:'center',  options:{
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                      
                      var styleRow = {color:"#0345ea", textAlign:'center', padding:paddingRow};
                      if(terminado === "0") {
                        styleRow = {color:"#0345ea", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                      }
                      var val = value;

                      var btnEliminarRegistroManual2 = "";
                      if((checkPermisos("1") || checkPermisos("141"))) {
                         btnEliminarRegistroManual2 = <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>this.handleEliminarIngresoManual(estadoCuenta[tableMeta.rowIndex].id, estadoCuenta[tableMeta.rowIndex].ecommId)}>
                           <DeleteIcon />
                         </IconButton>;
                       } 
                      
                      if(val === "btnEliminar" && (checkPermisos("1") || checkPermisos("102"))) {
                        val =  <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>this.handleEliminarEgreso(estadoCuenta[tableMeta.rowIndex].id)}>
                          <DeleteIcon />
                        </IconButton>;
                      }



                      
                      /*#12 El administrador tiene permiso de borrar o editar pagos registrados 
                      (aparece un icono para eliminar) pero en algunos casos no permite
                       la edición de los mismos.*/
                       

                      var btnEliminarRegistroManual = "";
                     if(estadoCuenta[tableMeta.rowIndex].registroManual === 1 && estadoCuenta[tableMeta.rowIndex].entradaSalida === "Entrada" && (checkPermisos("1") || checkPermisos("141"))) {
                        btnEliminarRegistroManual = <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>this.handleEliminarIngresoManual(estadoCuenta[tableMeta.rowIndex].id, estadoCuenta[tableMeta.rowIndex].ecommId)}>
                          <DeleteIcon />
                        </IconButton>;
                      } 

                      
                       /*  btnEliminarRegistroManual = <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>this.handleEliminarIngresoManual(estadoCuenta[tableMeta.rowIndex].id, estadoCuenta[tableMeta.rowIndex].ecommId)}>
                          <DeleteIcon />
                        </IconButton>;
                       */






                      return (
                        <div style={styleRow}>
                          <b>{val} {btnEliminarRegistroManual2} </b>
                        </div>
                      );
                    }
                  }
                },
                { label: 'Fecha', name: 'fechaMovimiento',  options:{
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                      var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                      if(terminado === "0") {
                        styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                      }


                      
                      return (
                        <div style={styleRow}>
                         {value}  
                        </div>
                      );
                    }
                  }
                },
 
               /*   #0 Agregar nombre de usuario a la 
                lista de pagos, solo se ve el numero de casa. */ 
                { label: casaLocal , name: 'casa',  options:{
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                    var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                    if(terminado === "0") {
                      styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                    }
                    return (
                      <div style={styleRow}>
                        {value}
                      </div>
                    );
                  }
                }
              },

              /*
 
              { label: labelResidenteLocatario, name: 'residente',  options:{
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                  var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                  if(terminado === "0") {
                    styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                  }
                  return (
                    <div style={styleRow}>
                      {value}
                    </div>
                  );
                }
              }
            },

            */


                { label: 'Concepto', name: 'concepto', options:{
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                      var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                      if(terminado === "0") {
                        styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                      }
                      return (
                        <div style={styleRow}>
                          {value}
                        </div>
                      );
                    }
                  }
                },

               

                { label: 'Mes pagado', name: 'mesPagado',  options:{
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                      var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                      if(terminado === "0") {
                        styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                      }
                      return (
                        <div style={styleRow}>
                          {value}
                        </div>
                      );
                    }
                  }
                },
                { label: 'Ingresos', name: 'montoEntrada',  options:{
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                      var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                      if(terminado === "0") {
                        styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                      }
                      return (
                        <div style={styleRow}>
                          {value}
                        </div>
                      );
                    }
                  }
                },
                { label: 'Egresos', name: 'montoSalida',  options:{
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      var terminado = estadoCuenta[tableMeta.rowIndex].terminado;
                      var styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', padding:paddingRow};
                      if(terminado === "0") {
                        styleRow = {color:"rgba(0, 0, 0, 0.54)", textAlign:'center', backgroundColor: '#e1f5fe', padding:paddingRow};
                      }
                      return (
                        <div style={styleRow}>
                          {value}
                        </div>
                      );
                    }

                  }
                },
              ]}
            />
          </div>
        </div>
      )
    } else if(searchTable === "" && Object.keys(estadoCuenta).length === 0) {
      return(
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Sin movimientos, selecciona un mes</h4>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const {theme, classes, dataUser, crearNotificacion, ajaxSource, getData,  cerrarNotificacion, checkPermisos} = this.props;
    const {value, estadoYear, estadoMonth, disableSelectMonths, openDialogCrearEgreso, handleOpenDialogCrearEgreso, openDialogCrearIngreso, handleOpenDialogCrearIngreso} = this.state;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;

    var tabMantto = "";
    tabMantto = <Tab style={{padding: '0px', marginLeft: '2px'}} label={
      <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
        <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
          <DollarIcon className="iconTab"/>
          <div className="textTab"><b>Lista </b><br/>
            <div className="textTabSub">Mantenimiento.</div>
          </div>
        </div>
      </div>
    }/>

    var tabRentas = "";
    if(checkPermisos("1") || checkPermisos("149")) {
      tabRentas = <Tab style={{padding: '0px', marginLeft: '2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <DollarIcon className="iconTab"/>
            <div className="textTab"><b>Lista </b><br/>
              <div className="textTabSub">Rentas.</div>
            </div>
          </div>
        </div>
      }/>
    }

    return(
      <MuiThemeProvider theme={tabs}>
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabMantto}
            {tabRentas}
          </StyledTabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
          style={{overFlow:'hidden'}}
          slideClassName="slideOverflow"
        >
          <TabContainer dir={theme.direction}>
            <MuiThemeProvider theme={FormInputs}>
              <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
                <div className="col-md-12 mtM">
                  <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
                </div>
                <div className="col-md-4" style={{marginTop:'20px'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="payYear"
                      style={{
                        background: '#FFF',
                        padding: '0px 5px 1px 5px'
                      }}
                    >
                      Año
                    </InputLabel>
                    <Select
                      value={estadoYear}
                      onChange={this.handleChange('estadoYear')}
                      input={
                        <OutlinedInput id="estadoYear" labelWidth={0}/>
                      }
                    >
                      <MenuItem value={actualYear}>{actualYear}</MenuItem>
                      <MenuItem value={passYear}>{passYear}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4" style={{marginTop:'20px'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-months"
                      style={{
                        background: 'inherit',
                        padding: '0px 5px 1px 5px'
                      }}

                    >
                      Mes
                    </InputLabel>
                    <Select
                      style={{width:'100%'}}
                      value={estadoMonth}
                      onChange={this.handleChange('estadoMonth')}
                      input={<OutlinedInput labelWidth={0} id="outlined-months"/>}
                    >
                      {months.map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {this.renderTablaEstadoCuenta()}
              <DialogEgresos
                open={openDialogCrearEgreso}
                handleClose={this.handleCloseDialogCrearEgreso}
                handleOpenDialogCrearEgreso={handleOpenDialogCrearEgreso}
                crearNotificacion={crearNotificacion}
                cerrarNotificacion={cerrarNotificacion}
                ajaxSource={ajaxSource}
                getData={getData}
                dataUser={dataUser}
                estadoYear={estadoYear}
                estadoMonth={estadoMonth}
                getEstadoCuenta={this.getEstadoCuenta}
              />

              <DialogIngresos
                open={openDialogCrearIngreso}
                handleClose={this.handleCloseDialogCrearIngreso}
                handleOpenDialogCrearIngreso={handleOpenDialogCrearIngreso}
                crearNotificacion={crearNotificacion}
                cerrarNotificacion={cerrarNotificacion}
                ajaxSource={ajaxSource}
                getData={getData}
                dataUser={dataUser}
                estadoYear={estadoYear}
                estadoMonth={estadoMonth}
                getEstadoCuenta={this.getEstadoCuenta}
              
              
              
              />
                

             
            </MuiThemeProvider>
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <MuiThemeProvider theme={FormInputs}>
              <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
                <div className="col-md-12 mtM">
                  <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
                </div>
                <div className="col-md-4" style={{marginTop:'20px'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="payYear"
                      style={{
                        background: '#FFF',
                        padding: '0px 5px 1px 5px'
                      }}
                    >
                      Año
                    </InputLabel>
                    <Select
                      value={estadoYear}
                      onChange={this.handleChange('estadoYear')}
                      input={
                        <OutlinedInput id="estadoYear" labelWidth={0}/>
                      }
                    >
                      <MenuItem value={actualYear}>{actualYear}</MenuItem>
                      <MenuItem value={passYear}>{passYear}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-4" style={{marginTop:'20px'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-months"
                      style={{
                        background: 'inherit',
                        padding: '0px 5px 1px 5px'
                      }}

                    >
                      Mes
                    </InputLabel>
                    <Select
                      style={{width:'100%'}}
                      value={estadoMonth}
                      onChange={this.handleChange('estadoMonth')}
                      input={<OutlinedInput labelWidth={0} id="outlined-months" disabled={disableSelectMonths}/>}
                    >
                      {months.map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {this.renderTablaEstadoCuenta()}
            </MuiThemeProvider>
          </TabContainer>
        </SwipeableViews>
      </MuiThemeProvider>
    )
  }
}

DivRevisarEstadoCuenta.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(DivRevisarEstadoCuenta);
