/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../componentsmKit/Header/Header.js";
import Footer from "../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../componentsmKit/Grid/GridItem.js";
import Button from "../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../assets/jss/material-kit-react/views/landingPage.js";

import HomeParallax from "./views/HomeParallax";
import HomeBannerUno from "./views/HomeBannerUno";
import HomeBannerDos from "./views/HomeBannerDos";
import HomeBannerTres from "./views/HomeBannerTres";
import HomePlanes from "./views/HomePlanes";
import HomeBannerCuatro from "./views/HomeBannerCuatro";


const dashboardRoutes = [];
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiListItemIcon: {
     root: {
       minWidth:'40px'
     }
    },
    MuiListItem: {
     root: {
       paddingTop:'3px',
       paddingBottom:'3px',
       fontFamily: 'Poppins Medium',
       fontSize:'15px'
     }
    }
  },
});

class WebUperaHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {changePageBand, loadedPage} = this.props;
    if(nextProps.changePageBand !== changePageBand) {
      loadedPage(true);
    }
  }

  render() {
    const {classes, changePage, handleScrollTo, mobile, ...rest} = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="Material Kit React"
          rightLinks={<HeaderLinks
            handleScrollTo={handleScrollTo}
          />}
          fixed
          changePage={changePage}
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          handleScrollTo={handleScrollTo}
          {...rest}
        />
        <HomeParallax
          mobile={mobile}
          changePage={changePage}
        />
        <div className={classNames(classes.main, classes.mainRaised)} style={{paddingBottom:'50px'}}>
          {!mobile &&
            <HomeBannerUno
              changePage={changePage}
            />
          }
          <HomeBannerDos
            mobile={mobile}
            changePage={changePage}
          />
          <HomeBannerTres
            mobile={mobile}
            changePage={changePage}
          />
          <HomePlanes
            mobile={mobile}
            changePage={changePage}
          />
          <HomeBannerCuatro
            mobile={mobile}
            changePage={changePage}
          />
        </div>
        <Footer
          changePage={changePage}
          mobile={mobile}
        />
      </MuiThemeProvider>
    )
  }
}

WebUperaHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WebUperaHome);
