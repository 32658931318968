/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../../componentsmKit/Header/Header.js";
import Footer from "../../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../../componentsmKit/Grid/GridItem.js";
import Button from "../../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

class HomeBannerCuatro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val:""
    }
  }

  render() {
    const {classes, mobile, changePage} = this.props;

    return(
      <div style={{ width:'100%', borderRadius:'0px'}}>
        <div className={classes.container}>
          <div style={{width:'100%', padding:'90px 0px'}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{width:'100%', textAlign:'center'}}>
                  {!mobile ?
                    <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'2.8125rem', lineHeight:'47px'}}>Comienza a utilizar <br></br> el sistema de administración y contabilidad <br></br> más simple y seguro.</h1>
                    :
                    <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'1.8125rem', lineHeight:'34px'}}>Comienza a utilizar <br></br> el sistema de administración y contabilidad <br></br> más simple y seguro.</h1>
                  }

                </div>
                <div style={{width:'100%', textAlign:'center', marginTop:'30px'}}>
                  <Button
                    color="blueUpera"
                    size="lg"
                    onClick={()=>changePage("uperaContacto")}
                  >
                    Empezar ahora
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

HomeBannerCuatro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeBannerCuatro);
