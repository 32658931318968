/*Componentes globales*/
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

/*Componentes de la aplicación*/
import {FilePond, File, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import * as Doka from './doka-4.1.2/bin/browser/doka.min';
import './doka-4.1.2/bin/browser/doka.min.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const $ = require('jquery');

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginImageExifOrientation, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit, FilePondPluginFileRename);

class DokaManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }

  render() {
    const {type, editorOptions, ajaxSource, ajaxSourcePath, urlServerUpload, onprocessfile, imagePreviewHeight, imageCropAspectRatio, imageResizeTargetWidth, imageResizeTargetHeight, imageEditInstantEdit, acceptedFileTypes, actualImage, fileRenameFunction, stylePanelLayout, onremovefile, instantUpload, txtUpload, labelIdle, onaddfile} = this.props;
    
    if(type === "imgUsers") {
      return (
        <FilePond
          acceptedFileTypes={acceptedFileTypes}
          labelIdle={[
            'Arrastra y suelta tus archivos ó <span class="filepond--label-action">Buscalos</span>'
          ]}
          instantUpload={true}
          imagePreviewHeight={imagePreviewHeight}
          imageCropAspectRatio={imageCropAspectRatio}
          imageResizeTargetWidth={imageResizeTargetWidth}
          imageResizeTargetHeight={imageResizeTargetHeight}
          imageEditInstantEdit={imageEditInstantEdit}
          imageEditEditor={Doka.create(editorOptions)}
          server={{
            url: ajaxSource,
            process: "./" + urlServerUpload,
            fetch: "",
            load: ""
          }}
          files={ajaxSourcePath + actualImage}
          onprocessfile={onprocessfile}
          fileRenameFunction={fileRenameFunction}
          stylePanelLayout={stylePanelLayout}
          styleLoadIndicatorPosition="center bottom"
          styleProgressIndicatorPosition="right bottom"
          styleButtonRemoveItemPosition="left bottom"
          styleButtonProcessItemPosition="right bottom"
          labelFileProcessingComplete="Carga completa"
          labelFileWaitingForSize="Esperando el tamaño"
          labelFileSizeNotAvailable="Tamaño no disponible"
          labelFileCountSingular="Archivo en lista"
          labelFileCountPlural="Archivos en lista"
          labelFileLoading="Cargando"
          labelFileAdded="Agregado"
          labelFileRemoved="Removido"
          labelFileLoadError="Error durante la carga"
          labelFileProcessing="Subiendo"
          labelFileProcessingAborted="Subida cancelada"
          labelFileProcessingError="Error durante la subida"
          labelTapToCancel="Cancelar"
          labelTapToRetry="Deshacer"
          labelTapToUndo="Cerrar"
          labelButtonRemoveItem="Quitar"
          labelButtonAbortItemLoad="Abortar"
          labelButtonRetryItemLoad="Reintentar"
          labelButtonAbortItemProcessing="Cancelar"
          labelButtonUndoItemProcessing="Deshacer"
          labelButtonRetryItemProcessing="Reintentar"
          labelButtonProcessItem="Subir"
        >

        </FilePond>
      );
    } else if(type === "image") {
      return(
        <FilePond
          server={{
            url: ajaxSource,
            process: "./" + urlServerUpload,
            fetch: "",
            load: ""
          }}
          acceptedFileTypes={acceptedFileTypes}
          labelIdle={labelIdle}
          instantUpload={true}
          onprocessfile={onprocessfile}
          onremovefile={onremovefile}
          onaddfile={onaddfile}
          fileRenameFunction={fileRenameFunction}

          imagePreviewHeight={imagePreviewHeight}
          imageEditInstantEdit={imageEditInstantEdit}
          imageEditEditor={Doka.create(editorOptions)}

          labelFileProcessingComplete="Carga completa"
          labelFileWaitingForSize="Esperando el tamaño"
          labelFileSizeNotAvailable="Tamaño no disponible"
          labelFileCountSingular="Archivo en lista"
          labelFileCountPlural="Archivos en lista"
          labelFileLoading="Cargando"
          labelFileAdded="Agregado"
          labelFileRemoved="Removido"
          labelFileLoadError="Error durante la carga"
          labelFileProcessing="Subiendo"
          labelFileProcessingAborted="Subida cancelada"
          labelFileProcessingError="Error durante la subida"
          labelTapToCancel="Cancelar"
          labelTapToRetry="Deshacer"
          labelTapToUndo="Cerrar"
          labelButtonRemoveItem="Quitar"
          labelButtonAbortItemLoad="Abortar"
          labelButtonRetryItemLoad="Reintentar"
          labelButtonAbortItemProcessing="Cancelar"
          labelButtonUndoItemProcessing="Deshacer"
          labelButtonRetryItemProcessing="Reintentar"
          labelButtonProcessItem="Subir"
          labelInvalidField="Tipo de archivo no valido"
        />
      )
    } else if(type === "docs") {
      return(
        <FilePond
          server={{
            url: ajaxSource,
            process: "./" + urlServerUpload,
            fetch: "",
            load: ""
          }}
          labelIdle={[
            txtUpload + ' Arrastra y suelta tus archivos ó <span class="filepond--label-action">Buscalos</span>'
          ]}
          instantUpload={true}
          allowMultiple={true}
          maxFiles={10}
          onprocessfile={onprocessfile}
          onremovefile={onremovefile}
          fileRenameFunction={fileRenameFunction}
          labelFileProcessingComplete="Carga completa"
          labelFileWaitingForSize="Esperando el tamaño"
          labelFileSizeNotAvailable="Tamaño no disponible"
          labelFileCountSingular="Archivo en lista"
          labelFileCountPlural="Archivos en lista"
          labelFileLoading="Espere... "
          labelFileAdded="Agregado"
          labelFileRemoved="Removido"
          labelFileLoadError="Error durante la carga"
          labelFileProcessing="Subiendo"
          labelFileProcessingAborted="Subida cancelada"
          labelFileProcessingError="Error durante la subida"
          labelTapToCancel="Cancelar"
          labelTapToRetry="Deshacer"
          labelTapToUndo="Cerrar"
          labelButtonRemoveItem="Quitar"
          labelButtonAbortItemLoad="Abortar"
          labelButtonRetryItemLoad="Reintentar"
          labelButtonAbortItemProcessing="Cancelar"
          labelButtonUndoItemProcessing="Deshacer"
          labelButtonRetryItemProcessing="Reintentar"
          labelButtonProcessItem="Subir"
          labelInvalidField="Tipo de archivo no valido"
        />
      )
    }
  }
}


export default DokaManager;
