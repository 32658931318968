import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import NotificationIcon from '@material-ui/icons/NotificationImportantRounded';
import CreditCardIcon from '@material-ui/icons/CreditCardRounded';
import DocumentIcon from '@material-ui/icons/DescriptionRounded';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import AccountIcon from '@material-ui/icons/AccountCircleRounded';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import TicketsIcon from '@material-ui/icons/HowToVoteRounded';
import KpisIcon from '@material-ui/icons/PieChartRounded';
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
  margin: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  menuItem: {
    '&:focus': {
      backgroundColor: '#0345ea',
      borderRadius: '5px',
      '& $primary, & $icon': {
        color: "#fff",
      },
      '& $secondary': {
        color: "#fff",
      }
    },
    '&:hover' : {
      backgroundColor: '#90a4ae',
      borderRadius: '5px',
      '& $primary, & $icon': {
        color: '#fff',
      }
    }
  },
  menuSelected: {
    backgroundColor: '#0345ea',
    borderRadius: '5px',
    '& $primary, & $icon': {
      color: "#fff",
    },
  },
  primary: {
    fontSize: '18px'
  },
  paper:{
    backgroundColor: '#FFF !important',
    //backgroundColor: '#eee !important',
    boxShadow: 'none !important'
  }
});

const menuTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiMenuItem: {
      root: {
        height: 'auto',
        whiteSpace: 'inherit'
      },
      gutters: {
        paddingLeft: '6px',
        paddingRight: '6px'
      }
    },
    MuiTypography: {
      root: {
        overflow: 'hidden',
        wordWwrap: 'break-word',
        textOverflow: 'ellipsis',
        padding:'0px 16px !important'
      },
      body2: {
        padding:'0px 16px !important'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#FFF',
        //backgroundColor: '#eee'
      },
      elevation1: {
        //boxShadow: Array(25).fill('none') + "!important",
        backgroundColor: '#FFF ',
        //backgroundColor: '#eee'
      },
    },
    MuiListItem: {
      root: {
        paddingTop: '7px',
        paddingBottom: '7px'
      }
    },
    MuiListItemText: {
      inset: {
        paddingLeft:'0px'
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: '10px',
        marginLeft: '10px',
        minWidth: '0px'
      }
    }
  }
})

class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      optionSelected: "",
    }
  }

  componentDidMount() {
    const {handleDivMainEmpty, handleOptionSelected, dataUser} = this.props;
    if(dataUser.userUperaTipoUsuario === "Administrador") {
      this.setState({optionSelected: 4}, () => {
        handleDivMainEmpty(false);
        handleOptionSelected(4);
      });
    }
  }

  handleChangeMenu = (menuOption) => {
    const {handleDivMainEmpty, handleOptionSelected} = this.props;
    this.setState({optionSelected:menuOption},()=> {
      handleDivMainEmpty(false);
      handleOptionSelected(menuOption);
    });
  }

  renderOption1 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<DocumentIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("3")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(1)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_edocuenta.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Revisar lista de pago" secondary={<span style={sl}>Listado de ingresos y egresos.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption2 = (opS) => {
    const {classes, checkPermisos, optionSelected} = this.props;
    var sl = {};
    if(opS !== "" || optionSelected === 2) {
      sl = {color:'#fff'}
    }
    //<CreditCardIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("4")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(2)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_pagos.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Pago en línea" secondary={<span style={sl}>Realizar pagos y descargar recibos.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption3 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<DollarIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("6")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(3)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_pagos.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Registrar pago" secondary={<span style={sl}>Registra pagos en efectivo.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption4 = (opS) => {
    const {classes, checkPermisos, totalNuevasNotifcaciones} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<NotificationIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("5")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(4)}>
            <Badge badgeContent={totalNuevasNotifcaciones} color="secondary">
              <ListItemIcon className={classes.icon}>
                <img src="assets/img/iconos/icono_notificaciones.png" style={{width:'30px', height:'30px'}}/>
              </ListItemIcon>
            </Badge>
            <ListItemText classes={{primary: classes.primary}} inset primary="Mis Notificaciones" secondary={<span style={sl}>Revisa tus notificaciones.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption5 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<AccountIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("8")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(5)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_micuenta.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Mi Cuenta" secondary={<span style={sl}>Cambia tu contraseña.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption6 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<SettingsIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("7")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(6)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_administracion.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Administración" secondary={<span style={sl}>Administra usuarios, cuotas y registra pagos en efectivo.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption7 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<TicketsIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("9")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(7)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_crearticket.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Tickets de Mantenimiento" secondary={<span style={sl}>Genera un nuevo ticket.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption8 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    //<TicketsIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("10")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(8)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_revisarticket.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Tickets de Mantenimiento" secondary={<span style={sl}>Revisar tickets.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption9 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {color: 'rgba(0, 0, 0, 0.54)'};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    // <KpisIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("11")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(9)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_indicadoresMantto.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItem style={{display:'unset', padding:'0px'}}>
              <div style={{padding: '0px 16px', fontSize:'18px'}}>Indicadores de Mantenimiento</div>
              <div style={{padding: '0px 16px'}}><span style={sl}>Genera reportes.</span></div>
            </ListItem>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption10 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    // <KpisIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("12")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(10)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_comunicacionInterna.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Comunicación interna" secondary={<span style={sl}>Crea mensajes.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption11 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    // <KpisIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("13")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(11)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_encuestas.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Encuestas" secondary={<span style={sl}>Crea o contesta encuestas.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption12 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    // <KpisIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("14")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(12)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_accesos_qr.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary="Accesos" secondary={<span style={sl}>Ingresos mediante QR.</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption13 = (opS) => {
    const {classes, checkPermisos} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }
    // <KpisIcon style={sl}/>
    if(checkPermisos("1") || checkPermisos("15")) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(13)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_facturacion.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary={checkPermisos("125") ? "Facturación y pagos" : "Registro de pagos"} secondary={<span style={sl}>{checkPermisos("125") ? "Registra pagos y revisa facturas." : "Registra pagos"}</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }
  }

  renderOption14 = (opS) => {
    const {classes, checkPermisos, dataUser} = this.props;

    var sl = {};
    if(opS !== "") {
      sl = {color:'#fff'}
    }

    if((checkPermisos("1") || checkPermisos("124")) && dataUser.casasLocales.length > 0) {
      return(
        <MuiThemeProvider theme={menuTheme}>
          <MenuItem className={classes.menuItem +" "+ opS} onClick={()=>this.handleChangeMenu(14)}>
            <ListItemIcon className={classes.icon}>
              <img src="assets/img/iconos/icono_facturacion.png" style={{width:'30px', height:'30px'}}/>
            </ListItemIcon>
            <ListItemText classes={{primary: classes.primary}} inset primary={checkPermisos("125") ? "Estado de cuenta" : "Estado de cuenta"} secondary={<span style={sl}>{checkPermisos("125") ? "Historial de pagpos" : "Historial de pagos"}</span>}/>
          </MenuItem>
        </MuiThemeProvider>
      )
    }



  }

  /*Crear estados de cuenta personales para cada usuario, con sus pagos y saldos. #8 */


  render() {
    const {classes, optionSelected} = this.props;

    var op1S = "";
    var op2S = "";
    var op3S = "";
    var op4S = "";
    var op5S = "";
    var op6S = "";
    var op7S = "";
    var op8S = "";
    var op9S = "";
    var op10S = "";
    var op11S = "";
    var op12S = "";
    var op13S = "";
    var op14S = "";

    if(optionSelected === 1) {
      op1S = classes.menuSelected;
    }
    if(optionSelected === 2) {
      op2S = classes.menuSelected;
    }
    if(optionSelected === 3) {
      op3S = classes.menuSelected;
    }
    if(optionSelected === 4) {
      op4S = classes.menuSelected;
    }
    if(optionSelected === 5) {
      op5S = classes.menuSelected;
    }
    if(optionSelected === 6) {
      op6S = classes.menuSelected;
    }
    if(optionSelected === 7) {
      op7S = classes.menuSelected;
    }
    if(optionSelected === 8) {
      op8S = classes.menuSelected;
    }
    if(optionSelected === 9) {
      op9S = classes.menuSelected;
    }
    if(optionSelected === 10) {
      op10S = classes.menuSelected;
    }
    if(optionSelected === 11) {
      op11S = classes.menuSelected;
    }
    if(optionSelected === 12) {
      op12S = classes.menuSelected;
    }
    if(optionSelected === 13) {
      op13S = classes.menuSelected;
    }
    if(optionSelected === 14) {
      op14S = classes.menuSelected;
    }

    return (
      <MuiThemeProvider theme={menuTheme}>
        <Paper className={classes.paper}>
          <MenuList>
            {this.renderOption6(op6S)   /*Administración*/}
            {this.renderOption13(op13S)   /*Facturación y pagos*/}
            {this.renderOption2(op2S)   /*Pago en linea*/}
            {this.renderOption1(op1S)   /*Revisar Lista de Pago*/}
            {this.renderOption14(op14S) /*Revisar estado personal de usuario */}
            {this.renderOption7(op7S)   /*Tickets de mantenimiento*/}
            {this.renderOption8(op8S)   /*Revisar tickets de mantenimiento*/}
            {this.renderOption4(op4S)   /*Mis notificaciones*/}
            {this.renderOption10(op10S)  /*Comunicación Interna*/}
            {this.renderOption11(op11S)  /*Encuestas*/}
            {this.renderOption9(op9S)   /*Indicadores Mantenimiento*/}
            {this.renderOption5(op5S)   /*Mi cuenta*/}
            {this.renderOption12(op12S)   /*Accesos QR*/}
          </MenuList>
        </Paper>
      </MuiThemeProvider>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Menu);