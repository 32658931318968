/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../componentsmKit/Header/Header.js";
import Footer from "../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../componentsmKit/Grid/GridItem.js";
import Button from "../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../assets/jss/material-kit-react/views/landingPage.js";

import HomeParallax from "./views/HomeParallax";
import HomeBannerUno from "./views/HomeBannerUno";
import HomeBannerDos from "./views/HomeBannerDos";
import HomeBannerTres from "./views/HomeBannerTres";
import HomePlanes from "./views/HomePlanes";
import HomeBannerCuatro from "./views/HomeBannerCuatro";


const dashboardRoutes = [];
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiListItemIcon: {
     root: {
       minWidth:'40px'
     }
    },
    MuiListItem: {
     root: {
       paddingTop:'8px',
       paddingBottom:'8px',
       fontFamily: 'Poppins Medium',
       fontSize:'15px'
     }
    }
  },
});

class WebUperaHerramientas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {changePageBand, loadedPage} = this.props;
    if(nextProps.changePageBand !== changePageBand) {
      loadedPage(true);
    }
  }

  render() {
    const {classes, changePage, handleScrollTo, mobile, ...rest} = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="Material Kit React"
          rightLinks={<HeaderLinks
            handleScrollTo={handleScrollTo}
          />}
          fixed
          changePage={changePage}
          changeColorOnScroll={{
            height: 400,
            color: "white"

          }}
          handleScrollTo={handleScrollTo}
          {...rest}
        />
        <div className={classes.main} style={{paddingBottom:'50px', paddingTop:'70px'}}>
          <div style={{ width:'100%', borderRadius:'0px', paddingBottom:'50px'}}>
            <div className={classes.container}>
              <div style={{width:'100%'}}>
                {!mobile ?
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{width: '100%', marginTop: '50px'}}>
                        <h1 className={classes.title} style={{
                          marginTop: '15px',
                          fontFamily: 'Poppins SemiBold',
                          color: '#131316',
                          fontWeight: '50',
                          fontSize: '2.8125rem',
                          lineHeight: '47px'
                        }}>
                          Upera es un software de <br></br> administración y contabilidad <br></br> enfocado a la <font
                          style={{color: '#036adb'}}>gestión comercial y</font> <br></br> <font
                          style={{color: '#036adb'}}>residencial.</font>
                        </h1>
                      </div>
                      <div style={{width: '100%'}}>
                        <h4 className={classes.title} style={{
                          marginTop: '5px',
                          fontFamily: 'Poppins SemiBold',
                          color: '#7d7d82',
                          fontSize: '18px'
                        }}>Nuestra meta es hacer que la administración y facturación de tu negocio sea mucho
                          más <br></br> eficiente y automatizada.</h4>
                      </div>
                    </GridItem>
                  </GridContainer>
                  :
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{width: '100%', marginTop: '50px', textAlign:'center'}}>
                        <h1 className={classes.title} style={{
                          marginTop: '15px',
                          fontFamily: 'Poppins SemiBold',
                          color: '#131316',
                          fontWeight: '50',
                          fontSize: '30px',
                          lineHeight: '33px'
                        }}>
                          Upera es un software de <br></br> administración y  <br></br> contabilidad enfocado a  <br></br> la <font
                          style={{color: '#036adb'}}>gestión comercial y</font> <br></br> <font
                          style={{color: '#036adb'}}>residencial.</font>
                        </h1>
                      </div>
                      <div style={{width: '100%', textAlign:'center'}}>
                        <h4 className={classes.title} style={{
                          marginTop: '5px',
                          fontFamily: 'Poppins SemiBold',
                          color: '#7d7d82',
                          fontSize: '13px'
                        }}>Nuestra meta es hacer que la administración y facturación de tu negocio sea mucho
                          más <br></br> eficiente y automatizada.</h4>
                      </div>
                    </GridItem>
                  </GridContainer>
                }
              </div>
            </div>
          </div>
          <div style={{backgroundColor:!mobile ? '#162845' : '#FFF', width:'100%', borderRadius:'0px'}}>
            {!mobile ?
              <div className={classes.container}>
                <div style={{width: '100%'}}>
                  <GridContainer style={{alignItems: 'center'}}>
                    <GridItem xs={12} sm={12} md={3}>
                      <div style={{width: '100%', marginTop: '30px', marginBottom: '30px'}}>
                        <h1 className={classes.title} style={{
                          marginTop: '15px',
                          fontFamily: 'Poppins SemiBold',
                          color: '#FFF',
                          fontWeight: '50',
                          fontSize: '2.8125rem',
                          lineHeight: '47px'
                        }}>
                          Herramientas <br></br> que en verdad <br></br> <font
                          style={{color: '#036adb'}}>funcionan.</font>
                        </h1>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <img src="assets/img/webPage/bgHerramientas.png" style={{width: '100%'}}/>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
              :
              <div style={{width: '100%'}}>
                <img src="assets/img/webPage/bgHerramientasMobile.png" style={{width: '100%'}}/>
              </div>
            }
          </div>
          {mobile &&
          <div style={{width: '100%'}}>
            <GridContainer style={{alignItems: 'center', margin:'0px'}}>
              <GridItem xs={12} sm={12} md={12} style={{paddingBottom:'0px'}}>
                <div style={{width: '100%', marginTop: '20px', textAlign:'center'}}>
                  <h1 className={classes.title} style={{
                    marginTop: '15px',
                    fontFamily: 'Poppins SemiBold',
                    color: '#131316',
                    fontWeight: '50',
                    fontSize: '30px',
                    lineHeight: '33px'
                  }}>
                    Herramientas <br></br> que en verdad <br></br> <font
                    style={{color: '#036adb'}}>funcionan.</font>
                  </h1>
                </div>
              </GridItem>
            </GridContainer>
          </div>}
          <div style={{width:'100%', borderRadius:'0px'}}>
            <div className={classes.container}>
              <div style={{width:'100%', paddingTop:!mobile ? '30px' : '10px'}}>
                <GridContainer style={{alignItems:'flex-end'}}>
                  <GridItem xs={12} sm={12} md={6}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/usuarios.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Gestiona información de usuarios, <br></br> cuotas y personal.</font>} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/pagoEnLinea.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Registra y realiza pagos en línea, transferencias, <br></br> pagos en establecimientos y genera un historial <br></br> de todos tus movimientos.</font>} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/listaPagos.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Crea estados de cuenta al instante. Registra <br></br> ingresos y egresos. Exporta toda la información <br></br> y automatiza tus procesos.</font>} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/facturas.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Realiza y envía facturas automáticamente. <br></br> Lleva un controsl de todas las facturas emitidas y <br></br> realiza movimientos contables*.</font>} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/codigosAccesos.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Crea y envía códigos de acceso <br></br> para visitantes y proveedores.</font>} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/sos.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Configura un botón SOS para enviar mensajes <br></br> de auxilio y comparte tu ubicación en tiempo real.</font>} />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <img src="assets/img/webPage/gestionaFactuas.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Gestiona facturas y recibos emitidos <br></br> desde el software.</font>} />
                      </ListItem>
                      <ListItem style={{marginBottom:'30px'}}>
                        <ListItemIcon>
                          <img src="assets/img/webPage/notificaciones.png" style={{width:!mobile ? '65px': '50px', height:!mobile ? '65px': '50px', marginRight:'10px'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary={<font className={classes.textListDarkGray}>Envía y recibe notificaciones y recordatorios <br></br> vía email y mensajes.**.</font>} />
                      </ListItem>
                    </List>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <img src="assets/img/webPage/mockupHerramientas.png" style={{width:'85%'}}/>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          <div style={{backgroundColor:'#e4e4e4', width:'100%', borderRadius:'0px'}}>
            <div className={classes.container}>
              <div style={{width:'100%'}}>
                <GridContainer style={{alignItems:'center'}}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{width:'100%', marginTop:'30px'}}>
                      <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#036adb', fontWeight:'50', fontSize:'1.8125rem', lineHeight:'30px'}}>
                        Estas y muchas funciones <br></br> más <font style={{color:'#131316'}}>solo en upera.</font>
                      </h1>
                    </div>
                    <div style={{width:'100%', marginBottom:'30px'}}>
                      <h5 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins Regular', color:'#979797', fontWeight:'50', fontSize:'10px', lineHeight:'12px'}}>
                        * El módulo de facturación es una herramienta de solicitud y envío de facturas. El servicio de timbrado no se incluye en el sistema. <br></br> La conexión a un sistema de facturación tiene un costo extra. <br></br>
                        ** Los mensajes son enviados vía SMS o WhatsApp.
                      </h5>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
        <Footer
          changePage={changePage}
          mobile={mobile}
        />
      </MuiThemeProvider>
    )
  }
}

WebUperaHerramientas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WebUperaHerramientas);
