import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import ResponderIcon from '@material-ui/icons/ThumbsUpDownRounded';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#777777',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
  cardGray: {
    boxShadow: 'none',
    backgroundColor:'#eee !important',
    width:'100%'
  },
  cardSelected: {
    boxShadow: 'none',
    backgroundColor: '#fce4ec !important',
    width: '100%'
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

class DivResultados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded:null,
    }
  }

  handleChangePanel = (panel, comentarios) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
    this.setState({comentarios:comentarios});
  };

  render() {
    const self = this;
    const {classes, resultados, ajaxSource, checkPermisos, prodEnv} = this.props;
    const {expanded, maxCharacters} = this.state;

    if (Object.keys(resultados).length > 0) {
      return (
        <div className="row" style={{paddingBottom:'20px', margin: '0px'}}>
          <div className="col-md-12 mtM">
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Resultados</h3>
          </div>
          <div className="col-md-12">
            {resultados.map(function (arr, index) {

              var classActive = classes.heading;
              if(expanded === arr.encuestaId) {
                classActive = classes.headingActive;
              }

              return(
                <MuiThemeProvider theme={ExpansionPanels} key={index}>
                  <ExpansionPanel expanded={expanded === arr.encuestaId} onChange={self.handleChangePanel(arr.encuestaId, arr.comentarios)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classActive} style={{color:'#737373'}}>{arr.estatus}</Typography>
                      <div className={classes.secondaryHeading}>
                       Plazo: {arr.fechaLimite} | Encuesta: {arr.pregunta}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'inline'}}>
                      <div className="row" style={{width:'100%', margin: '0px'}}>
                        <div className="col-md-12" style={{marginTop:'20px'}}>
                          <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
                        </div>
                        {arr.opciones.map(function (arrO, index) {

                          var classCard = classes.cardGray;

                          var re = /(?:\.([^.]+))?$/;
                          var ext = re.exec(arrO.imgPath)[1];

                          var path = <a href={ajaxSource + arrO.imgPath} target="_blank">
                            <img className="img-raised rounded img-fluid" src={ajaxSource + arrO.imgPath} style={{maxWidth:'300px'}}/>
                          </a>

                          if(ext === "pdf" || ext === "PDF") {
                            path = <iframe
                              src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + arrO.imgPath}
                              style={{width: '100%', height: '300px', border: '4px solid #737373', borderRadius: '4px'}}/>;
                          }


                          return(
                            <div key={index} className="col-md-12">
                              <Card className={classCard} style={{marginTop:'10px'}}>
                                <CardContent>
                                  <div style={{whiteSpace:'pre-line'}}>
                                    <h3 style={{margin:'0px'}}>{index+1}) Opción: {arrO.titulo}</h3>
                                  </div>
                                  <div className="row" style={{width:'100%', margin: '0px'}}>
                                    <div className="col-md-10">
                                      <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
                                        {path}
                                      </div>
                                      <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                                        Descripción: {arrO.descripcion}
                                      </div>
                                    </div>
                                    <div className="col-md-2 text-center">
                                      <h3>VOTOS:</h3> <br/> <h1>{arrO.votos}</h1>
                                    </div>
                                  </div>
                                </CardContent>
                              </Card>
                            </div>
                          )
                        })}
                        <div className="col-md-12">

                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </MuiThemeProvider>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Sin encuestas</h4>
            </div>
          </div>
        </div>
      )
    }
  }
}

DivResultados.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivResultados);
