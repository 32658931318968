import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import Button from '@material-ui/core/Button';
import DialogAreas from "./DialogAreas";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataMaterialTable from "../../commons/DataMaterialTable";
import DeleteIcon from "@material-ui/core/SvgIcon/SvgIcon";


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#777777',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

class DivAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      dataAreas:[],
      openDialogAreas: false,
      handleOpenDialogAreas: 0,
      optionDialog: "",
      expanded:null,
      areaIdDelete:""
    }
  }

  handleOpenDialogAreas = (option) => {
    const {handleOpenDialogAreas} = this.state;
    this.setState({optionDialog:option},()=> {
      this.setState({openDialogAreas: true}, ()=> {
        this.setState({handleOpenDialogAreas:handleOpenDialogAreas+1});
      });
    });
  }

  handleCloseDialogAreas = () => {
    this.setState({openDialogAreas: false});
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleDeleteArea = (areaId) => {
    const {crearAlerta} = this.props;
    this.setState({areaIdDelete:areaId}, () => {
      crearAlerta("warning", "", "¿Seguro que deseas eliminar esta área?", true, this.deleteArea);
    });
  }

  createEditSubArea = (option, data, areaId) => {
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion} = this.props;
    var userId = dataUser.userId;

    var txtNoti = "Creando sub área...";
    var txtExito = "creada";
    var txtError = "crear";
    var subAreaId = "";
    if(option === "editar") {
      txtNoti = "Editando sub área....";
      txtExito = "editada";
      txtError = "editar";
      subAreaId = data[0].subAreaId;
    } else if(option === "eliminar") {
      txtNoti = "Eliminado sub área....";
      txtExito = "eliminada";
      txtError = "eliminar";
      subAreaId = data[0].subAreaId;
    }

    var edit = getData(ajaxSource, "administracion/createEditSubArea.php",{
      userId:userId,
      option:option,
      areaId:areaId,
      data:data,
      subAreaId:subAreaId}).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;
        if(data[0].error === 1) {
          crearNotificacion("success", "Sub área "+txtExito+" con exito.", "bottom", "right", 3000);
          return true;
        } else {
          crearNotificacion("error", "Hubo un problema al intentar "+txtError+" la sub área " + data[0].error, "bottom", "right", 3000);
          return false
        }
      }
    });

    return edit;
  }

  deleteArea = () => {
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, cerrarAlerta, getAdminInfo} = this.props;
    const {areaIdDelete} = this.state;
    var userId = dataUser.userId;

    cerrarAlerta();
    crearNotificacion("info", "Eliminando área...", "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/deleteArea.php",{
      userId:userId,
      areaId:areaIdDelete,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          crearNotificacion("success", "Área eliminada con exito.", "bottom", "right", 3000);
          getAdminInfo();
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar eliminar el área " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  renderBtnCrearArea = () => {
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("1") || checkPermisos("110")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right'}} onClick={()=>this.handleOpenDialogAreas("crear")}>
            Crear Área
            <AddIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }

  renderAreas = () => {
    const  self = this;
    const {classes, areas, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    const {expanded} = this.state;

    if((Object.keys(areas).length > 0)) {

      return (
        <div className="col-md-12" style={{marginTop:'24px', padding: '0px'}}>
          {areas.map(function (arr, index) {

            var classActive = classes.heading;
            var table = ""
            if(expanded === arr.areaId) {
              classActive = classes.headingActive;
              table = <DataMaterialTable
                type={"areas"}
                dataUser={dataUser}
                title={"Sub áreas"}
                data={arr.subAreas}
                areaId={arr.areaId}
                columns={[
                  { title: 'ID', field: 'areaId', editable: 'never', hidden:true},
                  { title: 'Sub Área', field: 'nombre', cellStyle: {textAlign: 'center'}},
                  { title: 'Responsable', field: 'proveedor',  cellStyle: {textAlign: 'center'}, lookup: arr.responsablesMantto},
                ]}
                crearNotificacion={crearNotificacion}
                createEditSubArea={self.createEditSubArea}
              />
            }

            return(
              <MuiThemeProvider theme={ExpansionPanels} key={index}>
                <ExpansionPanel expanded={expanded === arr.areaId} onChange={self.handleChangePanel(arr.areaId)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classActive} style={{color:'#737373'}}>{arr.nombre}</Typography>
                    <div className={classes.secondaryHeading}>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{display:'inline'}}>
                    <div className="row" style={{width:'100%', margin: '0px'}}>
                      <div className="col-md-12" style={{textAlign:'right'}}>
                        <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>self.handleDeleteArea(arr.areaId)}>
                          Eliminar
                        </Button>
                      </div>
                      <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                        {table}
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </MuiThemeProvider>
            )
          })}
        </div>
      )

    } else {
      return(
        <div className="col-md-12">
          <div className="text-center" style={{marginTop:'40px'}}>
            <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
            <h4>Sin áreas, crea una nueva.</h4>
          </div>
        </div>
      )
    }
  }

  render() {
    const {dataUser,crearNotificacion, cerrarNotificacion, ajaxSource, getData, getAdminInfo, crearAlerta, cerrarAlerta} = this.props;
    const {openDialogAreas, optionDialog, handleOpenDialogAreas, dataAreas} = this.state;

    return(
      <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
        <div className="col-md-6">
          <span><h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>Gestión de Áreas</h3></span>
        </div>
        <div className="col-md-6">
          {this.renderBtnCrearArea()}
        </div>
        {this.renderAreas()}
        <DialogAreas
          open={openDialogAreas}
          option={optionDialog}
          handleClose={this.handleCloseDialogAreas}
          handleOpenDialogAreas={handleOpenDialogAreas}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          ajaxSource={ajaxSource}
          getData={getData}
          dataUser={dataUser}
          getAdminInfo={getAdminInfo}
          dataAreas={dataAreas}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
        />
      </div>
    )
  }
}

DivAreas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivAreas);
