/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import CancelIcon from "@material-ui/icons/Cancel";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth : "700px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogDeclinarPago extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      value:"",
      razon:"",
      razonE:false
    };
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState);
    handleClose();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.setState({[name+'E']:false});
  };

  declinarPago = () => {
    const that = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, ecommId} = this.props;
    const {razon} = this.state;
    var userId = dataUser.userId;
    if(razon === "") {
      this.setState({razonE:true}, () => {
        crearNotificacion("warning", "Ingresa la razón por la cuál estas declinando el pago.", "bottom", "right");
      });
    } else {

      crearNotificacion("info", "Declinando el pago...", "bottom", "right", 1000000);

      getData(ajaxSource, "administracion/declinarPago.php",{
        userId:userId,
        ecommId:ecommId,
        razon:razon
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            setTimeout(function () {
              crearNotificacion("success", "Pago declinado con exito.", "bottom", "right", 3000);
              getAdminInfo();
              that.handleClose();
            },500);
          } else {
            crearNotificacion("error", "Hubo un problema al intentar declinar el pago " + data[0].error, "bottom", "right", 3000);
          }
        }
      });

    }
  }

  render() {
    const {classes, open, option} = this.props;
    const {razon, razonE} = this.state;

    return (
      <MuiThemeProvider theme={DialogT}>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="simple-dialog-title">
            <span className="darkGrayUpera">¿Porqué declinas el pago?</span>
            <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', top:'10px'}} onClick={()=>this.handleClose()}>
              <CloseIcon className="darkGrayUpera"/>
            </IconButton>
          </DialogTitle>
          <List>
            <MuiThemeProvider theme={FormInputs}>
              <ListItem style={{display:'inline'}}>
                <div className="row" style={{margin: '0px', width: '100%'}}>
                  <div className="col-md-12">
                    <TextField
                      error={razonE}
                      label="Razón"
                      placeholder="Razón"
                      id="nombre"
                      onChange={this.handleChange('razon')}
                      value={razon}
                      margin="normal"
                      variant="outlined"
                      style={{width:'100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <MuiThemeProvider theme={FormButtons}>
                      <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.declinarPago()}>
                        Declinar
                        <CancelIcon className={classes.rightIcon}/>
                      </Button>
                    </MuiThemeProvider>
                  </div>
                </div>
              </ListItem>
            </MuiThemeProvider>
          </List>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogDeclinarPago.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogDeclinarPago);
