import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/index';
import AppBar from '@material-ui/core/AppBar/index';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs/index';
import Tab from '@material-ui/core/Tab/index';
import Typography from '@material-ui/core/Typography/index';
import AddTicketIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ReOpenTicketIcon from '@material-ui/icons/SearchRounded';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';

import DivNuevoTicket from "./DivNuevoTicket";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Chip from "@material-ui/core/Chip";
import ClockIcon from '@material-ui/icons/AlarmRounded';
import ReinciIcno from '@material-ui/icons/LoopRounded';
import StarRatings from 'react-star-ratings';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';


const styles = theme => ({
  chipCurso: {
    margin: theme.spacing(1),
    backgroundColor: '#558b2f',
    color:'#FFF'
  },
  chipClose: {
    margin: theme.spacing(1),
    backgroundColor: '#e53935',
    color:'#FFF'
  },
  chip: {
    margin: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    marginBottom: '50px',
    //boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    boxShadow: 'none',
    width:'100%'
  },
  cardTicketSelected: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    color:'#FFF',
    backgroundColor:'#0345ea !important',
    width:'100%'
  },
  cardTicket: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    backgroundColor:'#cfd8dc !important',
    width:'100%'
  },
  cardTicket2: {
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);',
    color:'#FFF',
    backgroundColor:'#0345ea !important'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      },
      elevation4: {
        boxShadow: 'none !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '0px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px',
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          //border:'2px solid #1a49bf',
          backgroundColor:'transparent !important',
          boxShadow:'none'
        },
        backgroundColor:'transparent !important',
        boxShadow:'none'
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    },
    MuiTypography: {
      body1: {
        padding: '0px !important'
      },
      h5: {
        fontSize:'18px'
      }
    },
  }
})

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivMisTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      areas:[],
      subAreas:[],
      areaId:"",
      subAreaId:"",
      expanded:null,
      misTicketsAbiertos:[],
      misTicketsCerrados:[],
      buscarTicket:"",
      ticketSelected:"",
      tickeDataSelected:[],
      rating:0,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getTicketsInfo();
    }
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChangeTab = (event, value) => {
    this.setState({ticketSelected:""},()=> {
      this.setState({ value });
    });
  };

  handleChangeIndex = index => {
    this.setState({ticketSelected:""},()=> {
      this.setState({value: index});
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value },() => {
      if(name === "buscarTicket") {
        const {buscarTicket, misTicketsCerrados} = this.state;
        if(buscarTicket === "") {
          if (Object.keys(misTicketsCerrados).length === 0) {
            this.getTicketsInfo();
          }
        }
      }
    });
    this.setState({[name+'E']:false});
  };

  handleTicketSelected = (ticketId, data) => {
    this.setState({ticketSelected:ticketId, tickeDataSelected:data});
  }

  handleRating = (rating) => {
    const {crearAlerta, cerrarAlerta} = this.props;

    this.setState({rating:rating},()=> {

      var calificacion = "";
      if(rating === 1) {
        calificacion = "Mala";
      } else if(rating === 2) {
        calificacion = "Regular";
      } else if(rating === 3) {
        calificacion = "Buena";
      } else if(rating === 4) {
        calificacion = "Muy buena";
      }

      crearAlerta("warning", "", "¿Seguro que deseas calificar la atención como: "+calificacion+"?", true, this.calificarTicket);
    });
  }

  calificarTicket = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, cerrarAlerta, crearAlerta} = this.props;
    const {rating, ticketSelected} = this.state;
    var userId = dataUser.userId;
    cerrarAlerta();

    crearNotificacion("info", "Calificando ticket...", "bottom", "right", 1000000);

    getData(ajaxSource, "tickets/calificarTicket.php",{
      userId:userId,
      rating:rating,
      ticketId:ticketSelected
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if (data[0].error === 1) {
          self.getTicketsInfo();
          crearAlerta("success", "Gracias!", "por tu calificación",false, cerrarAlerta);
        } else {
          crearNotificacion("error", "Hubo un problema al intentar calificar el ticket " + data[0].error, "bottom", "right", 3000);
        }
      }
    })
  }

  getTicketsInfo = (searchData) => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion} = this.props;
    const {ticketSelected} = this.state;
    var userId = dataUser.userId;

    getData(ajaxSource, "tickets/ticketsInfo.php",{
      userId:userId,
      searchData:searchData,
      ticketId:ticketSelected
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var areas = data[0];
        var misTicketsAbiertos = data[1];
        var misTicketsCerrados = data[5];

        loadedPage(true);

        self.setState({areas:areas, misTicketsAbiertos:misTicketsAbiertos,misTicketsCerrados:misTicketsCerrados, ticketSelected:""},()=> {
          cerrarNotificacion();
        });
      }
    });
  }

  buscarTicket = () => {
    const {crearNotificacion} = this.props;
    const {buscarTicket} = this.state;
    crearNotificacion("info", "Buscando tickets...", "bottom", "right", 1000000);
    this.getTicketsInfo(buscarTicket);
  }

  renderTickets = () => {
    const self = this;
    const {ajaxSource, classes, checkPermisos, cerrarAlerta, crearAlerta, getData, crearNotificacion, cerrarNotificacion, dataUser} = this.props;
    const {expanded, misTicketsAbiertos, value, ticketSelected, tickeDataSelected, areas, rating} = this.state;

    var casaLocal = ""
    if(checkPermisos("124") && checkPermisos("125")) {
      casaLocal = "Res-Pla/Local-Casa";
    } else if(checkPermisos("124")) {
      casaLocal = "Residencial/Casa";
    } else if(checkPermisos("125")) {
      casaLocal = "Plaza/Local";
    }

    if(value === 0) {
      if ((Object.keys(misTicketsAbiertos).length > 0)) {
        return (
          <div className="row" style={{margin: '0px', width: '100%'}}>

            {misTicketsAbiertos.map(function (arr, index) {

              var labelReincidencia = "";
              if(arr.reincidencia === "1") {
                labelReincidencia = <div className="col-md-12 text-center" style={{marginBottom: '10px'}}><Chip icon={<ReinciIcno style={{color:'#FFF'}}/>} label={"Reincidencia del ticket: "+arr.originalTicketId} className={classes.chipClose} style={{fontSize:'20px'}}/></div>
              }

              return (
                <div className="col-md-12" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: '10px', paddingTop: '10px'}} data-tour="step6">
                  <Card className={classes.cardTicket2}>
                    <CardContent className="cardContent">
                      <div className="row" style={{ margin: '0px',width: '100%',borderBottom: '1px dashed #FFF',paddingBottom: '5px'}}>
                        {labelReincidencia}
                        <div className="col-md-4">
                          <span><h5 className="text-white" style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>Ticket: {arr.ticketId}</h5></span>
                        </div>
                        <div className="col-md-8" style={{textAlign: 'right'}}>
                          <span><h5 className="text-white" style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>{arr.fechaIni}</h5></span>
                        </div>
                      </div>
                      <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
                        <div className="col-md-6">
                          <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Nombre:</h6></span>
                          <span><h4 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{arr.solicitante}</b></h4></span>
                        </div>
                        <div className="col-md-6">
                          <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>{casaLocal}:</h6></span>
                          <span><h4 className="text-white" style={{marginTop: '0px',marginBottom: '0px', fontWeight:'500', textTransform: 'initial'
                          }}><b>{arr.residencialSolicitante}/{arr.casaSolicitante}</b></h4></span>
                        </div>
                      </div>
                      <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
                        <div className="col-md-12">
                          <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Area/Responsale:</h6></span>
                          <span><h4 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{arr.area}/{arr.responsable}</b></h4></span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <MuiThemeProvider theme={ExpansionPanels} key={index}>
                    <ExpansionPanel expanded={expanded === arr.ticketId} onChange={self.handleChangePanel(arr.ticketId)} style={{marginTop: '5px'}}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography style={{color: '#737373'}}>Mensaje</Typography>
                        <div className={classes.secondaryHeading}>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{display: 'inline'}}>
                        <div className="row" style={{width: '100%', margin: '0px'}}>
                          <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                            <img className="img-raised rounded img-fluid" src={ajaxSource + arr.imgPath}
                                 style={{width: '100%'}}/>
                          </div>
                          <div className="col-md-12" style={{color: 'rgb(115, 115, 115)', marginTop: '10px'}}>
                            {arr.mensaje}
                          </div>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </MuiThemeProvider>
                </div>
              )
            })}
          </div>
        )
      } else {
        return (
          <div className="row" style={{margin: '0px', width: '100%'}} data-tour="step6">
            <div className="col-md-12">
              <div className="text-center" style={{marginTop: '40px'}}>
                <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png"
                     style={{width: '100px'}}/>
                <h4>Sin tickets abiertos.</h4>
              </div>
            </div>
          </div>
        )
      }
    } else if(value === 1) {
      if (ticketSelected !== "") {

        var chip =  <Chip icon={<ClockIcon />} label={"Tiempo en curso: "+tickeDataSelected.tiempoAbierto} className={classes.chip} style={{fontSize:'20px'}}/>
        var evidencia = "";

        if(tickeDataSelected.ticketStatus === "1") {
          chip =  <Chip icon={<ClockIcon style={{color:'#FFF'}} />} label={"Tiempo solución: "+tickeDataSelected.tiempoAbierto} className={classes.chipClose} style={{fontSize:'20px'}}/>

          var estrellas = <div className="col-md-12 text-center" style={{color: 'rgb(115, 115, 115)', marginBottom:'20px'}}>
            <h3>Califica el servicio de mantenimiento</h3>
            <StarRatings
              rating={rating}
              starRatedColor="#0345ea"
              starHoverColor="#0345ea"
              changeRating={this.handleRating}
              numberOfStars={4}
              name='rating'
              starDimension="30px"
              starSpacing="5px"
            />
          </div>

          var est = <IconButton>
            <StarIcon style={{color:'#0345ea'}}/>
          </IconButton>;

          var ests = <div></div>;
          if(tickeDataSelected.estrellas === "1") {
            ests = <div>
              <h3>Satisfacción mala</h3>
              {est}
            </div>
          }
          if(tickeDataSelected.estrellas === "2") {
            ests = <div>
              <h3>Satisfacción regular</h3>
              {est}
              {est}
            </div>
          }
          if(tickeDataSelected.estrellas === "3") {
            ests = <div>
              <h3>Satisfacción buena</h3>
              {est}
              {est}
              {est}
            </div>
          }
          if(tickeDataSelected.estrellas === "4") {
            ests = <div>
              <h3>Satisfacción muy buena</h3>
              {est}
              {est}
              {est}
              {est}
            </div>
          }

          if(tickeDataSelected.estrellas !== 0 && tickeDataSelected.estrellas !== null) {
            estrellas = <div className="col-md-12 text-center" style={{color: 'rgb(115, 115, 115)', marginBottom:'20px'}}>
              {ests}
            </div>
          }

          evidencia =
            <ExpansionPanel style={{marginTop: '5px'}}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography style={{color: '#737373'}}>Evidencia Solución</Typography>
                <div className={classes.secondaryHeading}>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{display: 'inline'}}>
                <div className="row" style={{width: '100%', margin: '0px'}}>
                  {estrellas}
                  <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                    <img className="img-raised rounded img-fluid" src={ajaxSource + tickeDataSelected.imgPathClose}
                         style={{width: '100%'}}/>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
        }

        return (
          <div className="col-md-12" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: '10px', paddingTop: '10px'}}>
            <Card className={classes.cardTicketSelected}>
              <CardContent className="cardContent">
                <div className="row" style={{ margin: '0px',width: '100%',borderBottom: '1px dashed #FFF',paddingBottom: '5px'}}>
                  <div className="col-md-4">
                    <span><h5 className="text-white" style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>Ticket: {tickeDataSelected.ticketId}</h5></span>
                  </div>
                  <div className="col-md-8" style={{textAlign: 'right'}}>
                    <span><h5 className="text-white" style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>{tickeDataSelected.fechaIni}</h5></span>
                  </div>
                </div>
                <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
                  <div className="col-md-6">
                    <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Nombre:</h6></span>
                    <span><h4 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{tickeDataSelected.solicitante}</b></h4></span>
                  </div>
                  <div className="col-md-6">
                    <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>{casaLocal}:</h6></span>
                    <span><h4 className="text-white" style={{marginTop: '0px',marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{tickeDataSelected.residencialSolicitante}/{tickeDataSelected.casaSolicitante}</b></h4></span>
                  </div>
                </div>
                <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
                  <div className="col-md-12">
                    <span><h6 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Area/Responsale:</h6></span>
                    <span><h4 className="text-white" style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}><b>{tickeDataSelected.area}/{tickeDataSelected.responsable}</b></h4></span>
                  </div>
                </div>
              </CardContent>
            </Card>
            <MuiThemeProvider theme={ExpansionPanels}>
              <ExpansionPanel style={{marginTop: '5px'}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                  <Typography style={{color: '#737373'}}>Mensaje</Typography>
                  <div className={classes.secondaryHeading}>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'inline'}}>
                  <div className="row" style={{width: '100%', margin: '0px'}}>
                    <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                      <img className="img-raised rounded img-fluid" src={ajaxSource + tickeDataSelected.imgPath}
                           style={{width: '100%'}}/>
                    </div>
                    <div className="col-md-12" style={{color: 'rgb(115, 115, 115)', marginTop: '10px'}}>
                      {tickeDataSelected.mensaje}
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {evidencia}
            </MuiThemeProvider>
            <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
              {chip}
            </div>
            <DivNuevoTicket
              ajaxSource={ajaxSource}
              dataUser={dataUser}
              checkPermisos={checkPermisos}
              crearNotificacion={crearNotificacion}
              cerrarNotificacion={cerrarNotificacion}
              getData={getData}
              getTicketsInfo={this.getTicketsInfo}
              crearAlerta={crearAlerta}
              cerrarAlerta={cerrarAlerta}
              areas={areas}
              reincidencia={true}
              ticketId={tickeDataSelected.ticketId}
            />
          </div>
        )
      } else {
        return (
          <div className="row" style={{margin: '0px', width: '100%'}}>
            <div className="col-md-12">
              <div className="text-center" style={{marginTop: '40px'}}>
                <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png"
                     style={{width: '100px'}}/>
                <h4>Selecciona un ticket.</h4>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderTicketsCerrados = () => {
    const self = this;
    const {classes} = this.props;
    const {misTicketsCerrados, ticketSelected, buscarTicket} = this.state;

    var tickets = <div className="row" style={{margin: '0px', width: '100%'}}>
      <div className="col-md-12">
        <div className="text-center" style={{marginTop: '40px'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png"
               style={{width: '100px'}}/>
          <h4>Sin tickets cerrados.</h4>
        </div>
      </div>
    </div>

    if (Object.keys(misTicketsCerrados).length > 0 || buscarTicket !== "") {
      tickets = <MuiThemeProvider theme={FormInputs}>
        <div className="row" style={{paddingBottom: '20px', margin: '0px', width: '100%', marginTop: '20px'}}>
          <div className="col-md-8" style={{padding: '0px 3px', marginBottom:'10px'}}>
            <TextField
              label="Búscar"
              placeholder="Búscar por folio, o descripción del problema"
              id="buscarTicket"
              onChange={this.handleChange('buscarTicket')}
              margin="normal"
              value={buscarTicket}
              variant="outlined"
              style={{width:'100%'}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-4 text-center" style={{padding: '0px 3px', marginBottom:'10px'}}>
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" style={{marginTop:'20px'}} color="primary" className={classes.button} onClick={()=>this.buscarTicket()}>
                Buscar
                <SearchIcon className={classes.rightIcon}/>
              </Button>
            </MuiThemeProvider>
          </div>
          <div className="col-md-6" style={{padding: '0px 3px', paddingBottom:'20px'}}>

            {misTicketsCerrados.map(function (arr, index) {

              var ticketSelectedClass = classes.cardTicket;

              if(ticketSelected === arr.ticketId) {
                ticketSelectedClass = classes.cardTicketSelected;
              }

              var chipTotalReincidencias = "";
              if(arr.totalReincidencias > 0) {
                chipTotalReincidencias = <Chip label={"Reincidencias: "+ arr.totalReincidencias} className={classes.chipClose} />
              }


              return (
                <div className="col-md-12" style={{padding: '0px', marginBottom:'10px'}}>
                  <Button style={{padding:'0px', textAlign:'left', width:'100%'}} onClick={()=>self.handleTicketSelected(arr.ticketId, arr)}>
                    <Card className={ticketSelectedClass} style={{marginBottom: '0px'}}>
                      <CardContent className="cardContent" style={{paddingBottom: '0px'}}>
                        <div className="row" style={{ margin: '0px',width: '100%',borderBottom: '1px dashed #FFF',paddingBottom: '5px'}}>
                          <div className="col-md-4" style={{padding: '0px'}}>
                            <span><h5 style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>Ticket: {arr.ticketId}</h5></span>
                          </div>
                          <div className="col-md-8" style={{textAlign: 'right', padding: '0px'}}>
                            <span><h5 style={{marginTop: '0px', marginBottom: '0px', textTransform: 'initial'}}>{arr.fechaIni}</h5></span>
                          </div>
                        </div>
                        <div className="row" style={{ margin: '0px',width: '100%', paddingTop: '10px'}}>
                          <div className="col-md-12" style={{padding:'0px'}}>
                            <span><h6 style={{marginTop: '0px', marginBottom: '0px', fontWeight:'100', textTransform: 'initial'}}>Solicitante:</h6></span>
                            <span><h5 style={{marginTop: '0px', marginBottom: '0px', fontWeight:'500', textTransform: 'initial'}}>{arr.solicitante}</h5></span>
                          </div>
                          <div className="col-md-12" style={{padding:'0px', textAlign:'right'}}>
                            {chipTotalReincidencias}
                            <Chip label={"Cerrado el "+arr.fechaFin.split(" ")[0]} className={classes.chipCurso} />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Button>
                </div>
              )
            })}
          </div>
        </div>
      </MuiThemeProvider>
    }

    return(
      <div className="row" style={{paddingBottom: '20px', margin: '0px', width: '100%', marginTop: '20px'}}>
        <div className="col-md-12">
          <span><h3 className="darkGrayUpera" style={{margin:'0px'}}>Ultimos 10 tickets cerrados</h3> <h5 className="darkGrayUpera" style={{margin:'0px'}}>ó busca un ticket</h5></span>
        </div>
        <div className="col-md-6">

        </div>
        {tickets}
      </div>
    )
  }

  render() {
    const {classes, theme, dataUser, ajaxSource, cerrarNotificacion, checkPermisos, crearNotificacion, getData, crearAlerta, cerrarAlerta, handleOpenTour, mobile} = this.props;
    const {value, usuarios, cuotas, areas} = this.state;

    var tabGenerarTicket = "";
    var containerDivNuevoTicket = <div></div>
    if(checkPermisos("1") || checkPermisos("111")) {
      tabGenerarTicket = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <AddTicketIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>01</div>
            <div className="textTab"><b>Generar </b><br/><div className="textTabSub">nuevo ticket.</div></div>
            <div className="textTabSmall">Crea un nuevo ticket de mantenimiento para la administración.</div>
          </div>
        </div>
      } />

      containerDivNuevoTicket = <DivNuevoTicket
        ajaxSource={ajaxSource}
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        getTicketsInfo={this.getTicketsInfo}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        areas={areas}
        reincidencia={false}
        handleOpenTour={handleOpenTour}
        mobile={mobile}
      />
    }

    var tabReincidenciaTicket = "";
    var containerDivReincidenciaTicket = <div></div>
    if(checkPermisos("1") || checkPermisos("112")) {
      tabReincidenciaTicket = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <ReOpenTicketIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>02</div>
            <div className="textTab"><b>Reincidencia </b><br/><div className="textTabSub">ticket ya creado.</div></div>
            <div className="textTabSmall">Selecciona el ticket que quieres reenviar a la administración.
            </div>
          </div>
        </div>
      } />

      containerDivReincidenciaTicket = this.renderTicketsCerrados();
    }


    return (
      <div className="row" style={{margin:'0px', width:'100%'}}>
        <div className="col-md-6">
          <Card className={classes.card}>
            <CardContent className="cardContent">
              <MuiThemeProvider theme={tabs}>
                <AppBar position="static" color="default">
                  <StyledTabs
                    value={value}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    {tabGenerarTicket}
                    {tabReincidenciaTicket}
                  </StyledTabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={this.state.value}
                  onChangeIndex={this.handleChangeIndex}
                  style={{overFlow:'hidden', padding: ''}}
                  slideClassName="slideOverflow"
                >
                  <TabContainer dir={theme.direction}>
                    {containerDivNuevoTicket}
                  </TabContainer>
                  <TabContainer dir={theme.direction}>
                    {containerDivReincidenciaTicket}
                  </TabContainer>
                </SwipeableViews>
              </MuiThemeProvider>
            </CardContent>
          </Card>
        </div>
        <div className="col-md-6">
          {this.renderTickets()}
        </div>
      </div>
    );
  }
}

DivMisTickets.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivMisTickets);
