import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AddUserIcon from '@material-ui/icons/GroupAdd';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import MantenimientoIcon from '@material-ui/icons/BuildRounded';
import AutorizarIcon from '@material-ui/icons/AssignmentTurnedIn';

import styled from 'styled-components';

import DivUsuarios from "./DivUsuarios";
import DivCuotas from "./DivCuotas";
import DivPagoEnLinea from "../DivMainContent";
import DivAreas from "./DivAreas";
import DivAutorizarPagos from "./DivAutorizarPagos";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '20px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivAdministracion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      usuarios: [],
      usuariosParaMC:[],
      cuotas: [],
      areas:[],
      tipoUsuario: "",
      perfilUsuarios: [],
      casasLocales:[],
      casasLocalesCuotas:[],
      autorizacionPagos:[],
      rechargeAdminInfo:0,
      tipoUsuarioText:""
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getAdminInfo(2);
    }
  }

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, ()=> {
      if(name === "tipoUsuario") {
        const {tipoUsuario, perfilUsuarios} = this.state;
        console.log(tipoUsuario);

        var indexPerfilUsuarios = perfilUsuarios.findIndex(x => x.perfilId === tipoUsuario);
        var tipoUsuarioText = perfilUsuarios[indexPerfilUsuarios].tipoUsuario;

        console.log(tipoUsuarioText);
        this.setState({tipoUsuarioText:tipoUsuarioText});
        this.getAdminInfo();
      }
    });
  };

  getAdminInfo = (option, typeUser) => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos} = this.props;
    const {tipoUsuario, rechargeAdminInfo} = this.state;
    var userId = dataUser.userId;
    var tUser = tipoUsuario;

    if(typeUser !== undefined) {
      tUser = typeUser;
    }

    getData(ajaxSource, "administracion/adminInfo.php",{
      userId:userId,
      tipoUsuario:tUser
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var usuarios = data[0];
        var cuotas = data[1];
        var areas = data[3];
        var perfilUsuarios = data[5];
        var casasLocales = data[6];
        var casasLocalesCuotas = data[7];
        var autorizacionPagos = data[8];
        var usuariosParaMC = data[9];

        

        if(option === 1) {
          self.setState({usuarios:usuarios, cuotas:cuotas, areas:areas, perfilUsuarios:perfilUsuarios, casasLocales:casasLocales, casasLocalesCuotas:casasLocalesCuotas, autorizacionPagos:autorizacionPagos, usuariosParaMC:usuariosParaMC, rechargeAdminInfo:rechargeAdminInfo+1}, ()=> {
            loadedPage(true);
          });
        } else if(option === 2) {
          self.setState({usuarios:usuarios, cuotas:cuotas, areas:areas, perfilUsuarios:perfilUsuarios, casasLocales:casasLocales, casasLocalesCuotas:casasLocalesCuotas, autorizacionPagos:autorizacionPagos, usuariosParaMC:usuariosParaMC}, ()=> {

            var resiLocaUser = "Residente";
            if(checkPermisos("125")) {
              resiLocaUser = "Locatario";
            }
            console.log(perfilUsuarios)
            var indexPerfilUsuarios = perfilUsuarios.findIndex(x => x.tipoUsuario === resiLocaUser);
            
            console.log(indexPerfilUsuarios)
            var tipoUsuarioId = perfilUsuarios[indexPerfilUsuarios].perfilId;
            console.log(tipoUsuarioId);
            self.setState({tipoUsuario:tipoUsuarioId}, () => {
              self.getAdminInfo();
            });
          });
        } else {
          self.setState({usuarios:usuarios, cuotas:cuotas, areas:areas, perfilUsuarios:perfilUsuarios, casasLocales:casasLocales, casasLocalesCuotas:casasLocalesCuotas, autorizacionPagos:autorizacionPagos, usuariosParaMC:usuariosParaMC}, ()=> {
            loadedPage(true);
          });
        }
      }
    });
  }

  render() {
    const {theme, dataUser, ajaxSource, cerrarNotificacion, checkPermisos, crearNotificacion, getData, crearAlerta, cerrarAlerta, handleOpenTour, dialogStateForTour, handleOptionSelected, mobile, prodEnv} = this.props;
    const {value, usuarios, cuotas, areas, tipoUsuario, perfilUsuarios, casasLocales, casasLocalesCuotas, rechargeAdminInfo, autorizacionPagos, usuariosParaMC, tipoUsuarioText} = this.state;

    var tabCreacionCuotas = "";
    var containerDivCuotas = <div></div>
    if(checkPermisos("1") || checkPermisos("107")) {
      tabCreacionCuotas = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <DollarIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>02</div>
            <div className="textTab"><b>Creación </b><br/><div className="textTabSub">de cuotas.</div></div>
          </div>
        </div>
      } />

      containerDivCuotas = <DivCuotas
        ajaxSource={ajaxSource}
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        getAdminInfo={this.getAdminInfo}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        cuotas={cuotas}
        handleOptionSelected={handleOptionSelected}
      />
    }

    var tabGestionAreas = "";
    var containerDivAreas = <div></div>
    if(checkPermisos("1") || checkPermisos("109")) {
      tabGestionAreas = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <MantenimientoIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>03</div>
            <div className="textTab"><b>Gestión </b><br/><div className="textTabSub">áreas mantenimiento.</div></div>
          </div>
        </div>
      } />

      containerDivAreas = <DivAreas
        ajaxSource={ajaxSource}
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        getAdminInfo={this.getAdminInfo}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        areas={areas}
      />
    }

    var tabAutorizarPagos = "";
    var containerDivAutorizarPagos = <div></div>
    if(checkPermisos("1") || checkPermisos("147")) {
      tabAutorizarPagos = <Tab style={{padding: '0px', marginLeft: '2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <AutorizarIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>04</div>
            <div className="textTab"><b>Autorizar </b><br/>
              <div className="textTabSub">registro de pagos.</div>
            </div>
          </div>
        </div>
      }/>

      containerDivAutorizarPagos = <DivAutorizarPagos
        ajaxSource={ajaxSource}
        dataUser={dataUser}
        checkPermisos={checkPermisos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        getData={getData}
        getAdminInfo={this.getAdminInfo}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        autorizacionPagos={autorizacionPagos}
        prodEnv={prodEnv}
      />




      
    }


    

    return (
      <MuiThemeProvider theme={tabs}>
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab style={{padding:'0px'}} label={
              <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
                <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
                  <AddUserIcon className="iconTab"/>
                  <div style={{fontSize: '11px'}}>01</div>
                  <div className="textTab"><b>Gestión</b><br/><div className="textTabSub">de usuarios.</div></div>
                </div>
              </div>
            } />
            {tabCreacionCuotas}
            {tabGestionAreas}
            {tabAutorizarPagos}
          </StyledTabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
          style={{overFlow:'hidden'}}
          slideClassName="slideOverflow"
        >
          <TabContainer dir={theme.direction}>
            <DivUsuarios
              ajaxSource={ajaxSource}
              dataUser={dataUser}
              usuarios={usuarios}
              usuariosParaMC={usuariosParaMC}
              checkPermisos={checkPermisos}
              crearNotificacion={crearNotificacion}
              cerrarNotificacion={cerrarNotificacion}
              getData={getData}
              getAdminInfo={this.getAdminInfo}
              crearAlerta={crearAlerta}
              cerrarAlerta={cerrarAlerta}
              cuotas={cuotas}
              handleOpenTour={handleOpenTour}
              dialogStateForTour={dialogStateForTour}
              mobile={mobile}
              tipoUsuario={tipoUsuario}
              handleChange={this.handleChange}
              perfilUsuarios={perfilUsuarios}
              casasLocales={casasLocales}
              casasLocalesCuotas={casasLocalesCuotas}
              rechargeAdminInfo={rechargeAdminInfo}
              tipoUsuarioText={tipoUsuarioText}
            />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivCuotas}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivAreas}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivAutorizarPagos}
          </TabContainer>
        </SwipeableViews>
      </MuiThemeProvider>
    );
  }
}

DivAdministracion.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivAdministracion);
