/*eslint-disable*/
import React, {Component} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import GridContainer from "../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../componentsmKit/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import Button from "../../componentsmKit/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:"",
      nombreE:false,
      telefono:"",
      telefonoE:false,
      correo:"",
      correoE:false,
      mensaje:"",
      mensajeE:false,
    }
  }

  render() {
    const {classes, whiteFont, handleChangeOption, changePage, mobile} = this.props;
    const {nombre, nombreE, telefono, telefonoE, correo, correoE, mensaje, mensajeE} = this.state;
    const footerClasses = classNames({
      [classes.footer]: true,
      [classes.footerWhiteFont]: whiteFont
    });
    const aClasses = classNames({
      [classes.a]: true,
      [classes.footerWhiteFont]: whiteFont
    });

    const styleHead = {color:'#000', fontFamily:'Poppins Medium'}
    const styleHeadContacto = {color:'#000', fontFamily:'Poppins Medium', marginTop:'0px'}
    const styleSub = {color:'#969999', fontFamily:'Poppins Medium', margin:'0px', fontSize:'15px'}

    return(
      <footer style={{backgroundColor:'#f2f2f2', paddingTop:'50px', paddingBottom:'50px'}}>
        <div id="footer" className={classes.container}>
          <div style={{width:'100%', borderBottom:'1px solid #969999', paddingBottom:'30px'}}>
            <GridContainer>
              <GridItem xs={12} md={4}>
                <div style={{width:'100%', textAlign:'left'}}>
                  <img src="assets/img/iconos/LOGO_AZUL.png" style={{width:'120px'}}/>
                  <h4 style={!mobile ? styleHead : {color:'#000', fontFamily:'Poppins Medium', fontSize:'11px'}}>Todos los derechos reservados © upera.mx</h4>

                </div>
                <div style={{width:'100%', textAlign:'left'}}>
                  <img src="assets/img/webPage/appStore.png" style={{width:'140px'}}/>
                  <img src="assets/img/webPage/playStore.png" style={{width:'140px', marginLeft:'10px'}}/>
                </div>
              </GridItem>
              <GridItem xs={12} md={2}>
                <div style={{width:'100%', textAlign:'left', marginTop: !mobile ? '0px' : '30px'}}>
                  <h4 style={styleHead}>Upera</h4>
                  <h5 style={styleSub}>Sobre nostros</h5>
                  <h5 style={styleSub}>Nuestras redes</h5>
                </div>
                <div style={{width:'100%', textAlign:'left', marginTop:'10px'}}>
                  <a href="https://instagram.com/upera_mx?igshid=xlgcz0f38g75" target="_blank" style={{cursor:'pointer'}}>
                    <img src="assets/img/webPage/instagram.png" style={{width:'25px'}}/>
                  </a>
                  <a href="https://www.facebook.com/UperaMx/" target="_blank" style={{cursor:'pointer'}}>
                    <img src="assets/img/webPage/facebook.png" style={{width:'25px', marginLeft:'10px'}}/>
                  </a>
                </div>
              </GridItem>
              <GridItem xs={12} md={3}>
                <div style={{width:'100%', textAlign:'left', marginTop: !mobile ? '0px' : '30px'}}>
                  <h4 style={styleHead}>Recursos</h4>
                  <h5 style={styleSub}>Pide un acceso de prueba</h5>
                  <h5 style={styleSub}>Media Kit</h5>
                  <h5 style={styleSub}>Centro de soporte</h5>
                </div>
              </GridItem>
              <GridItem xs={12} md={3}>
                <div style={{width:'100%', textAlign:'left', marginTop: !mobile ? '0px' : '30px'}}>
                  <h4 style={styleHead}>Contacto</h4>
                  <h5 style={styleSub}>WhatsApp // 55.4911.9003</h5>
                  <h5 style={styleSub}>Correo // hola@upera.mx</h5>
                </div>
                <div style={{width:'100%', textAlign:'left', marginTop:'10px'}}>
                  <Button
                    color="blueUpera"
                    size="lg"
                    onClick={()=>changePage("uperaContacto")}
                  >
                    Envíanos un mensaje
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <div style={{width:'100%', marginTop: '20px'}}>
            <GridContainer style={{alignItems:'center'}}>
              <GridItem xs={12}>
                <div style={{with:'100%', textAlign:'left'}}>
                  <h5 style={{color:'#969999', fontFamily:'Poppins Medium', margin:'0px', fontSize:'13px', float:'right', marginLeft:'10px'}}>Términos y condiciones.</h5>
                  <h5 style={{color:'#969999', fontFamily:'Poppins Medium', margin:'0px', fontSize:'13px', float:'right', marginLeft:'30px'}}>Aviso de Privacidad.</h5>
                  <h5 style={{color:'#000', fontFamily:'Poppins Medium', margin:'0px', fontSize:'11px', float:'right', marginLeft:'10px'}}>Hecho en México.</h5>
                  <img src="assets/img/webPage/incMexico.png" style={{width:'20px', float: 'right', marginLeft:'10px'}}/>
                  <h5 style={{color:'#969999', fontFamily:'Poppins Medium', margin:'0px', fontSize:'11px', float:'right'}}>© Upera - Todos los derechos reservados | </h5>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(styles)(Footer);
