import {prepareRoutes} from "@curi/router";
import WebUperaHome from './componentes/pages/WebUperaHome';
import WebUperaContacto from './componentes/pages/WebUperaContacto';
import WebUperaHerramientas from './componentes/pages/WebUperaHerramientas';
import Login from './componentes/Login';
import PageClosedSession from './componentes/PageClosedSession';
import Home from './componentes/Home';
import HomeMC from './componentes/HomeMC';
import Proximamente from './componentes/Proximamente';
import PageNotFound from './componentes/PageNotFound';
import HomeDIR from "./componentes/HomeDIR";

//var upera = "upera";
export default prepareRoutes([
  {
    name: "proximamante",
    path: "proximamante",
    response() {
      return {body: Proximamente};
    }
  },
  {
    name: "inicio",
    path: "inicio",
    response() {
      return {body: WebUperaHome};
    }
  },
  {
    name: "uperaContacto",
    path: "uperaContacto",
    response() {
      return {body: WebUperaContacto};
    }
  },
  {
    name: "uperaHerramientas",
    path: "uperaHerramientas",
    response() {
      return {body: WebUperaHerramientas};
    }
  },
  {
    name: "login",
    path: "login",
    response() {
      return {body: Login};
    }
  },
  {
    name: "home",
    path: "home",
    response() {
      return {body: Home};
    }
  },
  {
    name: "homeMC",
    path: "homeMC",
    response() {
      return {body: HomeMC};
    }
  },
  {
    name: "homeDIR",
    path: "homeDIR",
    response() {
      return {body: HomeDIR};
    }
  },
  {
    name: "sessionClosed",
    path: "sessionClosed",
    response() {
      return {body: PageClosedSession};
    }
  },
  {
    name: "Catch All",
    path: "(.*)",
    response() {
      return {body: PageNotFound};
    }
  }
]);
