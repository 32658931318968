/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import NewRelaseIcon from '@material-ui/icons/NewReleasesRounded';
import NewIcon from '@material-ui/icons/FiberNewRounded';
import ListIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/CloseRounded';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

const materialTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: '#fff !important',
      },
    },
    MuiInput: {
      input: {
        color: '#bfc2cb !important'
      },
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: '2px solid #00bcd4',
        },
        '&:after': {
          borderBottom: '2px solid #00bcd4'
        },
        '&:before': {
          borderBottom: '2px solid rgba(0, 188, 212, 0.42);'
        }
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .6) !important',
        backdropFilter: 'blur(15px)',
        border:'2px solid #FFF',
        borderRadius:'20px !important'
      }
    },
    MuiMenuItem: {
      root: {
        color: "#bfc2cb"
      }
    },
    MuiSelect: {
      icon: {
        color: "#fff"
      }
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth : "600px",
        margin: "48px auto !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DialogNewUpdateAvalible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:0,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {

  }

  render() {
    const {classes, handleClose, open, newVersion, versionMejoras, handleCloseDialogUpdateAvalible, checkSistemVersion, handleOpenNovedades, version, logVersiones, updateSistemVersion} = this.props;

    var ver = newVersion;
    var title = <DialogTitle id="simple-dialog-title">
      <div style={{width:'100%'}}>
        <img src="assets/img/iconos/LOGO_BLANCO.png" className="img-fluid" style={{width: '160px', marginBottom:'15px'}} alt="asd"/>
      </div>
      <div style={{width:'100%', marginBottom:'10px'}}>
        <span className="text-white" style={{marginTop:'10px', fontFamily:'Poppins Medium', fontSize: '25px', lineHeight: '20px'}}>Tenemos una nueva <br/> actualización para ti.</span>
      </div>
      <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="primary" className={classes.button} style={{margin:'auto', borderRadius: '20px'}}>
          Mejoras {newVersion}
        </Button>
      </MuiThemeProvider>
    </DialogTitle>
    var btnActualizar =  <Button variant="contained" color="default" className={classes.button} style={{margin:'auto', borderRadius: '20px'}} onClick={()=>updateSistemVersion()}>
      <UpdateIcon className={classes.leftIcon}/>
      Actualizar
    </Button>

    var btnCerrar =  <Button variant="contained" color="default" className={classes.button} style={{margin:'auto', borderRadius: '20px'}} onClick={()=>handleClose()}>
    <CloseIcon className={classes.leftIcon}/>
    Cerrar
    </Button>


    var content = <List style={{overflow:'hidden'}}>
      {versionMejoras.map(function (row, index) {
        var datosMejoras = row.mejora.split("||");
        return (
          <ListItem key={index}>
            <div className="row" style={{width:"100%"}}>
              <div className="col-md-12">
                <span className="text-white" style={{fontSize:'12px'}}><NewRelaseIcon style={{fontSize:'18px'}}/> {row.mejora}</span>
              </div>
            </div>
          </ListItem>
        )
      })}
      <ListItem>
        {btnActualizar}
        {btnCerrar}
      </ListItem>
    </List>

    if(handleOpenNovedades === true) {
      ver = version;
      title = <DialogTitle id="simple-dialog-title">
        <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px'}} onClick={()=>handleClose()}>
          <CloseIcon className="text-white"/>
        </IconButton>
        <div style={{width:'100%'}}>
          <img src="assets/img/iconos/LOGO_BLANCO.png" className="img-fluid" style={{width: '160px', marginBottom:'15px'}} alt="asd"/>
        </div>
        <div style={{width:'100%', marginBottom:'10px'}}>
          <span className="text-white" style={{marginTop:'10px', fontFamily:'Poppins Medium', fontSize: '25px', lineHeight: '20px'}}>Log de <br/> versiones.</span>
        </div>
      </DialogTitle>
      btnActualizar= "";
      var vers = "";
      content = <List style={{overflow:'hidden'}}>
        {logVersiones.map(function (row, index) {

          var version = <div className="col-md-12">
            <MuiThemeProvider theme={FormButtons}>
              <Button variant="contained" color="primary" className={classes.button} style={{margin:'auto', borderRadius: '20px', marginBottom: '18px'}}>
                {row.version}
              </Button>
            </MuiThemeProvider>
          </div>

          if(vers === "") {
            vers = row.version;
          } else if(vers === row.version) {
            version = "";
          } else if(vers !== row.version) {
            vers = row.version;
          }

          var datosMejoras = row.mejora.split("||");
          return (
            <ListItem key={index}>
              <div className="row" style={{width:"100%"}}>
                {version}
                <div className="col-md-12">
                  <span className="text-white" style={{fontSize:'12px'}}><NewRelaseIcon style={{fontSize:'18px'}}/> {row.fecha}: {row.mejora}</span>
                </div>
              </div>
            </ListItem>
          )
        })}
        <ListItem>
          {btnActualizar}
        </ListItem>
      </List>
    }

    return (
      <MuiThemeProvider theme={materialTheme}>
        <Dialog
          scroll={'body'}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}>
          {title}
          {content}
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogNewUpdateAvalible.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogNewUpdateAvalible);
