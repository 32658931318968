import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/ChatBubbleRounded';
import CreateChatIcon from '@material-ui/icons/RateReviewRounded';
import CreateChatDifusionIcon from '@material-ui/icons/RecordVoiceOverRounded';
import InboxIcon from '@material-ui/icons/AllInboxRounded';
import styled from 'styled-components';
import DivCrearMensajes from "./DivCrearMensajes";
import DivMensajesDifusion from "./DivMensajesDifusion";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  }
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '20px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivComunicacionInterna extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      destinatarios: [],
      emisores: [],
      grupos: [],
      mensajesDifusion: []
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected, checkPermisos} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getComunicacionInternaInfo();
      if(checkPermisos("128") && !checkPermisos("127")) {
        this.setState({value:1});
      }
    }
  }

  getComunicacionInternaInfo = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos} = this.props;
    var userId = dataUser.userId;

    getData(ajaxSource, "comunicacionInterna/comunicacionInternaInfo.php",{
      userId:userId,
      }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var destinatarios = data[0];
        var emisores = data[1];
        var grupos = data[2];
        var mensajesDifusion = data[3];

        self.setState({destinatarios:destinatarios, emisores:emisores, grupos:grupos, mensajesDifusion:mensajesDifusion} ,() => {
          loadedPage(true);
        });
      }
    });
  }

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };


  render() {
    const {theme, dataUser, ajaxSource, cerrarNotificacion, checkPermisos, crearNotificacion, getData, crearAlerta, cerrarAlerta, handleOpenTour, dialogStateForTour, handleOptionSelected, mobile} = this.props;
    const {value, destinatarios, emisores, grupos, mensajesDifusion} = this.state;

    var userUperaPerfil = dataUser.userUperaPerfil;

    var numberTab1 = "01";
    var numberTab2 = "02";
    var numberTab3 = "03";
    var numberTab4 = "04";

    if(userUperaPerfil === "7" || userUperaPerfil === "8") {
      numberTab2 = "01";
      numberTab4 = "02";
    }

    var tabCrearMensajeDirecto = <div></div>;
    var containerDivMensajeDirecto = <div></div>;
    if(checkPermisos("1") || checkPermisos("127")) {
      tabCrearMensajeDirecto = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <CreateChatIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>{numberTab1}</div>
            <div className="textTab"><b>Crear </b><br/><div className="textTabSub">Mensaje privado.</div></div>
          </div>
        </div>
      } />

      containerDivMensajeDirecto =
        <div>
          <DivCrearMensajes
            tab={0}
            index={value}
            ajaxSource={ajaxSource}
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            destinatarios={destinatarios}
          />
        </div>
    }

    var tabMisMensajesDirectos = <div></div>;
    var containerDivMisMensajesDirectos = <div></div>;
    if(checkPermisos("1") || checkPermisos("128")) {
      tabMisMensajesDirectos = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <ChatIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>{numberTab2}</div>
            <div className="textTab"><b>Mis </b><br/><div className="textTabSub">mensajes privados.</div></div>
          </div>
        </div>
      } />

      containerDivMisMensajesDirectos =
        <div>
          <DivCrearMensajes
            tab={1}
            index={value}
            ajaxSource={ajaxSource}
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            destinatarios={emisores}
          />
        </div>
    }

    var tabCrearMensajeDifusion = <div></div>;
    var containerDivCrearMensajeDifusion = <div></div>;
    if(checkPermisos("1") || checkPermisos("129")) {
      tabCrearMensajeDifusion = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <CreateChatDifusionIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>{numberTab3}</div>
            <div className="textTab"><b>Crear </b><br/><div className="textTabSub">mensajes de difusión.</div></div>
          </div>
        </div>
      } />

      containerDivCrearMensajeDifusion =
        <div>
          <DivCrearMensajes
            tab={2}
            index={value}
            ajaxSource={ajaxSource}
            dataUser={dataUser}
            checkPermisos={checkPermisos}
            crearNotificacion={crearNotificacion}
            cerrarNotificacion={cerrarNotificacion}
            getData={getData}
            crearAlerta={crearAlerta}
            cerrarAlerta={cerrarAlerta}
            destinatarios={grupos}
            usuarios={destinatarios}
            getComunicacionInternaInfo={this.getComunicacionInternaInfo}
          />
        </div>
    }

    var tabMensajesDifusion = <div></div>;
    var containerDivMensajesDifusion = <div></div>;
    if(checkPermisos("1") || checkPermisos("131")) {
      tabMensajesDifusion = <Tab style={{padding:'0px',marginLeft:'2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <InboxIcon className="iconTab"/>
            <div style={{fontSize: '11px'}}>{numberTab4}</div>
            <div className="textTab"><b>Mis </b><br/><div className="textTabSub">mensajes de difusión.</div></div>
          </div>
        </div>
      } />

      containerDivMensajesDifusion =
        <div>
         <DivMensajesDifusion
           tab={3}
           mensajesDifusion={mensajesDifusion}
           index={value}
           ajaxSource={ajaxSource}
           dataUser={dataUser}
           checkPermisos={checkPermisos}
           crearNotificacion={crearNotificacion}
           cerrarNotificacion={cerrarNotificacion}
           getData={getData}
           crearAlerta={crearAlerta}
           cerrarAlerta={cerrarAlerta}
         />
        </div>
    }

    return (
      <MuiThemeProvider theme={tabs}>
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabCrearMensajeDirecto}
            {tabMisMensajesDirectos}
            {tabCrearMensajeDifusion}
            {tabMensajesDifusion}
          </StyledTabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
          style={{overFlow:'hidden'}}
          slideClassName="slideOverflow"
        >
          <TabContainer dir={theme.direction}>
            {containerDivMensajeDirecto}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivMisMensajesDirectos}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivCrearMensajeDifusion}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {containerDivMensajesDifusion}
          </TabContainer>
        </SwipeableViews>
      </MuiThemeProvider>
    );
  }
}

DivComunicacionInterna.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivComunicacionInterna);
