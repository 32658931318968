import React, {Component} from "react";
import {MuiThemeProvider, createMuiTheme, withStyles} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});
const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, .6) !important',
        backdropFilter: 'blur(5px)',
        border:'2px solid #FFF',
        borderRadius:'20px !important'
      }
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth : "600px",
        margin: "48px auto !important"
      }
    },
    MuiFab:{
      primary:{
        backgroundColor: '#0345ea',
        "&:hover":{
          backgroundColor: '#d32f2f'
        }
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop:'5px !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(255,255,255,.7)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        } ,
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important"
        }
      },
      disabled: {
        borderRadius: '5px'
      },
      notchedOutline: {
        borderColor: 'rgba(255,255,255,.8)'
      },
      input: {
        color:'#FFF',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#FFF'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color:'#FFF !important'
      }
    },
    MuiFormLabel: {
      root: {
        color:"rgba(255,255,255,.4)",
        fontWeight: '200'
      }
    },
    MuiSelect: {
      icon: {
        color:'#FFF'
      },
      select: {
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      }
    },
    MuiInputBase:{
      root:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      },
      input:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
        }
      },
    },
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  },
});

//ErrorBoundary component c1-12
class ErrorReporter extends Component{
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      open: false,
    };
  }

  componentDidCatch(error, info) {
    const self = this;
    const {ajaxSource, dataUser, getData} = this.props;
    const {estabaHaciendo} = this.state;
    var userId = dataUser.userId;

    var e = error;

    if(ajaxSource === "") {
      getData(ajaxSource, "errorReporter/createTicketAutomatico.php",{
        userId:userId,
        error:error.message,
        info:info.componentStack
      }).then(function (response) {
        if (response.data) {
          var data = response.data;

          self.setState({ hasError: true });
        }
      });
    }
  }

  handleClose = () => {
    this.setState({open:false});
  };

  render() {
    const {classes, login, handleOpen, error, errorInfo, prodEnv} = this.props;

    if (this.state.hasError) {
      return (
        <MuiThemeProvider theme={FormInputs}>
          <div>
            <img src="assets/img/error.png" className="img-fluid" style={{marginTop:'4vh'}} alt="asd"/>
            <Dialog
              scroll={'body'}
              disableBackdropClick={true}
              disableEscapeKeyDown={true}
              fullWidth={true}
              maxWidth={"sm"}
              onClose={this.handleClose}
              open={true}
              aria-labelledby="simple-dialog-title"
              TransitionComponent={Transition}>
              <DialogTitle id="simple-dialog-title">
                <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', padding:'0px'}}>
                  <img src="assets/img/iconos/LOGO_BLANCO.png" className="img-fluid" style={{width: '95px'}} alt="asd"/>
                </IconButton>
                <div className="text-white" style={{width:'100%', fontFamily:'Poppins Medium', fontSize: '35px', lineHeight: '32px', paddingBottom: '16px'}}>
                  Oops!
                </div>
                <div style={{width:'100%', marginBottom:'10px'}}>
                  <span className="text-white" style={{marginTop:'10px', fontFamily:'Poppins Medium', fontSize: '25px', lineHeight: '20px'}}>Algo salio mal.</span>
                </div>
                <div className="text-white" style={{marginTop:'10px', fontFamily:'Poppins Regular', fontSize: '14px'}}>Se ha generado un ticket de manera automática e informado al equipo de soporte técnico. <br></br> <br></br> Este error persistirá hasta que se realicen las correcciones necesarias. Lamentamos los inconvenientes que esto pueda causar, una vez solucionado el error te notificaremos vía email o WhatsApp. <br></br></div>
              </DialogTitle>
              <List style={{overflow:'hidden'}}>
                <ListItem>
                  <div className="row" style={{width:"100%", margin:'0px'}}>
                    <div className="col-md-12" style={{textAlign: 'center'}}>
                      <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '10px'}} onClick={() => window.location.reload()}>
                        Cerrar
                      </Button>
                    </div>
                  </div>
                </ListItem>
              </List>
            </Dialog>
          </div>
        </MuiThemeProvider>
      );
    }

    return this.props.children;
  }
}

ErrorReporter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorReporter);
