/*Componentes globales*/
import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from '@material-ui/core/Button';
import Cookies from 'universal-cookie';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon'
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import withFixedColumns from 'react-table-hoc-fixed-columns';
import './commons/css/tableHomeDir.css';

/*Componentes de la aplicación*/
const $ = require('jquery');
var cookies = new Cookies();
const ReactTableFixedColumns = withFixedColumns(ReactTable);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#525D8D',
    color: '#FFF',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    fontFamily:'Poppins Regular',
    border: '1px solid #525D8D',
  },
}))(Tooltip);

class HomeDIR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      manttoRenta:"mantto",
      resPlazas:[],
      plazaSelected:"",
      plazaIdSelected:"",
      year:"",
      reporte:[],
      totalDatos:0,
      tableKey:0,
      totalAnual:0,
      totalVencido:0,
      totalPorPagar:0,
      totalDelMesEnCurso:0
    }
  }

  componentDidMount() {
    //arr.resPlazaId, arr.nombreResPlaza
    const {loadedPage, dataUser} = this.props;
    var actualYear = dataUser.sistemYear;
    this.setState({year:actualYear,resPlazas:dataUser.userResidencialName}, () => {
      const {resPlazas} = this.state;
      if(resPlazas !== undefined) {
        this.setState({plazaSelected: resPlazas[0].nombreResPlaza, plazaIdSelected: resPlazas[0].resPlazaId}, () => {
          this.getHomeDirInfo();
        });
      }
    })
    loadedPage(true);
  }

  getHomeDirInfo = () => {
    const that = this;
    const {dataUser, ajaxSource, getData, crearNotificacion, cerrarNotificacion} = this.props;
    const {plazaSelected, plazaIdSelected, year, tableKey, manttoRenta} = this.state;
    var userId = dataUser.userId;
    var tab = 0;
    if(manttoRenta === "rentas") {
      tab = 1;
    }
    crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);
    getData(ajaxSource, "direccion/getHomeDirInfo.php",{
      userId:userId,
      residencialId:plazaIdSelected,
      year:year,
      tab:tab
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;
        var reporte = data[0];
        var totalAnual = that.formatMoney(data[1]);
        var totalPorPagar = that.formatMoney(data[2]);
        var totalDelMesEnCurso = that.formatMoney(data[3]);

        var totalDatos = Object.keys(reporte).length;
        that.setState({totalDatos:totalDatos,reporte:reporte,totalAnual:totalAnual, totalPorPagar:totalPorPagar, totalDelMesEnCurso:totalDelMesEnCurso}, () => {
          that.setState({tableKey:tableKey+1});
        });
      }
    });
  }

  descargarReportePDF = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, loadedPage, checkPermisos, prodEnv} = this.props;
    const {year, plazaIdSelected, manttoRenta} = this.state;
    var userId = dataUser.userId;
    var tab = 0;
    if(manttoRenta === "rentas") {
      tab = 1;
    }

    crearNotificacion("info", "Generando reporte...", "bottom", "right", 1000000);

    getData(ajaxSource, "direccion/crearReportePdfAnual.php",{
      userId:userId,
      estadoYear:year,
      plazaIdSelected:plazaIdSelected,
      tab:tab
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();

        var data = response.data;
        if(data[0].error === 2) {
          crearNotificacion("error", "Hubo un problema al intentar cerrar el estado de cuenta " + data[0].error, "bottom", "right", 3000);
        } else if(data[0].error === 1) {
          var path = data[1];
          window.open(prodEnv+path, '_blank');
        }
      }
    });
  }

  hanldeSelectResPlaza = (resPlazaId, resPlaza) => {
    this.setState({plazaSelected:resPlaza, plazaIdSelected:resPlazaId}, () => {
      this.getHomeDirInfo();
    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, ()=> {
      if(name === "year") {
        this.getHomeDirInfo();
      }
    });
  };

  formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

  renderCell = (d, index) => {
    if(index !== "total" && index !== "local") {
      var estatus = d.pagos[index].status;
      var fechaPago = "";
      if (estatus === "Pagado") {
        if (d.pagos[index].monto !== "-") {
          estatus = d.pagos[index].monto;
          fechaPago = d.pagos[index].fechaPagoRegistroManual;
        }
      }
      var color = "rgb(255,255,255)";
      if (estatus === "Vencido") {
        color = "rgb(233,74,80)";
      } else if (estatus == "En curso") {
        color = "rgb(37,154,219)";
      }
      if(fechaPago === "" || fechaPago === null) {
        return (
          <font style={{color: color, cursor: 'pointer'}}>{estatus}</font>
        )
      } else {
        return (
          <HtmlTooltip
            placement="top"
            title={
              <React.Fragment>
                <Typography color="inherit">Fecha de pago:</Typography>
                <font style={{fontFamily:'Poppins SemiBold', fontSize: '15px'}}>{fechaPago}</font>
              </React.Fragment>
            }
          >
            <font style={{color: color, cursor: 'pointer'}}>{estatus}</font>
          </HtmlTooltip>
        )
      }
    } else if(index === "total") {
      var totalPorCasa = this.formatMoney(d.totalPorCasa);
      return (
        <font style={{color: 'rgb(49,171,57)'}}>${totalPorCasa}</font>
      )
    } else if(index === "local") {

      return (
        <HtmlTooltip
          placement="top"
          title={
            <React.Fragment>
              <font style={{fontFamily:'Poppins Regular'}}>Locatario: {d.locatarioName}</font>
              <br/>
              <font style={{fontFamily:'Poppins Regular'}}>Email: {d.locatarioEmail}</font>
            </React.Fragment>
          }
        >
          <font style={{color: '#FFF', fontFamily:'Poppins Bold', cursor: 'pointer'}}>{d.casaLocal}</font>
        </HtmlTooltip>
      )
    }
  }

  renderFooter = (i) => {
    const {reporte, totalAnual} = this.state;
    var total = 0;
    reporte.map(function (arr, index) {
      total = parseFloat(total) + parseFloat(arr.pagos[i].montoN);
    })
    var t = this.formatMoney(total);
    //this.setState({totalAnual:totalAnual+t});
    return(
      <span style={{color: 'rgb(49,171,57)'}}>${t}</span>
    )
  }

  render() {
    const that = this;
    const {classes, dataUser, documentHeight, mobileiPad, mobile} = this.props;
    const {manttoRenta, resPlazas, plazaSelected, year, reporte, totalDatos, tableKey, totalAnual, totalPorPagar, totalDelMesEnCurso} = this.state;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;

    const data = [{
      name: 'Tanner Linsley',
      age: 26,
      friend: {
        name: 'Jason Maurer',
        age: 23,
      }
    }]

    return (
      <MuiThemeProvider theme={theme}>
        <div
          className="main"
          style={{
            border:'1px solid #000',
            height:documentHeight+'px',
            marginTop:'0px',
            background: "linear-gradient(#242428, #252528, #25262a, #2e3035, #35373e, #3c3f47)",
            maxHeight:documentHeight+'px',
            overflowY:'auto'
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <img src="assets/img/iconos/LOGO_BLANCO.png" style={{width:'100px', marginTop:'30px'}}/>
                  </div>
                  <div className="col-md-7"></div>
                  <div className="col-md-2">
                    <Button
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      endIcon={<Icon>login</Icon>}
                      style={{
                        background:'#0345ea',
                        borderRadius:'15px',
                        marginTop:'30px'
                      }}
                      onClick={()=>this.props.closeSession()}
                    >
                      <font style={{fontFamily:'Poppins Bold', width:'100%', fontSize:'16px', textTransform: 'none'}}>Cerrar Sesión</font>
                    </Button>
                  </div>
                </div>
              </div>
              <div className={!mobileiPad ? "col-md-3" : "col-md-12"}>
                <h1 style={{
                  fontSize: '50px',
                  fontWeight: 'bold',
                  marginTop: '30px',
                  marginBottom:'0px',
                  color:'#FFF'
                }}>Hola <font style={{fontSize:'20px'}}>{dataUser.userName}</font></h1>
                <h1 style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginTop: '10px',
                  marginBottom:'30px',
                  color:'#525D8D'
                }}>Qué cuenta quieres <br/> revisar el día de hoy? </h1>
                <div className="row">
                  <div className="col-md-6">
                    <Button
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      size="medium"
                      className={classes.button}
                      onClick={()=>this.setState({manttoRenta:'mantto'}, () => {this.getHomeDirInfo()})}
                      style={{
                        background:manttoRenta === "mantto" ? '#0345ea' : 'transparent',
                        borderRadius:'15px',
                        border:manttoRenta === "mantto" ? '' : '1px solid #525D8D'
                      }}
                    >
                      <font style={{fontFamily:'Poppins Bold', fontSize:'16px'}}>Mantenimiento</font>
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      size="medium"
                      className={classes.button}
                      onClick={()=>this.setState({manttoRenta:'rentas'}, () => {this.getHomeDirInfo()})}

                      style={{
                        background:manttoRenta === "mantto" ? 'transparent' : '#0345ea',
                        borderRadius:'15px',
                        border:manttoRenta === "mantto" ? '1px solid #525D8D' : ''
                      }}
                    >
                      <font style={{fontFamily:'Poppins Bold', fontSize:'16px'}}>Renta</font>
                    </Button>
                  </div>

                </div>
                <div className="row" style={{marginTop:'50px'}}>
                  <>
                    {
                      resPlazas !== undefined &&
                      Object.keys(resPlazas).length > 0 &&
                      resPlazas.map(function (arr, index) {

                      var btnBackground = "transparent";
                      if(plazaSelected === arr.nombreResPlaza) {
                        btnBackground = "linear-gradient(to right, #4B4C9C, #8179CC)";
                      }

                      return(
                        <div className="col-md-12" style={{marginBottom:'10px'}}>
                          <Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={classes.button}
                            onClick={()=>that.hanldeSelectResPlaza(arr.resPlazaId, arr.nombreResPlaza)}
                            style={{
                              background:btnBackground,
                              borderRadius:'15px',
                              border:plazaSelected !== arr.nombreResPlaza ? '1px solid #525D8D' : '',
                              boxShadow:'none'
                            }}
                          >
                            <font style={{
                              fontFamily:plazaSelected !== arr.nombreResPlaza ? 'Poppins Regular' : 'Poppins Bold',
                              width:'100%',
                              textAlign:'left',
                              fontSize:'16px'
                            }}>{arr.nombreResPlaza}</font>
                          </Button>
                        </div>
                      )
                    })}
                  </>
                </div>
              </div>
              <div className={!mobileiPad ? "col-md-7" : mobile ? "col-md-12" : "col-md-10"}>
                <div style={{width:'100%', flexDirection:'row', paddingTop:'45px'}}>
                  <table>
                    <tr>
                      <td>
                        <h1 style={{
                          fontSize: !mobile ? '25px' : '15px',
                          fontWeight: 'bold',
                          marginBottom:'0px',
                          color:'#FFF',
                          lineHeight:0
                        }}>Lista de pagos</h1>
                        <br/>
                        <h1 style={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          marginTop:'10px',
                          color:'#525D8D',
                          lineHeight:0
                        }}>{manttoRenta === "mantto" ? 'Mantenimiento' : 'Rentas'}</h1>
                      </td>
                      <td style={{
                        paddingLeft:'30px',
                        width:'130px'
                      }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel
                            ref={ref => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="year"
                            style={{
                              background: 'transparent',
                              padding: '0px',
                              color:'#525D8D'
                            }}
                          >
                            Año
                          </InputLabel>
                          <Select
                            value={year}
                            onChange={this.handleChange('year')}
                            input={
                              <OutlinedInput id="year" labelWidth={40}/>
                            }
                          >
                            <MenuItem value={nextYear}>{nextYear}</MenuItem>
                            <MenuItem value={actualYear}>{actualYear}</MenuItem>
                            <MenuItem value={passYear}>{passYear}</MenuItem>
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{
                        paddingLeft:'10px',
                        width:'130px'
                      }}>
                        <Button
                          fullWidth={true}
                          variant="contained"
                          color="primary"
                          size="medium"
                          className={classes.button}
                          endIcon={<Icon>cloud_download</Icon>}
                          style={{
                            background:'#525D8D',
                            borderRadius:'15px',
                          }}
                          onClick={()=>this.descargarReportePDF()}
                        >
                          <font style={{fontFamily:'Poppins Bold', width:'100%', fontSize:'16px'}}>PDF</font>
                        </Button>
                      </td>
                      <td style={{
                        paddingLeft:'10px',
                        width:'130px'
                      }}>
                        {/*
                        <Button
                          fullWidth={true}
                          variant="contained"
                          color="primary"
                          size="medium"
                          className={classes.button}
                          endIcon={<Icon>cloud_download</Icon>}
                          style={{
                            background:'#525D8D',
                            borderRadius:'15px',
                          }}
                        >
                          <font style={{fontFamily:'Poppins Bold', width:'100%', fontSize:'16px'}}>CSV</font>
                        </Button>
                        */}
                      </td>
                    </tr>
                  </table>
                </div>
                <div style={{width:'100%', marginTop:'15px', padding:'8px', backgroundColor:'#32363D', borderRadius:'15px'}}>
                  {Object.keys(reporte).length > 0 &&
                    <ReactTableFixedColumns
                      key={tableKey}
                      showPagination={false}
                      defaultPageSize={totalDatos}
                      data={reporte}
                      style={{height: !mobile ? documentHeight - 250 : documentHeight, border: '0px'}}
                      columns={[{
                        id:"Local",
                        Header: 'Local',
                        accessor: d => that.renderCell(d, "local"),
                        fixed: 'left',
                        Footer: (
                          <span style={{color:'#FFF'}}>
                            <strong>TOTAL:</strong>
                          </span>
                        )
                      }, {
                        id: 'Ene',
                        Header: 'Ene',
                        accessor: d => that.renderCell(d, 0),
                        Footer: () => that.renderFooter(0)
                      }, {
                        id: 'Feb',
                        Header: 'Feb',
                        accessor: d => that.renderCell(d, 1),
                        Footer: () => that.renderFooter(1)
                      }, {
                        id: 'Mar',
                        Header: 'Mar',
                        accessor: d => that.renderCell(d, 2),
                        Footer: () => that.renderFooter(2)
                      }, {
                        id: 'Abr',
                        Header: 'Abr',
                        accessor: d => that.renderCell(d, 3),
                        Footer: () => that.renderFooter(3)
                      }, {
                        id: 'May',
                        Header: 'May',
                        accessor: d => that.renderCell(d, 4),
                        Footer: () => that.renderFooter(4)
                      }, {
                        id: 'Jun',
                        Header: 'Jun',
                        accessor: d => that.renderCell(d, 5),
                        Footer: () => that.renderFooter(5)
                      }, {
                        id: 'Jul',
                        Header: 'Jul',
                        accessor: d => that.renderCell(d, 6),
                        Footer: () => that.renderFooter(6)
                      }, {
                        id: 'Ago',
                        Header: 'Ago',
                        accessor: d => that.renderCell(d, 7),
                        Footer: () => that.renderFooter(7)
                      }, {
                        id: 'Sep',
                        Header: 'Sep',
                        accessor: d => that.renderCell(d, 8),
                        Footer: () => that.renderFooter(8)
                      }, {
                        id: 'Oct',
                        Header: 'Oct',
                        accessor: d => that.renderCell(d, 9),
                        Footer: () => that.renderFooter(9)
                      }, {
                        id: 'Nov',
                        Header: 'Nov',
                        accessor: d => that.renderCell(d, 10),
                        Footer: () => that.renderFooter(10)
                      }, {
                        id: 'Dic',
                        Header: 'Dic',
                        accessor: d => that.renderCell(d, 11),
                        Footer: () => that.renderFooter(11)
                      }, {
                        id: 'Total',
                        Header: 'Total',
                        fixed: 'right',
                        accessor: d => that.renderCell(d, "total"),
                        getProps: (state, rowInfo) => ({
                          style: {
                            textAlign: 'right'
                          }
                        })
                      }]}
                    />
                  }
                </div>
              </div>
              <div className={!mobile ? "col-md-2" : "col-md-12"}>
                <div style={{marginTop: !mobile ? '123px' : '20px'}}>
                  <ExpansionPanel
                    defaultExpanded={true}
                    style={{background:'#3D4570'}}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color:'#FFF'}} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          color:'#FFF',
                          fontFamily:'Poppins Bold',
                          fontSize:'16px',
                        }}>
                        Total ingreso anual
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography
                        style={{
                          color:'rgb(49,171,57)',
                          fontFamily:'Poppins SemiBold',
                          fontSize:'25px'
                        }}>
                        ${totalAnual}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div style={{marginTop:'10px'}}>
                  <ExpansionPanel
                    style={{background:'rgb(233,74,80)'}}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color:'#FFF'}} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} style={{color:'#FFF', fontFamily:'Poppins Bold', fontSize:'16px'}}>
                        Total pendientes
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography style={{color:'rgba(0,0,0,.7)', fontFamily:'Poppins SemiBold', fontSize:'25px'}}>
                        ${totalPorPagar}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
                <div style={{marginTop:'10px'}}>
                  <ExpansionPanel
                    style={{background:'rgb(37,154,219)'}}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon style={{color:'#FFF'}} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} style={{color:'#FFF', fontFamily:'Poppins Bold', fontSize:'16px'}}>
                        Ingreso del mes
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography style={{color:'rgba(0,0,0,.7)', fontFamily:'Poppins SemiBold', fontSize:'25px'}}>
                        ${totalDelMesEnCurso}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

HomeDIR.propTypes = {
  classes: PropTypes.object.isRequired,
};

//ESTILOS Y TEMAS
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular'
  },
  overrides: {
    MuiPaper: {
      rounded : {
        borderRadius:'15px'
      }
    },
    MuiButton: {
      root: {
        borderRadius:'8px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      },
      containedSizeLarge: {
        padding: '19px 22px'
      },
      label: {
        textTransform:'none'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop:'10px !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: '#525D8D',
        },
        "&$focused $notchedOutline": {
          borderColor: '#525D8D'
        } ,
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important"
        },
        borderRadius:'15px'
      },
      disabled: {
        borderRadius: '15px'
      },
      notchedOutline: {
        borderColor: '#525D8D'
      },
      input: {
        color:'#FFF',
        borderRadius: '15px',
        padding: '11.5px 14px !important'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#525D8D'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color:'#525D8D !important'
      },
      outlined: {
        transform: 'translate(14px, 13px) scale(1)'
      }
    },
    MuiFormLabel: {
      root: {
        color:"#525D8D",
        fontWeight: '200'
      }
    },
    MuiSelect: {
      icon: {
        color:'#525D8D'
      },
      select: {
        "&.Mui-disabled": {
          backgroundColor:'#525D8D',
          borderRadius:'15px'
        }
      }
    },
    MuiInputBase:{
      root:{
        "&.Mui-disabled": {
          backgroundColor:'#525D8D',
          borderRadius:'15px'
        }
      },
      input:{
        "&.Mui-disabled": {
          backgroundColor:'#525D8D',
        }
      },
    },
    MuiExpansionPanel: {
      rounded: {
        "&:last-child": {
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px'
        },
        "&:first-child": {
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px'
        }
      }
    }
  }
});
const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  button: {
    fontFamily:'Poppins Regular',
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  formControl: {
    marginTop: '10px',
    marginBottom: '8px',
    width: '100%',
  },
});

export default withStyles(styles)(HomeDIR);
