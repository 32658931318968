/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
 
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
 
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
 
import EditIcon from '@material-ui/icons/EditRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
 
 

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '10px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth : "1200px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const DrawerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba(38, 49, 96, .9) !important',
        backdropFilter: 'blur(15px)',
        width:'65%',
        padding:'0px',
        borderBottomLeftRadius:'15px',
        borderTopLeftRadius:'15px',
        flex:'none',
        marginTop:'-1px !important'
      }
    },
    MuiPaper:{
      elevation16: {
        boxShadow:'none !important'
      }
    }
  }
});

const DrawerThemeMobile = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiBackdrop:{
      root:{
        backgroundColor:'transparent !important'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba(51, 62, 102, .9) !important',
        backdropFilter: 'blur(7px)',
        width:'100%'
      }
    },
    MuiPaper:{
      elevation16: {
        boxShadow:'none !important'
      }
    }
  }
});

const DialogTMobile = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        width: 'calc(100% - 23px) !important',
        maxWidth: 'calc(100% - 23px) !important',
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop:'10px !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(255,255,255,.7)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        } ,
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important"
        }
      },
      disabled: {
        borderRadius: '5px'
      },
      notchedOutline: {
        borderColor: 'rgba(255,255,255,.8)'
      },
      input: {
        color:'#FFF',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#FFF'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color:'#FFF !important'
      }
    },
    MuiFormLabel: {
      root: {
        color:"rgba(255,255,255,.4)",
        fontWeight: '200'
      }
    },
    MuiSelect: {
      icon: {
        color:'#FFF'
      },
      select: {
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      }
    },
    MuiInputBase:{
      root:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      },
      input:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
        }
      },
    },
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}



class DialogHouse extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      residenteId:"",
      casaLocalId:"",
      value:"",
      nombres:"",
      nombresE:false,
      userCasasLocales:[],
      cuotaE: false,
      cuota: "",
      disableBtnPay: false,
      disableFormEdit:true,
      predial:"",
      predialE:false,
      userPrecioM2:"",
      userPrecioM2E:false,
      casaLocal:"",
      casaLocalE:false,
      payedMonthsYear:[],
      blurForm:false,
      userIdCreated:"",
      disabeCasaLocal:true,
      showFormAddLocalCasa:false,
      showFormAddCuentas:false,
      casaLocal:"",
      casaLocalE:false,
      m2:"",
      m2E:false,
      predial:"",
      predialE:false,
       
    };
  }

  
  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState, ()=> {
      handleClose();
    });
  };

  
  handleChange = name => event => {
      this.setState({[name]: event.target.value, [name + 'E']: false});
  
  };

  

  handleValidateInputOnlyNumbers = (m2) => {
    var RE = /^\d*\.?\d*$/;
    return RE.test(m2);
  } 


  hanldleDeleteUser = () => {
    const {crearAlerta} = this.props;
    crearAlerta("warning", "", "¿Seguro que deseas eliminar este usuario? Se eliminará toda información referente al usuario", true, this.deleteUser);
  }

  hanldeEnableForm = (value) => {
    this.setState({disableFormEdit:value});
  }
  
  handleEditarCasaLocal = (casaLocalId, index) => {
    this.setState({indexCasaLocalSelectedEdit:index, casaLocalIdSelected:casaLocalId});
  }


  deleteHouse = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, dataRes, cerrarAlerta} = this.props;
    const {casaLocalId} = this.state;
    cerrarAlerta();

    var userId = dataUser.userId;
    crearNotificacion("info", "Eliminando usuario...", "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/deleteUser.php",{
      userId:userId,
      casaLocalId: casaLocalId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          setTimeout(function () {
            crearNotificacion("success", "Se elemino con exito.", "bottom", "right", 3000);
            getAdminInfo();
            self.handleClose();
          },500);
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar eliminar " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }


/* 
  Agregar casas
  El administrador debe de poder agregar casas o locales #11
  */

  addCasaLocalUser = (option) => {
    
    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion, dataRes, getAdminInfo, checkPermisos} = this.props;
    const {userIdCreated, blurForm, casaLocal, m2, m2E,agregarCuota, predial, proximoMesPago, proximoYearPago, casaLocalIdSelected, tipoUsuario, residenteId} = this.state;
    var userId = dataUser.userId;

   
    
    

    var txtExito = "";
    var txtError = "";
    var txtError2 = "";
    var textCasaLocal = "la casa";

    if(option === "agregar") {
      txtExito = "Casa agregada con exito.";
      txtError = "Hubo un problema al intentar agregar la casa";
      txtError2 = "Esta casa ya se encuentra asignada a otro usuario"
    } else if(option === "eliminar") {
      txtExito = "Casa eliminada con exito.";
      txtError = "Hubo un problema al intentar eliminar la casa";
    } else if(option === "editar") {
      txtExito = "Casa editada con exito.";
      txtError = "Hubo un problema al intentar editar la casa";
    }

    if(checkPermisos("125")) {
      if(option === "agregar") {
        txtExito = "Local agregado con exito.";
        txtError = "Hubo un problema al intentar agregar el local";
        txtError2 = "Este local ya se encuentra asignado a otro usuario"
      } else if(option === "eliminar") {
        txtExito = "Local eliminado con exito.";
        txtError = "Hubo un problema al intentar eliminar el local";
      } else if(option === "editar") {
        txtExito = "Local editado con exito.";
        txtError = "Hubo un problema al intentar editar el local";
      }
      textCasaLocal = "el local";
    }
    

    

    if(casaLocal=== "" && option === "agregar") {
      this.setState({casaLocalE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el nombre", "bottom", "right");
      });
    }
    else if(m2 === "" && option === "agregar" && checkPermisos("125")) {
      this.setState({m2E:true}, ()=> {
        crearNotificacion("warning", "Ingresa los m2.", "bottom", "right");
      });
      
    } 
    else if(m2 !== "" && m2 !== undefined && (this.handleValidateInputOnlyNumbers(m2) === false) && checkPermisos("125")) {
      this.setState({celularE:true}, ()=> {
        crearNotificacion("warning", "Ingrese una medida valida", "bottom", "right");
      });
    } 
    else {


      crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);

      getData(ajaxSource, "administracion/createHouse.php", {
        userId:userId,
        option:option,
        casaLocal:casaLocal,
        m2: m2,
        predial: predial

      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            setTimeout(function () {
              crearNotificacion("success", txtExito, "bottom", "right", 3000);
              getAdminInfo();
              self.handleClose();
            },500);
          } else {
            crearNotificacion("error", txtError + data[0].error, "bottom", "right", 3000);
            console.log("fault");
          }
        }
      });

     
    }
  }

  
   
  
   

 
  renderNombrePersona = () => {
    const {disableFormEdit, casaLocal, casaLocalE} = this.state;

    return(
     
    <div className="col-md-4">
      <TextField
        disabled={disableFormEdit}
        error={casaLocalE}
        label="Nombre"
        placeholder="Nombre"
        id="nombres"
        onChange={this.handleChange('casaLocal')}
        value={casaLocal}
        margin="normal"
        variant="outlined"
        style={{width:'100%'}}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
    )
  }

  renderPredial(){

    const {disableFormEdit, predialE, predial} = this.state


   return (

    <TextField
                      disabled={disableFormEdit}
                      error={predialE}
                      label="Predial"
                      placeholder="Ingresa el predial"
                      id="predial"
                      value={predial}
                      margin="normal"
                      variant="outlined"
                      style={{width:'100%'}}
                      onChange={this.handleChange('predial')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      />
     
   )

  }

  renderAddM2(){

    const {disableFormEdit, m2E, m2} = this.state




    return(

      <TextField
      disabled={disableFormEdit}
      error={m2E}
      label="m2"
      placeholder="Ingresa los m2"
      id="m2"
      value={m2}
      margin="normal"
      variant="outlined"
      style={{width:'100%'}}
      onChange={this.handleChange('m2')}
      InputLabelProps={{
        shrink: true
      }}
      />
    
    )
  

  }

  renderSelectMesAPagar = () => {
    const {classes, checkPermisos} = this.props;
    const {proximoMesPago, proximoMesPagoE} = this.state;

    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    return (
      <FormControl variant="outlined" className={classes.formControl} error={proximoMesPagoE}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="sexo"
          style={{
            background: 'transparent',
            padding: '0px'
          }}
        >
          Mes a pagar
        </InputLabel>
        <Select
          value={proximoMesPago}
          onChange={this.handleChange('proximoMesPago')}
          input={
            <OutlinedInput id="sexo" labelWidth={100}/>
          }
        >
          {months.map(function (arr, index) {

            return (
              <MenuItem key={index} value={months[index]}>
                {months[index]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  
  
  render() {
    const {classes, open, option, checkPermisos,  mobile, casasLocales } = this.props;
    const {predial, email, emailE ,predialE, disableFormEdit, tipoUsuarioText,userCasasLocales,showFormAddLocalCasa,casaLocal, casaLocalE,m2E, a_M2E, m2, agregarCuota, agregarCuotaE, blurForm} = this.state;
   
    var btnText = "Crear casa";
    var textCasaLocal = 'la casa';
    if(checkPermisos("125")) { //Permisoa plaza
      btnText = "Crear local";
      textCasaLocal = 'el local';
    }
    if(tipoUsuarioText === "Usuario Multicuentas") {
      btnText = "Crear cuentas";
    }

    var step1 = <div style={{marginBottom: '-10px', fontSize: '18px', fontWeight: 'bold', color: '#0345ea'}}>Paso uno</div>
    var title = btnText;
     
    var btnEliminar = "";
    var btnCancelar = "";
    var btnView = "";
    var btn ="";

    if(option === "agregar") {

      if(checkPermisos("125")) { //Permisoa plaza
        title= "Crear local";
      }
      else{
        btnText = "Crear casa";
      }
       
     

      if(disableFormEdit === true) {
        btn = <Button variant="contained" color="default" className={classes.button} style={{marginTop: '0px'}}
                      onClick={() => this.hanldeEnableForm(false)}>
          Comenzar
          <EditIcon className={classes.rightIcon}/>
        </Button>
      } else {
        btn = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '0px'}}
                      onClick={() => this.addCasaLocalUser('agregar')}>
          Agregar
        </Button>
        btnCancelar = <Button variant="contained" color="default" className={classes.button} style={{marginTop: '0px'}} onClick={() => this.hanldeEnableForm(true)}>
          Cancelar
          <CancelIcon className={classes.rightIcon}/>
        </Button>

        btnView = <Button variant="contained" color="default" className={classes.button} style={{marginTop: '0px'}} onClick={() => this.renderTablaCasasLocales()}>
        Ver
        <CancelIcon className={classes.rightIcon}/>
        </Button>
        
      }

       
    }

   

    

    var labelCasaLocal = "";
    
    var labelResidenteLocatario = "Residente/Locatario";

    if(checkPermisos("124")) {
      
      labelResidenteLocatario = "Residente";
    } else if(checkPermisos("125")) {
      labelResidenteLocatario = "Locatario";
    }

    var themeD = DialogT;
    if(mobile === true) {
      themeD = DialogTMobile;
    }

    var drTheme = DrawerTheme;
    if(mobile === true) {
      drTheme = DrawerThemeMobile;
    }

    var styleListForm = {margin: '0px', width: '100%'}
    if(blurForm === true) {
      styleListForm = {margin: '0px', width: '100%'}
    }

    return (
      

      <MuiThemeProvider theme={drTheme}>
        <Drawer anchor="right" open={open} onClose={this.handleClose} disableBackdropClick={false}>
          <div style={{padding: '0px 20px'}}>
            <List>
            <MuiThemeProvider theme={FormInputs}>
              <IconButton aria-label="Cerrar" onClick={()=>this.handleClose()} style={{position: 'absolute',right: '19px',top: '15px', zIndex:'9999'
              }}>
                <CloseIcon style={{color:'#FFF'}}/>
              </IconButton>

              
              <ListItem>
                <div style={styleListForm}>
                  <div className="row" style={{margin: '0px', width: '100%', paddingBottom:'0px', marginBottom: '0px', marginTop:'20px'}}>
                    <div className="col-md-12">
                      <h3 style={{marginTop:'0px', color:'#FFF', borderBottom: '3px solid #0f4ce6', fontWeight:'bold'}}>{title}</h3>
                    </div>
                     
                   
                    
                </div>
                
                  <div className="row" style={{margin: '0px', width: '100%'}}>
                    <div className="col-md-12">
                      <h4 style={{marginTop:'5px', color:'#FFF', fontWeight:'bold'}}>Datos de {textCasaLocal}</h4>
                    </div>
                  </div>
                  {tipoUsuarioText !== "Usuario Multicuentas" &&
                  this.renderNombrePersona()
                  }
                  <div className="row" style={{margin: '0px', width: '100%', paddingBottom:'0px', marginBottom: '0px'}}>
                  


                    <div className="col-md-4">
                      {checkPermisos("125") &&  this.renderAddM2()
                      }
                     
                    </div>
                     
                    <div className="col-md-4">
                      { checkPermisos("125") && this.renderPredial()}
                    </div>

 
                        


                    
                     
                   
                    
                  </div>
                  <div className="row" style={{margin: '0px', width: '100%', paddingBottom:'0px', marginBottom: '0px'}}>
                    <div className="col-md-12" style={{marginTop: '20px'}}>
                      <MuiThemeProvider theme={FormButtons}>
                        {btn}
                        {btnCancelar}
                        {btnEliminar}
                        
                      </MuiThemeProvider>
                    </div>
                  </div>
                </div>
              </ListItem>
              
                
               
            </MuiThemeProvider>
          </List>
          </div>
        </Drawer>
      </MuiThemeProvider>
    );
  }
}

DialogHouse.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogHouse);
