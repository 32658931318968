/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
 
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
 
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CancelIcon from '@material-ui/icons/CancelRounded';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth : "800px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogFacturaInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:"",
      mesDataSelected:[],
      facturaDataSelected:[],
      disableBtnPay:false,
      abono:false,
      montoAbono:null,
      montoAbonoE:false,
      abonos:[],
      totalAbono:0,
      totalAbonoTxt:"",
      montoRestanteTxt:"",
      montoRestante:0,
      monto:null,
      montoE:false,
      fechaPago:null,
      fechaPagoE:false,
      payMonth:[],
      montoConDescuento:"",
      descuento:0,
      numeroRecibosFacturas:"Uno",
      regitrarSinFactura:false,
      editarRegistro:false,
      ecommId:"",
      montoEcomm:"",
      facturaId:""
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {open, mesDataSelected, facturaDataSelected, checkPermisos} = this.props;
    if(nextProps.open !== open) {
      this.setState({facturaDataSelected:facturaDataSelected, mesDataSelected:mesDataSelected, fechaPago:null, fechaPagoE:false, monto:null, montoE:false}, () => {
        if(mesDataSelected.status === "RF" || (!checkPermisos("125") && mesDataSelected.status === "RP")) {
          this.setState({payMonth: [mesDataSelected.mesNumber], monto:mesDataSelected.montoNumber});
        }
        this.getAbonosInfo();
      });
    }
  }

  payFactura = () => {
    const that = this;
    const {dataUser, prodEnv, year, crearNotificacion, cerrarNotificacion, ajaxSource, getData, handleClose, facturacionInfo, tab, checkPermisos} = this.props;
    const {mesDataSelected, facturaDataSelected, monto, montoE, fechaPago, regitrarSinFactura, montoConDescuento} = this.state;

    var registroSinFactura = 0;
    var payMonth = mesDataSelected.mes;
    var userId = dataUser.userId;
    var resId = facturaDataSelected.locatarioId;
    var precioPagar = mesDataSelected.status === "RP" ? monto : mesDataSelected.montoNumber;
    if(montoConDescuento !== "") {
      precioPagar = montoConDescuento;
    }
    var concpCuota = "Mantenimiento";
    if(tab == 1) {
      concpCuota = "Renta";
    }
    var localId = facturaDataSelected.localId;
    var local = facturaDataSelected.local;
    var multiLocal = mesDataSelected.multiLocal;
    var estatus = mesDataSelected.status;
    var pagoFactura = mesDataSelected.status === "RP" ? "No" : "Si";
    var totalMeses = mesDataSelected.totalMeses;
    var meses = mesDataSelected.meses;
    var facturaId = mesDataSelected.facturaId;
    if(meses === undefined) {meses = "";}
    if(facturaId === undefined) {facturaId = "";}
    if(regitrarSinFactura === true) {registroSinFactura = 1;}

    console.log("payMonth: "+payMonth+" || "+"userId: "+userId+" || "+"resId: "+resId+" || "+"precioPagar: "+precioPagar+" || "+"concpCuota: "+concpCuota+" || "+"localId: "+localId+" || "+"local: "+local+" || "+"multiLocal: "+multiLocal+" || "+"estatus: "+estatus+" || "+"pagoFactura: "+pagoFactura+" || "+"fechaPago: "+fechaPago+" || totalMeses:"+totalMeses+" || meses:"+meses+" || facturaId: "+facturaId);

    if(fechaPago === null && ((estatus === "RP" || estatus === "Pendiente" || regitrarSinFactura === true) || (!checkPermisos("125") && (estatus === "RF" || estatus === "Pendiente" || regitrarSinFactura === true)))) {
      this.setState({fechaPagoE:true},()=> {
        crearNotificacion("warning", "Selecciona la fecha del pago.", "bottom", "right");
      });
    } else if(precioPagar === "" || precioPagar === null) {
      this.setState({montoE:true},()=> {
        crearNotificacion("warning", "Ingresa el monto.", "bottom", "right");
      });
    } else  {

      crearNotificacion("info", "Registrando pago...", "bottom", "right", 1000000);

      var y = fechaPago.getFullYear();
      var m = fechaPago.getMonth() + 1;
      if(m < 10){m = "0"+m;}
      var d = fechaPago.getDate();
      if(d < 10){d = "0"+d;}

      var date = y + "-" + m + "-" + d;

      console.log(date);

      getData(ajaxSource, "ecomm/payOrder.php", {
        userId: userId,
        payMonths: payMonth,
        payYear: year,
        montoPagar: precioPagar,
        residenteId: resId,
        option: "pagoEfectivo",
        conceptoCuota: concpCuota,
        casaLocal: localId,
        formaPago: "Transferencia bancaria",
        numeroRecibosFacturas: "Uno",
        pagoFactura: pagoFactura,
        locales: local,
        multiLocal: multiLocal,
        estatus: estatus,
        fechaPago:date,
        totalMeses:totalMeses,
        meses:meses,
        facturaId:facturaId,
        registroSinFactura:registroSinFactura,
        tab:tab
      }).then(function (response) {
        if (response.data) {
          var data = response.data;

          that.setState({disableBtnPay: false}, () => {

            if (data[0].error === 1) {
              cerrarNotificacion();
              facturacionInfo();
              handleClose();
              setTimeout(function () {
                crearNotificacion("success", "Pago registrado con exito.", "bottom", "right", 3000);
              }, 500);
            }
          });
        }
      });
    }
  }

  createFacturaConfirm = (ecommId, montoEcomm) => {
    const that = this;
    const {crearAlerta} = this.props;

    var eId = "";
    var mE = "";
    if(ecommId !== undefined) {
      eId = ecommId;
      mE = montoEcomm;
    }

    this.setState({ecommId:eId, montoEcomm:montoEcomm}, () => {
      crearAlerta("warning", "", "¿Seguro que deseas crear esta factura?", true, that.createFactura);
    });
  }

  createFactura = () => {
    const that = this;
    const {crearAlerta} = this.props;
    const {dataUser, prodEnv, year, crearNotificacion, cerrarNotificacion, ajaxSource, getData, facturacionInfo, cerrarAlerta, tab, dev} = this.props;
    const {mesDataSelected, facturaDataSelected, monto, montoE, fechaPago, montoConDescuento, payMonth, numeroRecibosFacturas, ecommId, montoEcomm} = this.state;
    cerrarAlerta();
    var userId = dataUser.userId;
    var resId = facturaDataSelected.locatarioId;
    var localId = facturaDataSelected.localId;
    var local = facturaDataSelected.local;
    var multiLocal = mesDataSelected.multiLocal;

    if(payMonth.length === 0 && ecommId === "") {
      this.setState({payMonthE:true},()=> {
        crearNotificacion("warning", "Selecciona el(los) mes(es) a pagar.", "bottom", "right");
      });
    } else {
      crearNotificacion("info", "Generando factura...", "bottom", "right", 1000000);

      var m = monto;
      if(montoConDescuento !== "") {
        m = montoConDescuento;
      }

      var eId = "";
      var pM = payMonth;
      if(ecommId !== "") {
        m = montoEcomm;
        eId = ecommId;
        var mes = mesDataSelected.mes;
        var meses = [];
        meses.push(mes);
        pM = meses;
      }

      getData(ajaxSource, "facturacion/crearFactura.php", {
        userId: userId,
        payMonths: pM,
        payYear: year,
        montoPagar: m,
        residenteId: resId,
        casaLocal: localId,
        numeroRecibosFacturas: numeroRecibosFacturas,
        locales: local,
        multiLocal: multiLocal,
        predial:facturaDataSelected.predial,
        ecommId:eId,
        tab:tab,
        dev:dev
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          that.setState({disableBtnPay: false}, () => {
            if (data[0].error === 1) {
              cerrarNotificacion();
              facturacionInfo();
              that.handleClose();
              setTimeout(function () {
                crearNotificacion("success", "Factura generada con exito.", "bottom", "right", 3000);
              }, 500);
            }else{
              let mensajeError = data[0].mensaje || "";
              if (mensajeError) {
                setTimeout(function () {
                  crearAlerta("error", "Error", `Problema al generar factura:${mensajeError}`, false, cerrarAlerta);
                }, 200);
              }
              else {
                setTimeout(function () {
                  crearAlerta("error", "Error", `Problema al generar factura`, false, cerrarAlerta);
                }, 200);
              }
            }
          });
        }
      });
    }
  }

  cancelarFacturaConfirm = (ecommId, facturaId) => {
    const that = this;
    const {crearAlerta} = this.props;

    this.setState({ecommId:ecommId, facturaId:facturaId}, () => {
      crearAlerta("warning", "", "¿Seguro que deseas cancelar esta factura?", true, that.cancelarFactura);
    });
  }

  cancelarFactura = () => {
    const that = this;
    const {dataUser, prodEnv, year, crearNotificacion, cerrarNotificacion, ajaxSource, getData, tab, dev, facturacionInfo, crearAlerta, cerrarAlerta} = this.props;
    const {ecommId, facturaId} = this.state;
    cerrarAlerta();
    var userId = dataUser.userId;

    crearNotificacion("info", "Cancelando la factura...", "bottom", "right", 1000000);

    getData(ajaxSource, "facturacion/cancelarFactura.php", {
      userId: userId,
      ecommId: ecommId,
      facturaId: facturaId,
      dev:dev
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        that.setState({disableBtnPay: false}, () => {

          if (data[0].error === 1) {
            cerrarNotificacion();
            facturacionInfo();
            that.handleClose();
            setTimeout(function () {
              crearNotificacion("success", "Factura cancelada con exito.", "bottom", "right", 3000);
            }, 500);
          } else {
            cerrarNotificacion();
            setTimeout(function () {
              //crearNotificacion("error", "Error al cancelar la factura: "+data[0].error[0], "bottom", "right", 3000);
              crearAlerta("error", "Error al cancelar la factura:", data[0].error[0], false, cerrarAlerta);
            }, 500)
          }
        });
      }
    });
  }

  abonar = () => {
    const that = this;
    const {dataUser, prodEnv, year, crearNotificacion, cerrarNotificacion, ajaxSource, getData, handleClose, facturacionInfo, tab} = this.props;
    const {mesDataSelected, facturaDataSelected, montoAbono, montoRestante, fechaPago} = this.state;
    var facturaId = mesDataSelected.facturaId;
    var userId = dataUser.userId;
    var resId = facturaDataSelected.locatarioId;

    if(fechaPago === null) {
      this.setState({fechaPagoE:true},()=> {
        crearNotificacion("warning", "Selecciona la fecha del pago.", "bottom", "right");
      });
    } else if(montoAbono === "" || montoAbono === 0) {
      this.setState({montoAbonoE:true}, () => {
        crearNotificacion("warning", "Ingresa un monto.", "bottom", "right");
      });
    } else {

      var y = fechaPago.getFullYear();
      var m = fechaPago.getMonth() + 1;
      if(m < 10){m = "0"+m;}
      var d = fechaPago.getDate();
      if(d < 10){d = "0"+d;}

      var date = y + "-" + m + "-" + d;

      console.log(date);

      crearNotificacion("info", "Registrando abono...", "bottom", "right", 1000000);
      getData(ajaxSource, "facturacion/registrarAbono.php", {
        userId: userId,
        residenteId:resId,
        facturaId: facturaId,
        monto: montoAbono,
        fechaPago:date,
        tab:tab
      }).then(function (response) {
        if (response.data) {
          var data = response.data;

          that.setState({disableBtnPay: false}, () => {

            if (data[0].error === 1) {
              that.handleAbono(false);
              cerrarNotificacion();
              facturacionInfo();
              that.getAbonosInfo();

              setTimeout(function () {
                crearNotificacion("success", "Abono registrado con exito.", "bottom", "right", 3000);
                console.log(montoAbono+" == "+montoRestante);
                if(parseFloat(montoAbono) === montoRestante) {
                  that.payFactura();
                }
              }, 500);
            } else {
              crearNotificacion("success", "Ocurrio un error al intentar registrar el abono " + data[0].error, "bottom", "right", 3000);
            }
          });
        }
      });
    }
  }

  getAbonosInfo = () => {
    const that = this;
    const {dataUser, prodEnv, year, crearNotificacion, cerrarNotificacion, ajaxSource, getData, handleClose, facturacionInfo, tab} = this.props;
    const {mesDataSelected, facturaDataSelected, montoAbono} = this.state;
    var facturaId = mesDataSelected.facturaId;
    var userId = dataUser.userId;

    crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);
    getData(ajaxSource, "facturacion/abonosInfo.php", {
      userId: userId,
      facturaId: facturaId,
      tab:tab
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;
        var abonos = data[0];
        var totalAbono = data[1];
        var totalAbonoTxt = data[2];
        var montoRestanteTxt = data[3];
        var montoRestante = data[4];
        that.setState({abonos:abonos, totalAbono:totalAbono, totalAbonoTxt:totalAbonoTxt, montoRestanteTxt:montoRestanteTxt, montoRestante:montoRestante});
      }
    });
  }

  updateRegistro = (dataMonto, dataFecha) => {
    const that = this;
    const {dataUser, prodEnv, year, crearNotificacion, cerrarNotificacion, ajaxSource, getData, handleClose, facturacionInfo, tab} = this.props;
    const { mesDataSelected, facturaDataSelected, monto, fechaPago} = this.state;
    var userId = dataUser.userId;
    var ecommId = mesDataSelected.ecommId;
    var m = monto;
    var f = fechaPago;

    console.log(m);
    console.log(dataMonto);

    if(m === null) {
      m = dataMonto;
    }

    if(f === null) {
      f = dataFecha;
    } else {
      var y = f.getFullYear();
      var m = f.getMonth() + 1;
      if(m < 10){m = "0"+m;}
      var d = f.getDate();
      if(d < 10){d = "0"+d;}

      f = y + "-" + m + "-" + d;
    }

    if(m === "" || m === undefined) {
      this.setState({montoAbonoE:true}, () => {
        crearNotificacion("warning", "Ingresa un monto.", "bottom", "right");
      });
    } else {
      crearNotificacion("info", "Editando monto...", "bottom", "right", 1000000);
      getData(ajaxSource, "facturacion/editarMontoPasado.php", {
        userId: userId,
        ecommId:ecommId,
        monto:m,
        fechaPago:f,
        tab:tab
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          that.setState({disableBtnPay: false}, () => {

            if (data[0].error === 1) {
              facturacionInfo();
              that.handleClose()
              setTimeout(function () {
                crearNotificacion("success", "Monto editado con exito.", "bottom", "right", 3000);
              }, 500);
            } else {
              crearNotificacion("success", "Ocurrio un error al intentar editar el monto " + data[0].error, "bottom", "right", 3000);
            }
          });
        }
      });
    }
  }

  handleAbono = (option) => {
    this.setState({abono:option}, () => {
      if(option === false) {
        this.setState({montoAbono:"", montoAbonoE:false})
      }
    });
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState({montoRestante:"", totalAbonoTxt:"", monto:null, regitrarSinFactura:false, editarRegistro:false, ecommId:"", montoEcomm:""}, () => {
      handleClose();
    })
  }

  handleChange = name => event => {
    if(name === "monto" || name === "descuento") {
      var monto = event.target.value;
      monto = monto.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      console.log(monto);
      this.setState({[name]: monto, [name + 'E']: false}, () => {
        if(name === "descuento") {
          const {mesDataSelected} = this.props;
          const {montoConDescuento, descuento, monto} = this.state;

          if(descuento > 0 && descuento <= 100) {
            var p = Math.round((descuento / 100) * monto);
            this.setState({montoConDescuento: (monto - p).toFixed(2)});
          } else {
            this.setState({montoConDescuento: ""});
          }
        }
      });
    } else {
      this.setState({[name]: event.target.value, [name + 'E']: false}, () => {
        if(name === "payMonth") {
          const {mesDataSelected} = this.props;
          const {monto, payMonth} = this.state;
          var m = mesDataSelected.montoNumber;
          console.log(payMonth);
          if(payMonth.length > 0 ) {
            m = payMonth.length * mesDataSelected.montoNumber;
          }
          this.setState({monto:m, descuento:0, montoConDescuento:""});
          if(payMonth.length === 1) {
            this.setState({numeroRecibosFacturas:"Uno"});
          }
        }
      });
    }
  };

  handleChangeDate = (date) => {
    this.setState({fechaPago:date, fechaPagoE: false });
  }

  handleRegitrarSinFactura = (option) => {
    this.setState({regitrarSinFactura:option});
  }

  handleEditarRegistro = (option) => {
    this.setState({editarRegistro:option});
  }

  render() {
    const that = this;
    const {classes, open, year, prodEnv, monthNumber,checkPermisos} = this.props;
    const {mesDataSelected, facturaDataSelected, disableBtnPay, abono, montoAbono, montoAbonoE, abonos, montoRestante, totalAbonoTxt, montoRestanteTxt, montoE, monto, fechaPago, fechaPagoE, payMonth, payMonthE, descuento, montoConDescuento, numeroRecibosFacturas, regitrarSinFactura, editarRegistro} = this.state;
    const months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

    var m = monto;
    if(montoConDescuento !== "") {
      m = montoConDescuento;
    }
    var path = ""+prodEnv + mesDataSelected.pathXml+"";
    var p = path.replace("/", "__");

    return (
      <MuiThemeProvider theme={DialogT}>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"md"}
          onClose={this.handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="simple-dialog-title">
            <span className="darkGrayUpera">{
              mesDataSelected.pathPdf !== "-" ? "Factura" : (mesDataSelected.status === "RP" || mesDataSelected.status === "RF") && "Registrar Pago"
            }</span>
            <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', top:'10px'}} onClick={()=>this.handleClose()}>
              <CloseIcon className="darkGrayUpera"/>
            </IconButton>
          </DialogTitle>
          <div style={{width:'100%', padding:'20px'}}>
            <MuiThemeProvider theme={FormInputs}>
              <div className="row" style={{margin: '0px', width: '100%'}}>
                {checkPermisos("125") &&
                  <div className="col-md-4" style={{borderRight: '1px solid #eee'}}>
                    {
                      mesDataSelected.pathPdf !== "-" ?
                        <>
                          <div style={{width: '100%', textAlign: 'center'}}>
                            <a href={prodEnv + mesDataSelected.pathPdf} target="_blank"><img
                              src="assets/img/iconos/files/png/pdf.png" style={{width: '80px'}}/></a>
                          </div>
                          <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
                            <a
                              href={prodEnv + "ecomm/downloadXml.php?path=" + p + "&mes=" + mesDataSelected.mes + "&year=" + year + "&name=XML"}
                              target="_blank"><img src="assets/img/iconos/files/png/xml.png"
                                                   style={{width: '80px'}}/></a>
                          </div>
                          {mesDataSelected.status === "Pendiente" &&
                          <MuiThemeProvider theme={FormButtons}>
                            <Button disabled={disableBtnPay} variant="contained" fullWidth={true} color="secondary"
                                    className={classes.button}
                                    onClick={() => that.cancelarFacturaConfirm(mesDataSelected.ecommId, mesDataSelected.facturaId)}
                                    style={{marginTop: '30px'}}>
                              Cancelar factura
                            </Button>
                          </MuiThemeProvider>
                          }
                        </>
                        :
                        <div style={{width: '100%', textAlign: 'center'}}>
                          Sin Factura
                        </div>
                    }
                  </div>
                }
                <div className={checkPermisos("125") ? "col-md-8" : "col-md-12"}>
                  <table style={{width:'100%'}}>
                    <tr style={{borderBottom:'1px solid #eee'}}>
                      <td style={{padding:'5px 0px'}}>
                        <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>
                          {checkPermisos("125") ? "Locales" : "Casa"}
                        </h3>
                      </td>
                      <td style={{padding:'5px 0px'}}>
                        {facturaDataSelected.local}
                      </td>
                    </tr>
                    <tr style={{borderBottom:'1px solid #eee'}}>
                      <td style={{padding:'5px 0px'}}>
                        <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Mes:</h3>
                      </td>
                      <td style={{padding:'5px 0px'}}>
                        {(mesDataSelected.totalMeses !== "" && mesDataSelected.totalMeses !== null) ? mesDataSelected.meses : mesDataSelected.mes}
                      </td>
                    </tr>
                    {
                      (mesDataSelected.status !== "RP" && mesDataSelected.status !== "RF") &&
                      <>
                        <tr style={{borderBottom:'1px solid #eee'}}>
                          <td style={{padding:'5px 0px'}}>
                            <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Estatus:</h3>
                          </td>
                          <td style={{padding:'5px 0px'}}>
                            {mesDataSelected.status}
                          </td>
                        </tr>
                        { mesDataSelected.status === "Pagado" &&
                        <tr style={{borderBottom:'1px solid #eee'}}>
                          <td style={{padding:'5px 0px'}}>
                            <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Fecha Pago:</h3>
                          </td>
                          <td style={{padding:'5px 0px'}}>
                            {
                              (mesDataSelected.status === "Pagado" && mesDataSelected.pathPdf === "-" && editarRegistro === true) ?
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                  <DatePicker
                                    style={{width:'100%', marginTop: '16px', marginBottom: '8px'}}
                                    inputVariant="outlined"
                                    autoOk
                                    label="Fecha del pago"
                                    clearable
                                    disableFuture
                                    value={fechaPago}
                                    format="dd/MM/yyyy"
                                    onChange={date => this.handleChangeDate(date)}
                                    cancelLabel="Cancelar"
                                    clearLabel="Limpiar"
                                  />
                                </MuiPickersUtilsProvider>
                                :
                                mesDataSelected.fechaPagoRegistroManual
                            }
                          </td>
                        </tr>
                        }
                        <tr style={{borderBottom:'1px solid #eee'}}>
                          <td style={{padding:'5px 0px'}}>
                            <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Monto:</h3>
                          </td>
                          <td style={{padding:'5px 0px'}}>
                            {
                              (mesDataSelected.status === "Pagado" && mesDataSelected.pathPdf === "-") ?
                                <>
                                  <TextField
                                    disabled={!editarRegistro}
                                    autoFocus={true}
                                    error={montoE}
                                    label="Monto"
                                    placeholder="$$$"
                                    id="monto_nuevo"
                                    onChange={this.handleChange('monto')}
                                    value={monto !== null ? monto : mesDataSelected.montoNumber}
                                    margin="normal"
                                    variant="outlined"
                                    style={{width:'100%'}}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                  <div>
                                    {editarRegistro === true ?
                                      <>
                                        <MuiThemeProvider theme={FormButtons}>
                                          <Button disabled={disableBtnPay} variant="contained" color="primary" className={classes.button} onClick={() => that.updateRegistro(mesDataSelected.montoNumber,  mesDataSelected.fechaPagoRegistroManual)}>
                                            Actualizar
                                          </Button>
                                        </MuiThemeProvider>
                                        <MuiThemeProvider theme={FormButtons}>
                                          <Button disabled={disableBtnPay} variant="contained" color="default" className={classes.button} onClick={() => that.handleClose()}>
                                            Cancelar
                                          </Button>
                                        </MuiThemeProvider>
                                      </>
                                      :
                                      <>
                                      <MuiThemeProvider theme={FormButtons}>
                                        <Button disabled={disableBtnPay} variant="contained" color="default" className={classes.button} onClick={() => that.handleEditarRegistro(true)}>
                                          Editar
                                        </Button>
                                      </MuiThemeProvider>
                                      {(mesDataSelected.registroSinFactura === "1" && editarRegistro === false && facturaDataSelected.usuarioActivo === "1" && facturaDataSelected.usuarioFactura === "Si") &&
                                      <MuiThemeProvider theme={FormButtons}>
                                        <Button disabled={disableBtnPay} variant="contained" color="primary"  className={classes.button} onClick={() => that.createFacturaConfirm(mesDataSelected.ecommId, mesDataSelected.montoNumber)}>
                                          Generar factura
                                        </Button>
                                      </MuiThemeProvider>
                                      }
                                      </>
                                    }

                                  </div>
                                </> :
                              mesDataSelected.monto
                            }
                          </td>
                        </tr>
                      </>
                    }
                    {
                      (mesDataSelected.status === "RF" || (!checkPermisos("125") && mesDataSelected.status === "RP")) &&
                      <tr style={{borderBottom:'1px solid #eee'}}>
                        <td style={{padding:'5px 0px'}}>
                          <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Monto:</h3>
                        </td>
                        <td style={{padding:'5px 0px'}}>
                          ${m}
                        </td>
                      </tr>
                    }
                    {(totalAbonoTxt !== "" && Object.keys(abonos).length > 0 && montoRestante > 0) &&
                    <tr style={{borderBottom:'1px solid #eee'}}>
                      <td style={{padding:'5px 0px'}}>
                        <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Total abonado:</h3>
                      </td>
                      <td style={{padding:'5px 0px'}}>
                        {totalAbonoTxt}
                      </td>
                    </tr>
                    }
                    {(montoRestanteTxt !== "" && Object.keys(abonos).length > 0 && montoRestante > 0) &&
                    <tr style={{borderBottom:'1px solid #eee'}}>
                      <td style={{padding:'5px 0px'}}>
                        <h3 style={{margin:'0px', fontSize:'18px', fontWeight:'800'}}>Monto restante:</h3>
                      </td>
                      <td style={{padding:'5px 0px'}}>
                        {montoRestanteTxt}
                      </td>
                    </tr>
                    }
                  </table>
                  {((mesDataSelected.status === "RP" || regitrarSinFactura === true) || (!checkPermisos("125") && (mesDataSelected.status === "RF" || regitrarSinFactura === true))) &&
                  <div className="row" style={{width:'100%', marginTop:'20px'}}>
                    <div className="col-md-6">
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <DatePicker
                          style={{width:'100%', marginTop: '16px', marginBottom: '8px'}}
                          inputVariant="outlined"
                          autoOk
                          label="Fecha del pago"
                          clearable
                          disableFuture
                          value={fechaPago}
                          format="dd/MM/yyyy"
                          onChange={date => this.handleChangeDate(date)}
                          cancelLabel="Cancelar"
                          clearLabel="Limpiar"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-6">
                      {checkPermisos("125") &&
                      <TextField
                        error={montoE}
                        label="Monto"
                        placeholder="$$$"
                        id="monto"
                        onChange={this.handleChange('monto')}
                        value={monto}
                        margin="normal"
                        variant="outlined"
                        style={{width: '100%'}}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      }
                    </div>
                  </div>
                  }
                  { ((checkPermisos("125") && facturaDataSelected.usuarioActivo === "1" && facturaDataSelected.usuarioFactura === "Si" && mesDataSelected.status === "RF" && regitrarSinFactura === false) || (!checkPermisos("125") && (mesDataSelected.status === "RP" || mesDataSelected.status === "RF") && regitrarSinFactura === false )) &&
                  <div className="row" style={{width:'100%', marginTop:'20px'}}>
                    <div className="col-md-6">
                      <FormControl disabled={!checkPermisos("125") ? true : false} variant="outlined" className={classes.formControl} error={payMonthE}>
                        <InputLabel
                          ref={ref => {
                            this.InputLabelRef = ref;
                          }}
                          htmlFor="outlined-months"
                          style={{
                            background: 'inherit',
                            padding: '0px'
                          }}
                        >
                          Mes a pagar
                        </InputLabel>
                        <Select
                          multiple
                          style={{width: '100%'}}
                          value={payMonth}
                          onChange={this.handleChange('payMonth')}
                          input={<OutlinedInput labelWidth={90} id="outlined-months" error={payMonthE}/>}
                        >
                          {months.map(function (arr, index) {
                            if(index >= mesDataSelected.mesNumber) {
                              return (
                                <MenuItem key={index} value={index}>
                                  {months[index]}
                                </MenuItem>
                              )
                            }
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="% Descuento"
                        placeholder="%%%"
                        id="descuento"
                        value={descuento}
                        margin="normal"
                        variant="outlined"
                        style={{width: '100%'}}
                        type="number"
                        onChange={this.handleChange("descuento")}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      {(payMonth.length > 1 && checkPermisos("125")) &&
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                          ref={ref => {
                            this.InputLabelRef = ref;
                          }}
                          htmlFor="numeroRecibosFacturas"
                          style={{
                            background: '#FFF',
                            padding: '0px'
                          }}
                        >
                          ¿Factura por mes?
                        </InputLabel>
                        <Select
                          value={numeroRecibosFacturas}
                          onChange={this.handleChange('numeroRecibosFacturas')}
                          input={
                            <OutlinedInput id="formaPago" labelWidth={110}/>
                          }
                        >
                          <MenuItem value="Uno">Una factura por mes</MenuItem>
                          <MenuItem value="Todos">Una factura por todos los meses</MenuItem>
                        </Select>
                      </FormControl>
                      }
                    </div>
                  </div>
                  }
                  <div style={{width:'100%', marginTop:'20px'}}>
                    {mesDataSelected.status === "Pendiente" &&
                      <>
                        {(!abono && abonos.length === 0) &&
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <DatePicker
                                  style={{width:'100%', marginTop: '0px', marginBottom: '0px'}}
                                  inputVariant="outlined"
                                  autoOk
                                  label="Fecha del pago"
                                  clearable
                                  disableFuture
                                  value={fechaPago}
                                  format="dd/MM/yyyy"
                                  onChange={date => this.handleChangeDate(date)}
                                  cancelLabel="Cancelar"
                                  clearLabel="Limpiar"
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-md-6">
                              <MuiThemeProvider theme={FormButtons}>
                                <Button disabled={disableBtnPay} variant="contained" fullWidth={true} color="primary"  className={classes.button} onClick={() => that.payFactura()}>
                                  Registrar pago completo
                                </Button>
                              </MuiThemeProvider>
                              {!abono &&
                              <MuiThemeProvider theme={FormButtons}>
                                <Button disabled={disableBtnPay} variant="contained" fullWidth={true} color="default" className={classes.button} onClick={() => that.handleAbono(true)}>
                                  Abonar
                                </Button>
                              </MuiThemeProvider>
                              }
                            </div>
                          </div>
                        </>
                        }
                        {(!abono && abonos.length > 0) &&
                        <div className="row">
                          <div className="col-md-4">
                            <MuiThemeProvider theme={FormButtons}>
                              <Button disabled={disableBtnPay} variant="contained" color="default" className={classes.button} onClick={() => that.handleAbono(true)}>
                                Abonar
                              </Button>
                            </MuiThemeProvider>
                          </div>
                        </div>
                        }
                      </>
                    }
                    {((mesDataSelected.status === "RP" || regitrarSinFactura === true) || (!checkPermisos("125") && (mesDataSelected.status === "RF" || regitrarSinFactura === true))) &&
                      <div>
                        <MuiThemeProvider theme={FormButtons}>
                          <Button disabled={disableBtnPay} variant="contained" color="primary"  className={classes.button} onClick={() => that.payFactura()}>
                            Registrar pago
                          </Button>
                        </MuiThemeProvider>
                        {regitrarSinFactura === true &&
                        <MuiThemeProvider theme={FormButtons}>
                          <Button style={{marginLeft:'5px'}} disabled={disableBtnPay} variant="contained" color="default"  className={classes.button} onClick={() => that.handleRegitrarSinFactura(false)}>
                            Cancelar
                          </Button>
                        </MuiThemeProvider>
                        }
                      </div>
                    }
                    {(mesDataSelected.status === "RF" && regitrarSinFactura === false && checkPermisos("125")) &&
                    <div>
                      { (facturaDataSelected.usuarioActivo === "1" && facturaDataSelected.usuarioFactura === "Si") &&
                        <MuiThemeProvider theme={FormButtons}>
                          <Button disabled={disableBtnPay} variant="contained" color="primary"
                                  className={classes.button} onClick={() => that.createFacturaConfirm()}>
                            Generar factura
                          </Button>
                        </MuiThemeProvider>
                      }
                      <MuiThemeProvider theme={FormButtons}>
                        <Button style={{marginLeft:'5px'}} disabled={disableBtnPay} variant="contained" color="default"  className={classes.button} onClick={() => that.handleRegitrarSinFactura(true)}>
                          Registrar pago sin factura
                        </Button>
                      </MuiThemeProvider>
                    </div>
                    }
                  </div>
                </div>
              </div>
              {abono &&
              <div className="row" style={{margin: '0px', width: '100%', marginTop:'20px'}}>
                <div className="col-md-3">
                  <TextField
                    error={montoAbonoE}
                    label="Abono"
                    placeholder="$$$"
                    id="montoAbono"
                    value={montoAbono}
                    margin="normal"
                    variant="outlined"
                    style={{width:'100%'}}
                    onChange={that.handleChange('montoAbono')}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker
                      style={{width:'100%', marginTop: '16px', marginBottom: '0px'}}
                      inputVariant="outlined"
                      autoOk
                      label="Fecha del pago"
                      clearable
                      disableFuture
                      value={fechaPago}
                      format="dd/MM/yyyy"
                      onChange={date => this.handleChangeDate(date)}
                      cancelLabel="Cancelar"
                      clearLabel="Limpiar"
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-md-5">
                  <MuiThemeProvider theme={FormButtons}>
                    <Button variant="contained" color="default" className={classes.button} style={{marginTop:'22px'}} onClick={() => this.handleAbono(false)}>
                      Cancelar
                      <CancelIcon className={classes.rightIcon}/>
                    </Button>
                  </MuiThemeProvider>
                  <MuiThemeProvider theme={FormButtons}>
                    <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={() => this.abonar()}>
                      Abonar
                    </Button>
                  </MuiThemeProvider>
                </div>
              </div>
              }
              {Object.keys(abonos).length > 0 &&
              <div className="row" style={{margin: '0px', width: '100%', marginTop:'20px'}}>
                <span
                  style={{
                    fontSize: '1.25rem',
                    fontFamily: 'Poppins Regular',
                    fontWeight: '500',
                    lineHeight: '1.6',
                    width:'100%'
                  }}
                  className="darkGrayUpera">Abonos</span>
                {
                  abonos.map(function (arr, index) {
                    return(
                      <>
                        <div className="col-md-4">
                          <TextField
                            disabled={true}
                            label="Abono"
                            placeholder="$$$"
                            id="montoAbono"
                            value={arr.montoText}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            disabled={true}
                            label="Fecha del abono"
                            placeholder=""
                            id="montoAbono"
                            value={arr.fechaPago}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            disabled={true}
                            label="Fecha del registro"
                            placeholder=""
                            id="montoAbono"
                            value={arr.fechaRegistro}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </>
                    )
                  })
                }
              </div>
              }
            </MuiThemeProvider>
          </div>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogFacturaInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogFacturaInfo);