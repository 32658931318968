import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import CamerIcon from '@material-ui/icons/Camera';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Button from "@material-ui/core/Button";
import QrReader from 'react-qr-reader'
import Loader from "../../commons/Loader";
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#e4e3e1',
    color: '#737373 !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});
const Table = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      root: {
        elevation:'none !important'
      },
    },
  },
});

class DivAccesosQR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeScanner:false,
      qrId:"",
      disableBtn:false,
      loading:false,
      datosVisita:[],
      dataSet:false,
      ultimosAccesos:[]
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getLastQrs();
    }
  }

  activarEscaner = () => {
    this.setState({activeScanner:true, disableBtn:true})
  }

  handleScan = data => {
    if (data) {
      console.log(data);
      this.setState({qrId: data, activeScanner:false, loading:true}, ()=> {
        this.getQrInfo();
      });
    }
  }

  handleError = err => {
    this.setState({activeScanner:false, disableBtn:false});
    console.error(err)
  }

  getLastQrs = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, loadedPage} = this.props;
    const {qrId} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);

    getData(ajaxSource, "accesoVisitas/getInfoLastAccesos.php",{
      userId:userId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        loadedPage(true);
        self.setState({loading:false, disableBtn:false});
        var data = response.data;
        var ultimosAccesos = data[0];

        self.setState({ultimosAccesos:ultimosAccesos});
      }
    })
  }

  getQrInfo = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion} = this.props;
    const {qrId} = this.state;
    var userId = dataUser.userId;

    getData(ajaxSource, "accesoVisitas/getInfoAccesoVisitaByQR.php",{
      userId:userId,
      qrId:qrId,
    }).then(function (response) {
      if (response.data) {
        self.setState({loading:false, disableBtn:false});
        var data = response.data;
        var datosVisita = data[1];

        if(data[0].error === 1) {
          self.setState({datosVisita:datosVisita, dataSet:true});
        } else if(data[0].error === 2) {
          self.setState({datosVisita:[], dataSet:false});
          crearNotificacion("error", "El codigo QR de acceso no existe.", "bottom", "right", 3000);
        }
        console.log(data);
      }
    })
  }

  confirmarAcceso = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    const {qrId} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Confirmando Acceso...", "bottom", "right", 1000000);

    //https://upera.mx/accesoVisitas/confirmarAcesso.php
    getData(ajaxSource, "accesoVisitas/confirmarAcceso.php",{
      userId:userId,
      qrId:qrId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();

        self.setState({loading:false, disableBtn:false, dataSet:false});
        var data = response.data;

        if(data[0].error === 1) {
          crearNotificacion("success", "Acceso confirmado con exito.", "bottom", "right", 3000);
          self.getLastQrs();
        } else {
          crearNotificacion("error", "Hubo un problema al confirmar el acceso " + data[0].error, "bottom", "right", 3000);
        }
        console.log(data);
      }
    })
  }

  confirmarSalida = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    const {qrId} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Confirmando Salida...", "bottom", "right", 1000000);

    //https://upera.mx/accesoVisitas/confirmarAcesso.php
    getData(ajaxSource, "accesoVisitas/confirmarSalida.php",{
      userId:userId,
      qrId:qrId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();

        self.setState({loading:false, disableBtn:false, dataSet:false});
        var data = response.data;

        if(data[0].error === 1) {
          crearNotificacion("success", "Salida confirmada con exito.", "bottom", "right", 3000);
          self.getLastQrs();
        } else {
          crearNotificacion("error", "Hubo un problema al confirmar la salida " + data[0].error, "bottom", "right", 3000);
        }
        console.log(data);
      }
    })
  }

  renderScanner = () => {
    const {activeScanner} = this.state;

    if(activeScanner === true) {
      return (
        <div style={{width:'100%', flexDirection:'row'}}>
          <div style={{width:'100%', margin:'auto', marginTop:'20px', borderRadius: '8px'}}>
            <QrReader
              delay={300}
              resolution={500}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '100%', borderRadius:'11px' }}
            />
          </div>
        </div>
      )
    } else {
      return(
        <div style={{width:'100%', flexDirection:'row'}}>
          <img src="assets/img/iconos/icono_accesos_qr.png" style={{width:'200px', height:'200px', marginTop:'20px',}}/>
        </div>
      )
    }
  }

  renderResults = () => {
    const classes = this.props;
    const {loading, datosVisita, dataSet} = this.state;

    if(loading === true) {
      return(
        <div style={{marginTop:'20px'}}>
          <Loader/>
        </div>
      )
    } else if(dataSet === true) {

      var btnInfo = "";
      if(datosVisita.tipoAcceso !== "Residente") {
        if (datosVisita.tipoAcceso !== 'Personalizado' && datosVisita.qrEstatus === '0' && datosVisita.acceso === 1) {
          btnInfo = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}}
                            onClick={() => this.confirmarAcceso()}>
            Confirmar Acceso
            <CheckIcon className={classes.rightIcon}/>
          </Button>
        } else if (datosVisita.qrEstatus === '1' && datosVisita.acceso === 1) {
          btnInfo = <Button variant="contained" color="secondary" className={classes.button} style={{marginTop: '22px'}}
                            onClick={() => this.confirmarSalida()}>
            Confirmar Salida
            <CheckIcon className={classes.rightIcon}/>
          </Button>
        } else if (datosVisita.tipoAcceso === 'Personalizado' && (datosVisita.qrEstatus === '0' || datosVisita.qrEstatus === '2') && datosVisita.acceso === 1) {
          btnInfo = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}}
                            onClick={() => this.confirmarAcceso()}>
            Confirmar Acceso
            <CheckIcon className={classes.rightIcon}/>
          </Button>
        } else if (datosVisita.tipoAcceso === 'Vigencia anual 24/7' && (datosVisita.qrEstatus === '0' || datosVisita.qrEstatus === '2') && datosVisita.acceso === 1) {
          btnInfo = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}}
                            onClick={() => this.confirmarAcceso()}>
            Confirmar Acceso
            <CheckIcon className={classes.rightIcon}/>
          </Button>
        } else if (datosVisita.tipoAcceso === 'Personalizado' && datosVisita.qrEstatus === '0' && datosVisita.acceso === 0) {
          btnInfo =
            <Button variant="contained" color="secondary" className={classes.button} style={{marginTop: '22px'}}>
              QR fuera de horario
            </Button>
        } else if (datosVisita.qrEstatus === '2' && datosVisita.acceso === 0) {
          btnInfo =
            <Button variant="contained" color="secondary" className={classes.button} style={{marginTop: '22px'}}>
              El QR ya no es valido
            </Button>
        }
      } else {
        if ((datosVisita.qrEstatus === '0' || datosVisita.qrEstatus === '2') && datosVisita.acceso === 1) {
          btnInfo = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}}
                            onClick={() => this.confirmarAcceso()}>
            Confirmar Acceso
            <CheckIcon className={classes.rightIcon}/>
          </Button>
        } else if (datosVisita.qrEstatus === '1' && datosVisita.acceso === 1) {
          btnInfo = <Button variant="contained" color="secondary" className={classes.button} style={{marginTop: '22px'}}
                            onClick={() => this.confirmarSalida()}>
            Confirmar Salida
            <CheckIcon className={classes.rightIcon}/>
          </Button>
        } else if (datosVisita.acceso === 0) {
          btnInfo = <Button variant="contained" color="secondary" className={classes.button} style={{marginTop: '22px'}}>
            Falta de pago sin servicio de acceso
          </Button>
        }
      }

      return(
        <div style={{width:'100%', marginTop:'30px'}}>
          {datosVisita.tipoAcceso !== "Residente" &&
          <p><font style={{color: '#737373', fontSize: '18px'}}>Nombre de la visita:</font> <font
            style={{fontSize: '18px'}}>{datosVisita.nombreVisitante}</font></p>
          }
          {datosVisita.tipoAcceso === "Residente" &&
          <p><font style={{color: '#737373', fontSize: '18px'}}>Nombre del residente:</font> <font
            style={{fontSize: '18px'}}>{datosVisita.nombreVisitante}</font></p>
          }
          {datosVisita.tipoAcceso !== "Residente" &&
          <p><font style={{color: '#737373', fontSize: '18px'}}>Tipo de la visita:</font> <font
            style={{fontSize: '18px'}}>{datosVisita.tipoVisita}</font></p>
          }
          {datosVisita.tipoAcceso !== "Residente" &&
          <p><font style={{color: '#737373', fontSize: '18px'}}>Descripción del auto:</font> <font
            style={{fontSize: '18px'}}>{datosVisita.descripcionAuto}</font></p>
          }
          <p><font style={{color:'#737373', fontSize:'18px'}}>Casa:</font> <font style={{fontSize:'18px'}}>{datosVisita.casa}</font></p>
          <p><font style={{color:'#737373', fontSize:'18px'}}>Acceso:</font> <font style={{fontSize:'18px'}}>{datosVisita.tipoAcceso}</font></p>
          {datosVisita.tipoAcceso === "Personalizado" &&
          <>
          <p><font style={{color:'#737373', fontSize:'18px'}}>Dias permitidos:</font> <font style={{fontSize:'18px'}}>{datosVisita.diasAcceso}</font></p>
          <p><font style={{color:'#737373', fontSize:'18px'}}>Horas permitidas:</font> <font style={{fontSize:'18px'}}>{datosVisita.horasAcceso}</font></p>
          </>
          }
          <MuiThemeProvider theme={FormButtons}>
            {btnInfo}
          </MuiThemeProvider>
        </div>
      )
    }
  }

  renderUltimosAccesos = () => {
    const {dataUser} = this.props;
    const {ultimosAccesos} = this.state;

    if (Object.keys(ultimosAccesos).length > 0) {
      return (
        <div className="col-md-12">
          <MuiThemeProvider theme={Table}>
            <DataMaterialTableNE
              dataUser={dataUser}
              title={""}
              data={ultimosAccesos}
              columns={[
                {title: 'Tipo Acceso', field: 'tipoAcceso', cellStyle: {textAlign: 'center'}},
                {title: 'Casa', field: 'casa', cellStyle: {textAlign: 'center'}},
                {title: 'Visitante', field: 'nombreVisitante', cellStyle: {textAlign: 'center'}},
                {title: 'Tipo Visita', field: 'tipoVisita', cellStyle: {textAlign: 'center'}},
                {title: 'Auto', field: 'descripcionAuto', cellStyle: {textAlign: 'center'}},
                {
                  title: 'Estatus', field: 'qrEstatus', cellStyle: {textAlign: 'center'}, render: rowData => {

                    var estatus = "Entrada";
                    var colorStatus = "#ffc107";
                    if (rowData.qrEstatus === "2") {
                      estatus = "Salida";
                      colorStatus = "#558b2f";
                    }

                    return (
                      <div>
                        <font style={{color:colorStatus}}>{estatus}</font>
                      </div>
                    )
                  },
                },
                {title: 'Hora', field: 'fechaAcceso', cellStyle: {textAlign: 'center'}},
              ]}
            />
          </MuiThemeProvider>
        </div>
      )
    } else {
      return (
        <div className="col-md-12" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="text-center" style={{marginTop: '40px'}}>
            <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
            <h4>Sin accesos</h4>
          </div>
        </div>
      )
    }
  }

  render() {
    const self = this;
    const {classes} = this.props;
    const {disableBtn} = this.state;

    return (
      <div className="row" style={{paddingBottom:'20px', margin: '0px'}}>
        <div className="col-md-12 mtM">
          <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Accesos QR</h3>
        </div>
        <div className="col-md-4" style={{textAlign:'center'}}>
          <MuiThemeProvider theme={FormButtons}>
            <Button variant="contained" color="primary" disabled={disableBtn} className={classes.button} style={{marginTop: '22px'}} onClick={() => this.activarEscaner()}>
              Activar escaner
              <CamerIcon className={classes.rightIcon}/>
            </Button>
          </MuiThemeProvider>
          {this.renderScanner()}
        </div>
        <div className="col-md-8">
          {this.renderResults()}
        </div>
        <div className="col-md-12 mtM" style={{marginTop:'30px'}}>
          <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Ultimos Accesos</h3>
        </div>
        {this.renderUltimosAccesos()}
      </div>
    )
  }
}

DivAccesosQR.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivAccesosQR);
