import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DowloadIcon from '@material-ui/icons/CloudDownloadRounded';
import BallotIcon from '@material-ui/icons/BallotRounded';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import DataMaterialTable from "../administracion/DivAreas";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DivMisPagos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:""
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleRecibosYear, userRecibos, cerrarNotificacion} = this.props;
    if(nextProps.handleRecibosYear !== handleRecibosYear) {
      if(Object.keys(userRecibos).length === Object.keys(nextProps.userRecibos).length) {
        cerrarNotificacion();
      }
    }
  }

  onloadFrames = () => {
    const {cerrarNotificacion} = this.props;
    cerrarNotificacion();
  }

  renderMisRecibos = () => {
    const self = this;
    const {classes, ajaxSource, userRecibos, dataUser, prodEnv} = this.props;
    var residencialPlaza = dataUser.residencialPlaza;

    if (Object.keys(userRecibos).length > 0) {

      if(residencialPlaza === "residencial") {

        return (
          <div className="row" style={{width: '100%', margin: '0px'}}>
            {userRecibos.map(function (arr, index) {

              var path = ""+prodEnv + arr.pathRecibo+"";
              var p = path.replace("/", "__");

              if(arr.payMonth !== "no existe") {
                return (
                  <div key={index} className="col-md-4">
                    <h3 className="darkGrayUpera">{arr.payMonth}</h3>
                    <div style={{width: '100%'}} className="text-center">
                      <iframe
                        src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + arr.pathRecibo}
                        style={{width: '100%', height: '80%', border: '4px solid #737373', borderRadius: '4px'}}
                        onLoad={self.onloadFrames}/>
                      <MuiThemeProvider theme={FormButtons}>
                        <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '5px'}} href={prodEnv + "ecomm/downloadPdf.php?path=" + p + "&mes=" + arr.payMonth + "&year=" + arr.payYear + "&name=PDF"}>
                          Descargar Recibo
                          <DowloadIcon className={classes.rightIcon}/>
                        </Button>
                      </MuiThemeProvider>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        )
      } else {
        return(
          <div className="row" style={{width: '100%', margin: '0px'}}>
            <div className="col-md-12">
              <DataMaterialTableNE
                dataUser={dataUser}
                title={"Facturas"}
                data={userRecibos}
                columns={[
                  { title: 'ID', field: 'areaId', editable: 'never', hidden:true},
                  { title: 'Mes', field: 'payMonth', cellStyle: {textAlign: 'center'}},
                  { title: 'Local', field: 'casaLocal', cellStyle: {textAlign: 'center'}},
                  { title: 'Estatus', field: 'estatus', cellStyle: {textAlign: 'center'},
                    render: rowData => {

                      var colorStatus = "#558b2f";
                      if(rowData.estatus === "Pendiente") {
                        colorStatus = "#ffc107";
                      }
                      if(rowData.estatus === "Vencido") {
                        colorStatus = "#b71c1c";
                      }

                      return(<span style={{color:colorStatus}}>{rowData.estatus}</span>)
                    },
                  },
                  {
                    title: 'Factura',
                    field: 'pathPdf',
                    cellStyle: {textAlign: 'center'},
                    render: rowData => {
                      return(<a href={prodEnv + rowData.pathPdf} target="_blank"><img src="assets/img/iconos/files/png/pdf.png" style={{width:'40px'}}/></a>)
                    },
                  },
                  {
                    title: 'XML',
                    field: 'pathXml',
                    cellStyle: {textAlign: 'center'},
                    render: rowData => {

                      var path = ""+prodEnv + rowData.pathXml+"";
                      var p = path.replace("/", "__");

                      return(<a href={prodEnv+"ecomm/downloadXml.php?path="+p+"&mes="+rowData.payMonth+"&year="+rowData.payYear+"&name=XML"} target="_blank"><img src="assets/img/iconos/files/png/xml.png" style={{width:'40px'}}/></a>)
                    },
                  },
                  {
                    title: 'Recibo',
                    field: 'pathReciboPago',
                    cellStyle: {textAlign: 'center'},
                    render: rowData => {
                      if(rowData.pathReciboPago !== null) {
                        return(<a href={prodEnv + rowData.pathReciboPago} target="_blank"><img src="assets/img/iconos/files/png/pdf.png" style={{width:'40px'}}/></a>)
                      } else {
                        return(<div></div>)
                      }
                    },
                    },
                  { title: 'Factura complemento',
                    field: 'pathComplementoPdf',
                    cellStyle: {textAlign: 'center'},
                    render: rowData => {
                      if(rowData.pathComplementoPdf !== null) {
                        return(<a href={prodEnv + rowData.pathComplementoPdf} target="_blank"><img src="assets/img/iconos/files/png/pdf.png" style={{width:'40px'}}/></a>)
                      } else {
                        return(<div></div>)
                      }
                    },
                  },
                  {
                    title: 'XML Complmento',
                    field: 'pathComplementoXml',
                    ellStyle: {textAlign: 'center'},
                    render: rowData => {

                      var path = ""+prodEnv + rowData.pathComplementoXml+"";
                      var p = path.replace("/", "__");

                      if(rowData.pathComplementoXml !== null) {
                        return(<a href={prodEnv+"ecomm/downloadXml.php?path="+p+"&mes="+rowData.payMonth+"&year="+rowData.payYear+"&name=XML"} target="_blank"><img src="assets/img/iconos/files/png/xml.png" style={{width:'40px'}}/></a>)
                      } else {
                        return(<div></div>)
                      }
                    },
                  },
                  { title: 'Forma de pago', field: 'formaPago', cellStyle: {textAlign: 'center'}},
                ]}
              />
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="text-center" style={{marginTop:'40px', width: '100%'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
          <h4>Sin recibos de pago</h4>
        </div>
      )
    }
  }

  render() {
    const {classes, dataUser, recibosYear, recibosYearE, handleChange} = this.props;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;

    return (
      <MuiThemeProvider theme={FormInputs}>
        <form id="formDivPago">
          <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
            <div className="col-md-12">
              <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
            </div>
            <div className="col-md-4" style={{marginTop:'20px'}}>
              <FormControl variant="outlined" className={classes.formControl} error={recibosYearE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="recibosYear"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  Año
                </InputLabel>
                <Select
                  value={recibosYear}
                  onChange={handleChange('recibosYear')}
                  input={
                    <OutlinedInput id="recibosYear" labelWidth={0}/>
                  }
                >
                  <MenuItem value={nextYear}>{nextYear}</MenuItem>
                  <MenuItem value={actualYear}>{actualYear}</MenuItem>
                  <MenuItem value={passYear}>{passYear}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row" style={{paddingTop:'20px', margin:'0px'}}>
            {this.renderMisRecibos()}
          </div>
        </form>
      </MuiThemeProvider>
    );
  }
}

DivMisPagos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivMisPagos);
