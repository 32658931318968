/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowIcon from '@material-ui/icons/ArrowForwardRounded';
import ResetIcon from '@material-ui/icons/RestoreRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Slide from '@material-ui/core/Slide';
import Loader from "./commons/Loader";

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: '50px',
  },
  rightIconSmall: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  h1: {
    fontSize: '80px',
    marginBottom: '3vh',
    color:'#272936'
  },
  h1Mobile: {
    fontSize: '55px',
    marginBottom: '3vh',
    color:'#272936'
  },
  h3: {
    marginBottom: '3vh'
  },
  widthIcon: {
    width: '100px',
    marginLeft: '20px'
  }
});

const LoginInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'rgba(255,255,255,0.5) !important',
        padding: '1px 1px 5px 10px',
        marginTop: '-11px'
      },
    },
    MuiInput: {
      input: {
        color: '#bfc2cb !important',
        padding: '6px 3px 4px 8px'
      },
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none'
        },
        '&:before': {
          borderBottom: 'none'
        }
      },
    },
    MuiFormControl: {
      root: {
        background: 'rgba(0,0,0,0.5)',
        borderRadius: '8px',
        height: '44px'
      }
    },
    MuiInputLabel: {
      animated: {
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
      },
      shrink: {
        transform : 'translate(0, 14.5px) scale(0.75)'
      }
    }
  },
});

const ButtonsLogin = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

/*Componentes de la aplicación*/

/*Variables globales*/

class PageClosedSession extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",

    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  render() {
    const {classes, version, mobile, changePage} = this.props;

    var backGroundImage = "assets/img/closeD.png";
    if(mobile === true) {
      backGroundImage = "assets/img/closeM.png";
    }

    var styleBackGroundImage = {
      backgroundImage: "url(" + backGroundImage + ")",
      backgroundSize: "cover",
      backgroundPosition: "top center"
    };

    var classWelcome = classes.h1;
    var styleDivLoginButton = {};
    var displayFlex = {display:'flex'};
    var h1Center = {};
    var imgStyle = {width:'180px'};
    var styleButton = {color:'#FFF', width:'100%', textAlign:'center', position:'absolute', bottom:'15%'}
    //var imgStyle = {width:'350px'};
    var colStyle = {};

    if(mobile === true) {
      classWelcome = classes.h1Mobile;
      styleDivLoginButton = {width: '100%', textAlign: 'center', marginBottom: '20px'};
      displayFlex = {};
      h1Center = {textAlign: 'center'};
      imgStyle = {width:'150px'}
      colStyle = {textAlign:'center'}
      styleButton = {color:'#FFF', width:'100%', textAlign:'center', position:'absolute', bottom:'8%'}
    }

    return (
      <div>
        {/*Navbar*/}
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white" id="navigation-example" style={{padding: '10px'}}>
          <div className="container">
            <div className="collapse navbar-collapse justify-content-end"></div>
          </div>
        </nav>
        {/*Fin Navbar*/}
        <div className="wrapper wrapper-full-page" style={styleBackGroundImage}>
          <div className="page-header login-page">
            <div style={styleButton}>
              <MuiThemeProvider theme={ButtonsLogin}>
                <Button variant="contained" color="primary" className={classes.button} style={{margin: 'auto', borderRadius: '8px', marginTop: '18px'}} onClick={()=>changePage('login')}>
                  Iniciar Sesión
                </Button>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PageClosedSession.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageClosedSession);
