/*Componentes globales*/
import React, {Component} from 'react';

/*Componentes de la aplicación*/

/*Variables globales*/

class PageNotFound extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: ""
    }
  }

  componentDidMount = () => {
    const self = this;
    setTimeout(function() {
      self.props.loadedPage(true);
    },2000);
  }

  render() {
    return (
      <div id="mainContent" className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              NOT FOUND
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
