import React, {Component} from 'react';
 
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import MaterialTable from 'material-table';

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const Table = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiTableCell: {
      body: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px',
        textAlign: 'center !important'
      }
    },
    MuiList: {
      root: {
        backgroundColor:'#FFF'
      }
    }
  }
});

const TableUsuarios = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: Array(25).fill('none'),
      },
      root:{
        backgroundColor: "transparent"
      }
    },
    MuiTableCell: {
      body: {
        color: '#FFF',
        fontSize: '14px',
        textAlign: 'center !important'
      },
      head: {
        backgroundColor:'transparent !important',
        color:'#FFF !important'
      }
    },
    MuiIcon: {
      root: {
        color:'#FFF'
      }
    },
    MuiInputBase: {
      input: {
        color: '#FFF !important'
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255,255,255,0.42)'
        },
      }
    },
    MuiSelect: {
      icon: {
        color:'#FFF'
      }
    },
    MuiTypography: {
      caption: {
        color:'#FFF'
      }
    },
    MuiPopover:{
      paper: {
        backgroundColor:'#FFF !important'
      }
    },
    MuiList: {
      root: {
        backgroundColor:'#FFF'
      }
    }
  }
});


class DataMaterialTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data:this.props.data,
    }
  }

  handleValidateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  handleValidateInputOnlyNumbers = (celularNumber) => {
    return /^[0-9]+$/.test(celularNumber);
  }

  render() {
    const {title, data, columns, crearNotificacion, createEditSubArea, areaId, type, addCasaLocalUser, checkPermisos} = this.props;

    var tTheme = Table;
    if(type === "userCasasLocales") {
      tTheme = TableUsuarios;
    }

    return(
      <MuiThemeProvider theme={tTheme}>
        <MaterialTable
          title={title}
          columns={columns}
          data={data}
          options={{
            sorting: true,
            headerStyle: {
              color: '#737373',
              fontSize: '14px',
              textAlign: 'center'
            },
            rowStyle: {
              textAlign: 'center'
            }
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const data = this.state.data;
                  const ndata = [];

                  if(type === "areas") {
                    if (Object.keys(newData).length === 0) {
                      crearNotificacion("warning", "Selecciona un responsable.", "bottom", "right");
                      reject();
                    } else {
                      ndata.push(newData);
                      createEditSubArea("crear", ndata, areaId).then(ret => {

                        if (ret === true) {
                          data.push(newData);
                          this.setState({data: data, addData: []}, () => resolve());
                        } else {
                          reject();
                        }
                      });
                    }
                  }

                  if(type === "userCasasLocales") {
                    console.log(newData);

                    var lenght = 4;
                    if(checkPermisos("124")) {
                      lenght = 2;
                    }

                    if (Object.keys(newData).length < lenght) {
                      crearNotificacion("warning", "Ingresa todos los datos.", "bottom", "right");
                      reject();
                    } else {
                      ndata.push(newData);
                      addCasaLocalUser("agregar", ndata).then(ret => {

                        if (ret === true) {
                          data.push(newData);
                          this.setState({data: data, addData: []}, () => resolve());
                        } else {
                          reject();
                        }
                      });
                    }
                  }

                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const data = this.state.data;
                  const ndata = [];
                  ndata.push(newData);

                  if(type === "areas") {
                    var subAreaId = oldData.subAreaId;
                    createEditSubArea("editar", ndata, subAreaId).then(ret => {
                      console.log(ret);
                      if (ret === true) {
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        this.setState({data}, () => resolve());
                      } else {
                        reject();
                      }
                    });
                  }

                  if(type === "userCasasLocales") {
                    addCasaLocalUser("editar", ndata).then(ret => {
                      console.log(ret);
                      if (ret === true) {
                        const index = data.indexOf(oldData);
                        data[index] = newData;
                        this.setState({data}, () => resolve());
                      } else {
                        reject();
                      }
                    });
                  }

                }, 600);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  let data = this.state.data;
                  const index = data.indexOf(oldData);
                  const ndata = [];
                  ndata.push(oldData);

                  if(type === "areas") {
                    var subAreaId = oldData.subAreaId;
                    createEditSubArea("eliminar", ndata, subAreaId).then(ret => {
                      console.log(ret);
                      if (ret === true) {
                        data.splice(index, 1);
                        this.setState({data}, () => resolve());
                      } else {
                        reject();
                      }
                    });
                  }

                  if(type === "userCasasLocales") {
                    var casaLocalId = oldData.casaLocalId;

                    addCasaLocalUser("eliminar", ndata).then(ret => {
                      console.log(ret);
                      if (ret === true) {
                        data.splice(index, 1);
                        this.setState({data}, () => resolve());
                      } else {
                        reject();
                      }
                    });
                  }

                }, 600);
              }),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'Filas',
              labelRowsPerPage: 'Filas por página',
              firstAriaLabel: 'Primera página',
              firstTooltip: 'Primea página',
              previousAriaLabel: 'Página anterior',
              previousTooltip: 'Página anterior',
              nextAriaLabel: 'Página siguiente',
              nextTooltip: 'Página siguiente',
              lastAriaLabel: 'Última página',
              lastTooltip: 'Última página',
            },
            toolbar: {
              nRowsSelected: '{0} columna(s) seleccionadas',
              addRemoveColumns: 'Agrega o remueve columnas',
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar'
            },
            header: {
              actions: 'Acciones'
            },
            body: {
              emptyDataSourceMessage: 'Sin datos',
              filterRow: {
                filterTooltip: 'Filtro'
              },
              addTooltip: 'Agregar',
              deleteTooltip: 'Eliminar',
              editTooltip: 'Editar',
              editRow: {
                deleteText: '¿Seguro que deseas eliminar esta fila?',
                cancelTooltip: 'Cancelar',
                saveTooltip: 'Guardar'
              }
            }
          }}
        />
      </MuiThemeProvider>
    )
  }
}

export default DataMaterialTable;
