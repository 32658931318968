import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AddUserIcon from '@material-ui/icons/GroupAdd';
import Button from '@material-ui/core/Button';
import DialogUser from "./DialogUser";
import DialogHouse from "./DialogHouse";
import IconButton from '@material-ui/core/IconButton';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import InfoIcon from '@material-ui/icons/InfoRounded';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Checkbox from '@material-ui/core/Checkbox';
import HomeIcon from '@material-ui/icons/Home';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

class DivUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      openDialogUser: false,
      openDialogHouse: false,
      handleOpenDialogUser: 0,
      handleOpenDialogHome: 0,
      dataResidente: [],
      optionDialog:"",
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {rechargeAdminInfo} = this.props;
    if(nextProps.rechargeAdminInfo !== rechargeAdminInfo) {
      this.handleOpenDialogUser("editar");
    }
  }

  handleOpenDialogInfo = (dataResidente) => {
    const {usuarios} = this.props;
    this.setState({dataResidente:dataResidente},()=> {
      this.handleOpenDialogUser("editar");
    });
  }

 

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleOpenDialogUser = (option) => {
    const {dialogStateForTour} = this.props;
    const {handleOpenDialogUser} = this.state;
    dialogStateForTour(true);
    this.setState({optionDialog:option},
      ()=> {
      this.setState({openDialogUser: true}, 
        ()=> {
        this.setState({handleOpenDialogUser:handleOpenDialogUser+1});
      });
    });
  }

  handleCloseDialogUser = () => {
    const {dialogStateForTour} = this.props;
    this.setState({ optionDialog:""},
    ()=> {
      this.setState({openDialogUser: false});
      dialogStateForTour(false);
    });
  }

  handleEnableDisableUser = (residenteId, checked) => {
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion, getAdminInfo} = this.props;
    var userId = dataUser.userId;
    var activo = "1";
    var txtN1 = "Activando usuario...";
    if(checked === true) {
      activo = "0";
      txtN1 = "Desactivando usuario...";
    }
    console.log(residenteId, activo);

    crearNotificacion("info", txtN1, "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/enableDisableUser.php",{
      userId:userId,
      residenteId:residenteId,
      activo:activo
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        getAdminInfo();
      }
    });
  }

  /*Agregar la opción de poner exento de pago a los usuarios #4*/
  
  handleEnableDisableExento = (residenteId, checked) => {
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion, getAdminInfo} = this.props;
    var userId = dataUser.userId;
    var exento= "1";
    var txtN1 = "Activando exento de pago...";
    if(checked === true) {
      exento = "0";
      txtN1 = "Desactivando exento de pago...";
    }
    console.log(residenteId, exento);

    crearNotificacion("info", txtN1, "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/enableDisableExento.php",{
      userId:userId,
      residenteId:residenteId,
      exento:exento
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        getAdminInfo();
      }
    });
    
  }

  renderBtnCrearUsuario = () => {
    
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("125") || checkPermisos("103")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right', marginRight:'40px'}} onClick={()=>this.handleOpenDialogUser("crear")} data-tour="step1">
            Crear Usuario
            <AddUserIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }
  
  //#11 El administrador debe de poder agregar casas o locales Nota: pendiente por agregar códito para agregar casas
  openDialogNewHouse(option){
    
    const {dialogStateForTour} = this.props;
    const {handleOpenDialogHouse} = this.state;
    dialogStateForTour(true);
    this.setState({optionDialog:option},
      ()=> {
      this.setState({openDialogHouse: true}, 
        ()=> {
        this.setState({handleOpenDialogHouse:handleOpenDialogHouse+1});
      });
    });
    
  }

  handleCloseNewHouse = () => {
    const {dialogStateForTour} = this.props;
    this.setState({ optionDialog:""},
    ()=> {
      this.setState({openDialogHouse: false});
      dialogStateForTour(false);
    });
  }

        
  
   
  renderBtnCrearCasa = () => {
    const {classes, checkPermisos} = this.props;
    var casaLocal = "";

     if(checkPermisos("124")) {
      casaLocal = "Casa";
       
    } else if(checkPermisos("125")) {
      casaLocal = "Local";
     
    }
     
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right', marginRight:'40px'}} onClick={()=>this.openDialogNewHouse("agregar")}>
            Crear {casaLocal}
            <HomeIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    
  }

  renderTablaUsuarios = () => {
    const {classes, usuarios, checkPermisos, dataUser, handleOpenTour, tipoUsuario, handleChange, perfilUsuarios, tipoUsuarioText} = this.props;

    var casaLocal = ""
    var labelResidenteLocatario = "Residentes/Locatarios";
    if(checkPermisos("124") && checkPermisos("125")) {
      casaLocal = "Casa/Local";
    } else if(checkPermisos("124")) {
      casaLocal = "Casa";
      labelResidenteLocatario = "Residentes";
    } else if(checkPermisos("125")) {
      casaLocal = "Local";
      labelResidenteLocatario = "Locatarios";
    }

    return (
      <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
        <div className="col-md-6">
          <span>
            <h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>Gestión de Usuarios</h3>
          </span>
        </div>
        <div className="col-md-6">
          {this.renderBtnCrearUsuario()}
          {this.renderBtnCrearCasa()}
        </div>

        <div className="col-md-5" style={{marginTop:'10px'}}>
          <MuiThemeProvider theme={FormInputs}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="privilegios"
                style={{
                  background: '#FFF',
                  padding: '0px 5px 1px 5px'
                }}
              >
                Selecciona el tipo de usuario
              </InputLabel>
              <Select
                value={tipoUsuario}
                onChange={handleChange('tipoUsuario')}
                input={
                  <OutlinedInput id="tipoUsuario" labelWidth={0}/>
                }
              >
                {perfilUsuarios.map(function (arr, index) {

                  return (
                    <MenuItem key={index} value={arr.perfilId}>
                      {arr.tipoUsuario}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </MuiThemeProvider>
        </div>

        <div className="col-md-12" style={{marginTop:'10px'}}>
          {tipoUsuarioText !== "Usuario Multicuentas" ?
            <DataMaterialTableNE
              dataUser={dataUser}
              title={"Usuarios"}
              data={usuarios}
              columns={[
                { title: 'ID', field: 'areaId', editable: 'never', hidden:true},
                {
                  title: 'Info',
                  field: 'residenteId',
                  cellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {
                    return(<IconButton aria-label="Abrir" onClick={()=>this.handleOpenDialogInfo(rowData)}>
                      <InfoIcon/>
                    </IconButton>)
                  },
                },
                { title: 'Usuario', field: 'completName', cellStyle: {textAlign: 'center'}},
                { title: casaLocal, field: 'userCasasLocalesText', cellStyle: {textAlign: 'center'},
                  render: rowData => {
                    return(
                      <div>
                        {rowData.userCasasLocalesText}
                      </div>
                    )
                  },},


                { title: 'Correo', field: 'email', cellStyle: {textAlign: 'center'}},
                { title: 'Celular', field: 'celular', cellStyle: {textAlign: 'center'}},
                { title: 'Activo', field: 'activo', cellStyle: {textAlign: 'center'},
                
                
                render: rowData => {

                    var checked = true;
                     
                    if(rowData.activo === "0") {
                      checked = false;
                    }

                    var residenteId = rowData.residenteId;
                    return(
                      <div>
                        <Checkbox
                          checked={checked}
                          onChange={()=>this.handleEnableDisableUser(residenteId, checked)}
                        />
                      </div>
                    )
                  },},

                  // Agregar la opción de poner exento de pago a los usuarios #4
                  { title: 'Exento', field: 'exento', cellStyle: {textAlign: 'center'},
                
                
                  render: rowData => {
  
                      var checked = true;
                       
                      if(rowData.exento === "0") {
                        checked = false;
                      }
  
                      var residenteId = rowData.residenteId;
                      return(
                        <div>
                          <Checkbox
                            checked={checked}
                            onChange={()=>this.handleEnableDisableExento(residenteId, checked)}
                          />
                        </div>
                      )
                    },
                  },

 
                
                  
              ]}
            />
            :
            <DataMaterialTableNE
              dataUser={dataUser}
              title={"Usuarios"}
              data={usuarios}
              columns={[
                
                {
                  title: 'Info',
                  field: 'residenteId',
                  ellStyle: {textAlign: 'center'},
                  sorting:false,
                  render: rowData => {
                    return(<IconButton aria-label="Abrir" onClick={()=>this.handleOpenDialogInfo(rowData)}>
                      <InfoIcon/>
                    </IconButton>)
                  },
                },
                { title: 'Correo', field: 'email', cellStyle: {textAlign: 'center'}},
                { title: 'Activo', field: 'activo', cellStyle: {textAlign: 'center'},render: rowData => {

                    var checked = true;
                    if(rowData.activo === "0") {
                      checked = false;
                    }

                    var residenteId = rowData.residenteId;
                    return(
                      <div>
                        <Checkbox
                          checked={checked}
                          onChange={()=>this.handleEnableDisableUser(residenteId, checked)}
                        />
                      </div>
                    )
                  },},


              ]}
            />
          }
        </div>
      </div>
    );
  }

  render() {
    const {dataUser,crearNotificacion, cerrarNotificacion, ajaxSource, getData, getAdminInfo, crearAlerta, cerrarAlerta, cuotas, checkPermisos, mobile, perfilUsuarios, casasLocales, casasLocalesCuotas, usuarios, usuariosParaMC} = this.props;
    const {optionDialog, openDialogUser, openDialogHouse, handleOpenDialogUser, handleOpenDialogHouse, dataResidente} = this.state;

    return(
      <div>
        {this.renderTablaUsuarios()}
        <DialogUser
          open={openDialogUser}
          option={optionDialog}
          handleClose={this.handleCloseDialogUser}
          handleOpenDialogUser={handleOpenDialogUser}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          ajaxSource={ajaxSource}
          getData={getData}
          dataUser={dataUser}
          getAdminInfo={getAdminInfo}
          dataRes={dataResidente}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
          cuotas={cuotas}
          checkPermisos={checkPermisos}
          mobile={mobile}
          perfilUsuarios={perfilUsuarios}
          casasLocales={casasLocales}
          casasLocalesCuotas={casasLocalesCuotas}
          usuarios={usuarios}
          usuariosParaMC={usuariosParaMC}
        />

       <DialogHouse
          open={openDialogHouse}
          option={optionDialog}
          handleClose={this.handleCloseNewHouse}
          handleOpenDialogHome={handleOpenDialogHouse}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          ajaxSource={ajaxSource}
          getData={getData}
          dataUser={dataUser}
          getAdminInfo={getAdminInfo}
          dataRes={dataResidente}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
          cuotas={cuotas}
          checkPermisos={checkPermisos}
          mobile={mobile}
          perfilUsuarios={perfilUsuarios}
          casasLocales={casasLocales}
          casasLocalesCuotas={casasLocalesCuotas}
          usuarios={usuarios}
          usuariosParaMC={usuariosParaMC}
        />
      </div>
      
    )

  }
}

DivUsuarios.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivUsuarios);
