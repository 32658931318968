import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/index';
import SendIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import Button from '@material-ui/core/Button/index';

 
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import DokaManager from "../../commons/DokaManager";
 
import HelpIcon from '@material-ui/icons/LiveHelpRounded';



const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    },
    MuiTypography: {
      root: {
        padding: '0px !important'
      }
    },
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DivNuevoTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      areaId:"",
      areaIdE:false,
      subAreas:[],
      subAreaId:"",
      subAreaIdE:false,
      mensaje:"",
      mensajeE:false,
      maxCharacters:"0/128",
      imageUpload:false,
      imageLoaded:false,
      fileName:"",
      componentKey:0
    }
  }

  handleComponentKey = () => {
    const {componentKey} = this.state;
    this.setState({componentKey:componentKey+1});
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value },() => {
      if(name === "areaId") {
        const {areas} = this.props;
        var index = event.target.value.split("||")[1];
        this.setState({subAreas:areas[index].subAreas});
      }
      if(name === "mensaje") {
        const {mensaje} = this.state;
        var length = mensaje.length;
        this.setState({maxCharacters:length+"/128"});
      }
    });
    this.setState({[name+'E']:false});
  };

  getFileId = (error, file) => {
    var fileName = file.filename;
    this.setState({imageUpload: true, fileName:fileName});
  }

  onRemoveFile = (error, file) => {
    const {ajaxSource, getData, dataUser} = this.props;
    const {imageUpload} = this.state;

    if(imageUpload === true) {
      var userId = dataUser.userId;
      var fileName = file.filename;

      getData(ajaxSource, "tickets/deleteImageTicket.php",{
        userId:userId,
        fileName:fileName
      }).then(function (response) {
        if (response.data) {}
      });
    }

    this.setState({imageUpload: false, imageLoaded: false, fileName:"", fileExtension:""});
  }

  onAddfile = () => {
    this.setState({imageLoaded: true});
  }

  createTicket = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getTicketsInfo, reincidencia, ticketId} = this.props;
    const {areaId, subAreaId, mensaje, imageUpload, imageLoaded, fileName} = this.state;

    if(areaId === "" && reincidencia === false) {
      this.setState({areaIdE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el área correspondiente.", "bottom", "right");
      });
    } else if(subAreaId === "" && reincidencia === false) {
      this.setState({areaIdE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el sub área correspondiente.", "bottom", "right");
      });
    } else if(mensaje === "") {
      this.setState({mensajeE:true}, ()=> {
        crearNotificacion("warning", "Ingesa la descripción del problema.", "bottom", "right");
      });
    } else if(imageLoaded === true && imageUpload === false) {
      crearNotificacion("warning", "Sube la foto seleccionada.", "bottom", "right");
    } else {
      var userId = dataUser.userId;

      crearNotificacion("info", "Creando ticket...", "bottom", "right", 1000000);


      getData(ajaxSource, "tickets/createTicket.php",{
        userId:userId,
        areaId:areaId,
        subAreaId:subAreaId,
        mensaje:mensaje,
        fileName:fileName,
        ticketId:ticketId
      }).then(function (response) {

        ////
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            crearNotificacion("success", "Ticket creado con exito.", "bottom", "right", 3000);

            self.setState({fileName:"", areaId:"", imageLoaded: false, imageUpload: false, maxCharacters:"0/128", mensaje:"", subAreaId:""}, () => {
              self.handleComponentKey();
              getTicketsInfo();
            });

          } else {
            crearNotificacion("error", "Hubo un problema al intentar crear el ticket " + data[0].error, "bottom", "right", 3000);
          }
        }


        ///
      }
      
      
      );
    }
  }

  render() {
    const {classes, dataUser, crearNotificacion, cerrarNotificacion, ajaxSource, getData, crearAlerta, cerrarAlerta, areas, checkPermisos, reincidencia, handleOpenTour, mobile} = this.props;
    const {areaId, areaIdE, subAreaId, subAreaIdE, subAreas, mensaje, mensajeE, maxCharacters, componentKey} = this.state;

    var btn = "";
    if(checkPermisos("1") || checkPermisos("113")) {
      btn = <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.createTicket()}>
        Enviar Ticket
        <SendIcon className={classes.rightIcon}/>
      </Button>
    }

    if(reincidencia === true) {
      btn = <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.createTicket()}>
        Reenviar Ticket
        <SendIcon className={classes.rightIcon}/>
      </Button>
    }

    if(reincidencia === true) {
      return(
        <MuiThemeProvider theme={FormInputs}>
          <div className="row" style={{paddingBottom: '20px', margin: '0px', width:'100%'}}>
            <div className="col-md-12" style={{marginTop:'20px'}}>
              <TextField
                error={mensajeE}
                label="Mensaje"
                placeholder="Mensaje"
                id="mensaje"
                onChange={this.handleChange('mensaje')}
                margin="normal"
                variant="outlined"
                style={{width:'100%'}}
                multiline={true}
                rowsMax={7}
                rows={7}
                value={mensaje}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{maxLength: 128}}
                helperText={maxCharacters}
              />
            </div>
            <div className="col-md-12" style={{marginTop:'20px'}}>
              <DokaManager
                key={componentKey}
                type="image"
                labelIdle={[
                  'Arrastra y suelta ó <span class="filepond--label-action">busca</span> una foto del problema'
                ]}
                ajaxSource={ajaxSource}
                urlServerUpload={"uploadManager/index.php?option=imgTicket&userId="+dataUser.userId}
                onprocessfile={(error, file)=>this.getFileId(error, file)}
                onremovefile={(error, file)=>this.onRemoveFile(error, file)}
                onaddfile={this.onAddfile}
                fileRenameFunction={(file) => {return "imgTicket"+file.extension}}
                acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
                imagePreviewHeight={250}
                imageEditInstantEdit={false}
                editorOptions={{
                  cropAspectRatioOptions: [
                    {
                      label: 'Foto Ticket',
                    }
                  ]
                }}
              />
            </div>
            <div className="col-md-12 text-center" style={{marginTop:'20px'}}>
              <MuiThemeProvider theme={FormButtons}>
                {btn}
              </MuiThemeProvider>
            </div>
          </div>
        </MuiThemeProvider>
      )
    } else {

      return(
        <MuiThemeProvider theme={FormInputs}>
          <div className="row" style={{paddingBottom: '20px', margin: '0px', width:'100%'}}>
            <div className="col-md-12">
              <span><h3 className="darkGrayUpera" style={{marginTop: '20px', marginBottom: '0px'}}>Nuevo Ticket</h3></span>
            </div>
            <div className="col-md-12" style={{textAlign:'right'}}>
              <MuiThemeProvider theme={FormButtons}>
                <Button variant="contained" color="primary" className={classes.button} onClick={()=>handleOpenTour("nuevoTicket")}>
                  ¿Como genero un nuevo ticket?
                  <HelpIcon className={classes.rightIcon}/>
                </Button>
              </MuiThemeProvider>
            </div>
            <div className="col-md-12" style={{marginTop:'20px'}} data-tour="step1">
              <FormControl variant="outlined" className={classes.formControl} error={areaIdE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="areaId"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  Selecciona el área
                </InputLabel>
                <Select
                  value={areaId}
                  onChange={this.handleChange('areaId')}
                  input={
                    <OutlinedInput id="cuota" labelWidth={0}/>
                  }
                >
                  {areas.map(function (arr, index) {

                    return(
                      <MenuItem key={index} value={arr.areaId+"||"+index}>
                        {arr.nombre}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-12" style={{marginTop:'20px'}} data-tour="step2">
              <FormControl variant="outlined" className={classes.formControl} error={subAreaIdE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="subAreaId"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  Selecciona el sub área
                </InputLabel>
                <Select
                  value={subAreaId}
                  onChange={this.handleChange('subAreaId')}
                  input={
                    <OutlinedInput id="cuota" labelWidth={0}/>
                  }
                >
                  {subAreas.map(function (arr, index) {

                    return(
                      <MenuItem key={index} value={arr.subAreaId}>
                        {arr.nombre}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-12" style={{marginTop:'20px'}} data-tour="step3">
              <TextField
                error={mensajeE}
                label="Mensaje"
                placeholder="Mensaje"
                id="mensaje"
                onChange={this.handleChange('mensaje')}
                margin="normal"
                variant="outlined"
                style={{width:'100%'}}
                multiline={true}
                rowsMax={7}
                rows={7}
                value={mensaje}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{maxLength: 128}}
                helperText={maxCharacters}
              />
            </div>
            <div className="col-md-12" style={{marginTop:'20px'}} data-tour="step4">
              <DokaManager
                key={componentKey}
                type="image"
                labelIdle={[
                  'Arrastra y suelta ó <span class="filepond--label-action">busca</span> una foto del problema'
                ]}
                ajaxSource={ajaxSource}
                urlServerUpload={"uploadManager/index.php?option=imgTicket&userId="+dataUser.userId}
                onprocessfile={(error, file)=>this.getFileId(error, file)}
                onremovefile={(error, file)=>this.onRemoveFile(error, file)}
                onaddfile={this.onAddfile}
                fileRenameFunction={(file) => {return "imgTicket"+file.extension}}
                acceptedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
                imagePreviewHeight={250}
                imageEditInstantEdit={false}
                editorOptions={{
                  cropAspectRatioOptions: [
                    {
                      label: 'Foto Ticket',
                    }
                  ]
                }}
              />
            </div>
            <div className="col-md-12 text-center" style={{marginTop:'20px'}} data-tour="step5">
              <MuiThemeProvider theme={FormButtons}>
                {btn}
              </MuiThemeProvider>
            </div>
          </div>
        </MuiThemeProvider>
      )
    }
  }
}

DivNuevoTicket.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivNuevoTicket);
