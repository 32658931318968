/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Grid from '@material-ui/core/Grid';

import DokaManager from "./DokaManager";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
  container:{
    flexGrow: 1,
  }
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth : "1200px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const DialogTMobile = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        width: 'calc(100% - 23px) !important',
        maxWidth: 'calc(100% - 23px) !important',
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogDocumentos extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      value:"",
      dataPFolder:[],
      dataItems:[],
      newPath:"",
      actualPath:"",
      typeDelete:"",
      deletePath:"",
      componentKey:0
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOpenDialogDocumentos, dataUser} = this.props;
    if(nextProps.handleOpenDialogDocumentos !== handleOpenDialogDocumentos) {
      var residencialId = dataUser.userResidencialId;

      var path = "files/"+residencialId;

      this.setState({actualPath:residencialId+"/", newPath:path}, ()=> {
        this.scanFiles();
      });
    }
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState, ()=> {
      handleClose();
    });
  };

  scanFiles = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion} = this.props;
    const {newPath} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Cargando documentos...", "bottom", "right", 1000000);

    getData(ajaxSource, "documentos/scan.php",{newPath:newPath, userId:userId}).then(function (response) {
      if (response.data) {
        var data = response.data;

        self.setState({dataPFolder:data, dataItems:data.items}, ()=> {
         cerrarNotificacion();
        });
      }
    });
  }

  confirmDeleteFiles = (type, path) => {
    const {crearAlerta, cerrarAlerta} = this.props;

    var txtDelete = "este archivo";
    if(type === "folder") {
      txtDelete = "esta carpeta y todo su contenido";
    }

    this.setState({typeDelete:type, deletePath:path}, ()=> {
      crearAlerta("warning", "", "¿Seguro que deseas eliminar "+txtDelete+"?", true, this.deleteFiles);
    });
  }

  deleteFiles = () => {
    const self = this;
    const {crearNotificacion, cerrarNotificacion, ajaxSource, getData, dataUser, crearAlerta, cerrarAlerta} = this.props;
    const {typeDelete, deletePath} = this.state;
    var userId = dataUser.userId;

    cerrarAlerta();

    var txtNoty = "Archivo eliminado";
    var txtNoty2 = "el archivo";
    if(typeDelete === "folder") {
      txtNoty = "Carpeta eliminado";
      txtNoty2 = "la carpeta";
    }

    crearNotificacion("info", "Eliminando archivo...", "bottom", "right", 1000000);

    getData(ajaxSource, "documentos/deleteFiles.php",{deletePath:deletePath, userId:userId, typeDelete:typeDelete}).then(function (response) {
      if (response.data) {
        var data = response.data;
        cerrarNotificacion();

        if(data[0].error === 1) {
          crearNotificacion("success", txtNoty+" con exito.", "bottom", "right", 3000);
          setTimeout(function () {
            self.scanFiles();
          },900);
        } else if(data[0].error === 2) {
          crearNotificacion("error", "Hubo un error al eliminar "+txtNoty2+" "+ data[0].error, "bottom", "right", 1000000);
        }
      }
    });
  }

  getFileId = (error, file) => {
    this.scanFiles();
    this.handleComponentKey();
  }

  onRemoveFile = (error, file) => {

  }

  onAddfile = () => {

  }

  handleComponentKey = () => {
    const {componentKey} = this.state;
    this.setState({componentKey:componentKey+1});
  }

  handleChangeFolder = (newPath) => {

    console.log(newPath);
    var path = newPath.split("/");
    var i = 0;

    var actualPath = "";
    for(i=0;i<Object.keys(path).length;i++) {
      if (i > 1) {
        actualPath = actualPath + path[i] + "/";
      }
    }

    this.setState({actualPath:actualPath}, ()=> {
      this.setState({newPath: newPath}, () => {
        this.scanFiles();
      });
    })
  }

  handleChangePath = (index) => {
    const {newPath} = this.state;

    var path = newPath.split("/");
    var i = 0;

    var nPath = "";
    var actualPath = "";
    for(i=0;i<=index;i++) {
      nPath = nPath + path[i] + "/";
      if (i > 1) {
        actualPath = actualPath + path[i] + "/";
      }
    }

    var strAP= actualPath
    var newStrAP = strAP.substring(0, strAP.length - 1);

    this.setState({actualPath:newStrAP},()=> {
      var str= nPath
      var newStr = str.substring(0, str.length - 1);
      this.handleChangeFolder(newStr);
    });
  }

  renderPaths = () => {
    const self = this;
    const {title} = this.props;
    const {newPath} = this.state;
    if(title === "Refacciones") {
      path = "tableros/refacciones";
    }

    if(Object.keys(newPath).length > 0) {

      var path = newPath.split("/");

      return(
        <div style={{width: '100%', marginBottom: '20px'}}>
          {path.map(function (row, index) {
            if(index > 1 && title !== "Refacciones") {
              return (
                <span key={index} style={{marginLeft: '7px', cursor:'pointer'}}><h3 className="text-gray" style={{display:'inline-table'}} onClick={()=>self.handleChangePath(index)}>{" "+row+" / "}</h3></span>
              )
            } else if (index > 0 && title === "Refacciones") {
              return (
                <span key={index} style={{marginLeft: '7px', cursor:'pointer'}}><h3 className="text-gray" style={{display:'inline-table'}} onClick={()=>self.handleChangePath(index)}>{" "+row+" / "}</h3></span>
              )
            }
          })}
        </div>
      )
    }
  }

  renderBtnDeleteFiles = (type, path) => {
    const {classes, checkPermisos} = this.props;

    if(checkPermisos("1") || checkPermisos("138")) {
      return (
        <div style={{width:'100%'}}>
          <MuiThemeProvider theme={FormButtons}>
            <Button variant="contained" color="secondary" className={classes.button} style={{margin: 'auto', borderRadius: '20px', marginTop: '18px', marginRight: '5px', marginBottom:'10px'}} onClick={()=>this.confirmDeleteFiles(type, path)}>
              <DeleteIcon style={{margin:'0px'}}/>
            </Button>
          </MuiThemeProvider>
        </div>
      )
    }
  }

  renderFiles = () => {
    const self = this;
    const {classes, ajaxSource, prodEnv} = this.props;
    const {dataItems, dataPFolder, actualPath, openDialogUploadFiles, openDialogCreateFolder, newPath} = this.state;

    if(Object.keys(dataItems).length > 0) {
      return (
        <Grid container className={classes.container} spacing={2} justify="flex-start">
          {dataItems.map(function (row, index) {

            if(row.type === "folder") {
              var imgFolder = "fEmpty.png"
              var totalItems = Object.keys(row.items).length;
              if (totalItems > 0) {
                imgFolder = "fFull.png"
              }

              var txtItems = "Archivos";
              if (totalItems === 1) {
                txtItems = "Archivo";
              }

              return (
                <div key={index} style={{maxWidth: '120px', display: 'inline-table', cursor: 'pointer', marginRight: '20px'}}>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <div style={{textAlign: 'center'}}>
                      <img className="img-fluid" src={"assets/img/iconos/" + imgFolder} style={{width: '100%'}} onClick={() => self.handleChangeFolder(row.path)}/>
                      <h4 className="text-center" style={{marginBottom: '0px'}}>{row.name}</h4>
                      <h5 className="text-center text-gray">{totalItems + " " + txtItems}</h5>
                    </div>
                  </Slide>
                </div>
              )
            } else if(row.type === "file") {

              var imgFile = "file.png";
              var fileContent = <a href={ajaxSource + "documentos/" + row.path} target="_blank">
                <img className="img-fluid" src={"assets/img/iconos/files/png/" + imgFile} style={{width: '95px'}} />
              </a>

              if(row.extension === "pdf" || row.extension === "PDF") {
                imgFile = "pdf.png";

                fileContent = <iframe
                  src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + "documentos/"+  row.path}
                  style={{width: '100%', height: '160px', border: '4px solid #737373', borderRadius: '4px'}}/>

              } else if(row.extension === "xls" || row.extension === "XLS" || row.extension === "xlsx" || row.extension === "XLSX") {
                imgFile = "xls.png";

                fileContent = <iframe
                  src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + "documentos/"+  row.path}
                  style={{width: '100%', height: '160px', border: '4px solid #737373', borderRadius: '4px'}}/>

              } else if(row.extension === "PPT" || row.extension === "ppt" || row.extension === "pptx" || row.extension === "PPTX") {
                imgFile = "ppt.png";

                fileContent = <iframe
                  src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + "documentos/"+  row.path}
                  style={{width: '100%', height: '160px', border: '4px solid #737373', borderRadius: '4px'}}/>

              } else if(row.extension === "PNG" || row.extension === "png") {
                imgFile = "png.png";

                fileContent = <a href={ajaxSource + "documentos/" + row.path} target="_blank">
                  <img className="img-raised rounded img-fluid" src={ajaxSource + "documentos/" + row.path} style={{maxHeight:'160px'}} />
                </a>
              } else if(row.extension === "JPG" || row.extension === "jpg" || row.extension === "JEPG" || row.extension === "jepg") {
                imgFile = "jpg.png";

                fileContent = <a href={ajaxSource + "documentos/" + row.path} target="_blank">
                  <img className="img-raised rounded img-fluid" src={ajaxSource + "documentos/" + row.path} style={{maxHeight:'160px'}} />
                </a>
              } else if(row.extension === "MP4" || row.extension === "mp4") {
                imgFile = "mp4.png";

                fileContent = <a href={ajaxSource + "documentos/" + row.path} target="_blank">
                  <img className="img-fluid" src={"assets/img/iconos/files/png/" + imgFile} style={{width: '95px'}} />
                </a>
              } else if(row.extension === "iso" || row.extension === "ISO") {
                imgFile = "iso.png";

                fileContent = <a href={ajaxSource + "documentos/" + row.path} target="_blank">
                  <img className="img-fluid" src={"assets/img/iconos/files/png/" + imgFile} style={{width: '95px'}} />
                </a>
              } else if(row.extension === "zip" || row.extension === "ZIP" || row.extension === "rar" || row.extension === "RAR") {
                imgFile = "zip-1.png";

                fileContent = <a href={ajaxSource + "documentos/" + row.path} target="_blank">
                  <img className="img-fluid" src={"assets/img/iconos/files/png/" + imgFile} style={{width: '95px'}} />
                </a>
              }

              return (
                <Grid item xs={3}>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <div style={{textAlign: 'center'}}>
                      {self.renderBtnDeleteFiles("file", row.path)}
                      {fileContent}
                      <h6 className="text-center" style={{marginBottom: '0px', textOverflow: 'ellipsis', overflowWrap: 'break-word', maxWidth: '100%', margin: 'auto', marginTop: '10px'}}>{row.name}</h6>
                      <h6 className="text-center" style={{marginBottom: '0px', textOverflow: 'ellipsis', overflowWrap: 'break-word', maxWidth: '100%', margin: 'auto', marginTop: '0px', color:'#0345ea', fontSize:'12px'}}>{row.date}</h6>
                    </div>
                  </Slide>
                </Grid>
              )
            }
          })}
        </Grid>
      )
    } else {
      return (
        <div style={{width: '100%'}} className="text-center">
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
          <h4 className="text-center text-gray m-t-5" style={{marginBottom: '90px'}}>Sin documentos</h4>
        </div>
      )
    }
  }

  render() {
    const {classes, open, mobile, ajaxSource, dataUser, checkPermisos} = this.props;
    const {componentKey} = this.state;

    var themeD = DialogT;
    if(mobile === true) {
      themeD = DialogTMobile;
    }

    var dokaDocumentos = "";
    if(checkPermisos("1") || checkPermisos("139")) {
      dokaDocumentos = <div className="col-md-12">
        <DokaManager
          key={componentKey}
          type="image"
          labelIdle={[
            'Arrastra y suelta ó <span class="filepond--label-action">busca</span> una imagen o documento PDF para subirlo'
          ]}
          ajaxSource={ajaxSource}
          urlServerUpload={"uploadManager/index.php?option=fileDocumentos&userId="+dataUser.userId}
          onprocessfile={(error, file)=>this.getFileId(error, file)}
          onremovefile={(error, file)=>this.onRemoveFile(error, file)}
          onaddfile={this.onAddfile}
          acceptedFileTypes={["image/jpeg", "image/jpg", "image/png", "application/pdf"]}
          imagePreviewHeight={250}
          imageEditInstantEdit={false}
          editorOptions={{
            cropAspectRatioOptions: [
              {
                label: 'Foto Ticket',
              }
            ]
          }}
        />
      </div>
    }

    return (
      <MuiThemeProvider theme={themeD}>
        <Dialog
          scroll={'body'}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"md"}
          onClose={this.handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="simple-dialog-title">
            <span className="darkGrayUpera"><img src="assets/img/iconos/icono_documentos.png" style={{width:'250px'}} alt="img"/></span>
            <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', top:'10px'}} onClick={()=>this.handleClose()}>
              <CloseIcon className="darkGrayUpera"/>
            </IconButton>
          </DialogTitle>
          <List style={{borderTop:'1px dashed rgb(228, 227, 225)'}}>
            <MuiThemeProvider theme={FormInputs}>
              <ListItem style={{display:'inline'}}>
                <div className="row" style={{width:'100%', margin:'0px'}}>
                  {dokaDocumentos}
                  <div className="col-md-12" style={{marginTop:'20px'}}>
                    {this.renderPaths()}
                    {this.renderFiles()}
                  </div>
                </div>
              </ListItem>
            </MuiThemeProvider>
          </List>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogDocumentos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogDocumentos);
