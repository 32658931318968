import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#777777',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

class DivMensajesDifusion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded:null,
    }
  }


  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };


  render() {
    const self = this;
    const {classes, mensajesDifusion} = this.props;
    const {expanded} = this.state;

    if (Object.keys(mensajesDifusion).length > 0) {
      return (
        <div className="row" style={{paddingBottom:'20px', margin: '0px'}}>
          <div className="col-md-12 mtM">
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Mis mensajes de difusión</h3>
          </div>
          <div className="col-md-12">
            {mensajesDifusion.map(function (arr, index) {

              var classActive = classes.heading;
              if(expanded === arr.mensajeId) {
                classActive = classes.headingActive;
              }

              return(
                <MuiThemeProvider theme={ExpansionPanels} key={index}>
                  <ExpansionPanel expanded={expanded === arr.mensajeId} onChange={self.handleChangePanel(arr.mensajeId)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classActive} style={{color:'#737373'}}>{arr.fecha}</Typography>
                      <div className={classes.secondaryHeading}>
                        Mensaje de: {arr.senderName}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'inline'}}>
                      <div className="row" style={{width:'100%', margin: '0px'}}>
                        <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                          {arr.mensaje}
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </MuiThemeProvider>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Sin mensajes de difusión</h4>
            </div>
          </div>
        </div>
      )
    }
  }
}

DivMensajesDifusion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivMensajesDifusion);
