import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import OkIcon from '@material-ui/icons/CheckCircleRounded';
import AddIcon from '@material-ui/icons/AddCircle';
import DownLoadIcon from '@material-ui/icons/CloudDownloadRounded';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MaterialTable from "mui-datatables";
import styled from 'styled-components';

import DialogFacturaInfo from "./DialogFacturaInfo";
import AddUserIcon from "@material-ui/icons/GroupAdd";
import DivUsuarios from "../administracion/DivUsuarios";

const $ = require('jquery');

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  td: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  th: {
    color: '#737373',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '40%'
  },
  heading: {
    flexBasis: '50%',
    flexShrink: 0,
    background: '#e4e3e1',
    color: '#737373 !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'left',
    padding: '20px'
  },
  headingPendientePago: {
    flexBasis: '50%',
    flexShrink: 0,
    background: '#ffab00',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'left',
    padding: '20px'
  },
  container: {
    maxHeight: 440,
  },
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #FFF !important;
    background: #0345ea !important;
    border-radius: 5px
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent
  }
`;

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused:not $notchedOutline:not": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main:'#0345ea'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#FFF !important'
      }
    },
    MuiTab: {
      labelContainer: {
        padding: '2px 1px'
      },
      selected: {
        color: '#FFF !important',
        background: '#0345ea !important',
        borderRadius: '5px'
      },
      root : {
        background: '#cfd8dc',
        borderRadius: '5px'
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: '#FFF !important',
            background: '#0345ea !important',
            borderRadius: '5px'
          }
        }
      }
    },
    MuiTypography: {
      body1: {
        padding: '20px 0px 0px 0px !important'
      }
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: 'transparent'
      }
    },
    PrivateTabIndicator:{
      root: {
        backgroundColor: 'transparent !important'
      }
    }
  }
});

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivFacturacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:0,
      facturas:[],
      year:"",
      monthsTh:[],
      mesDataSelected:[],
      facturaDataSelected:[],
      openDialogInfo:false,
      monthNumber:0,
      rowsPerPage:7,
      page:0
    }
  }

  componentDidMount() {
    const { dataUser } = this.props;
    var actualYear = dataUser.sistemYear;

    this.setState({year:actualYear}, () => {
      this.facturacionInfo();
    })
  }

  facturacionInfo = () => {
    const that = this;
    const {dataUser, getData, ajaxSource, loadedPage, crearNotificacion, cerrarNotificacion} = this.props;
    const {year, value} = this.state;
    var userId = dataUser.userId;

    if(year !== "") {
      crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);
      getData(ajaxSource, "facturacion/facturacionInfo.php", {
        year: year,
        userId: userId,
        tab: value
      }).then(function (response) {
        if (response.data) {
          loadedPage(true);
          cerrarNotificacion();
          var data = response.data;
          var facturas = data[0].sort((a, b) => a.local.localeCompare(b.local));
          var monthsTh = data[1];
          var monthNumber = data[2];
          that.setState({facturas: facturas, monthsTh: monthsTh, monthNumber: monthNumber});
        }
      });
    }
  }

  handleChange = name => event => {
    const that = this;
    this.setState({ [name]: event.target.value, [name+'E']:false},()=> {
      if(name === "locatarioId") {
        that.setState({yearDisabled:false});
      }
      if(name === "year") {
        that.facturacionInfo();
      }
    });
  };

  handleFilterByValue = (array, string) => {
    return array.filter(o =>
      Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:+event.target.value});
    this.setState({page:0});
  };

  handleChangeTab = (event, value) => {
    this.setState({ value }, () => {
      this.facturacionInfo();
    });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  openDialogFacturaInfo = (mesData, facturaFata) => {
    const that = this;
    this.setState({mesDataSelected:mesData, facturaDataSelected:facturaFata}, () => {
      that.setState({openDialogInfo:true});
    });
  }

  closeDialogFacturaInfo = () => {
    this.setState({openDialogInfo:false});
  }

  renderTable = () => {
    const that = this;
    const {theme, classes, dataUser, crearNotificacion, ajaxSource, getData,  cerrarNotificacion, prodEnv, documentHeight, crearAlerta, cerrarAlerta, dev, checkPermisos} = this.props;
    const {value, year, facturas, monthsTh, openDialogInfo, mesDataSelected, facturaDataSelected, monthNumber, rowsPerPage, page} = this.state;
    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;
    var maxHeight = documentHeight - 400;


    return(
      <MuiThemeProvider theme={FormInputs}>
        <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
          <div className="col-md-4" style={{marginTop:'20px'}}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={ref => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="year"
                style={{
                  background: '#FFF',
                  padding: '0px 5px 1px 5px'
                }}
              >
                Año
              </InputLabel>
              <Select
                value={year}
                onChange={this.handleChange('year')}
                input={
                  <OutlinedInput id="year" labelWidth={0}/>
                }
              >
                <MenuItem value={nextYear}>{nextYear}</MenuItem>
                <MenuItem value={actualYear}>{actualYear}</MenuItem>
                <MenuItem value={passYear}>{passYear}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div style={{width:'100%', maxWidth:'100%', overflowY:'auto'}}>
            <TableContainer style={{maxHeight:maxHeight}}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{
                      minWidth:'150px'
                    }}>{checkPermisos("125") ? "Local" : "Casa"}</TableCell>
                    {monthsTh.map(mes => (
                      <TableCell key={mes} style={{minWidth:'100px'}}>
                        {mes}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Object.keys(facturas).length > 0 &&
                    facturas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(function (arr, index) {
                      return (
                        <TableRow key={index}>
                          <TableCell>{arr.local}</TableCell>
                          {arr.facturas.map(function (arrF, index) {
                            var monto = arrF.monto;
                            if(arrF.status === "Pendiente") {
                              monto = "Pendiente";
                            }
                            var btn = <a onClick={()=>that.openDialogFacturaInfo(arrF, arr)} style={{cursor:'pointer', textDecoration:'underline'}}>{monto}</a>
                            if(arrF.pathPdf === "-"){btn = monto}
                            if(arrF.status === "RP" || arrF.status === "RF") {
                              var btn = <a onClick={()=>that.openDialogFacturaInfo(arrF, arr)} style={{cursor:'pointer', textDecoration:'underline'}}>
                                <IconButton aria-label="add" size="small">
                                  <AddIcon fontSize="inherit" />
                                </IconButton>
                              </a>
                            }
                            if(arrF.totalMeses !== ""){
                              return (
                                <TableCell colSpan={arrF.totalMeses} style={{textAlign:'center', border:'1px solid #ccc'}}>
                                  {btn}
                                </TableCell>
                              )
                            } else {
                              if(monto !== "Pendiente") {
                                return (
                                  <TableCell>
                                    <a onClick={()=>that.openDialogFacturaInfo(arrF, arr)} style={{cursor:'pointer', textDecoration:'underline'}}>
                                      {btn}
                                    </a>
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell>
                                    {btn}
                                  </TableCell>
                                )
                              }
                            }
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 10, 25, 100]}
              component="div"
              count={facturas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage={<div>Filas por página</div>}
              nextIconButtonText="Siguiente"
            />
          </div>
        </div>
        <DialogFacturaInfo
          checkPermisos={checkPermisos}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          dataUser={dataUser}
          getData={getData}
          ajaxSource={ajaxSource}
          year={year}
          prodEnv={prodEnv}
          open={openDialogInfo}
          handleClose={this.closeDialogFacturaInfo}
          mesDataSelected={mesDataSelected}
          facturaDataSelected={facturaDataSelected}
          facturacionInfo={this.facturacionInfo}
          monthNumber={monthNumber}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
          tab={value}
          dev={dev}
        />
      </MuiThemeProvider>
    )
  }

  render() {
    const that = this;
    const {theme, checkPermisos} = this.props;
    const {value } = this.state;

    var tabMantto = "";
      tabMantto = <Tab style={{padding: '0px', marginLeft: '2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <DollarIcon className="iconTab"/>
            <div className="textTab"><b>Registro </b><br/>
              <div className="textTabSub">Mantenimiento.</div>
            </div>
          </div>
        </div>
      }/>

    var tabRentas = "";
    if(checkPermisos("1") || checkPermisos("149")) {
      tabRentas = <Tab style={{padding: '0px', marginLeft: '2px'}} label={
        <div style={{width: '100%', padding: '2px 1px', boxSizing: 'border-box'}}>
          <div className="containerTextTab" style={{textAlign: 'left', marginBottom: '10px'}}>
            <DollarIcon className="iconTab"/>
            <div className="textTab"><b>Registro </b><br/>
              <div className="textTabSub">Rentas.</div>
            </div>
          </div>
        </div>
      }/>
    }

    return(
      <>
        <MuiThemeProvider theme={tabs}>
          <AppBar position="static" color="default">
            <StyledTabs
              value={value}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {tabMantto}
              {tabRentas}
            </StyledTabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
            style={{overFlow:'hidden'}}
            slideClassName="slideOverflow"
          >
            <TabContainer dir={theme.direction}>
              {this.renderTable()}
            </TabContainer>
            <TabContainer dir={theme.direction}>
              {this.renderTable()}
            </TabContainer>
          </SwipeableViews>
        </MuiThemeProvider>
      </>
    )
  }
}

DivFacturacion.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(DivFacturacion);