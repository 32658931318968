 /*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import SelectIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import MyDatePicker from "../../commons/MyDatePicker";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth : "700px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogIngresos extends Component {



  constructor(props) {
    super(props);
    this.state = {
      value:"",
      fechaEg: new Date(),
      fechaIngreso:"",
      conceptoE: false,
      concepto: "",
      showSelectConcept: true,
      newConcepto:"", //Concepto a agregar
      monto:"",
      montoE: false,
      conceptos: [], //Aray
      disableSelectConcepto: true
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOpenDialogCrearIngreso} = this.props;
    const {fechaEg} = this.state;
    if(nextProps.handleOpenDialogCrearIngreso !== handleOpenDialogCrearIngreso) {
      var nDate = this.transformDate(fechaEg, "");
      this.setState({fechaIngreso: nDate});
      this.getDialogIngresosInfo();
    }
  }

  transformDate = (date, option) => {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (year < 10) {
      year = "0" + year;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    var nDate = "";
    if(option === "conHora") {
      nDate = hours + ":" + minutes + ":00";
    } else if(option === "dateConHora") {
      nDate = year + "-" + month + "-" + day +" "+ hours + ":" + minutes + ":00";
    } else {
      nDate = year + "-" + month + "-" + day;
    }

    return nDate;
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState({fallaSelected: false}, ()=> {
      this.setState({showSelectConcept:true});
      this.setState({newConcepto:""});
      this.setState({concepto:""});
      this.setState({disableSelectConcepto:true});
      handleClose();
    });
  };

  handleDateChangeAct = date => {
    var nDate = this.transformDate(date, "");

    this.setState({fechaEg: date});
    this.setState({fechaIngreso: nDate});
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value },()=>{
      if(name === "concepto") {
        if(event.target.value === "agregar") {
          this.setState({showSelectConcept:false});
        }

        
      }
    });
    this.setState({[name+'E']:false});
  };

  handleShowSelectConcepto = () => {
    this.setState({showSelectConcept:true},()=> {
      this.setState({newConcepto:""});
    })
  }


  // !!cHecar

  
  handleEliminarConceptos = (conceptoId) => {
    const self = this;
    const {
      ajaxSource,
      getData,
      dataUser,
      crearNotificacion,
      cerrarNotificacion,
      cerrarAlerta,
    } = this.props;
    var userId = dataUser.userId;

    crearNotificacion(
      "info",
      "Eliminando egreso...",
      "bottom",
      "right",
      1000000
    );
    
    if (conceptoId === "" || conceptoId === "agregar") {
      this.setState({ conceptoE: true }, () => {
        crearNotificacion(
          "warning",
          "Seleccione un concepto.",
          "bottom",
          "right"
        );
      });
    }
    
  else{
    getData(ajaxSource, "estadoCuenta/deleteConceptos.php", {
      userId: userId,
      conceptoId: conceptoId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if (data[0].error === 1) {
          cerrarNotificacion();
          crearNotificacion(
            "success",
            "Concepto eliminado con exito.",
            "bottom",
            "right",
            3000
          );
          self.getDialogIngresosInfo();
        } else {
          cerrarNotificacion();
          crearNotificacion(
            "error",
            "Hubo un problema al intentar eliminar el concepto " +
              data[0].error,
            "bottom",
            "right",
            3000
          );
        }
      }
    });
  };

  }



//Cambios 13 Enero
  //Test
  

  renderSelectInputConcepto = () => {
    const {classes} = this.props;
    const {concepto, conceptoE, showSelectConcept, disableSelectConcepto, conceptos} = this.state;
    
    var deleteBtn = <IconButton style={{padding:'0px'}} aria-label="Delete" onClick={()=>console.log("hi")}> <DeleteIcon />
    </IconButton>;
     


    if(showSelectConcept === true) {

       
      return (
        <MuiThemeProvider theme={FormInputs}>
          <FormControl variant="outlined" className={classes.formControl} error={conceptoE}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="payYear"
              style={{
                background: '#FFF',
                padding: '0px 5px 1px 5px'
              }}
            >
              Concepto
            </InputLabel>

            
            <Select
              value={concepto}
              onChange={this.handleChange('concepto')}
              input={
                <OutlinedInput id="concepto" labelWidth={0} disabled={disableSelectConcepto}/>
              }
            >
              <MenuItem value="agregar">Agregar</MenuItem>
               

              {conceptos.map(function (arr, index) {
                return(
                  <MenuItem key={index} value={arr.concepto}> 
                  {arr.concepto}
                    
                  </MenuItem>
                )
              })}

               
            </Select>


          </FormControl>
        </MuiThemeProvider>
      )
    } else {
      return(
        <MuiThemeProvider theme={FormInputs}>
          <div style={{width: '100%'}}>
            <IconButton style={{width:'50px', marginTop: '18px'}} onClick={()=>this.handleShowSelectConcepto()}>
              <SelectIcon/>
            </IconButton>
            <TextField
              error={conceptoE}
              label="Concepto"
              placeholder="Concepto"
              id="concepto"
              onChange={this.handleChange('newConcepto')}
              margin="normal"
              variant="outlined"
              style={{width:'85%'}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </MuiThemeProvider>
      )
    }
  }

  getDialogIngresosInfo = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion} = this.props;
    var userId = dataUser.userId;
    

    crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);

    getData(ajaxSource, "estadoCuenta/consultarIngresos.php",{
      userId:userId,
      }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var conceptos = data[0];

        self.setState({conceptos:conceptos}, ()=> {
          self.setState({disableSelectConcepto:false});
          cerrarNotificacion();
        });
      }
    });
  }

  crearIngreso = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, estadoYear, estadoMonth, getEstadoCuenta} = this.props;
    const {concepto, newConcepto, monto, showSelectConcept, fechaIngreso} = this.state;
    
    if(concepto === "") {
      this.setState({conceptoE:true}, ()=> {
        crearNotificacion("warning", "Seleccione un concepto.", "bottom", "right");
      });
    } else if(concepto === "agregar" && newConcepto === "" && showSelectConcept === false) {
      this.setState({conceptoE:true}, ()=> {
        crearNotificacion("warning", "Ingresa un concepto.", "bottom", "right");
      });
    } else if(monto === "" || monto === 0 || monto < 0) {
      this.setState({montoE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el monto.", "bottom", "right");
      });
    } else {

      var userId = dataUser.userId;
      var concept = concepto;
      if(concepto === "agregar" && newConcepto !== "" && showSelectConcept === false){
        concept = newConcepto;
      }

      crearNotificacion("info", "Creando ingreso...", "bottom", "right", 1000000);

      getData(ajaxSource, "estadoCuenta/createIngreso.php",{
        userId:userId,
        concept:concept,
        monto:monto,
        estadoYear:estadoYear,
        estadoMonth:estadoMonth,
        fechaIngreso:fechaIngreso}).then(function (response) {
        
          if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            cerrarNotificacion();
            setTimeout(function () {
              crearNotificacion("success", "Egreso creado con exito.", "bottom", "right", 3000);
              getEstadoCuenta();
              self.handleClose();
            },500);
          } else {
            cerrarNotificacion();
            crearNotificacion("error", "Hubo un problema al intentar crear el egreso " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  

  render() {
    const {classes, open} = this.props;
    const {fechaEg, montoE, monto, concepto} = this.state;
    var btnEliminarConcepto = <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.handleEliminarConceptos(concepto)}>
      Eliminar Concepto
    <DeleteIcon className={classes.rightIcon}/>
    </Button>

     

    
    return (
      <MuiThemeProvider theme={DialogT}>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"md"}
          onClose={this.handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="simple-dialog-title">
            <span className="darkGrayUpera">Crear Ingreso</span>
            <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', top:'10px'}} onClick={()=>this.handleClose()}>
              <CloseIcon className="darkGrayUpera"/>
            </IconButton>
          </DialogTitle>
          <List>
            <MuiThemeProvider theme={FormInputs}>
              <ListItem>
                <div className="row" style={{margin: '0px', width: '100%'}}>
                  <div className="col-md-12">
                    <MyDatePicker
                      disabled={false}
                      label={"Fecha Ingreso"}
                      selectedDate={fechaEg}
                      handleDateChange={this.handleDateChangeAct}
                    />
                  </div>
                  <div className="col-md-8">
                    {this.renderSelectInputConcepto()}
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={montoE}
                      label="Monto"
                      placeholder="$$$"
                      id="monto"
                      onChange={this.handleChange('monto')}
                      margin="normal"
                      variant="outlined"
                      style={{width:'100%'}}
                      value={monto}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <MuiThemeProvider theme={FormButtons}>
                      <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.crearIngreso()}>
                        Crear
                        <AddIcon className={classes.rightIcon}/>
                      </Button>
                    
                     {btnEliminarConcepto}
                    </MuiThemeProvider>
                  </div>
                </div>
              </ListItem>
            </MuiThemeProvider>
          </List>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogIngresos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogIngresos);
