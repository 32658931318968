/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import DataMaterialTable from "../../commons/DataMaterialTable";
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '10px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        maxWidth : "1200px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const DrawerTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba(38, 49, 96, .9) !important',
        backdropFilter: 'blur(15px)',
        width:'65%',
        padding:'0px',
        borderBottomLeftRadius:'15px',
        borderTopLeftRadius:'15px',
        flex:'none',
        marginTop:'-1px !important'
      }
    },
    MuiPaper:{
      elevation16: {
        boxShadow:'none !important'
      }
    }
  }
});

const DrawerThemeMobile = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiBackdrop:{
      root:{
        backgroundColor:'transparent !important'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba(51, 62, 102, .9) !important',
        backdropFilter: 'blur(7px)',
        width:'100%'
      }
    },
    MuiPaper:{
      elevation16: {
        boxShadow:'none !important'
      }
    }
  }
});

const DialogTMobile = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthMd: {
        width: 'calc(100% - 23px) !important',
        maxWidth: 'calc(100% - 23px) !important',
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop:'10px !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(255,255,255,.7)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        } ,
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important"
        }
      },
      disabled: {
        borderRadius: '5px'
      },
      notchedOutline: {
        borderColor: 'rgba(255,255,255,.8)'
      },
      input: {
        color:'#FFF',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#FFF'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color:'#FFF !important'
      }
    },
    MuiFormLabel: {
      root: {
        color:"rgba(255,255,255,.4)",
        fontWeight: '200'
      }
    },
    MuiSelect: {
      icon: {
        color:'#FFF'
      },
      select: {
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      }
    },
    MuiInputBase:{
      root:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      },
      input:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
        }
      },
    },
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogUser extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      residenteId:"",
      value:"",
      nombres:"",
      nombresE:false,
      apPaterno:"",
      apPaternoE:false,
      apMaterno:"",
      apMaternoE:false,
      email:"",
      emailE:"",
      celular:"",
      celularE:false,
      tipoUsuario:"",
      tipoUsuarioE:false,
      tipoUsuarioText:"",
      sexo:"",
      sexoE:false,
      userCasasLocales:[],
      usuariosMC:[],
      restablecerPass:"NO",
      payYearE:false,
      payYear:"",
      months:[],
      payMonthE:false,
      payMonth:[],
      disableSelectMonths:true,
      cuotaE: false,
      cuota: "",
      disableBtnPay: false,
      disableFormEdit:true,
      cuotaId:"",
      conceptoCuota:"",
      montoPagar:"",
      montoConDescuento:"",
      factura:"No",
      fisicaMoral:"fisica",
      rfc:"",
      rfcE:false,
      direccionFiscal:"No",
      calle:"",
      calleE:false,
      numero:"",
      numeroE:false,
      colonia:"",
      coloniaE:false,
      municipio:"",
      municipioE:false,
      estado:"",
      estadoE:false,
      pais:"",
      paisE:false,
      cp:"",
      cpE:false,
      formaPago:"Pago en efectivo",
      m3Local:"",
      m3LocalE:false,
      cfdi:"P01",
      formaPagoSat:"",
      formaPagoSatE:false,
      metodoPagoSat:"",
      metodoPagoSatE:false,
      predial:"",
      predialE:false,
      userPrecioM2:"",
      userPrecioM2E:false,
      casaLocal:"",
      casaLocalE:false,
      payedMonthsYear:[],
      blurForm:false,
      userIdCreated:"",
      disabeCasaLocal:true,
      showFormAddLocalCasa:false,
      showFormAddCuentas:false,
      agregarCasaLocal:"",
      agregarCasaLocalE:false,
      agregarM2:"",
      agregarM2E:false,
      agregarCuota:"",
      agregarCuotaE:false,
      agregarPredial:"",
      agregarPredialE:false,
      proximoMesPago:"",
      proximoMesPagoE:false,
      proximoYearPago:"",
      proximoYearPagoE:false,
      indexCasaLocalSelectedEdit:"",
      casaLocalIdSelected:"",
      numeroRecibosFacturas:"Uno",
      descuento:0,
      envioFacturaComplemento:"Si",
      montoPagarOriginal:"",
      indexUserIdMCSelectedEdit:"",
      userIdMCSelected:"",
      agregarCuentaE:false,
      agregarCuenta:"",
      nombreMC:"",
      facturaPorLocal:"",
      fechaPago:null,
      fechaPagoE:false,
      regimenFiscal: '601',
      regimenFiscalE: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOpenDialogHome,handleOpenDialogUser, option, usuarios, open, dataRes} = this.props;
    const {userIdCreated} = this.state;
 
    var residenteId = dataRes.residenteId;
     

    if(nextProps.handleOpenDialogUser !== handleOpenDialogUser && open === true) {
      if(option === "editar") {

        residenteId = userIdCreated;
        if(userIdCreated === "") {
          residenteId = dataRes.residenteId;
        }

        var dirFiscal = "No";
        if(dataRes.direccionFiscal !== null && dataRes.direccionFiscal !== "" && dataRes.direccionFiscal !== "null") {
          dirFiscal = dataRes.direccionFiscal;
        }

        var index = usuarios.map(function(e) { return e.residenteId; }).indexOf(residenteId);
        var dataResidente = usuarios[index];

        var celular = dataResidente.celular.split("+521")[1];
        this.setState({ disableFormEdit:true,nombres:dataResidente.nombres, apPaterno:dataResidente.appPaterno, apMaterno:dataResidente.appMaterno, email:dataResidente.email, celular:celular, tipoUsuario:dataResidente.upera, tipoUsuarioText:dataResidente.tipoUsuario, sexo:dataResidente.sexo, userCasasLocales:dataResidente.userCasasLocales, restablecerPass:"NO", factura:dataResidente.factura, fisicaMoral:dataResidente.fisicaMoral, rfc:dataResidente.rfc, cp:dataResidente.cp, m3Local:dataResidente.m3Local, cfdi:dataResidente.usoCfdi, predial:dataResidente.predial, userPrecioM2:dataResidente.cuotaId, formaPagoSat:dataResidente.formaPagoSat,metodoPagoSat:dataResidente.metodoPagoSat,envioFacturaComplemento:dataResidente.envioFacturaComplemento, direccionFiscal:dirFiscal, calle:dataResidente.calle, numero:dataResidente.numero, colonia:dataResidente.colonia, municipio:dataResidente.municipio, estado:dataResidente.estado, pais:dataResidente.pais, residenteId:residenteId, usuariosMC:dataResidente.usuariosMC, facturaPorLocal:dataResidente.facturaPorLocal, montoPagarOriginal:"", regimenFiscal:dataResidente.regimenFiscal});
      } else {
        this.setState({disableFormEdit:false});
      }
    }
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState, ()=> {
      handleClose();
    });
  };

  handleChange = name => event => {
    const self = this;
    const {residenteId} = this.state;
    this.setState({ [name]: event.target.value, [name+'E']:false },()=> {
      if(name === "payYear") {
        const {dataRes, usuarios} = this.props;
        const {userIdCreated} = this.state;

        var resId = userIdCreated;
        if(userIdCreated === "") {
          resId = residenteId;
        }

        var index = usuarios.map(function(e) { return e.residenteId; }).indexOf(resId);
        var dataResidente = usuarios[index];

        var year = event.target.value;
        self.setState({payedMonthsYear:dataResidente['year'+year],  disabeCasaLocal:false, casaLocal:"", payMonth: [], months: []});
      }
      if(name === "casaLocal") {
        const {payedMonthsYear, casaLocal, userCasasLocales} = this.state;
        self.setState({payMonth:[]},()=> {
          self.handleSelectMonths(payedMonthsYear[casaLocal]);
        });
        var index = userCasasLocales.findIndex(x => x.casaLocalId === casaLocal);
        var montoPagar = userCasasLocales[index].montoPagar;
        var conceptoCuota = userCasasLocales[index].cuotaConcepto;

        self.setState({montoPagar:montoPagar,conceptoCuota:conceptoCuota});
      }
      if(name === "agregarCasaLocal") {
        const {casasLocales} = this.props;
        const {agregarCasaLocal} = this.state;
        var indexCasaLocal = casasLocales.findIndex(x => x.casaLocalId === agregarCasaLocal);
        var m2 = casasLocales[indexCasaLocal].m2;
        var predial = casasLocales[indexCasaLocal].predial;

        self.setState({agregarM2:m2, agregarPredial:predial});
      }
      if(name === "payMonth") {
        const {payMonth, montoPagar, montoPagarOriginal} = this.state;
        var totalMeses = payMonth.length;
        if(montoPagarOriginal === "") {
          self.setState({montoPagarOriginal:montoPagar});
        }

        setTimeout(function () {
          const {montoPagarOriginal} = self.state;
          if(totalMeses >= 1) {
            var nuevoMonto = montoPagarOriginal * totalMeses;
            self.setState({montoPagar: nuevoMonto});
          }
        },800);

      }
      if(name === "descuento") {
        const {descuento, montoPagar, montoConDescuento} = this.state;

        if(descuento > 0 && descuento <= 100) {
          var p = Math.round((descuento / 100) * montoPagar);
          self.setState({montoConDescuento: montoPagar - p});
        } else {
          self.setState({montoConDescuento: ""});
        }
      }
      if(name === "tipoUsuario") {
        const {perfilUsuarios} = this.props;
        const {tipoUsuario} = this.state;
        var indexPerfilUsuarios = perfilUsuarios.findIndex(x => x.perfilId === tipoUsuario);
        var perfilUsuarioText = perfilUsuarios[indexPerfilUsuarios].tipoUsuario;
        console.log(perfilUsuarioText);
        self.setState({tipoUsuarioText:perfilUsuarioText});
      }
      if(name === "agregarCuenta") {
        const {usuariosParaMC} = this.props;
        const {agregarCuenta} = this.state;

        var indexUsuariosParaMC = usuariosParaMC.findIndex(x => x.residenteId === agregarCuenta);
        var nombreMC = usuariosParaMC[indexUsuariosParaMC].completName;

        console.log(nombreMC);
        console.log(agregarCuenta);
        self.setState({nombreMC:nombreMC});
      }
      if(name === "facturaPorLocal") {
        self.handleUpdateFacturaPorLocal();
      }
    });
  };

  handleChangeDate = (date) => {
    this.setState({fechaPago:date, fechaPagoE: false });
  }

  handleUpdateFacturaPorLocal = () => {
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, option, dataRes, checkPermisos} = this.props;
    const {facturaPorLocal, residenteId, tipoUsuario} = this.state;
    var userId = dataUser.userId;

    crearNotificacion("info", "Guardando...", "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/updateCasaPorLocalOption.php",{
      userId:userId,
      residenteId:residenteId,
      facturaPorLocal:facturaPorLocal,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          setTimeout(function () {
            crearNotificacion("success", "Opción guardada con exito.", "bottom", "right", 3000);
            setTimeout(function () {
              getAdminInfo("", tipoUsuario);
            }, 800);
          },500);
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar actualizar la opción " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  handleValidateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  handleValidateInputOnlyNumbers = (celularNumber) => {
    return /^[0-9]+$/.test(celularNumber);
  }

  handleValidateRfc = (rfc) => {
    const{fisicaMoral} = this.state;

    var _rfc_pattern = "";
    if(fisicaMoral === "moral") {
      _rfc_pattern = "^(([A-ZÑ&]{3})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{3})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{3})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{3})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
    } else if(fisicaMoral === "fisica") {
      _rfc_pattern = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
        "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
    }

    if (rfc.match(_rfc_pattern)){
      //alert("La estructura de la clave de RFC es valida");
      return true;
    }else {
      //alert("La estructura de la clave de RFC es incorrecta.");
      return false;
    }
  }

  handleSelectMonths = (payedMonths) => {
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    var userPayedMonths = [];
    for(var i= 0; i<12; i++) {

      if(!payedMonths.includes(months[i])) {
        userPayedMonths.push(months[i]);
      }
    }
    this.setState({months:userPayedMonths},()=> {
      this.setState({disableSelectMonths:false});
    });
  }

  hanldleDeleteUser = () => {
    const {crearAlerta} = this.props;
    crearAlerta("warning", "", "¿Seguro que deseas eliminar este usuario? Se eliminará toda información referente al usuario", true, this.deleteUser);
  }

  hanldeEnableForm = (value) => {
    this.setState({disableFormEdit:value});
  }

  handleShowFormAddLocalCasa = (state) => {
    this.setState({showFormAddLocalCasa:state});
  }

  handleShowFormAddCuentas = (state) => {
    this.setState({showFormAddCuentas:state});
  }

  handleEditarCasaLocal = (casaLocalId, index) => {
    this.setState({indexCasaLocalSelectedEdit:index, casaLocalIdSelected:casaLocalId});
  }

  handleEditarCuenta = (userIdMC, index) => {
    this.setState({indexUserIdMCSelectedEdit:index, userIdMCSelected:userIdMC});
  }

  createEditUser = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, option, dataRes, checkPermisos} = this.props;
    const {nombres, apPaterno, apMaterno, email, celular, tipoUsuario, tipoUsuarioText, sexo, noCasa, restablecerPass, factura, fisicaMoral, rfc, direccionFiscal, cp, m3Local, cfdi, predial, userPrecioM2, formaPagoSat, metodoPagoSat, envioFacturaComplemento, calle, numero, colonia, municipio, estado, pais, residenteId, regimenFiscal} = this.state;

    var celularLength = 0;
    if(celular !== "" && celular !== undefined) {
      celularLength = celular.length;
    }

    if(factura === "Si" && rfc === "") {
      this.setState({rfcE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el RFC del usuario.", "bottom", "right");
      });
    } else if(factura === "Si" && this.handleValidateRfc(rfc) === false) {
      this.setState({rfcE:true}, ()=> {
        crearNotificacion("warning", "La estructura de la clave del RFC es incorrecta.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && calle === "") {
      this.setState({calleE:true}, ()=> {
        crearNotificacion("warning", "Ingresa la calle.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && numero === "") {
      this.setState({numeroE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el número interior o exterior.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && colonia === "") {
      this.setState({coloniaE:true}, ()=> {
        crearNotificacion("warning", "Ingresa la colonia.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && municipio === "") {
      this.setState({municipioE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el municipio.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && estado === "") {
      this.setState({estadoE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el estado.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && pais === "") {
      this.setState({paisE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el país.", "bottom", "right");
      });
    } else if(factura === "Si" && direccionFiscal === "Si" && (cp !== "" && this.handleValidateInputOnlyNumbers(cp) === false)) {
      this.setState({cpE:true}, ()=> {
        crearNotificacion("warning", "Ingresa un codigo postal valido.", "bottom", "right");
      });
    } else if(factura === "Si" && formaPagoSat === "") {
      this.setState({formaPagoSatE:true}, ()=> {
        crearNotificacion("warning", "Selecciona una forma de pago.", "bottom", "right");
      });
    } else if(factura === "Si" && metodoPagoSat === "") {
      this.setState({metodoPagoSatE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el metodo de pago.", "bottom", "right");
      });
    } else if(factura === "Si" && fisicaMoral === "moral" && nombres === "") {
      this.setState({nombresE:true}, ()=> {
        crearNotificacion("warning", "Ingresa la razon social del usuario.", "bottom", "right");
      });
    } else if(nombres === "" && fisicaMoral === "fisica" && tipoUsuarioText !== "Usuario Multicuentas") {
      this.setState({nombresE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el nombre o nombres del usuario.", "bottom", "right");
      });
    } else if (apPaterno === "" && fisicaMoral === "fisica" && tipoUsuarioText !== "Usuario Multicuentas") {
      this.setState({apPaternoE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el apellido paterno del usuario.", "bottom", "right");
      });
    } else if (apMaterno === "" && fisicaMoral === "fisica" && tipoUsuarioText !== "Usuario Multicuentas") {
      this.setState({apMaternoE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el apellido materno del usuario.", "bottom", "right");
      });
    }
     else if(this.handleValidateEmail(email) === false) {
      this.setState({emailE:true}, ()=> {
        crearNotificacion("warning", "Ingresa un email valido.", "bottom", "right");
      });
    } else if(celular !== "" && celular !== undefined && (celularLength < 10 || this.handleValidateInputOnlyNumbers(celular) === false) && tipoUsuarioText !== "Usuario Multicuentas") {
      this.setState({celularE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el número celular a 10 digitos.", "bottom", "right");
      });
    } else if (tipoUsuario === "") {
      this.setState({tipoUsuarioE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el tipo de usuario.", "bottom", "right");
      });
    } else if (sexo === "" && tipoUsuarioText !== "Usuario Multicuentas") {
      this.setState({sexoE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el sexo del usuario.", "bottom", "right");
      });
    } else {
      var userId = dataUser.userId;

      var txtNoti = "Creando usuario...";
      var txtExito = "creado";
      var txtError = "crear";
      var resId = "";
      if(option === "editar") {
        txtNoti = "Editando usuario....";
        txtExito = "editado";
        txtError = "editar";
        resId = residenteId;
      }

      crearNotificacion("info", txtNoti, "bottom", "right", 1000000);

      getData(ajaxSource, "administracion/createEditUser.php",{
        userId:userId,
        nombres:nombres,
        apPaterno:apPaterno,
        apMaterno:apMaterno,
        email:email,
        celular:celular,
        tipoUsuario:tipoUsuario,
        sexo:sexo,
        noCasa:noCasa,
        restablecerPass:restablecerPass,
        option:option,
        residenteId:resId,
        factura:factura,
        fisicaMoral:fisicaMoral,
        rfc:rfc,
        direccionFiscal:direccionFiscal,
        calle:calle,
        numero:numero,
        colonia:colonia,
        municipio:municipio,
        estado:estado,
        pais:pais,
        cp:cp,
        userPrecioM2:userPrecioM2,
        m3Local:m3Local,
        cfdi:cfdi,
        predial:predial,
        formaPagoSat:formaPagoSat,
        metodoPagoSat:metodoPagoSat,
        envioFacturaComplemento:envioFacturaComplemento,
        tipoUsuarioText:tipoUsuarioText,
        regimenFiscal : regimenFiscal,
        }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;
          var userIdCreado = data[1];
          console.log("usuarioCreado: "+userIdCreado);
          console.log("tipoUsuario: "+tipoUsuario);

          if(data[0].error === 1) {
            cerrarNotificacion();
            setTimeout(function () {
              crearNotificacion("success", "Usuario "+txtExito+" con exito.", "bottom", "right", 3000);
              setTimeout(function () {
                getAdminInfo("", tipoUsuario);
                self.setState({disableFormEdit:true, blurForm:true, userIdCreated:userIdCreado});
              }, 800);
            },500);
          } else if(data[0].error === 2) {
            cerrarNotificacion();
            self.setState({emailE:true}, ()=> {
              crearNotificacion("error", "Este correo ya fue registrado con otro usuario.", "bottom", "right", 3000);
            });
          } else if(data[0].error === 3) {
            cerrarNotificacion();
            self.setState({celularE:true}, ()=> {
              crearNotificacion("error", "Este celular ya fue registrado con otro usuario.", "bottom", "right", 3000);
            });
          } else {
            cerrarNotificacion();
            crearNotificacion("error", "Hubo un problema al intentar "+txtError+" el usuario " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  deleteUser = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, dataRes, cerrarAlerta} = this.props;
    const {residenteId} = this.state;
    cerrarAlerta();

    var userId = dataUser.userId;
    crearNotificacion("info", "Eliminando usuario...", "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/deleteUser.php",{
      userId:userId,
      residenteId:residenteId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          setTimeout(function () {
            crearNotificacion("success", "Usuario eliminado con exito.", "bottom", "right", 3000);
            getAdminInfo();
            self.handleClose();
          },500);
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar eliminar el usuario " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  payEfectivo = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, dataRes, checkPermisos} = this.props;
    const {payMonth, payYear, conceptoCuota, formaPago, montoPagar, casaLocal, numeroRecibosFacturas, tipoUsuario, userIdCreated, montoConDescuento, residenteId, fechaPago} = this.state;

    var concpCuota = conceptoCuota;
    var casaLocalTxt = "la casa";
    if(checkPermisos("125")) {
      casaLocalTxt = "el local";
    }
    if(fechaPago === null) {
      this.setState({fechaPagoE:true},()=> {
        crearNotificacion("warning", "Selecciona la fecha de pago.", "bottom", "right");
      });
    } else if(payYear === "") {
      this.setState({payYearE:true},()=> {
        crearNotificacion("warning", "Seleccione el año correspondiente.", "bottom", "right");
      });
    } else if(casaLocal === "") {
      this.setState({casaLocalE:true},()=> {
        crearNotificacion("warning", "Seleccione "+casaLocalTxt+".", "bottom", "right");
      });
    } else if(Object.keys(payMonth).length === 0) {
      this.setState({payMonthE:true},()=> {
        crearNotificacion("warning", "Seleccione al menos un mes a pagar.", "bottom", "right");
      });
    } else {
      var userId = dataUser.userId;
      var resId = userIdCreated;
      if(userIdCreated === "") {
        resId = residenteId;
      }

      crearNotificacion("info", "Registrando pago...", "bottom", "right", 1000000);

      var precioPagar = montoPagar;
      if(checkPermisos("125")) {
        precioPagar = montoPagar;
        concpCuota = "Mantenimiento"
      }

      if(montoConDescuento !== "") {
        precioPagar = montoConDescuento;
      }

      var numRecibosFacturas = numeroRecibosFacturas;
      if(Object.keys(payMonth).length == 1) {
        numRecibosFacturas = "Uno";
      }

      getData(ajaxSource, "ecomm/payOrder.php",{
        userId:userId,
        payMonths:payMonth,
        payYear:payYear,
        montoPagar:precioPagar,
        residenteId:resId,
        option:"pagoEfectivo",
        conceptoCuota:concpCuota,
        casaLocal:casaLocal,
        formaPago:formaPago,
        numeroRecibosFacturas:numRecibosFacturas,
        fechaPago:fechaPago
      }).then(function (response) {
        if (response.data) {
          var data = response.data;

          self.setState({disableBtnPay:false, montoPagarOriginal:""},()=> {

            if(data[0].error === 1) {
              cerrarNotificacion();
              //self.handleClose();

              setTimeout(function () {
                crearNotificacion("success", "Pago registrado con exito.", "bottom", "right", 3000);
                getAdminInfo(1, tipoUsuario);
              },500);
            }
          });
        }
      });
    }
  }


  //Agregar casas

  addCasaLocalUser = (option, clid) => {
    console.log("casaLocalId:"+clid+"||OPTION+"+option);

    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion, dataRes, getAdminInfo, checkPermisos} = this.props;
    const {userIdCreated, blurForm, agregarCasaLocal, agregarM2, agregarCuota, agregarPredial, proximoMesPago, proximoYearPago, casaLocalIdSelected, tipoUsuario, residenteId} = this.state;
    var userId = dataUser.userId;

    var resId = residenteId;
    if(userIdCreated !== "") {
      resId = userIdCreated;
    }

    var txtExito = "";
    var txtError = "";
    var txtError2 = "";
    var textCasaLocal = "la casa";

    if(option === "agregar") {
      txtExito = "Casa agregada con exito.";
      txtError = "Hubo un problema al intentar agregar la casa";
      txtError2 = "Esta casa ya se encuentra asignada a otro usuario"
    } else if(option === "eliminar") {
      txtExito = "Casa eliminada con exito.";
      txtError = "Hubo un problema al intentar eliminar la casa";
    } else if(option === "editar") {
      txtExito = "Casa editada con exito.";
      txtError = "Hubo un problema al intentar editar la casa";
    }

    if(checkPermisos("125")) {
      if(option === "agregar") {
        txtExito = "Local agregado con exito.";
        txtError = "Hubo un problema al intentar agregar el local";
        txtError2 = "Este local ya se encuentra asignado a otro usuario"
      } else if(option === "eliminar") {
        txtExito = "Local eliminado con exito.";
        txtError = "Hubo un problema al intentar eliminar el local";
      } else if(option === "editar") {
        txtExito = "Local editado con exito.";
        txtError = "Hubo un problema al intentar editar el local";
      }
      textCasaLocal = "el local";
    }

    if(agregarCasaLocal === "" && option === "agregar") {
      this.setState({agregarCasaLocalE:true}, ()=> {
        crearNotificacion("warning", "Selecciona "+textCasaLocal+".", "bottom", "right");
      });
    } else if(agregarM2 === "" && checkPermisos("125") && option === "agregar") {
      this.setState({agregarM2E:true}, ()=> {
        crearNotificacion("warning", "Ingresa los M2.", "bottom", "right");
      });
    } else if(agregarCuota === "" && option === "agregar") {
      this.setState({agregarCuotaE:true}, ()=> {
        crearNotificacion("warning", "Selecciona la cuota.", "bottom", "right");
      });
    } else if(agregarCuota === "" && option === "editar") {
      this.setState({agregarCuotaE:true}, ()=> {
        crearNotificacion("warning", "Selecciona la cuota.", "bottom", "right");
      });
    } else if(agregarPredial === "" && checkPermisos("125") && option === "agregar") {
      this.setState({agregarPredialE:true}, ()=> {
        crearNotificacion("warning", "Selecciona la cuota.", "bottom", "right");
      });
    }  else if(proximoMesPago === "" && checkPermisos("125") && option === "agregar") {
      this.setState({proximoMesPagoE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el proximo mes a pagar.", "bottom", "right");
      });
    } else if(proximoYearPago === "" && checkPermisos("125") && option === "agregar") {
      this.setState({proximoYearPagoE:true}, ()=> {
        crearNotificacion("warning", "Selecciona el proximo año a pagar.", "bottom", "right");
      });
    } else {

      var casaLocalId = agregarCasaLocal;
      if(option === "eliminar") {
        casaLocalId = clid;
      }

      if(option === "editar") {
        casaLocalId = casaLocalIdSelected;
      }

      crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);

      getData(ajaxSource, "administracion/addCasaLocalUser.php", {
        userId: userId,
        option: option,
        residenteId: resId,
        casaLocalId: casaLocalId,
        m2: agregarM2,
        cuotaId: agregarCuota,
        predial: agregarPredial,
        proximoMesPago:proximoMesPago,
        proximoYearPago:proximoYearPago
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if (data[0].error === 1) {
            crearNotificacion("success", txtExito, "bottom", "right", 3000);
            self.handleShowFormAddLocalCasa(false);
            self.setState({indexCasaLocalSelectedEdit:"", casaLocalIdSelected:"", agregarCasaLocal:"", agregarM2:"", agregarPredial:"", agregarCuota:"", proximoMesPago:"", proximoYearPago:""});
            getAdminInfo(1, tipoUsuario);
          } else if (data[0].error === 2) {
            crearNotificacion("error", txtError2, "bottom", "right", 3000);
          } else {
            crearNotificacion("error", txtError + " " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  addCuentasUser = (option, clid) => {
  const self = this;
  const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion, dataRes, getAdminInfo, checkPermisos} = this.props;
  const {userIdCreated, blurForm, agregarCuenta, userIdMCSelected, tipoUsuario, residenteId} = this.state;
  var userId = dataUser.userId;

  var resId = residenteId;
  if(userIdCreated !== "") {
    resId = userIdCreated;
  }

  var txtExito = "";
  var txtError = "";
  var txtError2 = "";
  var txtLoading = "";

  if(option === "agregar") {
    txtLoading = "Agregando cuenta...";
    txtExito = "Cuenta agregada con exito.";
    txtError = "Hubo un problema al intentar agregar la cuenta";
    txtError2 = "Esta cuenta ya se encuentra agregada a otro usuario."
  } else if(option === "eliminar") {
    txtLoading = "Eliminando cuenta...";
    txtExito = "Cuenta eliminada con exito.";
    txtError = "Hubo un problema al intentar eliminar la cuenta";
  } else if(option === "editar") {
    txtExito = "Cuenta editada con exito.";
    txtError = "Hubo un problema al intentar editar la cuenta";
  }

  if(agregarCuenta === "" && option === "agregar") {
    this.setState({agregarCuentaE:true}, ()=> {
      crearNotificacion("warning", "Selecciona la cuenta.", "bottom", "right");
    });
  } else {
    var userIdMC = agregarCuenta;
    if(option === "eliminar") {
      userIdMC = clid;
    }

    if(option === "editar") {
      userIdMC = userIdMCSelected;
    }

    crearNotificacion("info", txtLoading, "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/addCuentaToUserMC.php", {
      userId: userId,
      option: option,
      residenteId: resId,
      userIdMC: userIdMC,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if (data[0].error === 1) {
          crearNotificacion("success", txtExito, "bottom", "right", 3000);
          self.handleShowFormAddCuentas(false);
          self.setState({indexUserIdMCSelectedEdit:"", userIdMCSelected:"", agregarCuenta:""});
          getAdminInfo(1, tipoUsuario);
        } else if (data[0].error === 2) {
          crearNotificacion("error", txtError2, "bottom", "right", 3000);
        } else {
          crearNotificacion("error", txtError + " " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }
}

  renderPasswordOption = () => {
    const {classes, option} = this.props
    const {restablecerPass, disableFormEdit} = this.state;

    if(option === "crear") {
      return(
        <TextField
          disabled={true}
          label="Contraseña"
          placeholder="Contraseña"
          id="celular"
          value="1234567"
          margin="normal"
          variant="outlined"
          style={{width:'100%'}}
          InputLabelProps={{
            shrink: true
          }}
          type="password"
        />
      )
    } else {
      return(
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor="restablecerPass"
            style={{
              background: '#FFF',
              padding: '0px'
            }}
          >
            ¿Restablecer contraseña?
          </InputLabel>
          <Select
            value={restablecerPass}
            onChange={this.handleChange('restablecerPass')}
            input={
              <OutlinedInput id="restablecerPass" labelWidth={210} disabled={disableFormEdit}/>
            }
          >
            <MenuItem value={"NO"}>
              NO
            </MenuItem>
            <MenuItem value={"SI"}>
              SI
            </MenuItem>
          </Select>
        </FormControl>
      )
    }
  }

  renderSelectUsoCfdi = () => {
    const {classes} = this.props;
    const {factura, cfdi, disableFormEdit, fisicaMoral} = this.state;

    var usos = [
      {cfdi:'G01',descripcion:'Adquisición de mercancias', fisicaMoral:'ambas'},
      {cfdi:'G02',descripcion:'Devoluciones, descuentos o bonificaciones', fisicaMoral:'ambas'},
      {cfdi:'G03',descripcion:'Gastos en general', fisicaMoral:'ambas'},
      {cfdi:'I01',descripcion:'Construcciones', fisicaMoral:'ambas'},
      {cfdi:'I02',descripcion:'Mobilario y equipo de oficina por inversiones', fisicaMoral:'ambas'},
      {cfdi:'I03',descripcion:'Equipo de transporte', fisicaMoral:'ambas'},
      {cfdi:'I04',descripcion:'Equipo de computo y accesorios', fisicaMoral:'ambas'},
      {cfdi:'I05',descripcion:'Dados, troqueles, moldes, matrices y herramental', fisicaMoral:'ambas'},
      {cfdi:'I06',descripcion:'Comunicaciones telefónicas', fisicaMoral:'ambas'},
      {cfdi:'I07',descripcion:'Comunicaciones satelitales', fisicaMoral:'ambas'},
      {cfdi:'I08',descripcion:'Otra maquinaria y equipo', fisicaMoral:'ambas'},
      {cfdi:'D01',descripcion:'Honorarios médicos, dentales y gastos hospitalarios', fisicaMoral:'fisica'},
      {cfdi:'D02',descripcion:'Gastos médicos por incapacidad o discapacidad', fisicaMoral:'fisica'},
      {cfdi:'D03',descripcion:'Gastos funerales', fisicaMoral:'fisica'},
      {cfdi:'D04',descripcion:'Donativos', fisicaMoral:'fisica'},
      {cfdi:'D05',descripcion:'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)', fisicaMoral:'fisica'},
      {cfdi:'D06',descripcion:'Aportaciones voluntarias al SAR', fisicaMoral:'fisica'},
      {cfdi:'D07',descripcion:'Primas por seguros de gastos médicos', fisicaMoral:'fisica'},
      {cfdi:'D08',descripcion:'Gastos de transportación escolar obligatoria', fisicaMoral:'fisica'},
      {cfdi:'D09',descripcion:'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones', fisicaMoral:'fisica'},
      {cfdi:'D10',descripcion:'Pagos por servicios educativos (colegiaturas)', fisicaMoral:'fisica'},
      {cfdi:'P01',descripcion:'Por definir', fisicaMoral:'ambas'},
    ];

    if(factura === "Si") {
      return(
        <div className="col-md-8">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="fisicaMoral"
              style={{
                background: '#FFF',
                padding: '0px'
              }}
            >
              Uso CFDI
            </InputLabel>
            <Select
              value={cfdi}
              onChange={this.handleChange('cfdi')}
              input={
                <OutlinedInput id="cfdi" labelWidth={70} disabled={disableFormEdit}/>
              }
            >
              {usos.map(function (arr, index) {

                if(arr.fisicaMoral === "ambas" || arr.fisicaMoral === fisicaMoral) {
                  return (
                    <MenuItem key={index} value={arr.cfdi}>
                      {arr.descripcion}
                    </MenuItem>
                  )
                }
              })}
            </Select>
          </FormControl>
        </div>
      )
    }
  }

  renderRegimenFiscal  = () => {
    const {classes} = this.props;
    const {factura, regimenFiscal, disableFormEdit, fisicaMoral} = this.state;


    console.log(regimenFiscal)
    var regimenFiscales = [
      {regimenFiscal:'601',descripcion:'General de Ley Personas Morales', fisicaMoral:'moral'},
      {regimenFiscal:'603',descripcion:'Personas Morales con Fines no Lucrativos', fisicaMoral:'moral'},
      {regimenFiscal:'610',descripcion:'Residentes en el Extranjero sin Establecimiento Permanente en México', fisicaMoral:'ambas'},
      {regimenFiscal:'620',descripcion:'Soociedades Cooperativas de Produccion que optan por definir sus ingresos', fisicaMoral:'moral'},
      {regimenFiscal:'622',descripcion:'Actividades Agricolas, Ganaderas, Silvicolas y Pesqueras', fisicaMoral:'moral'},
      {regimenFiscal:'623',descripcion:'Opcional para Grupos de Sociedades', fisicaMoral:'moral'},
      {regimenFiscal:'624',descripcion:'Coordinados', fisicaMoral:'moral'},
      {regimenFiscal:'626',descripcion:'Regimen Simplificado de Confianza', fisicaMoral:'moral'},
      

      {regimenFiscal:'605',descripcion:'Sueldo y Salarios e Ingresos Asimilados a Salarios', fisicaMoral:'fisica'},
      {regimenFiscal:'606',descripcion:'Arrendamiento',fisicaMoral:'fisica'},
      {regimenFiscal:'607',descripcion:'Régimen de Enajenación o Adquisición de Bienes',fisicaMoral:'fisica'},
      {regimenFiscal:'608',descripcion:'Demás Ingresos',fisicaMoral:'fisica'},

      {regimenFiscal:'611',descripcion:'Ingresos por Dividendos (socios y accionistas)',fisicaMoral:'fisica'},
      {regimenFiscal:'612',descripcion:'Personas Físicas con Actividdades Empresariales y Profesionales',fisicaMoral:'fisica'},
      {regimenFiscal:'614',descripcion:'Ingresos por intereses',fisicaMoral:'fisica'},
      {regimenFiscal:'615',descripcion:'Régimen de los ingresos por obtencion de premios ', fisicaMoral:'fisica'},
      {regimenFiscal:'616',descripcion:'Sin obligaciones fiscales', fisicaMoral:'fisica'},
      {regimenFiscal:'621',descripcion:'Incorporación Fiscal', fisicaMoral:'fisica'},
      {regimenFiscal:'625',descripcion:'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',fisicaMoral:'fisica'}, ,
      {regimenFiscal:'626',descripcion:'Regimen Simplificado de Confianza',fisicaMoral:'fisica'},
    ];


    if(factura === "Si") {
      return(
        <div className="col-md-8">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="regimenFiscal"
              style={{
                background: '#FFF',
                padding: '0px'
              }}
            >
              Regimen fiscal
            </InputLabel>
            <Select
              value={regimenFiscal}
              onChange={this.handleChange('regimenFiscal')}
              input={
                <OutlinedInput id="regimenFiscal" labelWidth={70} disabled={disableFormEdit}/>
              }
            >
              {regimenFiscales.map(function (arr, index) {

                if(arr.fisicaMoral === "ambas" || arr.fisicaMoral === fisicaMoral) {
                  return (
                    <MenuItem key={index} value={arr.regimenFiscal}>
                      {arr.descripcion}
                    </MenuItem>
                  )
                }
              })}
            </Select>
          </FormControl>
        </div>
      )
    }
  }

  renderSelectFormaPago = () => {
    const {classes} = this.props;
    const {factura, formaPagoSat, formaPagoSatE, disableFormEdit} = this.state;

    var formaPagos = [
      {formaPago:'01',descripcion:'Efectivo'},
      {formaPago:'03',descripcion:'Transferencia electrónica de fondos'},
      {formaPago:'04',descripcion:'Tarjeta de crédito'},
      {formaPago:'28',descripcion:'Tarjeta de débito'},
      {formaPago:'99',descripcion:'Por definir'},
    ];

    if(factura === "Si") {
      return(
        <div className="col-md-4">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="formaPagoSat"
              style={{
                background: 'transparent',
                padding: '0px'
              }}
            >
              Forma de Pago
            </InputLabel>
            <Select
              value={formaPagoSat}
              onChange={this.handleChange('formaPagoSat')}
              input={
                <OutlinedInput id="formaPagoSat" labelWidth={125} disabled={disableFormEdit} error={formaPagoSatE}/>
              }
            >
              {formaPagos.map(function (arr, index) {
                return (
                  <MenuItem key={index} value={arr.formaPago}>
                    {arr.descripcion}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      )
    }
  }



  // UPDATE 11/11/22 REGIMEN FISCAL


  renderSelectMetodoPago = () => {
    const {classes} = this.props;
    const {factura, metodoPagoSat, metodoPagoSatE, disableFormEdit} = this.state;

    var metodoPagos = [
      {metodoPago:'PUE', descripcion:'Pago en una sola exhibición'},
      //{metodoPago:'PIP', descripcion:'Pago inicial y parcialidades'},
      {metodoPago:'PPD', descripcion:'Pago en parcialidades o diferido'},
    ];

    if(factura === "Si") {
      return(
        <div className="col-md-4">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="formaPagoSat"
              style={{
                background: 'transparent',
                padding: '0px'
              }}
            >
              Metodo de pago
            </InputLabel>
            <Select
              value={metodoPagoSat}
              onChange={this.handleChange('metodoPagoSat')}
              input={
                <OutlinedInput id="metodoPagoSat" labelWidth={125} disabled={disableFormEdit} error={metodoPagoSatE}/>
              }
            >
              {metodoPagos.map(function (arr, index) {
                return (
                  <MenuItem key={index} value={arr.metodoPago}>
                    {arr.descripcion}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      )
    }
  }

  renderNombrePersona = () => {
    const {disableFormEdit, nombres, nombresE, apPaterno, apPaternoE, apMaterno, apMaternoE, fisicaMoral} = this.state;

    if(fisicaMoral === "fisica") {
      return(
        <div className="row" style={{margin: '0px', width: '100%'}}>
          <div className="col-md-4">
            <TextField
              disabled={disableFormEdit}
              error={nombresE}
              label="Nombre(s)"
              placeholder="Nombre(s)"
              id="nombres"
              onChange={this.handleChange('nombres')}
              value={nombres}
              margin="normal"
              variant="outlined"
              style={{width:'100%'}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              disabled={disableFormEdit}
              error={apPaternoE}
              label="Apellido Paterno"
              placeholder="Apellido Paterno"
              id="apPaterno"
              onChange={this.handleChange('apPaterno')}
              value={apPaterno}
              margin="normal"
              variant="outlined"
              style={{width:'100%'}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              disabled={disableFormEdit}
              error={apMaternoE}
              label="Apellido Materno"
              placeholder="Apellido Materno"
              id="apMaterno"
              onChange={this.handleChange('apMaterno')}
              value={apMaterno}
              margin="normal"
              variant="outlined"
              style={{width:'100%'}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      )
    } else {
      return(
        <div className="col-md-12">
          <TextField
            disabled={disableFormEdit}
            error={nombresE}
            label="Razón Social"
            placeholder="Razón Social"
            id="apMaterno"
            onChange={this.handleChange('nombres')}
            value={nombres}
            margin="normal"
            variant="outlined"
            style={{width:'100%'}}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )
    }
  }

  renderSelectMesAPagar = () => {
    const {classes, checkPermisos} = this.props;
    const {proximoMesPago, proximoMesPagoE} = this.state;

    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    return (
      <FormControl variant="outlined" className={classes.formControl} error={proximoMesPagoE}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="sexo"
          style={{
            background: 'transparent',
            padding: '0px'
          }}
        >
          Mes a pagar
        </InputLabel>
        <Select
          value={proximoMesPago}
          onChange={this.handleChange('proximoMesPago')}
          input={
            <OutlinedInput id="sexo" labelWidth={100}/>
          }
        >
          {months.map(function (arr, index) {

            return (
              <MenuItem key={index} value={months[index]}>
                {months[index]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  renderSelectYearAPagar = () => {
    const {classes, checkPermisos, dataUser} = this.props;
    const {proximoYearPago, proximoYearPagoE} = this.state;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;

    return (
      <FormControl variant="outlined" className={classes.formControl} error={proximoYearPagoE}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="sexo"
          style={{
            background: 'transparent',
            padding: '0px'
          }}
        >
          Año
        </InputLabel>
        <Select
          value={proximoYearPago}
          onChange={this.handleChange('proximoYearPago')}
          input={
            <OutlinedInput id="sexo" labelWidth={50}/>
          }
        >
          <MenuItem value={passYear}>{passYear}</MenuItem>
          <MenuItem value={actualYear}>{actualYear}</MenuItem>
          <MenuItem value={nextYear}>{nextYear}</MenuItem>
        </Select>
      </FormControl>
    )
  }

  renderTablaCasasLocales = () => {
    const self = this;
    const {classes, perfilUsuarios, dataUser, checkPermisos, crearNotificacion, dataRes, option, casasLocales, casasLocalesCuotas} = this.props;
    const {userCasasLocales, disableFormEdit, tipoUsuario, tipoUsuarioText, blurForm, showFormAddLocalCasa, agregarCasaLocal, agregarCasaLocalE, agregarM2E, agregarM2, agregarCuota, agregarCuotaE,agregarPredial, agregarPredialE, indexCasaLocalSelectedEdit, facturaPorLocal, factura} = this.state;

    var labelCasasLocales = "la casa";
    var textCasasLocales = "casa";
    var textCasasLocalesTH = "Casa";
    var textCuota = "Cuota";
    var columnM2 = { title: "m2", field: 'm2', cellStyle: {textAlign: 'center'}, hidden:true}
    var columnPredial = { title: "Predial", field: 'predial', cellStyle: {textAlign: 'center'}, hidden:true}
    if(checkPermisos("125")) {
      labelCasasLocales = "el local";
      textCasasLocales = "local";
      textCasasLocalesTH = "Local";
      columnM2 = { title: "m2", field: 'm2', cellStyle: {textAlign: 'center'}}
      textCuota = "Precio x M2";
      columnPredial = { title: "Predial", field: 'predial', cellStyle: {textAlign: 'center'}}
    }

    var formAddLocalUser = "";
    if(showFormAddLocalCasa === true || Object.keys(userCasasLocales).length === 0) {
      formAddLocalUser = <tr>
        <td>
          <FormControl variant="outlined" className={classes.formControl} error={agregarCasaLocalE}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="agregarCasaLocalE"
              style={{
                padding: '0px'
              }}
            >
              Selecciona {labelCasasLocales}
            </InputLabel>
            <Select
              value={agregarCasaLocal}
              onChange={this.handleChange('agregarCasaLocal')}
              input={
                <OutlinedInput labelWidth={135} id="agregarCasaLocal" error={agregarCasaLocalE}/>
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
                
              {casasLocales.map(function (arr, index) {
                return (
                  <MenuItem key={index} value={arr.casaLocalId}>
                    {arr.casaLocal}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </td>
        {checkPermisos("125") && (
        <td>
          <TextField
            error={agregarM2E}
            label="M2"
            placeholder="Ingresa los M2"
            id="agregarM2"
            value={agregarM2}
            margin="normal"
            variant="outlined"
            style={{width:'100%'}}
            onChange={this.handleChange('agregarM2')}
            InputLabelProps={{
              shrink: true
            }}
          />
        </td>
        )}
        <td>
          <FormControl variant="outlined" className={classes.formControl} error={agregarCuotaE}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="agregarCuota"
              style={{
                padding: '0px'
              }}
            >
              Selecciona la cuota
            </InputLabel>
            <Select
              value={agregarCuota}
              onChange={this.handleChange('agregarCuota')}
              input={
                <OutlinedInput labelWidth={160} id="agregarCuota" error={agregarCuotaE}/>
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {casasLocalesCuotas.map(function (arr, index) {

                return (
                  <MenuItem key={index} value={arr.cuotaId}>
                    {arr.concepto}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </td>
        {checkPermisos("125") && (
        <td>
          <TextField
            error={agregarPredialE}
            label="Predial"
            placeholder="Ingresa el predial"
            id="agregarPredial"
            value={agregarPredial}
            margin="normal"
            variant="outlined"
            style={{width:'100%'}}
            onChange={this.handleChange('agregarPredial')}
            InputLabelProps={{
              shrink: true
            }}
          />
        </td>
        )}
        <td>
          {this.renderSelectMesAPagar()}
        </td>
        <td>
          {this.renderSelectYearAPagar()}
        </td>
        <td>

        </td>
      </tr>
    }

    var btnAgregarCasaLocal = <MuiThemeProvider theme={FormButtons}>
      <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.handleShowFormAddLocalCasa(true)}>
        Agregar {textCasasLocales}
        <AddIcon className={classes.rightIcon}/>
      </Button>
    </MuiThemeProvider>

    var btnCancelarCasaLocal = "";
    var btnAgregarNuevoCasaLocal = "";
    var btnEditarCasaLocal = "";
    var btnCancelarEditarCasaLocal = "";

    if(showFormAddLocalCasa === true || Object.keys(userCasasLocales).length === 0) {
      btnAgregarCasaLocal = "";
      btnCancelarCasaLocal = <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="default" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.handleShowFormAddLocalCasa(false)}>
          Cancelar
          <CancelIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>
      btnAgregarNuevoCasaLocal = <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.addCasaLocalUser("agregar")}>
          Agregar
          <AddIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>
    }

    if(indexCasaLocalSelectedEdit !== "") {
      btnAgregarNuevoCasaLocal = "";
      btnAgregarCasaLocal = "";
      btnEditarCasaLocal =  <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.addCasaLocalUser("editar")}>
          Guardar Cambios
          <EditIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>
      btnCancelarEditarCasaLocal = <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="default" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.handleEditarCasaLocal("", "")}>
          Cancelar
          <CancelIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>

    }

    if(Object.keys(userCasasLocales).length === 0) {
      btnCancelarCasaLocal = "";
    }

    var step2 = "";
    if(option === "crear") {
      step2 = <div style={{marginBottom: '-10px', fontSize: '18px', fontWeight: 'bold', color: '#0345ea'}}>Paso dos</div>
    }

    var styleThResidencial = {color:'#FFF', textAlign:'center', width:'270px'};
    if(checkPermisos("125")) {
      styleThResidencial = {color:'#FFF', textAlign:'center', minWidth:'134px'};
    }

    if((option === "editar" || blurForm === true) && tipoUsuarioText !== "Personal Mantenimiento" && tipoUsuarioText !== "Vigilante") {
      return (
        <div style={{margin: '0px', width: '100%'}}>
          <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px', paddingBottom:'0px', marginBottom: '0px'}}>
            <div className="col-md-12">
              {step2}
              <h3 style={{marginTop:'0px', color:'#FFF', borderBottom: '3px solid #0f4ce6', fontWeight:'bold'}}>Asignar {textCasasLocales} y cuota</h3>
            </div>
            <div className="col-md-12">
              {(userCasasLocales.length > 1 && checkPermisos("125") && factura === "Si") &&
                <MuiThemeProvider theme={FormInputs}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor=""
                      style={{
                        padding: '0px'
                      }}
                    >
                      ¿Factura por local?
                    </InputLabel>
                    <Select
                      value={facturaPorLocal}
                      onChange={this.handleChange('facturaPorLocal')}
                      input={
                        <OutlinedInput id="payYear" labelWidth={160}/>
                      }
                    >
                      <MenuItem value={0}>Factura por cada local</MenuItem>
                      <MenuItem value={1}>Una factura por todos los locales</MenuItem>
                    </Select>
                  </FormControl>
                </MuiThemeProvider>
              }
            </div>
            <div className="col-md-12" style={{marginTop:'20px'}}>
              <MuiThemeProvider theme={FormInputs}>
                <table style={{width:'100%'}}>
                  <thead>
                    <tr>
                      <th style={styleThResidencial}>{textCasasLocalesTH}</th>
                      {checkPermisos("125") && (
                      <th style={{color:'#FFF', textAlign:'center', width:'auto'}}>M2</th>
                      )}
                      <th style={styleThResidencial}>{textCuota}</th>
                      {checkPermisos("125") && (
                      <th style={{color:'#FFF', textAlign:'center'}}>Predial</th>
                      )}
                      <th style={{color:'#FFF', textAlign:'center'}}>Proximo Pago</th>
                      <th style={{color:'#FFF', textAlign:'center'}}> </th>
                    </tr>
                  </thead>
                  <tbody>
                  {userCasasLocales.map(function (arr, index) {

                    var editCasaLocal = false;
                    if(indexCasaLocalSelectedEdit === index) {
                      editCasaLocal = true;
                    }

                    return(
                      <tr key={index}>
                        <td>
                          <TextField
                            disabled={true}
                            label=""
                            placeholder=""
                            id=""
                            value={arr.casaLocal}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </td>
                        {checkPermisos("125") && (
                        <td>
                          <TextField
                            disabled={true}
                            label=""
                            placeholder=""
                            id=""
                            value={arr.m2}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </td>
                        )}
                        <td>
                          {!editCasaLocal ?
                            <TextField
                              disabled={true}
                              label=""
                              placeholder=""
                              id=""
                              value={arr.cuotaConcepto}
                              margin="normal"
                              variant="outlined"
                              style={{width:'100%'}}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                            :
                            <FormControl variant="outlined" className={classes.formControl} error={agregarCuotaE}>
                              <InputLabel
                                ref={ref => {
                                  self.InputLabelRef = ref;
                                }}
                                htmlFor="agregarCuota"
                                style={{
                                  padding: '0px'
                                }}
                              >
                                Selecciona la cuota
                              </InputLabel>
                              <Select
                                value={agregarCuota}
                                onChange={self.handleChange('agregarCuota')}
                                input={
                                  <OutlinedInput labelWidth={160} id="agregarCuota" error={agregarCuotaE}/>
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                {casasLocalesCuotas.map(function (arr, index) {

                                  return (
                                    <MenuItem key={index} value={arr.cuotaId}>
                                      {arr.concepto}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          }
                        </td>
                        {checkPermisos("125") && (
                        <td>
                          <TextField
                            disabled={true}
                            label=""
                            placeholder=""
                            id=""
                            value={arr.predial}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </td>
                        )}
                        <td>
                          <TextField
                            disabled={true}
                            label=""
                            placeholder=""
                            id=""
                            value={arr.proximoMesPago+ " "+arr.ultimoYearPagado}
                            margin="normal"
                            variant="outlined"
                            style={{width:'100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </td>
                        <td>
                          {!editCasaLocal &&
                          <MuiThemeProvider theme={FormButtons}>
                            <Button variant="contained" color="default" className={classes.button}
                                    onClick={() => self.handleEditarCasaLocal(arr.casaLocalId, index)}>
                              <EditIcon/>
                            </Button>
                          </MuiThemeProvider>
                          }
                          {editCasaLocal && <MuiThemeProvider theme={FormButtons}>
                            <Button variant="contained" color="secondary" className={classes.button} onClick={() => self.addCasaLocalUser("eliminar", arr.casaLocalId)}>
                              <DeleteIcon />
                            </Button>
                          </MuiThemeProvider>
                          }
                        </td>
                      </tr>
                    )
                  })}
                  {formAddLocalUser}
                  </tbody>
                </table>
              </MuiThemeProvider>
            </div>
            <div className="col-md-12">
              {btnAgregarCasaLocal}
              {btnAgregarNuevoCasaLocal}
              {btnCancelarCasaLocal}
              {btnEditarCasaLocal}
              {btnCancelarEditarCasaLocal}
            </div>
          </div>
        </div>
      )
    }
  }

  renderTablaUsuariosMC = () => {
    const self = this;
    const {classes, usuariosParaMC, option} = this.props;
    const {blurForm, tipoUsuarioText, showFormAddCuentas, agregarCuentaE, agregarCuenta, nombreMC, indexUserIdMCSelectedEdit,
      userIdMCSelected, usuariosMC} = this.state;

    var step2 = "";
    if(option === "crear") {
      step2 = <div style={{marginBottom: '-10px', fontSize: '18px', fontWeight: 'bold', color: '#0345ea'}}>Paso dos</div>
    }

    var btnAgregarCuenta = <MuiThemeProvider theme={FormButtons}>
      <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.handleShowFormAddCuentas(true)}>
        Agregar cuenta
        <AddIcon className={classes.rightIcon}/>
      </Button>
    </MuiThemeProvider>

    var btnCancelarCuenta = "";
    var btnAgregarNuevaCuenta = "";
    var btnEditarCuenta = "";
    var btnCancelarEditarCuenta = "";

    if(showFormAddCuentas === true || Object.keys(usuariosMC).length === 0) {
      btnAgregarCuenta = "";
      btnCancelarCuenta = <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="default" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.handleShowFormAddCuentas(false)}>
          Cancelar
          <CancelIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>
      btnAgregarNuevaCuenta = <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.addCuentasUser("agregar")}>
          Agregar
          <AddIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>
    }

    if(indexUserIdMCSelectedEdit !== "") {
      btnAgregarNuevaCuenta = "";
      btnAgregarCuenta = "";
      btnEditarCuenta =  "";
      btnCancelarEditarCuenta = <MuiThemeProvider theme={FormButtons}>
        <Button variant="contained" color="default" className={classes.button} style={{marginTop: '22px'}} onClick={() => this.handleEditarCuenta("", "")}>
          Cancelar
          <CancelIcon className={classes.rightIcon}/>
        </Button>
      </MuiThemeProvider>

    }

    if(Object.keys(usuariosMC).length === 0) {
      btnCancelarCuenta = "";
    }

    var formAddCuentas = "";
    if(showFormAddCuentas === true || Object.keys(usuariosMC).length === 0) {
      formAddCuentas = <tr>
        <td>
          <FormControl variant="outlined" className={classes.formControl} error={agregarCuentaE}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="agregarCuenta"
              style={{
                padding: '0px'
              }}
            >
              Selecciona el Email
            </InputLabel>
            <Select
              value={agregarCuenta}
              onChange={this.handleChange('agregarCuenta')}
              input={
                <OutlinedInput labelWidth={135} id="agregarCuenta" error={agregarCuentaE}/>
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {usuariosParaMC.map(function (arr, index) {
                return (
                  <MenuItem key={index} value={arr.residenteId}>
                    {arr.email}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </td>
        <td>
          <TextField
            disable={true}
            label="Nombre"
            placeholder="Selecciona un email"
            id="nombreMC"
            value={nombreMC}
            margin="normal"
            variant="outlined"
            style={{width:'100%'}}
            InputLabelProps={{
              shrink: true
            }}
          />
        </td>
      </tr>
    }

    if((option === "editar" || blurForm === true) && tipoUsuarioText !== "Personal Mantenimiento" && tipoUsuarioText !== "Vigilante") {
      return (
        <div style={{margin: '0px', width: '100%'}}>
          <div className="row"
               style={{margin: '0px', width: '100%', marginTop: '10px', paddingBottom: '0px', marginBottom: '0px'}}>
            <div className="col-md-12">
              {step2}
              <h3 style={{
                marginTop: '0px',
                color: '#FFF',
                borderBottom: '3px solid #0f4ce6',
                fontWeight: 'bold'
              }}>Asignar cuenta</h3>
            </div>
            <div className="col-md-12" style={{marginTop: '20px'}}>
              <MuiThemeProvider theme={FormInputs}>
                <table style={{width: '100%'}}>
                  <thead>
                    <tr>
                      <th style={{color:'#FFF', textAlign:'center', width:'50%'}}>Email</th>
                      <th style={{color:'#FFF', textAlign:'center', width:'50%'}}>Nombre</th>
                      <th style={{color:'#FFF', textAlign:'center'}}> </th>
                    </tr>
                  </thead>
                  <tbody>
                  {usuariosMC.map(function (arr, index) {

                    var editCuenta = false;
                    if(indexUserIdMCSelectedEdit === index) {
                      editCuenta = true;
                    }

                    return (
                      <tr key={index}>
                        <td>
                          <TextField
                            disabled={true}
                            label=""
                            placeholder=""
                            id=""
                            value={arr.email}
                            margin="normal"
                            variant="outlined"
                            style={{width: '100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </td>
                        <td>
                          <TextField
                            disabled={true}
                            label=""
                            placeholder=""
                            id=""
                            value={arr.completName}
                            margin="normal"
                            variant="outlined"
                            style={{width: '100%'}}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </td>
                        <td>
                          {!editCuenta &&
                          <MuiThemeProvider theme={FormButtons}>
                            <Button variant="contained" color="default" className={classes.button}
                                    onClick={() => self.handleEditarCuenta(arr.userId, index)}>
                              <EditIcon/>
                            </Button>
                          </MuiThemeProvider>
                          }
                          {editCuenta && <MuiThemeProvider theme={FormButtons}>
                            <Button variant="contained" color="secondary" className={classes.button} onClick={() => self.addCuentasUser("eliminar", arr.userId)}>
                              <DeleteIcon />
                            </Button>
                          </MuiThemeProvider>
                          }
                        </td>
                      </tr>
                    )
                  })}
                  {formAddCuentas}
                  </tbody>
                </table>
              </MuiThemeProvider>
            </div>
            <div className="col-md-12">
              {btnAgregarCuenta}
              {btnAgregarNuevaCuenta}
              {btnCancelarCuenta}
              {btnEditarCuenta}
              {btnCancelarEditarCuenta}
            </div>
          </div>
        </div>
      )
    }
  }

  renderDivRegistrarPago = () => {
    const {classes, option, dataUser, cuotas, checkPermisos} = this.props;
    const {payYearE, payYear, payMonthE, payMonth, months, disableSelectMonths, cuotaE, cuota, disableBtnPay, disableFormEdit, formaPago, casaLocal, casaLocalE, userCasasLocales, montoPagar, tipoUsuario, disabeCasaLocal, numeroRecibosFacturas, descuento, montoConDescuento, tipoUsuarioText, fechaPago, fechaPagoE} = this.state;

    if(option === "editar" && disableFormEdit === true) {

      var actualYear = dataUser.sistemYear;
      var passYear = actualYear - 1;
      var nextYear = actualYear + 1;

      var casaLocalLabel = "Casa";
      var totalRecibosLabel = "Total recibos";
      var option1TotalRecibosLabel = "Un recibo por cada mes";
      var option2TotalRecibosLabel = "Un recibo por todos los meses";
      if(checkPermisos("125")) {
        casaLocalLabel = "Local";
        totalRecibosLabel = "Total facturas";
        option1TotalRecibosLabel = "Una factura por cada mes";
        option2TotalRecibosLabel = "Una factura por todos los meses";
      }

      if(tipoUsuarioText !== "Personal Mantenimiento" && tipoUsuarioText !== "Vigilante" && tipoUsuarioText !== "Usuario Multicuentas") {
        return (
          <div className="row" style={{margin: '0px', width: '100%', marginTop: '10px'}}>
            <div className="col-md-12">
              <h3 style={{marginTop:'0px', color:'#FFF', borderBottom: '3px solid #0f4ce6', fontWeight:'bold'}}>Registrar pago en efectivo</h3>
            </div>
            <div className="col-md-12"></div>
            <MuiThemeProvider theme={FormInputs}>
              <div className="col-md-4">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    style={{width:'100%', marginTop: '10px', marginBottom: '8px'}}
                    inputVariant="outlined"
                    autoOk
                    label="Fecha del pago"
                    clearable
                    disableFuture
                    value={fechaPago}
                    format="yyyy/MM/dd"
                    onChange={date => this.handleChangeDate(date)}
                    cancelLabel="Cancelar"
                    clearLabel="Limpiar"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-md-4">
                <FormControl variant="outlined" className={classes.formControl} error={payYearE}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="payYear"
                    style={{
                      padding: '0px'
                    }}
                  >
                    Año
                  </InputLabel>
                  <Select
                    value={payYear}
                    onChange={this.handleChange('payYear')}
                    input={
                      <OutlinedInput id="payYear" labelWidth={30} error={payYearE}/>
                    }
                  >
                    <MenuItem value={nextYear}>{nextYear}</MenuItem>
                    <MenuItem value={actualYear}>{actualYear}</MenuItem>
                    <MenuItem value={passYear}>{passYear}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4">
                <FormControl variant="outlined" className={classes.formControl} error={casaLocalE}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="casaLocal"
                    style={{
                      padding: '0px'
                    }}
                  >
                    {casaLocalLabel}
                  </InputLabel>
                  <Select
                    value={casaLocal}
                    onChange={this.handleChange('casaLocal')}
                    input={
                      <OutlinedInput id="casaLocal" labelWidth={40} disabled={disabeCasaLocal} error={casaLocalE}/>
                    }
                  >
                    {userCasasLocales.map(function (arr, index) {

                      return (
                        <MenuItem key={index} value={arr.casaLocalId}>
                          {arr.casaLocal}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4">
                <FormControl variant="outlined" className={classes.formControl} error={payMonthE}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-months"
                    style={{
                      background: 'inherit',
                      padding: '0px'
                    }}

                  >
                    Mes a pagar
                  </InputLabel>
                  <Select
                    multiple
                    style={{width: '100%'}}
                    value={payMonth}
                    onChange={this.handleChange('payMonth')}
                    input={<OutlinedInput labelWidth={90} id="outlined-months" disabled={disableSelectMonths} error={payMonthE}/>}
                  >
                    {months.map(name => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="formaPago"
                    style={{
                      background: '#FFF',
                      padding: '0px'
                    }}
                  >
                    Forma de pago
                  </InputLabel>
                  <Select
                    value={formaPago}
                    onChange={this.handleChange('formaPago')}
                    input={
                      <OutlinedInput id="formaPago" labelWidth={125}/>
                    }
                  >
                    <MenuItem value="Pago en efectivo">Pago en efectivo</MenuItem>
                    <MenuItem value="Transferencia bancaria">Transferencia bancaria</MenuItem>
                    <MenuItem value="Deposito en ventanilla">Deposito en ventanilla</MenuItem>
                    <MenuItem value="Pago con cheque">Pago con cheque</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {Object.keys(payMonth).length >= 1 &&
              <div className="col-md-4">
                <TextField
                  label="% Descuento"
                  placeholder="%%%"
                  id="descuento"
                  value={descuento}
                  margin="normal"
                  variant="outlined"
                  style={{width: '100%'}}
                  type="number"
                  onChange={this.handleChange("descuento")}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              }
              {Object.keys(payMonth).length > 1 &&
              <div className="col-md-4">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="numeroRecibosFacturas"
                    style={{
                      background: '#FFF',
                      padding: '0px'
                    }}
                  >
                    {totalRecibosLabel}
                  </InputLabel>
                  <Select
                    value={numeroRecibosFacturas}
                    onChange={this.handleChange('numeroRecibosFacturas')}
                    input={
                      <OutlinedInput id="formaPago" labelWidth={110}/>
                    }
                  >
                    <MenuItem value="Uno">{option1TotalRecibosLabel}</MenuItem>
                    <MenuItem value="Todos">{option2TotalRecibosLabel}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              }
              <div className="col-md-12"></div>
              <div className="col-md-4">
                <TextField
                  disabled={true}
                  label="Monto a pagar"
                  placeholder="$$$"
                  id="montoPagar"
                  value={montoConDescuento !== "" ? montoConDescuento : montoPagar}
                  margin="normal"
                  variant="outlined"
                  style={{width: '100%'}}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className="col-md-12">
                <MuiThemeProvider theme={FormButtons}>
                  <Button disabled={disableBtnPay} variant="contained" color="primary" className={classes.button}
                          style={{marginTop: '22px'}} onClick={() => this.payEfectivo()}>
                    Registrar pago
                    <DollarIcon className={classes.rightIcon}/>
                  </Button>
                </MuiThemeProvider>
              </div>
            </MuiThemeProvider>
          </div>
        )
      }
    }
  }

  render() {
    const {classes, open, option, checkPermisos, cuotas, mobile, perfilUsuarios, dataUser} = this.props;
    const {montoE, monto, email, emailE, celular, celularE, tipoUsuario, tipoUsuarioE, sexo, sexoE, noCasa, disableFormEdit, factura, m3Local, m3LocalE, predial, predialE, userPrecioM2, userPrecioM2E, blurForm, fisicaMoral, rfcE, rfc, direccionFiscal, cpE, cp, envioFacturaComplemento, calle, calleE, numero, numeroE, colonia, coloniaE, municipio, municipioE, estado, estadoE, pais, paisE, tipoUsuarioText, regimenFiscal} = this.state;

    var btnText = "Crear y asignar casa";
    if(checkPermisos("125")) { //Permisoa plaza
      btnText = "Crear y asignar local";
    }
    if(tipoUsuarioText === "Usuario Multicuentas") {
      btnText = "Crear y asignar cuentas";
    }

    var step1 = <div style={{marginBottom: '-10px', fontSize: '18px', fontWeight: 'bold', color: '#0345ea'}}>Paso uno</div>
    var title = "Crear Usuario";
    var btn = <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'0px'}} onClick={()=>this.createEditUser()} disabled={blurForm}>
      {btnText}
      <AddIcon className={classes.rightIcon}/>
    </Button>
    var btnEliminar = "";
    var btnCancelar = "";

    if(option === "editar") {
      step1 = "";
      title = "Editar Usuario";

      if(disableFormEdit === true) {
        btn = <Button variant="contained" color="default" className={classes.button} style={{marginTop: '0px'}}
                      onClick={() => this.hanldeEnableForm(false)}>
          Editar
          <EditIcon className={classes.rightIcon}/>
        </Button>
      } else {
        btn = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '0px'}}
                      onClick={() => this.createEditUser()}>
          Actualizar
          <UpdateIcon className={classes.rightIcon}/>
        </Button>
        btnCancelar = <Button variant="contained" color="default" className={classes.button} style={{marginTop: '0px'}} onClick={() => this.hanldeEnableForm(true)}>
          Cancelar
          <CancelIcon className={classes.rightIcon}/>
        </Button>
      }

      //checkPermisos("104") administrador

      if(checkPermisos("1") || checkPermisos("104")) {
        btnEliminar =
          <Button variant="contained" color="secondary" className={classes.button} style={{marginTop: '0px'}} onClick={() => this.hanldleDeleteUser()}>
            <DeleteIcon className={classes.leftIcon}/>
            Eliminar
          </Button>
      }
    }

   

    var selectRequiereFactura = <div className="col-md-4">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="factura"
          style={{
            background: '#FFF',
            padding: '0px'
          }}
        >
          ¿Requiere factura?
        </InputLabel>
        <Select
          value={factura}
          onChange={this.handleChange('factura')}
          input={
            <OutlinedInput id="factura" labelWidth={150} disabled={disableFormEdit}/>
          }
        >
          <MenuItem value={"No"}>
            No
          </MenuItem>
          <MenuItem value={"Si"}>
            Si
          </MenuItem>
        </Select>
      </FormControl>
    </div>;

    var labelCasaLocal = "";
    var labelResidenteLocatario = "Residente/Locatario";

    if(checkPermisos("124")) {
      selectRequiereFactura = "";
      labelResidenteLocatario = "Residente";
    } else if(checkPermisos("125")) {
      labelResidenteLocatario = "Locatario";
    }

    var themeD = DialogT;
    if(mobile === true) {
      themeD = DialogTMobile;
    }

    var drTheme = DrawerTheme;
    if(mobile === true) {
      drTheme = DrawerThemeMobile;
    }

    var styleListForm = {margin: '0px', width: '100%'}
    if(blurForm === true) {
      styleListForm = {margin: '0px', width: '100%'}
    }

    return (
      <MuiThemeProvider theme={drTheme}>
        <Drawer anchor="right" open={open} onClose={this.handleClose} disableBackdropClick={false}>
          <div style={{padding: '0px 20px'}}>
            <List>
            <MuiThemeProvider theme={FormInputs}>
              <IconButton aria-label="Cerrar" onClick={()=>this.handleClose()} style={{position: 'absolute',right: '19px',top: '15px', zIndex:'9999'
              }}>
                <CloseIcon style={{color:'#FFF'}}/>
              </IconButton>
              <ListItem>
                <div style={styleListForm}>
                  <div className="row" style={{margin: '0px', width: '100%', paddingBottom:'0px', marginBottom: '0px', marginTop:'20px'}}>
                    <div className="col-md-12">
                      {step1}
                      <h3 style={{marginTop:'0px', color:'#FFF', borderBottom: '3px solid #0f4ce6', fontWeight:'bold'}}>{title}</h3>
                    </div>
                    <div className="col-md-12">
                      <h4 style={{marginTop:'5px', color:'#FFF', fontWeight:'bold'}}>Datos generales</h4>
                    </div>
                    <div className="col-md-4">
                      <FormControl variant="outlined" className={classes.formControl} error={tipoUsuarioE}>
                        <InputLabel
                          ref={ref => {
                            this.InputLabelRef = ref;
                          }}
                          htmlFor="tipoUsuario"
                          style={{
                            padding: '0px'
                          }}
                        >
                          Selecciona el tipo de usuario
                        </InputLabel>
                        <Select
                          value={tipoUsuario}
                          onChange={this.handleChange('tipoUsuario')}
                          input={
                            <OutlinedInput labelWidth={230} id="tipoUsuario" disabled={disableFormEdit}/>
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {perfilUsuarios.map(function (arr, index) {

                            return (
                              <MenuItem key={index} value={arr.perfilId}>
                                {arr.tipoUsuario}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    {tipoUsuarioText !== "Usuario Multicuentas" &&
                      selectRequiereFactura
                    }
                  </div>
                  {factura === "Si" &&
                    <div className="row" style={{margin:'0px', width:'100%'}}>
                      <div className="col-md-12">
                        <h4 style={{marginTop:'5px', color:'#FFF', fontWeight:'bold'}}>Datos fiscales</h4>
                      </div>
                      <div className="col-md-4">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel
                            ref={ref => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="fisicaMoral"
                            style={{
                              background: '#FFF',
                              padding: '0px'
                            }}
                          >
                            Tipo Persona
                          </InputLabel>
                          <Select
                            value={fisicaMoral}
                            onChange={this.handleChange('fisicaMoral')}
                            input={
                              <OutlinedInput id="fisicaMoral" labelWidth={105} disabled={disableFormEdit}/>
                            }
                          >
                            <MenuItem value={"fisica"}>
                              Física
                            </MenuItem>
                            <MenuItem value={"moral"}>
                              Moral
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={rfcE}
                          label="RFC"
                          placeholder="RFC"
                          id="rfc"
                          onChange={this.handleChange('rfc')}
                          value={rfc}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel
                            ref={ref => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="dirFiscal"
                            style={{
                              background: 'transparent',
                              padding: '0px'
                            }}
                          >
                            ¿Dirección físcal?
                          </InputLabel>
                          <Select
                            value={direccionFiscal}
                            onChange={this.handleChange('direccionFiscal')}
                            input={
                              <OutlinedInput id="dirFiscal" labelWidth={140} disabled={disableFormEdit}/>
                            }
                          >
                            <MenuItem value={"No"}>
                              No
                            </MenuItem>
                            <MenuItem value={"Si"}>
                              Si
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-12">
                        <TextField
                          disabled={disableFormEdit}
                          error={calleE}
                          label="Calle"
                          placeholder="Dirección físcal"
                          id="calle"
                          onChange={this.handleChange('calle')}
                          value={calle}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>
                      }
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={numeroE}
                          label="Número"
                          placeholder="Número int/ext"
                          id="numero"
                          onChange={this.handleChange('numero')}
                          value={numero}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      }
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={coloniaE}
                          label="Colonia"
                          placeholder="Colonia"
                          id="colonia"
                          onChange={this.handleChange('colonia')}
                          value={colonia}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      }
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={municipioE}
                          label="Municipio"
                          placeholder="Municipio"
                          id="municipio"
                          onChange={this.handleChange('municipio')}
                          value={municipio}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      }
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={estadoE}
                          label="Estado"
                          placeholder="Estado"
                          id="estado"
                          onChange={this.handleChange('estado')}
                          value={estado}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      }
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={paisE}
                          label="País"
                          placeholder="País"
                          id="pais"
                          onChange={this.handleChange('pais')}
                          value={pais}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      }
                      {direccionFiscal  === "Si" &&
                      <div className="col-md-4">
                        <TextField
                          disabled={disableFormEdit}
                          error={cpE}
                          label="Código postal"
                          placeholder="Código postal"
                          id="cp"
                          onChange={this.handleChange('cp')}
                          value={cp}
                          margin="normal"
                          variant="outlined"
                          style={{width:'100%'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{maxLength: 5}}
                        />
                      </div>
                      }
                      {this.renderSelectUsoCfdi()}
                      {this.renderRegimenFiscal()}
                      {this.renderSelectFormaPago()}
                      {this.renderSelectMetodoPago()}
                      <div className="col-md-4">
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel
                            ref={ref => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="formaPagoSat"
                            style={{
                              background: 'transparent',
                              padding: '0px'
                            }}
                          >
                            ¿Generar factura complemento?
                          </InputLabel>
                          <Select
                            value={envioFacturaComplemento}
                            onChange={this.handleChange('envioFacturaComplemento')}
                            input={
                              <OutlinedInput id="envioFacturaComplemento" labelWidth={255} disabled={disableFormEdit}/>
                            }
                          >
                            <MenuItem value={"No"}>
                              No
                            </MenuItem>
                            <MenuItem value={"Si"}>
                              Si
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  }
                  <div className="row" style={{margin: '0px', width: '100%'}}>
                    <div className="col-md-12">
                      <h4 style={{marginTop:'5px', color:'#FFF', fontWeight:'bold'}}>Datos personales</h4>
                    </div>
                  </div>
                  {tipoUsuarioText !== "Usuario Multicuentas" &&
                  this.renderNombrePersona()
                  }
                  <div className="row" style={{margin: '0px', width: '100%', paddingBottom:'0px', marginBottom: '0px'}}>
                    <div className="col-md-4">
                      <TextField
                        disabled={disableFormEdit}
                        error={emailE}
                        label="Correo Electronico"
                        placeholder="Correo Electronico"
                        id="email"
                        onChange={this.handleChange('email')}
                        value={email}
                        margin="normal"
                        variant="outlined"
                        style={{width:'100%'}}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    {tipoUsuarioText !== "Usuario Multicuentas" &&
                    <div className="col-md-4">
                      <TextField
                        disabled={disableFormEdit}
                        error={celularE}
                        label="Celular"
                        placeholder="Celular"
                        id="celular"
                        onChange={this.handleChange('celular')}
                        value={celular}
                        margin="normal"
                        variant="outlined"
                        style={{width: '100%'}}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{maxLength: 10}}
                      />
                    </div>
                    }
                    <div className="col-md-4">
                      {this.renderPasswordOption()}
                    </div>
                    {tipoUsuarioText !== "Usuario Multicuentas" &&
                    <div className="col-md-4">
                      <FormControl variant="outlined" className={classes.formControl} error={sexoE}>
                        <InputLabel
                          ref={ref => {
                            this.InputLabelRef = ref;
                          }}
                          htmlFor="sexo"
                          style={{
                            background: 'transparent',
                            padding: '0px'
                          }}
                        >
                          Selecciona el sexo
                        </InputLabel>
                        <Select
                          value={sexo}
                          onChange={this.handleChange('sexo')}
                          input={
                            <OutlinedInput id="sexo" labelWidth={150} disabled={disableFormEdit}/>
                          }
                        >
                          <MenuItem value={"H"}>
                            Hombre
                          </MenuItem>
                          <MenuItem value={"M"}>
                            Mujer
                          </MenuItem>

                          
                        </Select>
                      </FormControl>
                    </div>
                    }
                  </div>
                  <div className="row" style={{margin: '0px', width: '100%', paddingBottom:'0px', marginBottom: '0px'}}>
                    <div className="col-md-12" style={{marginTop: '20px'}}>
                      <MuiThemeProvider theme={FormButtons}>
                        {btn}
                        {btnCancelar}
                        {btnEliminar}
                      </MuiThemeProvider>
                    </div>
                  </div>
                </div>
              </ListItem>
              <ListItem>
                {tipoUsuarioText !== "Usuario Multicuentas" &&
                  this.renderTablaCasasLocales()
                }
                {tipoUsuarioText === "Usuario Multicuentas" &&
                  this.renderTablaUsuariosMC()
                }
              </ListItem>
              <ListItem>
                {(checkPermisos("1") || checkPermisos("142")) && this.renderDivRegistrarPago()}
              </ListItem>
            </MuiThemeProvider>
          </List>
          </div>
        </Drawer>
      </MuiThemeProvider>
    );
  }
}

DialogUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogUser);
