import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import OkIcon from '@material-ui/icons/CheckCircleRounded';
import WarningIcon from '@material-ui/icons/ReportProblemRounded';
import PauseIcon from '@material-ui/icons/PauseCircleFilledRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
  td: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  th: {
    color: '#737373',
    fontSize: '14px',
    border: '1px solid rgba(224, 224, 224, 1)',
    width: '40%'
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused:not $notchedOutline:not": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DivPagosDomiciliados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:""
    }
  }

  componentDidMount() {

  }

  render() {
    const {classes, pagosDomiciliados, alertCancelSuscription} = this.props;

    if (Object.keys(pagosDomiciliados).length > 0) {
      return (
        <MuiThemeProvider theme={FormInputs}>

          {pagosDomiciliados.map(function (arr, index) {

            var nt = atob(arr.nt);
            var ntX = nt.substr(nt.length - 4);

            var subEstado = "";
            var icon = "";
            if(arr.suscripStatus === "active") {
              subEstado = "Activa";
              icon = <OkIcon style={{verticalAlign: 'middle', color: '#558b2f'}}/>
            } else if(arr.suscripStatus === "past_due") {
              subEstado = "Vencida";
              icon = <WarningIcon style={{verticalAlign: 'middle', color: '#d32f2f'}}/>
            } else if(arr.suscripStatus === "paused") {
              subEstado = "Pausada";
              icon = <PauseIcon style={{verticalAlign: 'middle', color: '#fdd835'}}/>
            } else if(arr.suscripStatus === "canceled") {
              subEstado = "Cancelada";
              icon = <CancelIcon style={{verticalAlign: 'middle', color: '#607d8b'}}/>
            }

            return (
              <div key={index} className="row" style={{paddingBottom: '20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
                <div className="col-md-12">
                  <h3 className="darkGrayUpera" style={{marginTop: '0px'}}>Suscripción: {arr.subId}</h3>
                  <Table >
                    <TableBody>
                      <TableRow >
                        <TableCell className={classes.th} component="th" scope="row" align="left">
                          CARGO
                        </TableCell>
                        <TableCell className={classes.td} component="td" align="left">Mensual ${arr.monto}.00</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell className={classes.th} component="th" scope="row" align="left">
                          ESTADO
                        </TableCell>
                        <TableCell className={classes.td} component="td" align="left">{icon} {subEstado}</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell className={classes.th} component="th" scope="row" align="left">
                          TARJETA
                        </TableCell>
                        <TableCell className={classes.td} component="td" align="left">Terminacion: {ntX}</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell className={classes.th} component="th" scope="row" align="left">
                          FECHA DE INICIO
                        </TableCell>
                        <TableCell className={classes.td} component="td" align="left">{arr.dateInicio}</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell className={classes.th} component="th" scope="row" align="left">
                          ULTIMO PAGO
                        </TableCell>
                        <TableCell className={classes.td} component="td" align="left">{arr.dateUltimoPago}</TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell className={classes.th} component="th" scope="row" align="left">
                          PROXIMO CARGO
                        </TableCell>
                        <TableCell className={classes.td} component="td" align="left">{arr.dateProximoCargo}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="col-md-12 text-center">
                  <MuiThemeProvider theme={FormButtons}>
                    <Button variant="contained" color="default" className={classes.button} style={{marginTop:'22px'}} onClick={()=>alertCancelSuscription(arr.cusId)}>
                      Cancelar
                      <CancelIcon className={classes.rightIcon}/>
                    </Button>
                  </MuiThemeProvider>
                </div>
              </div>
            )
          })}
        </MuiThemeProvider>
      );
    } else {
      return(
        <div className="text-center" style={{marginTop:'40px'}}>
          <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
          <h4>Sin pagos domiciliados</h4>
        </div>
      )
    }
  }
}

DivPagosDomiciliados.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivPagosDomiciliados);
