/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../../componentsmKit/Header/Header.js";
import Footer from "../../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../../componentsmKit/Grid/GridItem.js";
import Button from "../../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

class HomeParallax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val:""
    }
  }

  render() {
    const {classes, mobile, changePage} = this.props;

    return(
      <Parallax filter image={!mobile ? "assets/img/webPage/bgHome.png" : ""} style={{alignItems: mobile ? 'flex-start' : 'center'}}>
        <div className={classes.container} style={{marginTop: mobile ? '100px' : '0px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <div style={{width:'100%'}}>
                {!mobile ?
                  <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'3.3125rem', lineHeight:'60px'}}>Administración y <br></br> contabilidad simple, <br></br> segura y automatizada.</h1>
                  :
                  <div style={{width:'100%', textAlign:'center'}}>
                    <h1 className={classes.title} style={{marginTop:'15px', fontFamily: 'Poppins SemiBold', color:'#131316', fontWeight:'50', fontSize:'30px', lineHeight:'33px', textAlign:'center'}}>Administración y <br></br> contabilidad simple, <br></br> segura y automatizada.</h1>
                  </div>
                }
              </div>
              {!mobile ?
                <div style={{width: '100%'}}>
                  <h4 className={classes.title} style={{
                    marginTop: '15px',
                    fontFamily: 'Poppins Medium',
                    color: '#7e7e84',
                    fontWeight: '50',
                    fontSize: '15px'
                  }}>Upera es un software de administración <br></br> y contabilidad enfocado a la
                    gestión <br></br> comercial y residencial.</h4>
                </div>
                :
                <div style={{width: '100%', textAlign:'center'}}>
                  <h4 className={classes.title} style={{
                    marginTop: '0px',
                    fontFamily: 'Poppins Medium',
                    color: '#7e7e84',
                    fontWeight: '50',
                    fontSize: '13px'
                  }}>Upera es un software de administración y contabilidad <br></br> enfocado a la
                    gestión comercial y residencial.</h4>
                </div>
              }
              {!mobile ?
                <div style={{width: '100%', marginTop: '1vh'}}>
                  <Button
                    color="blueUpera"
                    size="md"
                    onClick={()=>changePage("uperaContacto")}
                  >
                    Empezar ahora
                  </Button>
                  <Button
                    color="blueUperaB"
                    size="md"
                    marginLeft="10px"
                    onClick={()=>changePage("uperaContacto")}
                  >
                    Pruebalo gratis
                  </Button>
                </div>
                :
                <div style={{width: '100%', marginTop: '1vh', textAlign:'center'}}>
                  <Button
                    color="blueUpera"
                    size="md"
                    onClick={()=>changePage("uperaContacto")}
                  >
                    Empezar ahora
                  </Button>
                  <Button
                    color="blueUperaB"
                    size="md"
                    marginLeft="10px"
                    onClick={()=>changePage("uperaContacto")}
                  >
                    Pruebalo gratis
                  </Button>
                </div>
              }
              {!mobile ?
                <div style={{width: '100%', marginTop: '5vh'}}>
                  <h4 className={classes.title} style={{
                    marginTop: '15px',
                    fontFamily: 'Poppins Medium',
                    color: '#7e7e84',
                    fontWeight: '50',
                    fontSize: '11px'
                  }}>Aplican <font style={{color: '#036adb'}}> términos y condiciones.</font> Prueba gratis exclusiva
                    para nuevos usuarios.</h4>
                </div>
                :
                <>
                  <div style={{width: '100%', marginTop: '0vh'}}>
                    <h4 className={classes.title} style={{
                      marginTop: '15px',
                      marginBottom: '0px',
                      fontFamily: 'Poppins Medium',
                      color: '#7e7e84',
                      fontWeight: '50',
                      fontSize: '10px'
                    }}>Aplican <font style={{color: '#036adb'}}> términos y condiciones.</font> Prueba gratis exclusiva
                      para nuevos usuarios.</h4>
                  </div>
                  <div style={{width: '100%', marginTop: '0vh'}}>
                    <img src="assets/img/webPage/bgHomeMobile.png" style={{width:'95%', position:'absolute', right:'0px'}}/>
                  </div>
                </>
              }
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    )
  }
}

HomeParallax.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeParallax);
