/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from '@material-ui/core/Button';
import Cookies from 'universal-cookie';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

/*Componentes de la aplicación*/
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";

const $ = require('jquery');
var cookies = new Cookies();

/*Variables globales*/
const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  button: {
    fontSize: '10px',
    fontFamily:'Poppins Regular',
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

class HomeMC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }

  componentDidMount() {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  selectCuenta = (email) => {
    const that = this;
    const {ajaxSource, getData, loginSession, crearNotificacion, router} = this.props;
    console.log(email);

    getData(ajaxSource, "login/login.php", {
      emailLogin: email,
      passLogin: "",
      mc:"mc"
    }).then(function (response) {
      var data = response.data[0];

      if(data.error === 1){
        loginSession(data, router);
      } else if(data.error === 2){
        that.setState({showLoginLoading:false, showLoginForm:true},()=> {
          crearNotificacion("error", "No cuentas con privilegios.", "bottom", "right");
        });
      } else if(data.error === 3){
        that.setState({showLoginLoading:false, showLoginForm:true},()=> {
          crearNotificacion("error", "Tu contraseña es incorrecta.", "bottom", "right");
        });
      } else if(data.error === 4){
        that.setState({showLoginLoading:false, showLoginForm:true},()=> {
          crearNotificacion("error", "Email no existe.", "bottom", "right");
        });
      } else if(data.error === 5){
        that.setState({showLoginLoading:false, showLoginForm:true},()=> {
          crearNotificacion("error", "Tu usuario aun no se encuentra activo, favor de contactar al Administrador.", "bottom", "right");
        });
      }
    });
  }

  render() {
    const that = this;
    const {classes, dataUser} = this.props;

    return (
      <div className="main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="darkGrayUpera" style={{fontSize:'40px', marginBottom:'22px'}}>Mis cuentas</h1>
            </div>
          </div>
          <div className="row">
            {
              Object.keys(dataUser).length > 0 &&
              dataUser.usuariosMC.map(function (arr, index) {
                var arrayColors = ['#F96A57', '#FFCA69', '#B590FF', '#48D1E3', '#0245EA'];
                return(
                  <div className="col-md-2">
                    <Card
                      style={{
                        backgroundColor: arrayColors[(index % 5)],
                        borderRadius:'18px'
                      }}
                    >
                      <CardActionArea style={{minHeight:'230px'}} onClick={()=>that.selectCuenta(arr.email)}>
                        <CardContent style={{minHeight:'230px', padding:'30px'}}>
                          <div className="row">
                            <div className="col-md-12">
                              <h3 style={{
                                marginTop: '0px',
                                fontSize: '40px',
                                lineHeight: '35px',
                                fontWeight: '700',
                                color: '#FFF'
                              }}>{arr.residencial}</h3>
                            </div>
                          </div>
                          <div style={{
                            position:'absolute',
                            bottom: 10,
                            left: 16
                          }}>
                            <h3 style={{
                              fontSize: '15px',
                              color: '#FFF',
                            }}>Ingresar</h3>
                          </div>
                          <div style={{
                            position:'absolute',
                            bottom: 10,
                            right: 16
                          }}>
                            <img src="assets/img/icn-siguiente.png" style={{
                              width:'40px',
                              height:'40px'
                            }}/>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

HomeMC.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeMC);

