import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DollarIcon from "@material-ui/icons/AttachMoneyRounded";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteIcon from "@material-ui/icons/DeleteForeverRounded";
import OkIcon from "@material-ui/icons/CheckCircleRounded";
import AddIcon from "@material-ui/icons/AddCircle";
import DownLoadIcon from "@material-ui/icons/CloudDownloadRounded";
import FormControl from "@material-ui/core/FormControl";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";

import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTable from "mui-datatables";
import styled from "styled-components";

import Paper from "@material-ui/core/Paper";

const $ = require("jquery");

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    fontSize: 18,
  },
  body: {
    fontSize: 14,
    color: theme.palette.success.dark,
  },
}))(TableCell);

const StyledTableCellWarning = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.warning.dark,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
    color: theme.palette.warning.dark,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: "0px",
    width: "100%",
  },
  td: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "14px",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  th: {
    color: "#737373",
    fontSize: "14px",
    border: "1px solid rgba(224, 224, 224, 1)",
    width: "40%",
  },
  heading: {
    flexBasis: "50%",
    flexShrink: 0,
    background: "#e4e3e1",
    color: "#737373 !important",
    borderRadius: "5px",
    fontSize: "18px",
    textAlign: "left",
    padding: "20px",
  },
  headingPendientePago: {
    flexBasis: "50%",
    flexShrink: 0,
    background: "#ffab00",
    color: "#FFF !important",
    borderRadius: "5px",
    fontSize: "18px",
    textAlign: "left",
    padding: "20px",
  },
  container: {
    maxHeight: 440,
  },
});

const StyledTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #fff !important;
    background: #0345ea !important;
    border-radius: 5px;
  }
  .PrivateTabIndicator-colorPrimary {
    background-color: transparent;
  }
`;

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Poppins Regular",
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "rgba(0,0,0,0.4)",
        },
        "&$focused:not $notchedOutline:not": {
          borderColor: "#0345ea",
        },
      },
      disabled: {
        backgroundColor: "#eee",
        borderRadius: "5px",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "#737373",
      },
    },
    MuiCheckbox: {
      checked: {
        color: "#0345ea !important",
      },
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important",
      },
    },
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Poppins Regular",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "20px",
      },
      containedPrimary: {
        backgroundColor: "#0345ea",
        "&:hover": {
          backgroundColor: "#1a49bf",
        },
      },
    },
  },
});

const tabs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Poppins Regular",
  },
  shadows: Array(25).fill("none"),
  palette: {
    primary: {
      main: "#0345ea",
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#FFF !important",
      },
    },
    MuiTab: {
      labelContainer: {
        padding: "2px 1px",
      },
      selected: {
        color: "#FFF !important",
        background: "#0345ea !important",
        borderRadius: "5px",
      },
      root: {
        background: "#cfd8dc",
        borderRadius: "5px",
      },
      textColorPrimary: {
        Mui: {
          selected: {
            color: "#FFF !important",
            background: "#0345ea !important",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiTypography: {
      body1: {
        padding: "20px 0px 0px 0px !important",
      },
    },
    MuiPrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "transparent",
      },
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: "transparent !important",
      },
    },
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class DivEstadoPersonalCuenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      facturas: [],
      year: "",
      monthsTh: [],
      mesDataSelected: [],
      facturaDataSelected: [],
      openDialogInfo: false,
      monthNumber: 0,
      rowsPerPage: 7,
      page: 0,
      casaLocal: "",
      TOTAL: "",
    };
  }

  componentDidMount() {
    const { dataUser } = this.props;
    var actualYear = dataUser.sistemYear;

    var defaultCasaLocal = dataUser.casasLocales[0].casaLocal;

    this.setState({ casaLocal: defaultCasaLocal });

    this.setState({ year: actualYear }, () => {
      this.facturacionInfo();
    });
  }

  facturacionInfo = () => {
    const that = this;
    const {
      dataUser,
      getData,
      ajaxSource,
      loadedPage,
      crearNotificacion,
      cerrarNotificacion,
    } = this.props;
    const { year, value, casaLocal } = this.state;
    var userId = dataUser.userId;

    if (year !== "") {
      crearNotificacion("info", "Cargando...", "bottom", "right", 1000000);
      getData(ajaxSource, "facturacion/facturacionInfo.php", {
        year: year,
        userId: userId,
        tab: value,
      }).then(function (response) {
        if (response.data) {
          loadedPage(true);
          cerrarNotificacion();
          var data = response.data;
          console.log(data);
          var facturas = data[0].sort((a, b) => a.local.localeCompare(b.local));
          var monthsTh = data[1];
          var monthNumber = data[2];
          that.setState({
            facturas: facturas,
            monthsTh: monthsTh,
            monthNumber: monthNumber,
          });
        }
      });
    }
  };

  handleChange = (name) => (event) => {
    const that = this;
    this.setState({ [name]: event.target.value, [name + "E"]: false }, () => {
      if (name === "locatarioId") {
        that.setState({ yearDisabled: false });
      }
      if (name === "year") {
        that.facturacionInfo();
      }

      if (name === "local") {
      }
    });
  };

  handleFilterByValue = (array, string) => {
    return array.filter((o) =>
      Object.keys(o).some((k) =>
        o[k].toLowerCase().includes(string.toLowerCase())
      )
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  handleChangeTab = (event, value) => {
    this.setState({ value }, () => {
      this.facturacionInfo();
    });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  openDialogFacturaInfo = (mesData, facturaFata) => {
    const that = this;
    this.setState(
      { mesDataSelected: mesData, facturaDataSelected: facturaFata },
      () => {
        that.setState({ openDialogInfo: true });
      }
    );
  };

  closeDialogFacturaInfo = () => {
    this.setState({ openDialogInfo: false });
  };

  handleSelectCasaLocal = (e) => {
    this.setState({
      casaLocal: e.target.value,
    });
  };

  renderTable = () => {
    const that = this;
    const {
      theme,
      classes,
      dataUser,
      documentHeight,
      tipoUsuario,
      handleChange,
    } = this.props;
    const { value, year, casaLocal, facturas, total } = this.state;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;
    var maxHeight = documentHeight - 400;

    var varias_casas = new Boolean();

    var montos_facturas = [];
    var totalFacturas;

    if (dataUser.casasLocales.length === 1) {
      varias_casas = false;
    } else {
      varias_casas = true;
    }

    var results = facturas.filter(function (facturas) {
      return facturas.locatarioId == dataUser.userId;
    });

    Object.keys(results).length > 0 &&
      results.map(function (arr, index) {
        arr.facturas.map(function (arrF, index) {
          if (arrF.status === "Pagado" && varias_casas === false) {
            var montoFormat = arrF.monto.replace(/[$]/g, "");
            montos_facturas.push(parseInt(montoFormat));
            console.log(montos_facturas);
            totalFacturas = montos_facturas.reduce((a, b) => a + b, 0);
          }
        });
      });

    if (varias_casas) {
      var results = facturas.filter(function (facturas) {
        return facturas.local == casaLocal;
      });

      Object.keys(results).length > 0 &&
        results.map(function (arr, index) {
          arr.facturas.map(function (arrF, index) {
            if (arrF.status == "Pagado") {
              var montoFormat = arrF.monto.replace(/[$]/g, "");
              montos_facturas.push(parseInt(montoFormat));
              console.log(montos_facturas);
              totalFacturas = montos_facturas.reduce((a, b) => a + b, 0);
            }
          });
        });

      //Casas mayor a una

      return (
        <MuiThemeProvider theme={FormInputs}>
          <div
            className="row"
            style={{
              paddingBottom: "20px",
              borderBottom: "1px dashed #e4e3e1",
              margin: "0px",
            }}
          >
            <div className="col-md-4" style={{ marginTop: "20px" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="year"
                  style={{
                    background: "#FFF",
                    padding: "0px 5px 1px 5px",
                  }}
                >
                  Año
                </InputLabel>
                <Select
                  value={year}
                  onChange={this.handleChange("year")}
                  input={<OutlinedInput id="year" labelWidth={0} />}
                >
                  <MenuItem value={actualYear}>{actualYear}</MenuItem>
                  <MenuItem value={passYear}>{passYear}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-4" style={{ marginTop: "20px" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="casaLocal"
                  style={{
                    background: "#FFF",
                    padding: "0px 5px 1px 5px",
                  }}
                >
                  Casa
                </InputLabel>
                <Select
                  value={casaLocal}
                  onChange={this.handleSelectCasaLocal}
                  input={<OutlinedInput id="casaLocal" labelWidth={0} />}
                >
                  {dataUser.casasLocales.map(function (arr, index) {
                    return (
                      <MenuItem key={index} value={arr.casaLocal}>
                        {arr.casaLocal}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-4" style={{ marginTop: "20px" }}>
              <h3>Total pagado: ${totalFacturas}</h3>
            </div>
          </div>

          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Meses</StyledTableCell>
                  <StyledTableCell align="center">Monto</StyledTableCell>
                  <StyledTableCell align="center">Estatus</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(results).length > 0 &&
                  results.map(function (arr, index) {
                    return arr.facturas.map(function (arrF, index) {
                      if (arrF.status === "RP" || arrF.status === "RF") {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{arrF.mes}</TableCell>
                            <TableCell align="center">
                              {arrF.status === "Pagado" ? arrF.monto : "-"}
                            </TableCell>
                            <StyledTableCellWarning align="center">
                              {"Pendiente"}
                            </StyledTableCellWarning>
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{arrF.mes}</TableCell>
                            <TableCell align="center">
                              {arrF.status === "Pagado" ? arrF.monto : "-"}
                            </TableCell>
                            <StyledTableCell align="center">
                              {"Pagado"}
                            </StyledTableCell>
                          </TableRow>
                        );
                      }
                    });
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider theme={FormInputs}>
          <div
            className="row"
            style={{
              paddingBottom: "20px",
              borderBottom: "1px dashed #e4e3e1",
              margin: "0px",
            }}
          >
            <div className="col-md-4" style={{ marginTop: "20px" }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="year"
                  style={{
                    background: "#FFF",
                    padding: "0px 5px 1px 5px",
                  }}
                >
                  Año
                </InputLabel>
                <Select
                  value={year}
                  onChange={this.handleChange("year")}
                  input={<OutlinedInput id="year" labelWidth={0} />}
                >
                  <MenuItem value={actualYear}>{actualYear}</MenuItem>
                  <MenuItem value={passYear}>{passYear}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-4" style={{ marginTop: "20px" }}>
              <h3>Total pagado: ${totalFacturas}</h3>
            </div>
          </div>

          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Meses</StyledTableCell>
                  <StyledTableCell align="center">Monto</StyledTableCell>
                  <StyledTableCell align="center">Estatus</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(results).length > 0 &&
                  results.map(function (arr, index) {
                    return arr.facturas.map(function (arrF, index) {

                  if(arr.usuarioExento === "0"){

                   
                      if (arrF.status === "RP" || arrF.status === "RF") {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{arrF.mes}</TableCell>
                            <TableCell align="center">
                              {(arrF.status === "Pagado") ? arrF.monto : "-"}
                            </TableCell>
                            <StyledTableCellWarning align="center">
                              {"Pendiente"}
                            </StyledTableCellWarning>
                          </TableRow>
                        );
                      }
                      
                      
                      else {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center">{arrF.mes}</TableCell>
                            <TableCell align="center">
                              {arrF.status === "Pagado" ? arrF.monto : "-"}
                            </TableCell>
                            <StyledTableCell align="center">
                              {"Pagado"}
                            </StyledTableCell>
                          </TableRow>
                        );
                      }
                    }

                  else{ 
                    
                    // TODO: Verificar que los usuarios exentos de pagos aparezcan en el registro de pagos con la leyenda exento.

                    if (arrF.status === "RP" || arrF.status === "RF") {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{arrF.mes}</TableCell>
                          <TableCell align="center">
                            {(arrF.status === "Pagado") ? arrF.monto : "-"}
                          </TableCell>
                          <StyledTableCellWarning align="center">
                            {"Exento de pago"}
                          </StyledTableCellWarning>
                        </TableRow>
                      );
                    }
                    
                    
                    else {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{arrF.mes}</TableCell>
                          <TableCell align="center">
                            {arrF.status === "Pagado" ? arrF.monto : "-"}
                          </TableCell>
                          <StyledTableCell align="center">
                            {"Pagado"}
                          </StyledTableCell>
                        </TableRow>
                      );
                    }


                  }
                    });
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </MuiThemeProvider>
      );
    }
  };

  render() {
    const that = this;
    const { classes, dataUser, dataUserSet, theme, checkPermisos } = this.props;
    const { value } = this.state;

    var tabMantto = "";

    var name = dataUser.userName;

    tabMantto = (
      <Tab
        style={{ padding: "5px", marginLeft: "2px" }}
        label={
          <div
            style={{
              width: "100%",
              padding: "2px 1px",
              boxSizing: "border-box",
            }}
          >
            <div
              className="containerTextTab"
              style={{ textAlign: "left", marginBottom: "10px" }}
            >
              <div className="textTab">
                <b>Estado de cuenta de: </b>
                <br />
                <div className="textTabSub">{name}</div>
              </div>
            </div>
          </div>
        }
      />
    );

    var tabRentas = "";
    if (checkPermisos("1") || checkPermisos("149")) {
      tabRentas = (
        <Tab
          style={{ padding: "0px", marginLeft: "2px" }}
          label={
            <div
              style={{
                width: "100%",
                padding: "2px 1px",
                boxSizing: "border-box",
              }}
            >
              <div
                className="containerTextTab"
                style={{ textAlign: "left", marginBottom: "10px" }}
              >
                <DollarIcon className="iconTab" />
                <div className="textTab">
                  <b>Estado de cuenta de: </b>
                  <br />
                  <div className="textTabSub">{name}</div>
                </div>
              </div>
            </div>
          }
        />
      );
    }

    return (
      <>
        <MuiThemeProvider theme={tabs}>
          <AppBar position="static" color="default">
            <StyledTabs
              value={value}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {tabMantto}
              {tabRentas}
            </StyledTabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
            style={{ overFlow: "hidden" }}
            slideClassName="slideOverflow"
          >
            <TabContainer dir={theme.direction}>
              {this.renderTable()}
            </TabContainer>
            <TabContainer dir={theme.direction}>
              {this.renderTable()}
            </TabContainer>
          </SwipeableViews>
        </MuiThemeProvider>
      </>
    );
  }
}

DivEstadoPersonalCuenta.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivEstadoPersonalCuenta);
