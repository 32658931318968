import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/LiveHelpRounded';
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class DivPagoOxxo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:""
    }
  }

  componentDidMount() {

  }

  renderFichaOxxo = () => {
    const {fichaOxxoPay, oxxoSpei} = this.props;

    if (Object.keys(fichaOxxoPay).length > 0) {
      if(fichaOxxoPay.paymentMethod === "OxxoPay") {
        return (
          <div className="opps">
            <div className="opps-header">
              <div className="opps-reminder">
                Tu ficha siempre estará disponible en esta sección hasta que realices tu pago en tu tienda OXXO mas
                cercana, también puedes guardar tu ficha haciendo una captura de pantalla desde tu celular, o bien
                copiar el número de tu referencia.
              </div>
              <div className="opps-info">
                <div className="opps-brand col-md-3"><img src="assets/img/iconos/oxxopay_brand.png" alt="OXXOPay"/>
                </div>
                <div className="opps-ammount ol-md-9">
                  <h3 className="h3_OxxoPay">Monto a pagar</h3>
                  <h2>$ {fichaOxxoPay.amount} <sup>MXN</sup></h2>
                  <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                </div>
              </div>
              <div className="opps-reference">
                <h3 className="h3_OxxoPay">Pago mantenimiento {fichaOxxoPay.payMonth}</h3>
              </div>
              <div className="opps-reference">
                <h3 className="h3_OxxoPay">Referencia</h3>
                <h1 className="h1_OxxoPay">{fichaOxxoPay.reference}</h1>
              </div>
            </div>
            <div className="opps-instructions">
              <h3 className="h3_OxxoPay">Instrucciones</h3>
              <ol>
                <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/"
                                                           target="_blank">Encuéntrala aquí</a>.
                </li>
                <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de
                  venta.
                </li>
                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar
                  que se haya realizado correctamente.</strong> Conserva este comprobante de pago.
                </li>
              </ol>
              <div className="opps-footnote">Al completar estos pasos recibirás un correo
                de <strong>upera</strong> confirmando tu pago.
              </div>
            </div>
          </div>
        )
      } else if(fichaOxxoPay.paymentMethod === "SPEI") {
        return (
          <div className="opps">
            <div className="opps-header">
              <div className="opps-reminder">
                Tu ficha siempre estará disponible en esta sección hasta que realices tu pago en tu banca en linea, también puedes guardar tu ficha haciendo una captura de pantalla desde tu celular, o bien
                copiar la clabe.
              </div>
              <div className="opps-info">
                <div className="opps-brand col-md-3"><img src="assets/img/iconos/spei_brand.png" alt="spei_brand"/>
                </div>
                <div className="opps-ammount ol-md-9">
                  <h3 className="h3_OxxoPay">Monto a pagar</h3>
                  <h2>$ {fichaOxxoPay.amount} <sup>MXN</sup></h2>
                  <p>Utiliza exactamente esta cantidad al realizar el pago.</p>
                </div>
              </div>
              <div className="opps-reference">
                <h3 className="h3_OxxoPay">Pago mantenimiento {fichaOxxoPay.payMonth}</h3>
              </div>
              <div className="opps-reference">
                <h3 className="h3_OxxoPay">CLABE</h3>
                <h1 className="h1_OxxoPay">{fichaOxxoPay.reference}</h1>
              </div>
            </div>
            <div className="opps-instructions">
              <h3 className="h3_OxxoPay">Instrucciones</h3>
              <ol>
                <li>Accede a tu banca en línea.</li>
                <li>Da de alta la CLABE de esta ficha. <strong>Al elegir el banco deberás elegir STP (Sistema de Transferencia de Págos)</strong>.</li>
                <li>Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.</li>
                <li>Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
              </ol>
              <div className="opps-footnote">Al completar estos pasos recibirás un correo
                de <strong>upera</strong> confirmando tu pago.
              </div>
            </div>
          </div>
        )
      }
    }
  }

  render() {
    const {classes, dataUser, payMonths, payYear, payMonthsE, payYearE, disableBtnPay, months, handleChange, handleChangeMultiple, handleOxxoPay, disableSelectMonths, handleOpenTour, mobile, oxxoSpei, checkPermisos, userCasasLocales, casaLocal, casaLocalE} = this.props;

    var actualYear = dataUser.sistemYear;
    var passYear = actualYear - 1;
    var nextYear = actualYear + 1;

    var styleBtnHelp = {position: 'absolute', top: '-6px', right: '5px'};
    if(mobile === true) {
      styleBtnHelp = {margin: 'auto'};
    }

    var selectInputMonth = <div className="col-md-3" style={{marginTop:'17px'}} data-tour="step2">
      <FormControl variant="outlined" className={classes.formControl} error={payMonthsE}>
        <InputLabel
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          htmlFor="outlined-months"
          style={{
            background: 'inherit',
            padding: '0px 5px 1px 5px'
          }}

        >
          Mes a pagar
        </InputLabel>
        <Select
          style={{width:'100%'}}
          value={payMonths}
          onChange={handleChangeMultiple}
          input={<OutlinedInput labelWidth={0} id="outlined-months" disabled={monthsDisabled} error={payMonthsE}/>}
        >
          {months.map(function (arr, index) {

            if(index === 0) {
              return (
                <MenuItem key={index} value={arr}>
                  {arr}
                </MenuItem>
              )
            }
          })}
        </Select>
      </FormControl>
    </div>

    var residencialPlaza = dataUser.residencialPlaza;
    var monthsDisabled = disableSelectMonths;
    if(residencialPlaza === "plaza") {
      monthsDisabled = true;

      selectInputMonth = <div className="col-md-3" data-tour="step2"><TextField
        disabled={monthsDisabled}
        error={payMonthsE}
        label="Mes a pagar"
        placeholder="Mes a pagar"
        id="payMonths"
        margin="normal"
        variant="outlined"
        style={{width:'100%'}}
        value={payMonths}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </div>
    }

    var casaLocalLabel = "Casa"
    if(checkPermisos("125")) {
      casaLocalLabel = "Local";
    }

    var infoComision = <font style={{color:'#0345ea'}}>El pago en OXXO genera una comisión extra de <b>3.9% + IVA OXXO cobrará una comisión extra de $12mxn</b></font>;
    if(oxxoSpei === "spei") {
      infoComision = <font style={{color:'#0345ea'}}>El pago con SPEI genera una comisión extra de <b>1% + $8mxn + IVA</b></font>;
    }

    var infoCom = "";
    if(dataUser.comisiones === '1') {
      infoCom = infoComision;
    }

    return (
      <MuiThemeProvider theme={FormInputs}>
        <form id="formDivPago">
          <div className="row" style={{paddingBottom:'20px', borderBottom: '1px dashed #e4e3e1', margin: '0px'}}>
            <div className="col-md-12">
              <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
              <MuiThemeProvider theme={FormButtons}>
                <Button variant="contained" color="primary" className={classes.button} style={styleBtnHelp} onClick={()=>handleOpenTour("pagoEnOxxo")} data-tour="step7">
                  ¿Como realizo mi pago en OXXO o por SPEI?
                  <HelpIcon className={classes.rightIcon}/>
                </Button>
              </MuiThemeProvider>
            </div>
            <div className="col-md-3" style={{marginTop:'17px'}}>
              <FormControl variant="outlined" className={classes.formControl} error={payYearE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="oxxoSpei"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  OXXO o SPEI
                </InputLabel>
                <Select
                  value={oxxoSpei}
                  onChange={handleChange('oxxoSpei')}
                  input={
                    <OutlinedInput id="oxxoSpei" labelWidth={0}/>
                  }
                >
                  <MenuItem value="oxxo">OXXO</MenuItem>
                  <MenuItem value="spei">SPEI</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3" style={{marginTop:'17px'}} data-tour="step8">
              <FormControl variant="outlined" className={classes.formControl} error={payYearE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="payYear"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  Año
                </InputLabel>
                <Select
                  value={payYear}
                  onChange={handleChange('payYear')}
                  input={
                    <OutlinedInput id="payYear" labelWidth={0}/>
                  }
                >
                  <MenuItem value={nextYear}>{nextYear}</MenuItem>
                  <MenuItem value={actualYear}>{actualYear}</MenuItem>
                  <MenuItem value={passYear}>{passYear}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3" style={{marginTop:'17px'}}>
              <FormControl variant="outlined" className={classes.formControl} error={casaLocalE}>
                <InputLabel
                  ref={ref => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="casaLocal"
                  style={{
                    background: '#FFF',
                    padding: '0px 5px 1px 5px'
                  }}
                >
                  {casaLocalLabel}
                </InputLabel>
                <Select
                  value={casaLocal}
                  onChange={handleChange('casaLocal')}
                  input={
                    <OutlinedInput id="casaLocal" labelWidth={0} error={casaLocalE}/>
                  }
                >
                  {userCasasLocales.map(function (arr, index) {

                    return (
                      <MenuItem key={index} value={arr.casaLocalId}>
                        {arr.casaLocal}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
            {selectInputMonth}
            <div className="col-md-4" style={{marginTop:'20px'}}>
              <div style={{fontSize:'14px', width:'260px', lineHeight:'16px', marginTop:'4px', borderLeft:'1px solid #0345ea', paddingLeft:'7px'}}>
                {infoCom}
              </div>
            </div>
            <div className="col-md-12 text-center">
              <MuiThemeProvider theme={FormButtons}>
                <Button disabled={disableBtnPay} variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>handleOxxoPay()} data-tour="step11">
                  Generar ficha de pago
                  <DollarIcon className={classes.rightIcon}/>
                </Button>
              </MuiThemeProvider>
            </div>
          </div>
          <div className="row" style={{paddingTop:'20px', margin:'0px'}}>
            {this.renderFichaOxxo()}
          </div>
        </form>
      </MuiThemeProvider>

    );
  }
}

DivPagoOxxo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivPagoOxxo);
