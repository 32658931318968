import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import ResponderIcon from '@material-ui/icons/ThumbsUpDownRounded';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#777777',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
  cardGray: {
    boxShadow: 'none',
    backgroundColor:'#eee !important',
    width:'100%'
  },
  cardSelected: {
    boxShadow: 'none',
    backgroundColor: '#fce4ec !important',
    width: '100%'
  }
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

class DivMisEncuestas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded:null,
      selectedOption:"",
      encuestaId:"",
      comentarios:"",
      maxCharacters:"0/128",
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value},()=> {
      if(name === "comentarios") {
        const {comentarios} = this.state;
        var length = comentarios.length;
        this.setState({maxCharacters:length+"/128"});
      }
    });
  };

  handleChangePanel = (panel, comentarios) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
    this.setState({comentarios:comentarios});
  };

  contestarEncuestaAlert = (encuestaId) => {
    const self = this;
    const {crearNotificacion, cerrarNotificacion, cerrarAlerta, crearAlerta} = this.props;
    const {selectedOption} = this.state;

    if(selectedOption === "") {
      crearNotificacion("warning", "Selecciona una opción.", "bottom", "right");
    } else {
      this.setState({encuestaId:encuestaId}, () => {
        crearAlerta("warning", "", "¿Seguro que deseas participar con la opción seleccionada?", true, this.contestarEncuesta);
      });
    }
  }

  contestarEncuesta = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, getEncuestasInfo, cerrarAlerta, crearAlerta} = this.props;
    const {selectedOption, encuestaId, comentarios} = this.state;

    cerrarAlerta();

    var userId = dataUser.userId;
    crearNotificacion("info", "Contestando encuesta...", "bottom", "right", 1000000);

    getData(ajaxSource, "encuestas/contestarEncuesta.php",{
      userId:userId,
      selectedOption:selectedOption,
      encuestaId:encuestaId,
      comentarios:comentarios
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {

          self.setState({selectedOption:"",encuestaId:""}, () => {
            getEncuestasInfo();
            crearAlerta("success", "Gracias!", "por tu participación",false, cerrarAlerta);
          });

        } else {
          crearNotificacion("error", "Hubo un problema al intentar contestar la encuesta" + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }

  render() {
    const self = this;
    const {classes, encuestas, ajaxSource, checkPermisos, prodEnv} = this.props;
    const {expanded, selectedOption, maxCharacters, comentarios} = this.state;

    if (Object.keys(encuestas).length > 0) {
      return (
        <div className="row" style={{paddingBottom:'20px', margin: '0px'}}>
          <div className="col-md-12 mtM">
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Mis encuestas</h3>
          </div>
          <div className="col-md-12">
            {encuestas.map(function (arr, index) {

              var classActive = classes.heading;
              if(expanded === arr.encuestaId) {
                classActive = classes.headingActive;
              }

              var btnContestar = "";
              if((checkPermisos("1") || checkPermisos("136")) && arr.opcionContestada === "SC" && arr.estatus === "En curso") {
                btnContestar =
                  <MuiThemeProvider theme={FormButtons}>
                    <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}} onClick={()=>self.contestarEncuestaAlert(arr.encuestaId)}>
                      Contestar encuesta
                      <ResponderIcon className={classes.rightIcon}/>
                    </Button>
                  </MuiThemeProvider>
              }

              var comentariosDisabled = true;
              var statusRespuesta = "Contestada";
              if(arr.opcionContestada === "SC") {
                comentariosDisabled = false;
                statusRespuesta = "Por contestar"
              }

              if(arr.estatus === "Finalizada" && arr.opcionContestada === "SC") {
                statusRespuesta = "";
                comentariosDisabled = true;
              }

              return(
                <MuiThemeProvider theme={ExpansionPanels} key={index}>
                  <ExpansionPanel expanded={expanded === arr.encuestaId} onChange={self.handleChangePanel(arr.encuestaId, arr.comentarios)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classActive} style={{color:'#737373'}}>{arr.estatus} <br/> {statusRespuesta}</Typography>
                      <div className={classes.secondaryHeading}>
                       Plazo: {arr.fechaLimite} | Encuesta: {arr.pregunta}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'inline'}}>
                      <div className="row" style={{width:'100%', margin: '0px'}}>
                        <div className="col-md-12" style={{marginTop:'20px'}}>
                          <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Opciones</h3>
                        </div>
                        {arr.opciones.map(function (arrO, index) {

                          var classCard = classes.cardGray;
                          if(selectedOption === arrO.opcionId) {
                            classCard = classes.cardSelected;
                          }

                          var radioButton = "";
                          if(arr.opcionContestada === "SC" && arr.estatus === "En curso") {
                            radioButton = <Radio
                              checked={selectedOption === arrO.opcionId}
                              onChange={self.handleChange("selectedOption")}
                              value={arrO.opcionId}
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                          } else {
                            if(arr.opcionContestada === arrO.opcionId) {
                              classCard = classes.cardSelected;
                            }
                          }

                          var re = /(?:\.([^.]+))?$/;
                          var ext = re.exec(arrO.imgPath)[1];

                          var path = <a href={ajaxSource + arrO.imgPath} target="_blank">
                            <img className="img-raised rounded img-fluid" src={ajaxSource + arrO.imgPath} style={{maxWidth:'300px'}}/>
                          </a>

                          if(ext === "pdf" || ext === "PDF") {
                            path = <iframe
                              src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + prodEnv + arrO.imgPath}
                              style={{width: '100%', height: '300px', border: '4px solid #737373', borderRadius: '4px'}}/>;
                          }

                          return(
                            <div key={index} className="col-md-12">
                              <Card className={classCard} style={{marginTop:'10px'}}>
                                <CardContent>
                                  <div style={{whiteSpace:'pre-line'}}>
                                    <h3 style={{margin:'0px'}}>{index+1}) Opción: {arrO.titulo}</h3>
                                  </div>
                                  <div className="row" style={{width:'100%', margin: '0px'}}>
                                    <div className="col-md-11">
                                      <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
                                        {path}
                                      </div>
                                      <div style={{width:'100%', textAlign:'left', marginTop:'20px'}}>
                                        Descripción: {arrO.descripcion}
                                      </div>
                                    </div>
                                    <div className="col-md-1 text-center">
                                      {radioButton}
                                    </div>
                                  </div>
                                </CardContent>
                              </Card>
                            </div>
                          )
                        })}
                        <div className="col-md-12">
                          <MuiThemeProvider theme={FormInputs}>
                            <TextField
                              disabled={comentariosDisabled}
                              label="Comentarios"
                              placeholder="Comentarios"
                              id="comentarios"
                              onChange={self.handleChange('comentarios')}
                              margin="normal"
                              variant="outlined"
                              style={{width:'100%'}}
                              multiline={true}
                              rowsMax={7}
                              rows={7}
                              value={comentarios}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{maxLength: 128}}
                              helperText={maxCharacters}
                            />
                          </MuiThemeProvider>
                        </div>
                        <div className="col-md-12 text-center" style={{marginTop:'20px'}}>
                          {btnContestar}
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </MuiThemeProvider>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Sin encuestas</h4>
            </div>
          </div>
        </div>
      )
    }
  }
}

DivMisEncuestas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivMisEncuestas);
