import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import Button from '@material-ui/core/Button';
import DialogCuota from "./DialogCuota";
import TextField from '@material-ui/core/TextField';
import DataMaterialTableNE from "../../commons/DataMaterialTableNE";
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoRounded';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 10,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.33%',
    flexShrink: 0,
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});


class DivCuotas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      openDialogCuota: false,
      handleOpenDialogCuota: 0,
      dataCuota: [],
      optionDialog: "",
      precioM3:"",
      precioM3E:false
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected, precioM3} = this.props;
    if(handleOptionSelected.handleOptionSelected !== handleOptionSelected) {
      this.setState({precioM3:precioM3});
    }
  }

  handleOpenDialogInfo = (dataCuota) => {
    this.setState({dataCuota:dataCuota},()=> {
      this.handleOpenDialogCuota("editar");
    });
  }

  handleOpenDialogCuota = (option) => {
    const {handleOpenDialogCuota} = this.state;
    this.setState({optionDialog:option},()=> {
      this.setState({openDialogCuota: true}, ()=> {
        this.setState({handleOpenDialogCuota:handleOpenDialogCuota+1});
      });
    });
  }

  handleCloseDialogCuota = () => {
    this.setState({openDialogCuota: false});
  }

  renderBtnCrearCuota = (text) => {
    const {classes, checkPermisos} = this.props;
    if(checkPermisos("1") || checkPermisos("105")) {
      return(
        <MuiThemeProvider theme={FormButtons}>
          <Button variant="contained" color="primary" className={classes.button} style={{float: 'right'}} onClick={()=>this.handleOpenDialogCuota("crear")}>
            Crear cuota {text}
            <AddIcon className={classes.rightIcon}/>
          </Button>
        </MuiThemeProvider>
      )
    }
  }

  renderTablaCuotas = () => {
    const {cuotas, checkPermisos, dataUser} = this.props;
    const {precioM3, precioM3E} = this.state;

    var btnCrearCuota = this.renderBtnCrearCuota();
    var titleTable = "Cuotas";
    var theaderMonto = "Monto";

    if(checkPermisos("125")) {
      titleTable = "Cuotas por M2";
      theaderMonto = "Monto por M2";
      btnCrearCuota = this.renderBtnCrearCuota("por M2");
    }

    return (
      <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
        <div className="col-md-6">
          <span><h3 className="darkGrayUpera" style={{marginTop: '0px', marginBottom: '0px'}}>Gestión de Cuotas</h3></span>
        </div>
        <div className="col-md-6">
          {btnCrearCuota}
        </div>
        <div className="col-md-12">
          <DataMaterialTableNE
            dataUser={dataUser}
            title={titleTable}
            data={cuotas}
            columns={[
              { title: 'ID', field: 'areaId', editable: 'never', hidden:true},
              {
                title: 'Info',
                field: 'residenteId',
                ellStyle: {textAlign: 'center'},
                sorting:false,
                render: rowData => {
                  return(<IconButton aria-label="Abrir" onClick={()=>this.handleOpenDialogInfo(rowData)}>
                    <InfoIcon/>
                  </IconButton>)
                },
              },
              { title: 'Concepto', field: 'concepto', cellStyle: {textAlign: 'center'}},
              { title: theaderMonto, field: 'monto', cellStyle: {textAlign: 'center'}},
            ]}
          />
        </div>
      </div>
    );
  }

  render() {
    const {dataUser,crearNotificacion, cerrarNotificacion, ajaxSource, getData, getAdminInfo, crearAlerta, cerrarAlerta, checkPermisos} = this.props;
    const {optionDialog, openDialogCuota, handleOpenDialogCuota, dataCuota} = this.state;

    return(
      <div>
        {this.renderTablaCuotas()}
        <DialogCuota
          open={openDialogCuota}
          option={optionDialog}
          handleClose={this.handleCloseDialogCuota}
          handleOpenDialogCuota={handleOpenDialogCuota}
          crearNotificacion={crearNotificacion}
          cerrarNotificacion={cerrarNotificacion}
          ajaxSource={ajaxSource}
          getData={getData}
          dataUser={dataUser}
          getAdminInfo={getAdminInfo}
          dataCuota={dataCuota}
          crearAlerta={crearAlerta}
          cerrarAlerta={cerrarAlerta}
          checkPermisos={checkPermisos}
        />
      </div>
    )
  }
}

DivCuotas.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DivCuotas);
