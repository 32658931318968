/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from '@material-ui/core/Button';
import Cookies from 'universal-cookie';


/*Componentes de la aplicación*/
import Menu from './commons/Menu';
import DivMainContent from "./modulos/DivMainContent";
import DialogDocumentos from "./commons/DialogDocumentos";

const $ = require('jquery');
var cookies = new Cookies();

/*Variables globales*/
const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  button: {
    fontSize: '10px',
    fontFamily:'Poppins Regular',
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const avatarTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiAvatar: {
      root: {
        width: '60px !important',
        height: '60px !important',
        fontSize: '2.25rem !important',
        fontFamily:'Poppins Regular',
      },
      colorDefault: {
        color: '#737373 !important',
        backgroundColor: '#e4e3e1 !important'
      }
    },
    MuiBadge: {
      root: {
        margin: '0px !important'
      },
      colorPrimary: {
        backgroundColor: '#0345ea'
      },
      badge: {
        top:'54px',
      }
    }
  }
})

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      divMainEmpty: true,
      optionSelected:0,
      handleOptionSelected:0,
      totalNuevasNotifcaciones:0,
    }
  }

  componentDidMount = () => {
    const {loadedPage, handleOptionSelected} = this.props;
    this.loadNotificationCounter();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionPagoEnLinea, handleOptionEstadoCuentaPersonal, loadedPage, optionMenu} = this.props;
    const {handleOptionSelected} = this.state;
    if(nextProps.handleOptionPagoEnLinea !== handleOptionPagoEnLinea) {
      //loadedPage(false);
      this.setState({optionSelected:2},()=> {
        this.setState({handleOptionSelected:handleOptionSelected+1},()=> {
          this.handleDivMainEmpty(false);
        });
      });
    }

    if(nextProps.handleOptionEstadoCuentaPersonal !== handleOptionEstadoCuentaPersonal) {
      //loadedPage(false);
      this.setState({optionSelected:14},()=> {
        this.setState({handleOptionSelected:handleOptionSelected+1},()=> {
          this.handleDivMainEmpty(false);
        });
      });
    }
  }

  handleDivMainEmpty = (value) => {
    this.setState({divMainEmpty:value});
  }

  handleOptionSelected = (option) => {
    const self = this;
    const {loadedPage, mobile} = this.props;
    const {handleOptionSelected} = this.state;

    if(option === 1 || option === 2 || option === 6 || option === 4 || option === 5 || option === 7 || option === 8 || option === 9 || option === 10 || option === 11 || option === 12 || option === 13) {
      loadedPage(false);
    }
    setTimeout(function () {
      self.setState({optionSelected:option},()=> {
        self.setState({handleOptionSelected:handleOptionSelected+1}, ()=> {
          //cookies.set('uperaOptionMenu', option, {path: '/'});
          if(mobile === true) {
            $('html, body').animate({
              scrollTop: $("#divMainContent").offset().top - 80
            }, 600);
          }
        });
      });
    },500);
  }

  loadNotificationCounter = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage, crearNotificacion, cerrarNotificacion, checkPermisos} = this.props;
    const {tipoUsuario} = this.state;
    var userId = dataUser.userId;

    getData(ajaxSource, "notificaciones/notiInfo.php",{
      userId:userId,
    }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var totalNuevasNotifcaciones = data[1];

        self.setState({totalNuevasNotifcaciones:totalNuevasNotifcaciones},()=> {
          loadedPage(true);
        });
      }
    });
  }

  renderMenu = () => {
    const {classes, dataUser, dataUserSet, checkPermisos, crearNotificacion, cerrarNotificacion, getData, ajaxSource, crearAlerta, cerrarAlerta, loadedPage, mobile, documentHeight} = this.props;
    const {optionSelected, totalNuevasNotifcaciones} = this.state;

    var welcomeMsg = "Bienvenido.";
    if(dataUser.userSex === "M") {
      welcomeMsg = "Bienvenida.";
    }

    var iniLetterName = "";
    if(dataUserSet === true) {
      iniLetterName = dataUser.userName.substring(0, 1).toUpperCase();
    }

    var avatarUser =
      <Avatar aria-label="User">
        <b>{iniLetterName}</b>
      </Avatar>;

    if(checkPermisos("1") || checkPermisos("108")) {
      avatarUser = <Badge color="primary" badgeContent={"ADM"} className={classes.margin}>
        <Avatar aria-label="User">
          <b>{iniLetterName}</b>
        </Avatar>
      </Badge>
    }

    if(checkPermisos("123")) {
      avatarUser = <Badge color="primary" badgeContent={"MANTTO"} className={classes.margin}>
        <Avatar aria-label="User">
          <b>{iniLetterName}</b>
        </Avatar>
      </Badge>
    }

    return(
      <div className="row">
        <div className="col-md-12">
          <h1 className="darkGrayUpera" style={{fontSize:'40px', marginBottom:'22px'}}>{welcomeMsg}</h1>
        </div>
        <div className="col-md-12" style={{display:'flex'}}>
          <MuiThemeProvider theme={avatarTheme}>
            {avatarUser}
          </MuiThemeProvider>
          <h3 style={{margin: '0px', marginLeft: '15px', marginTop: '15px'}}>{dataUser.userName}</h3>
        </div>
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
          <div className="col-md-12">
            <h1 style={{fontSize: '31px', fontWeight: 'bold', marginTop: '30px', marginBottom:'30px'}}>¿Qué quieres hacer <br/> el día de hoy?</h1>
            <Menu
              dataUser={dataUser}
              handleDivMainEmpty={this.handleDivMainEmpty}
              handleOptionSelected={this.handleOptionSelected}
              checkPermisos={checkPermisos}
              optionSelected={optionSelected}
              totalNuevasNotifcaciones={totalNuevasNotifcaciones}
            />
          </div>
        </Slide>
      </div>
    )
  }

  render() {
    const {classes, dataUser, dataUserSet, checkPermisos, crearNotificacion, cerrarNotificacion, getData, ajaxSource, crearAlerta, cerrarAlerta, loadedPage, mobile, documentHeight, handleOpenTour, dialogStateForTour, prodEnv, handleReloadComponent, handleOpenDialogDocumentos, openDialogDocumentos, handleCloseDialogDocumentos, dev} = this.props;
    const {divMainEmpty, optionSelected, handleOptionSelected} = this.state;

    var styleMenu = {};
    var styleMainContent = {padding: '0px'};
    var h = 100;

    var menu = this.renderMenu();

    if(mobile === false) {
      styleMenu = {position: 'fixed'}
      styleMainContent = {padding: '0px', position: 'absolute', left: '35%'};
      h = 130;

      menu =
        <Scrollbars style={{height: documentHeight - h, overflowX: 'hidden'}}
          renderTrackHorizontal={props => <div {...props} style={{display: 'none !important'}} className="track-horizontal"/>}
          renderView={props => (
            <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
          )}
          hideTracksWhenNotNeeded={true}>
        {this.renderMenu()}
      </Scrollbars>
    }

    var menuStyle = {overflowX: 'hidden', borderRight: '2px solid #eee'}
    var mainContentStyle = {paddingLeft: '15px'}
    if(mobile === true) {
      menuStyle = {overflowX: 'hidden'}
      mainContentStyle = {paddingLeft:'0px', paddingRight:'0px'}
    }

    var dialogDocumentos = "";
    if(checkPermisos("1") || checkPermisos("121")) {
      dialogDocumentos = <DialogDocumentos
        open={openDialogDocumentos}
        handleOpenDialogDocumentos={handleOpenDialogDocumentos}
        handleClose={handleCloseDialogDocumentos}
        crearNotificacion={crearNotificacion}
        cerrarNotificacion={cerrarNotificacion}
        dataUser={dataUser}
        dataUserSet={dataUserSet}
        checkPermisos={checkPermisos}
        getData={getData}
        ajaxSource={ajaxSource}
        crearAlerta={crearAlerta}
        cerrarAlerta={cerrarAlerta}
        mobile={mobile}
        prodEnv={prodEnv}
      />
    }

    return (
      <div className="main">
        <div className="container">
          <div className="row">
            <div className="col-md-3 menuLeft" style={menuStyle}>
              {menu}
              <Button color="primary" target="e_blank" href="assets/files/AVISOPRIVACIDAD_UPERA2019.pdf" className={classes.button}>
                Aviso de privacidad
              </Button>
              <Button color="primary" target="e_blank"  href="assets/files/TERMINOS_UPERA2019.pdf" className={classes.button}>
                Terminos y condiciones
              </Button>
            </div>
            <div id="divMainContent" className="col-md-9 mainContent" style={mainContentStyle}>
              <DivMainContent
                loadedPage={loadedPage}
                divMainEmpty={divMainEmpty}
                optionSelected={optionSelected}
                crearNotificacion={crearNotificacion}
                cerrarNotificacion={cerrarNotificacion}
                dataUser={dataUser}
                dataUserSet={dataUserSet}
                checkPermisos={checkPermisos}
                getData={getData}
                ajaxSource={ajaxSource}
                crearAlerta={crearAlerta}
                cerrarAlerta={cerrarAlerta}
                handleOptionSelected={handleOptionSelected}
                handleOpenTour={handleOpenTour}
                dialogStateForTour={dialogStateForTour}
                mobile={mobile}
                prodEnv={prodEnv}
                handleReloadComponent={handleReloadComponent}
                loadNotificationCounter={this.loadNotificationCounter}
                documentHeight={documentHeight}
                dev={dev}
              />
            </div>
            {dialogDocumentos}
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);

