import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from 'reactour';
import Button from '@material-ui/core/Button';

const $ = require('jquery');

const stepsPagoEnlinea = [
  {
    content: () => (
      <div>
        <h3>¿Como realizo un pago?</h3>
        En este tour guiado te mostraremos como realizar tu pago.
      </div>
    ),
    position: "top"
  },
  {
    selector: '[data-tour="step1"]',
    content: () => (
      <div>
        Primero deberás seleccionar el año correspondiente a tu pago.
      </div>
    ),
  },
  {
    selector: '[data-tour="step2"]',
    content: () => (
      <div>
        Después podrás seleccionar el mes a pagar, cabe mencionar que solo verás los meses que aún no pagas en el año seleccionado.
      </div>
    ),
  },
  {
    selector: '[data-tour="step4"]',
    content: () => (
      <div>
        Ingresa los datos correspondientes de la tarjeta con la que realizarás el pago, puede ser débito o crédito (Mastercard, Visa) e inclusive American Express.
      </div>
    ),
  },
  {
    selector: '[data-tour="step5"]',
    content: () => (
      <div>
        Si seleccionas esta opción podrás registrar el número de tu tarjeta, para que puedas realizar tus próximos pagos mas rápido.
      </div>
    ),
  },
  {
    selector: '[data-tour="step6"]',
    content: () => (
      <div>
        También puedes domiciliar tu pago, si seleccionas esta opción a partir de la fecha que realices tu pago, cada mes se te hará el cargo automático a tu tarjeta. En cualquier momento puedes cancelar este pago domiciliado en la opción "Pagos Domiciliados".
      </div>
    ),
  },
  {
    selector: '[data-tour="step7"]',
    content: () => (
      <div>
        Por ultimo solo debes dar clic en el botón pagar. Si tu tarjeta tiene los fondos suficientes y la información proporcionada es correcta verás el siguiente mensaje:
        <div style={{width:'100%', marginTop: '10x', marginBottom: '10px'}} className="text-center">
          <img className="img-raised rounded img-fluid" src="assets/img/uperaTour/noti_ok_pago_linea.PNG" style={{width:'100%'}}/>
        </div>
        Tu comprobante de pago llegará a tu correo y recibirás una notificación via whatsapp.
      </div>
    ),
  },
]

const stepsPagoEnOxxo = [
  {
    content: () => (
      <div>
        <h3>¿Como realizo un pago en OXXO?</h3>
        En este tour guiado te mostraremos como realizar tu en tiendas OXXO.
      </div>
    ),
    position: "top"
  },
  {
    selector: '[data-tour="step8"]',
    content: () => (
      <div>
        Primero deberás seleccionar el año correspondiente a tu pago.
      </div>
    ),
  },
  {
    selector: '[data-tour="step9"]',
    content: () => (
      <div>
        Despues podrás seleccionar el mes a pagar, cabe mencionar que solo verás los meses que aún no pagas en el año seleccionado.
      </div>
    ),
  },
  {
    selector: '[data-tour="step11"]',
    content: () => (
      <div>
        Por ultimo da click en el botón "Generar ficha de pago", esto generará tu ficha de pago digital, esta ficha siempre estará presente en este apartado hasta que realices tu pago en tiendas OXXO. Sólo deberás acudir a cualquier sucursal y mencionar que realizarás un pago mediante OXXO Pay y darle al cajero tu número de ficha. <br/>
        Tu comprobante de pago llegará a tu correo y recibirás una notificación vía whatsapp confirmando tu pago.
      </div>
    ),
  },
]

const stepsEditarCrearUsuarios = [
  {
    content: () => (
      <div>
        <h3>¿Como gestiono a los usuarios?</h3>
        En este tour guiado te mostraremos como crear usuarios, editar su informción y registrar un pago en efectivo.
      </div>
    ),
    position: "top"
  },
  {
    selector: '[data-tour="step1"]',
    content: () => (
      <div>
        Para crear un usuario nuevo, debes dar clic en el botón "Crar usuario"
      </div>
    ),
  },
  {
    content: () => (
      <div>
        En la ventanta que se abrirá deberas llenar el formulario con la información solicitada del usuario.
      </div>
    ),
  },
]

const stepsNuevoTicket = [
  {
    content: () => (
      <div>
        <h3>¿Como genero un nuevo ticket?</h3>
        En este tour guiado te mostraremos como generar un nuevo ticket de mantenimiento.
      </div>
    ),
    position: "top"
  },
  {
    selector: '[data-tour="step1"]',
    content: () => (
      <div>
        Primero, selecciona el área reponsable al problema que quieres reportar.
      </div>
    ),
  },
  {
    selector: '[data-tour="step2"]',
    content: () => (
      <div>
        Segundo, debes seleccionar el subarea repsonsable.
      </div>
    ),
  },
  {
    selector: '[data-tour="step3"]',
    content: () => (
      <div>
        Tercero, ingresa una descripción del problema, maximo 128 caracteres.
      </div>
    ),
  },
  {
    selector: '[data-tour="step4"]',
    content: () => (
      <div>
        Cuarto, si lo deseas puedes subir una foto donde se aprecie el problema que estas reportando.
        Para esto arrastra o busca la foto deseada y da clic en el boton:

        <div style={{width:'100%', marginTop: '10x', marginBottom: '10px'}} className="text-center">
          <img className="img-raised rounded img-fluid" src="assets/img/uperaTour/btn_subir.PNG" style={{width:'50px'}}/>
        </div>

        Cuando veas el mensaje de "Carga completa", significa que tu foto se ha subido exitosamente.

        <div style={{width:'100%', marginTop: '10x', marginBottom: '10px'}} className="text-center">
          <img className="img-raised rounded img-fluid" src="assets/img/uperaTour/carga_completa.PNG" style={{width:'100%'}}/>
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="step5"]',
    content: () => (
      <div>
        Quinto, da click en el botón "Enviar".
      </div>
    ),
  },
  {
    selector: '[data-tour="step6"]',
    content: () => (
      <div>
        En esta sección veras tus tickets abiertos.
      </div>
    ),
  },
]

const styles = theme => ({
  progress: {
    margin: '0px',
    marginRight: '10px',
    color: '#00bcd4 !important',
    width:'30px',
    height: '30px'
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

class UperaTour extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      defaultStep:0,
      step:0
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {dialogStateForTour, tour} = this.props;
    const {step} = this.state;

    if(nextProps.dialogStateForTour !== dialogStateForTour) {

      if(tour === "crearEditarUsuario" && step === 1) {
        this.goToStep(2);
      }
    }
  }

  disableBody = target => disableBodyScroll(target);
  enableBody = target => enableBodyScroll(target);

  goToStep = (step) => {
    this.setState({step: step});
  }

  getStep = (curr) => {
    const {tour} = this.props;
    this.setState({step:curr});
    var step = curr;
    console.log(step);
    if(step > 0 && tour !== "crearEditarUsuario" && step !== 2 && step !== 3) {

      $('html, body').animate({
        scrollTop: $("[data-tour='step" + step + "']").offset().top - 200
      }, 0);
    }
  }

  render() {
    const { classes, tour, open, closeTour} = this.props;
    const {defaultStep, step} = this.state;

    var steps = [];
    if(tour === "pagoEnLinea") {
      steps = stepsPagoEnlinea;
    } else if(tour === "pagoEnOxxo") {
      steps = stepsPagoEnOxxo;
    } else if(tour === "crearEditarUsuario") {
      steps = stepsEditarCrearUsuarios;
    } else if(tour === "nuevoTicket") {
      steps = stepsNuevoTicket;
    }

    return (
      <Tour
        steps={steps}
        isOpen={open}
        onRequestClose={closeTour}
        rounded={5}
        accentColor="#0345ea"
        onAfterOpen={this.disableBody}
        onBeforeClose={this.enableBody}
        maskClassName="mask"
        className="helper"
        disableInteraction={false}
        closeWithMask={false}
        getCurrentStep={this.getStep}
        startAt={0}
        goToStep={step}
        lastStepNextButton={
          <MuiThemeProvider theme={FormButtons}>
            <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}}>
              ¡Listo!
            </Button>
          </MuiThemeProvider>
        }
      />
    );
  }
}

UperaTour.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UperaTour);
