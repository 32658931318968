import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: '0px',
    width: '100%'
  },
  heading: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#e4e3e1',
    color: '#737373 !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  headingActive: {
    flexBasis: '20%',
    flexShrink: 0,
    background: '#0345ea',
    color: '#FFF !important',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center',
    padding: '20px'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    display: 'inherit',
    verticalAlign: 'middle',
    marginLeft: '10px',
    fontSize: '17px',
    marginTop: '20px',
    fontWeight: 'bold'
  },
});

const ExpansionPanels = createMuiTheme( {
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: Array(25).fill('none'),
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0px 0px 0px 12px'
      }
    },
    MuiExpansionPanel: {
      root: {
        "&$expanded": {
          border:'2px solid #1a49bf'
        }
      }
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: '0px !important'
      }
    }
  }
})

class DivNotificaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded:null,
      notificaciones: []
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOptionSelected} = this.props;
    if(nextProps.handleOptionSelected !== handleOptionSelected) {
      this.getNotiInfo();
    }
  }

  handleChangePanel = (panel, leido) => (event, expanded) => {
    this.setState({expanded: expanded ? panel : false,}, () => {

      if(leido === '0') {
        const self = this;
        const {ajaxSource, dataUser, getData, loadNotificationCounter} = this.props;
        var userId = dataUser.userId;
        getData(ajaxSource, "notificaciones/setNotiReaded.php", {
          notiId: panel,
          userId:userId
        }).then(function (response) {
          if (response.data) {
            self.getNotiInfo();
            loadNotificationCounter();
          }
        });
      }
    }) ;
  };

  getNotiInfo = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, loadedPage} = this.props;
    var userId = dataUser.userId;

    getData(ajaxSource, "notificaciones/notiInfo.php",{
      userId:userId
      }).then(function (response) {
      if (response.data) {
        var data = response.data;
        var notificaciones = data[0];
        loadedPage(true);

        self.setState({notificaciones:notificaciones});
      }
    });
  }

  render() {
    const self = this;
    const {classes} = this.props;
    const {notificaciones, expanded} = this.state;

    if (Object.keys(notificaciones).length > 0) {
      return (
        <div className="row" style={{paddingBottom:'20px', margin: '0px'}}>
          <div className="col-md-12 mtM">
            <h3 className="darkGrayUpera" style={{marginTop:'0px'}}>Mis notificaciones</h3>
          </div>
          <div className="col-md-12">
            {notificaciones.map(function (arr, index) {

              var classActive = classes.heading;
              if(arr.leido === "0") {
                classActive = classes.headingActive;
              }

              return(
                <MuiThemeProvider theme={ExpansionPanels} key={index}>
                  <ExpansionPanel expanded={expanded === arr.notiId} onChange={self.handleChangePanel(arr.notiId, arr.leido)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classActive} style={{color:'#737373'}}>{arr.fecha}</Typography>
                      <div className={classes.secondaryHeading}>
                        {arr.title}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'inline'}}>
                      <div className="row" style={{width:'100%', margin: '0px'}}>
                        <div className="col-md-12" style={{color: 'rgb(115, 115, 115)'}}>
                          {arr.msg}
                        </div>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </MuiThemeProvider>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div className="row" style={{paddingTop: '20px', margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-center" style={{marginTop:'40px'}}>
              <img className="img-raised rounded-circle img-fluid" src="assets/img/vacio.png" style={{width: '100px'}}/>
              <h4>Sin notificaciones</h4>
            </div>
          </div>
        </div>
      )
    }
  }
}

DivNotificaciones.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DivNotificaciones);
