/*Componentes globales*/
import React, {Component} from 'react';
import SweetAlert from 'sweetalert2-react';

class Alerta extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <SweetAlert
        show={this.props.mostrarAlerta}
        type={this.props.tipo}
        title={this.props.titulo}
        text={this.props.txt}
        confirmButtonText='Ok!'
        buttonsStyling={false}
        confirmButtonClass='btn btn-success'
        onConfirm={this.props.onConfirmAlerta}
        showCancelButton={this.props.showCancelButton}
        cancelButtonText='CANCELAR'
        cancelButtonClass='btn btn-default'
        onCancel={()=>this.props.cerrarAlerta()}
        style={{zIndex:'1000000'}}
      />
    );
  }
}

export default Alerta;
