/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import UpdateIcon from '@material-ui/icons/UpdateRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
});

const DialogT = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxWidth : "700px",
        margin: "48px auto !important"
      },
      paper: {
        overflow: 'hidden !important',
        overflowY: 'hidden !important'
      }
    }
  }
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(0,0,0,0.4)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        }
      },
      disabled: {
        backgroundColor: '#eee',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#737373'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "#fff !important"
      }
    }
  },
});

const FormButtons = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DialogAreas extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      value:"",
      nombre:"",
      nombreE:"",
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {handleOpenDialogAreas, option, dataAreas} = this.props;
    if(nextProps.handleOpenDialogAreas !== handleOpenDialogAreas) {
      if(option === "editar") {
        this.setState({concepto:dataAreas.nombre});
      }
    }
  }

  handleClose = () => {
    const {handleClose} = this.props;
    this.setState(this.initialState);
    handleClose();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.setState({[name+'E']:false});
  };


  hanldleDeleteArea = () => {
    const {crearAlerta} = this.props;
    crearAlerta("warning", "", "¿Seguro que deseas eliminar esta área?", true, this.deleteCuota);
  }

  createEditArea = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, option, dataAreas} = this.props;
    const {nombre} = this.state;

    if(nombre === "") {
      this.setState({nombreE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el nombre del área.", "bottom", "right");
      });
    }  else {
      var userId = dataUser.userId;

      var txtNoti = "Creando área...";
      var txtExito = "creada";
      var txtError = "crear";
      var areaId = "";
      if(option === "editar") {
        txtNoti = "Editando área....";
        txtExito = "editada";
        txtError = "editar";
        areaId = dataAreas.areaId;
      }

      crearNotificacion("info", txtNoti, "bottom", "right", 1000000);

      getData(ajaxSource, "administracion/createEditArea.php",{
        userId:userId,
        option:option,
        areaId:areaId,
        nombre:nombre,
        }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;

          if(data[0].error === 1) {
            setTimeout(function () {
              crearNotificacion("success", "Área "+txtExito+" con exito.", "bottom", "right", 3000);
              getAdminInfo();
              self.handleClose();
            },500);
          } else {
            crearNotificacion("error", "Hubo un problema al intentar "+txtError+" el área " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  deleteArea = () => {
    const self = this;
    const {ajaxSource, getData, dataUser, crearNotificacion, cerrarNotificacion, getAdminInfo, dataAreas, cerrarAlerta} = this.props;
    cerrarAlerta();

    var userId = dataUser.userId;
    var areaId = dataAreas.areaId;

    crearNotificacion("info", "Eliminando área...", "bottom", "right", 1000000);

    getData(ajaxSource, "administracion/deleteArea.php",{
      userId:userId,
      areaId:areaId,
    }).then(function (response) {
      if (response.data) {
        cerrarNotificacion();
        var data = response.data;

        if(data[0].error === 1) {
          cerrarNotificacion();
          setTimeout(function () {
            crearNotificacion("success", "Área eliminada con exito.", "bottom", "right", 3000);
            getAdminInfo();
            self.handleClose();
          },500);
        } else {
          cerrarNotificacion();
          crearNotificacion("error", "Hubo un problema al intentar eliminar el área " + data[0].error, "bottom", "right", 3000);
        }
      }
    });
  }


  render() {
    const {classes, open, option} = this.props;
    const {nombre, nombreE} = this.state;

    var title = "Crear Área";
    var btn = <Button variant="contained" color="primary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.createEditArea()}>
      Crear
      <AddIcon className={classes.rightIcon}/>
    </Button>
    var btnEliminar = "";

    if(option === "editar") {
      title = "Editar Área";

      btn = <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '22px'}}
                    onClick={() => this.createEditArea()}>
        Actualizar
        <UpdateIcon className={classes.rightIcon}/>
      </Button>
      btnEliminar =  <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'22px'}} onClick={()=>this.hanldleDeleteArea()}>
        <DeleteIcon className={classes.leftIcon}/>
        Eliminar
      </Button>
    }

    return (
      <MuiThemeProvider theme={DialogT}>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="simple-dialog-title">
            <span className="darkGrayUpera">{title}</span>
            <IconButton aria-label="Cerrar" style={{position:'absolute', right: '10px', top:'10px'}} onClick={()=>this.handleClose()}>
              <CloseIcon className="darkGrayUpera"/>
            </IconButton>
          </DialogTitle>
          <List>
            <MuiThemeProvider theme={FormInputs}>
              <ListItem style={{display:'inline'}}>
                <div className="row" style={{margin: '0px', width: '100%'}}>
                  <div className="col-md-12">
                    <TextField
                      error={nombreE}
                      label="Nombre del área"
                      placeholder="Nombre del área"
                      id="nombre"
                      onChange={this.handleChange('nombre')}
                      value={nombre}
                      margin="normal"
                      variant="outlined"
                      style={{width:'100%'}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <MuiThemeProvider theme={FormButtons}>
                      {btn}
                      {btnEliminar}
                    </MuiThemeProvider>
                  </div>
                </div>
              </ListItem>
            </MuiThemeProvider>
          </List>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

DialogAreas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogAreas);
