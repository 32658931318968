import React, {Component} from 'react';
 
import BuggIcon from '@material-ui/icons/BugReport';
 
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Cookies from 'universal-cookie';
import Slide from "@material-ui/core/Slide";
import CloseIcon from '@material-ui/icons/CloseRounded';
 
import Button from "@material-ui/core/Button";
 
 
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const actions = [
  { icon: <BuggIcon />, name: 'Reportar Error', option:'reportarError' },
];

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const FormInputs = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily:'Poppins Regular',
  },
  overrides: {
    MuiDialog: {
      paper:{
        backgroundColor: 'rgba(0, 0, 0, .6) !important',
        backdropFilter: 'blur(15px) !important',
        border:'2px solid #FFF',
        borderRadius:'20px !important'
      },
      paperWidthLg: {
        maxWidth : "600px",
        margin: "48px auto !important"
      }
    },
    MuiFab:{
      primary:{
        backgroundColor: '#0345ea',
        "&:hover":{
          backgroundColor: '#d32f2f'
        }
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop:'5px !important'
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: 'rgba(255,255,255,.7)',
        },
        "&$focused $notchedOutline": {
          borderColor: '#0345ea'
        } ,
        legend: {
          width: "calc(8px + var(--label-width) * 0.75) !important"
        }
      },
      disabled: {
        borderRadius: '5px'
      },
      notchedOutline: {
        borderColor: 'rgba(255,255,255,.8)'
      },
      input: {
        color:'#FFF',
        borderRadius: '5px'
      }
    },
    MuiFormControlLabel: {
      label: {
        color: '#FFF'
      }
    },
    MuiCheckbox: {
      checked: {
        color: '#0345ea !important'
      }
    },
    MuiInputLabel: {
      shrink: {
        background: "transparent !important",
        color:'#FFF !important'
      }
    },
    MuiFormLabel: {
      root: {
        color:"rgba(255,255,255,.4)",
        fontWeight: '200'
      }
    },
    MuiSelect: {
      icon: {
        color:'#FFF'
      },
      select: {
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      }
    },
    MuiInputBase:{
      root:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
          borderRadius:'5px'
        }
      },
      input:{
        "&.Mui-disabled": {
          backgroundColor:'rgba(0,18,35,.4)',
        }
      },
    },
    MuiButton: {
      root:{
        borderRadius:'20px'
      },
      containedPrimary: {
        backgroundColor: '#0345ea',
        '&:hover': {
          backgroundColor: '#1a49bf',
        }
      }
    }
  },
});

class ErrorsReporterDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      openDialogReportError:false,
      estabaHaciendo:"",
      estabaHaciendoE:false,
      errorSistema:"",
      errorSistemaE:"",
      showResult:false
    }
  }

  componentDidMount() {
    const {login} = this.props;
    if(login === true) {
      this.setState({hidden:false});
    } else {
      this.setState({hidden:true});
    }
  }

  handleOpen = () => {
    this.setState({open:true});
  };

  handleClose = () => {
    this.setState({open:false});
  };

  handleOpenDialogReporError = () => {
    this.setState({openDialogReportError:true, open:false});
  }

  handleCloseDialogReporError = () => {
    this.setState({openDialogReportError:false});
  }

  handleAction = (option) => {
    console.log(option);
    if(option === "reportarError") {
      this.handleOpenDialogReporError();
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value },()=> {});
    this.setState({[name+'E']:false});
  }

  createTicket = () => {
    const self = this;
    const {ajaxSource, dataUser, getData, crearNotificacion, cerrarNotificacion} = this.props;
    const {estabaHaciendo, errorSistema} = this.state;
    var userId = dataUser.userId;

    if(errorSistema === "") {
      this.setState({errorSistemaE:true}, ()=> {
        crearNotificacion("warning", "Ingresa el error que te presento el sistema.", "bottom", "right");
      });
    } else if(estabaHaciendo === "") {
      this.setState({estabaHaciendoE:true}, ()=> {
        crearNotificacion("warning", "Ingresa lo que estabas haciendo cuando se presento el error.", "bottom", "right");
      });
    } else {
      crearNotificacion("info", "Creando ticket de soporte...", "bottom", "right", 1000000);

      getData(ajaxSource, "errorReporter/createTicket.php",{
        userId:userId,
        estabaHaciendo:estabaHaciendo,
        errorSistema:errorSistema
      }).then(function (response) {
        if (response.data) {
          cerrarNotificacion();
          var data = response.data;
          //cerrarNotificacion();

          if(data[0].error === 1) {
            setTimeout(function () {
              crearNotificacion("success", "Ticket creado con exito.", "bottom", "right", 3000);
              self.setState({showResult:true});
            },500);
          } else {
            crearNotificacion("error", "Hubo un problema al intentar crear el ticket " + data[0].error, "bottom", "right", 3000);
          }
        }
      });
    }
  }

  renderForm = () => {
    const {classes} = this.props;
    const {estabaHaciendo,estabaHaciendoE,errorSistema,errorSistemaE, showResult} = this.state;

    if(showResult === false) {
      return (
        <div className="row" style={{width: "100%", margin: '0px'}}>
          <div className="col-md-12">
            <TextField
              error={errorSistemaE}
              label="¿Que error te mostro el sistema?"
              placeholder=""
              id="errorSistema"
              onChange={this.handleChange('errorSistema')}
              value={errorSistema}
              margin="normal"
              variant="outlined"
              style={{width: '100%'}}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className="col-md-12" style={{marginTop: '10px'}}>
            <TextField
              error={estabaHaciendoE}
              label="¿Que estabas haciendo cuando se presento el error?"
              placeholder=""
              id="estabaHaciendo"
              onChange={this.handleChange('estabaHaciendo')}
              value={estabaHaciendo}
              margin="normal"
              variant="outlined"
              style={{width: '100%'}}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className="col-md-12" style={{textAlign: 'center'}}>
            <Button variant="contained" color="primary" className={classes.button} style={{marginTop: '10px'}}
                    onClick={() => this.createTicket()}>
              Enviar ticket
              <SendIcon className={classes.rightIcon}/>
            </Button>
          </div>
        </div>
      )
    } else {
      return(
        <div className="row" style={{width: "100%", margin: '0px'}}>
          <div className="col-md-12">
            <div className="text-white" style={{marginTop:'10px', fontFamily:'Poppins Regular', fontSize: '14px'}}>Se ha generado un ticket e informado al equipo de soporte técnico. <br></br> <br></br> Lamentamos los inconvenientes que esto pueda causar, una vez solucionado el error te notificaremos vía email o WhatsApp. <br></br></div>
          </div>
        </div>
      )
    }
  }

  render() {
    const {classes, login, handleOpen} = this.props;
    const {open,hidden,openDialogReportError,estabaHaciendo,estabaHaciendoE,errorSistema,errorSistemaE} = this.state;

    return(
      <>
        {login &&
        <MuiThemeProvider theme={FormInputs}>
          
           
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={() => this.handleAction("reportarError")}
            style={{
              display: "flex",
              justifyContent: "center",
              background: '#0345ea',
              borderRadius: '8px',
              position: 'fixed',
              cursor: 'pointer',
              bottom: '0px',
              right: '0px',
              zIndex: 1000000000
            }}

            /* El botón de reportar error en versión web tapa las flechas 
            para desplegar más información y para ir hacia adelante o 
            hacia atrás en algunas resoluciones. #15 */
          >
            <font style={{fontFamily: 'Poppins Regular'}}>Reportar Error</font>
          </Button>


        
          <Dialog
            scroll={'body'}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={"sm"}
            onClose={this.handleCloseDialogReporError}
            open={openDialogReportError}
            aria-labelledby="simple-dialog-title"
            TransitionComponent={Transition}>



            <DialogTitle id="simple-dialog-title">
              <IconButton aria-label="Cerrar" style={{position: 'absolute', right: '10px'}}
                          onClick={() => this.handleCloseDialogReporError()}>
                <CloseIcon className="text-white" style={{color: '#FFF'}}/>
              </IconButton>
              <div style={{width: '100%'}}>
                <img src="assets/img/iconos/LOGO_BLANCO.png" className="img-fluid"
                     style={{width: '135px', marginBottom: '0px'}} alt="asd"/>
              </div>
              <div style={{width: '100%', marginBottom: '10px'}}>
                <span className="text-white"
                      style={{marginTop: '10px', fontFamily: 'Poppins Medium', fontSize: '25px', lineHeight: '20px'}}>Reporte de errores.</span>
              </div>
            </DialogTitle>
            <List style={{overflow: 'hidden'}}>
              <ListItem>
                {this.renderForm()}
              </ListItem>
            </List>
          </Dialog>
        </MuiThemeProvider>
        }
      </>
    )
  }
}

ErrorsReporterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorsReporterDialog);
