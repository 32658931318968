/*Componentes globales*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

/*Componentes de la aplicación*/

/*Variables globales*/
const styles = theme => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
});

class Proximamente extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      divMainEmpty: true,
      optionSelected:0,
      handleOptionSelected:0
    }
  }

  componentDidMount = () => {
    const {loadedPage} = this.props;
    loadedPage(true);
  }

  render() {
    const {mobile} = this.props;

    var backGroundImage = "assets/img/bg-proximamante.png";

    if(mobile === true) {
      backGroundImage = "assets/img/bg-proximamante2.jpg"
    }

    var styleBackGroundImage = {
      backgroundImage: "url(" + backGroundImage + ")",
      backgroundSize: "cover",
      backgroundPosition: "top center"
    };

    var img = <img src={backGroundImage} style={{width:'100%'}}/>;
    var style = styleBackGroundImage;
    var classim = "page-header";
    if(mobile === true) {
      style = {};
      classim = "";
    }

    if(mobile === false) {
      //img = "";
      style = {}
    }

    return (
      <div className="wrapper wrapper-full-page" style={{background:'#FFF'}}>
        <div className={classim} style={style}>
          {img}
        </div>
      </div>
    );
  }
}

Proximamente.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Proximamente);

