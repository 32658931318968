/*Componentes globales*/
import React, {Component} from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {createMuiTheme, makeStyles, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
// @material-ui/icons

import Header from "../../../componentsmKit/Header/Header.js";
import Footer from "../../../componentsmKit/Footer/Footer.js";
import GridContainer from "../../../componentsmKit/Grid/GridContainer.js";
import GridItem from "../../../componentsmKit/Grid/GridItem.js";
import Button from "../../../componentsmKit/CustomButtons/Button.js";
import HeaderLinks from "../../../componentsmKit/Header/HeaderLinks.js";
import Parallax from "../../../componentsmKit/Parallax/Parallax.js";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/CheckCircle';

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

class HomePlanes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val:"",
      usuariosOption:"0-100",
      precioPlanBasico:"$10",
      precioPlanEstandar:"$15",
      precioPlanPro:"$20"
    }
  }

  handleSelectOption = (option) => {
    this.setState({usuariosOption:option}, ()=> {

      if(option === "0-100") {
        this.setState({precioPlanBasico:"$10",precioPlanEstandar:"$15",precioPlanPro:"$20"})
      }

      if(option === "101-500") {
        this.setState({precioPlanBasico:"$9",precioPlanEstandar:"$14",precioPlanPro:"$19"})
      }

      if(option === "501-1000") {
        this.setState({precioPlanBasico:"$8",precioPlanEstandar:"$13",precioPlanPro:"$18"})
      }

      if(option === "1000+") {
        this.setState({precioPlanBasico:"Especial",precioPlanEstandar:"Especial",precioPlanPro:"Especial"})
      }
    });
  }


  render() {
    const {classes, mobile, changePage} = this.props;
    const {usuariosOption, precioPlanBasico, precioPlanEstandar, precioPlanPro} = this.state;

    return(
      <div id="homePlanes" style={{ width:'100%', borderRadius:'0px', backgroundColor:'#f2f2f2'}}>
        <div className={classes.container}>
          <div style={{width:'100%', padding:!mobile ? '50px 130px': '50px 0px'}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{width:'100%', textAlign:'center'}}>
                  <img src="assets/img/iconos/LOGO_AZUL.png" style={{width:'100px'}}></img>
                </div>
                {!mobile ?
                  <div style={{width: '100%', textAlign: 'center'}}>
                    <h1 className={classes.title} style={{
                      marginTop: '30px',
                      fontFamily: 'Poppins SemiBold',
                      color: '#055fc3',
                      fontWeight: '50',
                      fontSize: '2.1125rem',
                      lineHeight: '38px'
                    }}>Upera a la medida <br></br> Elige el plan que se ajuste a tus necesidades, <br></br> nostros nos
                      encargamos del resto.</h1>
                  </div>
                  :
                  <div style={{width: '100%', textAlign: 'center'}}>
                    <h1 className={classes.title} style={{
                      marginTop: '30px',
                      fontFamily: 'Poppins SemiBold',
                      color: '#131316',
                      fontWeight: '50',
                      fontSize: '2.1125rem',
                      lineHeight: '38px'
                    }}>Upera a la medida</h1>
                    <h4 className={classes.title} style={{
                      marginTop: '0px',
                      fontFamily: 'Poppins SemiBold',
                      color: '#131316',
                      fontWeight: '50',
                      fontSize: '15px',
                      lineHeight: '14pxpx'
                    }}>Elige el plan que se ajuste a tus necesidades, <br></br> nostros nos
                      encargamos del resto.</h4>
                  </div>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12} style={{textAlign:'center'}}>
                <div style={{width:'100%', marginTop:'30px'}}>
                  {!mobile ?
                    <h1 className={classes.title} style={{
                      marginTop: '30px',
                      fontFamily: 'Poppins SemiBold',
                      color: '#131316',
                      fontWeight: '50',
                      fontSize: '1.4125rem',
                      lineHeight: '38px'
                    }}>Elige el número de usuarios</h1>
                    :
                    <h1 className={classes.title} style={{
                      marginTop: '10px',
                      fontFamily: 'Poppins SemiBold',
                      color: '#131316',
                      fontWeight: '50',
                      fontSize: '1.1125rem',
                    }}>Elige el número de usuarios</h1>
                  }
                </div>
                {!mobile ?
                  <>
                    <Button
                      color={usuariosOption === "0-100" ? "blueUpera" : "grayUperaB"}
                      size="lg"
                      onClick={() => this.handleSelectOption("0-100")}
                      marginLeft="10px"
                    >
                      0-100 usuarios
                    </Button>
                    <Button
                      color={usuariosOption === "101-500" ? "blueUpera" : "grayUperaB"}
                      size="lg"
                      onClick={() => this.handleSelectOption("101-500")}
                      marginLeft="10px"
                    >
                      101-500 usuarios
                    </Button>
                    <Button
                      color={usuariosOption === "501-1000" ? "blueUpera" : "grayUperaB"}
                      size="lg"
                      onClick={() => this.handleSelectOption("501-1000")}
                      marginLeft="10px"
                    >
                      501-1000 usuarios
                    </Button>
                    <Button
                      color={usuariosOption === "1000+" ? "blueUpera" : "grayUperaB"}
                      size="lg"
                      onClick={() => this.handleSelectOption("1000+")}
                      marginLeft="10px"
                    >
                      1000+ usuarios
                    </Button>
                  </>
                  :
                  <GridContainer >
                    <GridItem xs={12} style={{textAlign:'center'}}>
                      <Button
                        color={usuariosOption === "0-100" ? "blueUpera" : "grayUperaB"}
                        size="md"
                        onClick={() => this.handleSelectOption("0-100")}
                        fullWidth={true}
                      >
                        0-100 usuarios
                      </Button>
                      <Button
                        color={usuariosOption === "101-500" ? "blueUpera" : "grayUperaB"}
                        size="md"
                        onClick={() => this.handleSelectOption("101-500")}
                        fullWidth={true}
                      >
                        101-500 usuarios
                      </Button>
                      <Button
                        color={usuariosOption === "501-1000" ? "blueUpera" : "grayUperaB"}
                        size="md"
                        onClick={() => this.handleSelectOption("501-1000")}
                        fullWidth={true}
                      >
                        501-1000 usuarios
                      </Button>
                      <Button
                        color={usuariosOption === "1000+" ? "blueUpera" : "grayUperaB"}
                        size="md"
                        onClick={() => this.handleSelectOption("1000+")}
                        fullWidth={true}
                      >
                        1000+ usuarios
                      </Button>
                    </GridItem>
                  </GridContainer>
                }
              </GridItem>
            </GridContainer>
            <GridContainer style={{marginTop:'30px'}}>
              <GridItem xs={12} sm={12} md={4} style={{textAlign:'center'}}>
                <div style={{backgroundColor:'#FFF', border:'2px solid #cccccc', borderRadius:'11px', padding:'50px 0px'}}>
                  <div style={{width:'100%'}}>
                    <h1 className={classes.title} style={{marginTop:'0px', fontFamily: 'Poppins Regular', color:'#131316', fontSize:'1.4125rem', lineHeight:'38px'}}>Plan <font style={{color:'#036adb'}}>Básico</font></h1>
                  </div>
                  <div style={{width:'100%'}}>
                    <h1 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins SemiBold', color:'#036adb'}}>{precioPlanBasico}</h1>
                  </div>
                  <div style={{width:'100%'}}>
                    <h4 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins Medium', color:'#7e7e84', fontWeight:'50', fontSize:'15px'}}>mxn/mes por usuario</h4>
                  </div>
                  <div style={{width:'100%'}}>
                    <h4 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins Medium', color:'#131316', fontWeight:'50', fontSize:'18px'}}>Activación anual</h4>
                  </div>
                  <div style={{width:'100%', borderBottom: '2px solid #cccccc', paddingBottom:'20px'}}>
                    <h1 className={classes.title} style={{margin:'0px', fontSize:'1.8125rem', fontFamily: 'Poppins SemiBold', color:'#036adb'}}>$4,499</h1>
                  </div>
                  <div style={{width:'100%', padding:'30px', borderBottom: '2px solid #cccccc'}}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Gestión de usuarios y cuotas." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Registro de pagos." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Estados de cuenta automáticos." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Notificaciones." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Botón SOS." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Pago en línea." />
                      </ListItem>
                      {!mobile ?
                        <>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon style={{color: '#FFF'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Reportes de mantenimiento."/>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon style={{color: '#FFF'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Módulo de encuenstas y votaciones."/>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon style={{color: '#FFF'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Accesos a visitas con códigos QR*"/>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <CheckIcon style={{color: '#FFF'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Módulo de facturación automática**"/>
                          </ListItem>
                        </>
                        :
                        <></>
                      }
                    </List>
                  </div>
                  <div style={{width:'100%', padding:'30px'}}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Esenciales" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Soporte en linea 24/7.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Personalización de dashboard web.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Actualizaciones de sistema.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Certificados de seguridad.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Respaldo de información por dos años.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Acceso a herramientas vía app y web.</font>
                        } />
                      </ListItem>
                    </List>
                  </div>
                  <div style={{width:'100%', padding:'30px', paddingBottom:'0px'}}>
                    <Button
                      color="blueUpera"
                      size="lg"
                      fullWidth={true}
                      onClick={()=>changePage("uperaContacto")}
                    >
                      Empezar ahora
                    </Button>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{textAlign:'center', marginTop: !mobile ? '0px' : '20px'}}>
                <div style={{backgroundColor:'#FFF', border:'2px solid #cccccc', borderRadius:'11px', padding:'50px 0px'}}>
                  <div style={{width:'100%'}}>
                    <h1 className={classes.title} style={{marginTop:'0px', fontFamily: 'Poppins Regular', color:'#131316', fontSize:'1.4125rem', lineHeight:'38px'}}>Plan <font style={{color:'#036adb'}}>Estandar</font></h1>
                  </div>
                  <div style={{width:'100%'}}>
                    <h1 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins SemiBold', color:'#036adb'}}>{precioPlanEstandar}</h1>
                  </div>
                  <div style={{width:'100%'}}>
                    <h4 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins Medium', color:'#7e7e84', fontWeight:'50', fontSize:'15px'}}>mxn/mes por usuario</h4>
                  </div>
                  <div style={{width:'100%'}}>
                    <h4 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins Medium', color:'#131316', fontWeight:'50', fontSize:'18px'}}>Activación anual</h4>
                  </div>
                  <div style={{width:'100%', borderBottom: '2px solid #cccccc', paddingBottom:'20px'}}>
                    <h1 className={classes.title} style={{margin:'0px', fontSize:'1.8125rem', fontFamily: 'Poppins SemiBold', color:'#036adb'}}>$6,999</h1>
                  </div>
                  <div style={{width:'100%', padding:'30px', borderBottom: '2px solid #cccccc'}}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Gestión de usuarios y cuotas." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Registro de pagos." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Estados de cuenta automáticos." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Notificaciones." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Botón SOS." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Pago en línea." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Reportes de mantenimiento." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Módulo de encuenstas y votaciones." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Accesos a visitas con códigos QR*" />
                      </ListItem>
                      {!mobile ?
                        <ListItem>
                          <ListItemIcon>
                            <CheckIcon style={{color: '#FFF'}}/>
                          </ListItemIcon>
                          <ListItemText primary="Módulo de facturación automática**"/>
                        </ListItem>
                        :
                        <></>
                      }
                    </List>
                  </div>
                  <div style={{width:'100%', padding:'30px'}}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Esenciales" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Soporte en linea 24/7.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Personalización de dashboard web.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Actualizaciones de sistema.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Certificados de seguridad.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Respaldo de información por dos años.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Acceso a herramientas vía app y web.</font>
                        } />
                      </ListItem>
                    </List>
                  </div>
                  <div style={{width:'100%', padding:'30px', paddingBottom:'0px'}}>
                    <Button
                      color="blueUpera"
                      size="lg"
                      fullWidth={true}
                      onClick={()=>changePage("uperaContacto")}
                    >
                      Empezar ahora
                    </Button>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{textAlign:'center', marginTop: !mobile ? '0px' : '20px'}}>
                <div style={{backgroundColor:'#FFF', border:'2px solid #cccccc', borderRadius:'11px', padding:'50px 0px'}}>
                  <div style={{width:'100%'}}>
                    <h1 className={classes.title} style={{marginTop:'0px', fontFamily: 'Poppins Regular', color:'#131316', fontSize:'1.4125rem', lineHeight:'38px'}}>Plan <font style={{color:'#036adb'}}>Pro</font></h1>
                  </div>
                  <div style={{width:'100%'}}>
                    <h1 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins SemiBold', color:'#036adb'}}>{precioPlanPro}</h1>
                  </div>
                  <div style={{width:'100%'}}>
                    <h4 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins Medium', color:'#7e7e84', fontWeight:'50', fontSize:'15px'}}>mxn/mes por usuario</h4>
                  </div>
                  <div style={{width:'100%'}}>
                    <h4 className={classes.title} style={{margin:'0px', fontFamily: 'Poppins Medium', color:'#131316', fontWeight:'50', fontSize:'18px'}}>Activación anual</h4>
                  </div>
                  <div style={{width:'100%', borderBottom: '2px solid #cccccc', paddingBottom:'20px'}}>
                    <h1 className={classes.title} style={{margin:'0px', fontSize:'1.8125rem', fontFamily: 'Poppins SemiBold', color:'#036adb'}}>$9,999</h1>
                  </div>
                  <div style={{width:'100%', padding:'30px', borderBottom: '2px solid #cccccc'}}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Gestión de usuarios y cuotas." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Registro de pagos." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Estados de cuenta automáticos." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Notificaciones." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Botón SOS." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Pago en línea." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Reportes de mantenimiento." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Módulo de encuenstas y votaciones." />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListBlue} primary="Accesos a visitas con códigos QR*" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#22b573'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGreen} primary="Módulo de facturación automática**" />
                      </ListItem>
                    </List>
                  </div>
                  <div style={{width:'100%', padding:'30px'}}>
                    <List component="nav" aria-label="main mailbox folders">
                      <ListItem>
                        <ListItemIcon>
                          <CheckIcon style={{color:'#036adb'}}/>
                        </ListItemIcon>
                        <ListItemText className={classes.textList} primary="Esenciales" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Soporte en linea 24/7.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Personalización de dashboard web.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Actualizaciones de sistema.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Certificados de seguridad.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Respaldo de información por dos años.</font>
                        } />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText className={classes.textListGray} primary={
                          <font style={{fontSize:'15px'}}>Acceso a herramientas vía app y web.</font>
                        } />
                      </ListItem>
                    </List>
                  </div>
                  <div style={{width:'100%', padding:'30px', paddingBottom:'0px'}}>
                    <Button
                      color="blueUpera"
                      size="lg"
                      fullWidth={true}
                      onClick={()=>changePage("uperaContacto")}
                    >
                      Empezar ahora
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer style={{marginTop:'30px'}}>
              <GridItem xs={12} sm={12} md={12}>
                <h4 className={classes.title} style={{fontFamily: 'Poppins Medium', color:'#7e7e84', fontSize:'10px', margin:'0px'}}>*El módulo de acceso a visitas debe conectarse a hardware no incluido en upera. Para poder integrar upera se debe contar con un sistema de accesos abierto. <br></br> **El de facturación automática brinda servicio de envío y cancelación de facturas. Para poder integrar upera se debe contar con un sistema de facturación abierto. Upera no incluye el servicio de timbrado. <br></br> <br></br> Aplican <font style={{color:'#055fc3'}}>términos y condiciones.</font></h4>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

HomePlanes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomePlanes);
